import {
  TimeZone,
  TimeSeriesData,
  ITimeSeriesDataPoint,
  LiveDataPoint,
} from 'types'
import { format, parseISO } from 'date-fns'

export const dateFormatter = new Intl.DateTimeFormat('en-UK', {
  month: 'short',
  hour: '2-digit',
  minute: '2-digit',
  second: '2-digit',
  day: '2-digit',
  timeZone: 'Etc/UTC',
  year: '2-digit',
})

export const shortDateFormatter = new Intl.DateTimeFormat('en-UK', {
  year: '2-digit',
  month: 'numeric',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
  second: 'numeric',
  hour12: false,
  timeZone: 'Etc/UTC',
})

export const changeTimeZone = (
  date: Date,
  timeZone: TimeZone = TimeZone.UTC,
) => {
  try {
    return timeZone === TimeZone.UTC
      ? new Date(dateFormatter.format(date))
      : new Date(new Date(date).toISOString())
  } catch (e) {
    console.log('🚀 ~ changeTimeZone ~ e:', e, date, timeZone)
    return date
  }
}

export const convertDateToUTCOrLocal = (
  date: number | Date | string,
  local = false,
): Date => {
  const convertedDate = local
    ? new Date(date)
    : changeTimeZone(new Date(date), TimeZone.UTC)
  return convertedDate
}

export const isoStringToDateString = (iso: string, includeTime: boolean) =>
  iso
    ? format(
        new Date(iso),
        includeTime ? 'ddLLLyy HH:mm:ss' : 'ddLLLyy',
      ).toUpperCase()
    : ''

export const underlyingOrIsoToReadableFormat = (
  exp: string,
  includeTime: boolean = false,
) => {
  if (!exp) return ''
  try {
    parseISO(exp)
    return isoStringToDateString(exp, includeTime)
  } catch (e) {
    // Date alread in 01JAN24 format
    return exp?.replace('SYN.', '')
  }
}
