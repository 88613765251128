import {
  Chart,
  ChartArea,
  ChartSeries,
  ChartSeriesItem,
  ChartSeriesLabels,
  ChartTooltip,
  ChartXAxis,
  ChartXAxisItem,
  ChartYAxis,
  ChartYAxisItem,
} from '@progress/kendo-react-charts'
import { ReactNode } from 'react'
import { FutureHeatMapDataPoint } from 'types'

import { formatNumber } from '@telerik/kendo-intl'
import 'hammerjs'
import { useTheme } from 'styled-components'
import { ChartNoSeriesData } from '../ChartLoader'
import WaterMark from '../Watermark'

interface IHeatMapChartProps {
  data: Array<FutureHeatMapDataPoint>
  color: (dataItem: FutureHeatMapDataPoint) => string
  onCellClick: (dataItem: FutureHeatMapDataPoint) => void
  tooltipRenderer?: (props: any) => ReactNode
  isOneMinData?: boolean
}
const FutureHeatMapChart: React.FC<IHeatMapChartProps> = (
  props: IHeatMapChartProps,
) => {
  const { data, color, onCellClick, tooltipRenderer, isOneMinData } = props
  const theme = useTheme()
  return (
    <>
      {!data.length ? (
        <ChartNoSeriesData />
      ) : (
        <WaterMark heightAsPercentage="10%">
          <Chart
            renderAs="svg"
            transitions={false}
            style={{
              cursor: isOneMinData ? 'not-allowed' : 'pointer',
              font: '0.7em "OpenSans"',
              height: '100%',
              touchAction: 'auto',
            }}
            onSeriesClick={
              isOneMinData
                ? () => {}
                : (e) => {
                    onCellClick(e.dataItem)
                  }
            }
          >
            <ChartArea opacity={0} />
            <ChartTooltip
              render={tooltipRenderer}
              color="white"
              background={theme.palette.common.blue4}
            />
            <ChartSeries>
              <ChartSeriesItem
                type="heatmap"
                xField="xInstrument"
                yField="yInstrument"
                field="value"
                data={data}
                color={(e) => color(e.dataItem)}
              >
                <ChartSeriesLabels
                  content={({ value }) => formatNumber(value, 'n1')}
                  font="0.85em 'OpenSans'"
                  margin={10}
                  padding={10}
                  position="left"
                />
              </ChartSeriesItem>
            </ChartSeries>
            <ChartYAxis>
              <ChartYAxisItem
                categories={data.map((y) => y.xInstrument)}
                labels={{
                  mirror: false,
                  font: "0.7em 'OpenSans'",
                  margin: 0,
                  padding: 0,
                }}
              />
            </ChartYAxis>
            <ChartXAxis>
              <ChartXAxisItem
                categories={data.map((y) => y.xInstrument)}
                labels={{
                  content: ({ value }) => value,
                  mirror: false,
                  font: "0.7em 'OpenSans'",
                  rotation: 'auto',
                }}
              />
            </ChartXAxis>
          </Chart>
        </WaterMark>
      )}
    </>
  )
}

export default FutureHeatMapChart
