import { InfoCircledIcon } from '@radix-ui/react-icons'

import Tooltip from 'components/atoms/tooltip'
import useLocalStorage from 'hooks/useLocalStorage'
import styled from 'styled-components'

interface InfoTooltipProps {
  title: string
}

const Svg = styled(InfoCircledIcon)<{ pulse?: 1 | 0 }>`
  path {
    ${({ pulse, theme }) =>
      pulse
        ? `
animation: pulse 4s infinite;

@keyframes pulse {
  0% {
    opacity; 0.2;
    background-color: inherit;
    transform: scale(1.02)
  }

  50% {
    opacity; 0.4;
    background-color: ${theme.palette.primary.dark};
    fill: ${theme.palette.primary.main};
    transform: scale(0.97)
  }

  100% {
    background-color: inherit;
    transform: scale(1.02)
  }
}
`
        : ''}
  }
`
const InfoTooltip = ({ title }: InfoTooltipProps): JSX.Element => {
  const [usedTooltip, setUseTooltip] = useLocalStorage(
    title.split(' ').join('-'),
  )
  return (
    <Tooltip onOpen={() => setUseTooltip(true)} content={<span>{title}</span>}>
      <Svg
        style={{
          width: '14px',
          height: '14px',
          alignSelf: 'flex-end',
          margin: '0 0.2rem',
        }}
        pulse={usedTooltip ? 0 : 1}
      />
    </Tooltip>
  )
}

export default InfoTooltip
