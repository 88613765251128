import { API_ROOT_URL } from 'consts'
import { Frequency } from 'types'
import { fetchWithAuth } from './fetch'

export type EndpointDefinitionRequiringZipping = {
  path: string
  itemsRequireZipping: true
  singularTimestamp?: boolean
}

export type EndpointDefinitionNotRequiringZipping = {
  path: string
  itemsRequireZipping: false
}

export type EndpointDefinition =
  | EndpointDefinitionRequiringZipping
  | EndpointDefinitionNotRequiringZipping

export type MultiSeriesResult<T> = {
  successes: Array<T>
  errors?: Array<Error>
}

export type UnzippedItem<T> = {
  [Property in keyof T]: Array<T[Property]>
}

const zipItem = <T>(
  unzippedItem: UnzippedItem<T>,
  singularTimestamp: boolean,
): Array<T> => {
  const keys = Object.keys(unzippedItem) as Array<keyof T>
  if (keys.length === 0) {
    throw new Error('Response contains empty item')
  }
  const itemCount = unzippedItem[keys[0]].length
  const zippedItem = Array.from(Array(itemCount)).map((_, i) => {
    return keys.reduce((acc, cur) => {
      return {
        ...acc,
        [cur]:
          cur === 'timestamp' && singularTimestamp
            ? unzippedItem[cur][0]
            : unzippedItem[cur][i],
      }
    }, {} as T)
  })
  return zippedItem
}

export type BSUnzippedItemApiResponse<T> = {
  data: {
    count: number
    items: Array<UnzippedItem<T>>
    timestamp?: number
  }
  error?: {
    code: number
    message: string
  }
}

export type BSApiResponse<T> = {
  data: {
    count: number
    items: Array<T>
    timestamp?: number
  }
  error?: {
    code: number
    message: string
  }
}

export type BaseModelParametersQueryParams = {
  model: string
  exchange: string
  currency: string
  frequency: Frequency
}

export type ModelParametersSpecifiedDatetimeParams =
  BaseModelParametersQueryParams & {
    date: string
  }

export type ModelParametersSpecifiedTimestampParams =
  BaseModelParametersQueryParams & {
    timestamp: string
  }

export type ModelParametersDatetimeRangeParams =
  BaseModelParametersQueryParams & {
    startTime: string
    endTime: string
    tenor: string
  }

export type ModelParametersDatetimeRangeWithFieldParams =
  ModelParametersDatetimeRangeParams & {
    field: string
  }

export type ModelParametersQueryParams =
  | ModelParametersSpecifiedDatetimeParams
  | ModelParametersDatetimeRangeParams
  | ModelParametersDatetimeRangeWithFieldParams
  | ModelParametersSpecifiedTimestampParams

export type smileItemRes = {
  0.01: number[]
  0.1: number[]
  0.2: number[]
  0.4: number[]
  0.05: number[]
  0.5: number[]
  0.25: number[]
  '1delta': number[]
  '5delta': number[]
  '10delta': number[]
  '20delta': number[]
  '25delta': number[]
  '40delta': number[]
  '50delta': number[]
  '-0.01': number[]
  '-0.1': number[]
  '-0.2': number[]
  '-0.4': number[]
  '-0.05': number[]
  '-0.5': number[]
  '-0.25': number[]
  '-1delta': number[]
  '-5delta': number[]
  '-10delta': number[]
  '-20delta': number[]
  '-25delta': number[]
  '-40delta': number[]
  '-50delta': number[]
  atm: number[]
  from_lookback: boolean[]
  tenor_days: number[]
  timestamp: number[]
}

const fetchUnzippedItems = async <T>(
  endpoint: EndpointDefinitionRequiringZipping,
  params: URLSearchParams,
  singularTimestamp: boolean,
  typeguard?: (x) => x is T,
  signal?: AbortSignal,
): Promise<[T[][], number | undefined]> => {
  const queryString = params.toString()
  const url = `${API_ROOT_URL}${endpoint.path}?${queryString}`
  const { data: parsedResponse } = await fetchWithAuth<
    BSUnzippedItemApiResponse<T>
  >({
    url,
    options: { mode: 'cors', signal },
  })
  const items = parsedResponse?.items
  if (!items) {
    throw new Error('No results for query')
  }
  const result = items.map((item) => zipItem<T>(item, singularTimestamp))
  if (typeguard && !result.every((x) => x.every(typeguard))) {
    throw new Error('Response does not match expected structure')
  }
  return [
    result,
    parsedResponse?.timestamp ? parsedResponse?.timestamp / 1000000 : undefined,
  ]
}

const fetchItems = async <T>(
  endpoint: EndpointDefinitionNotRequiringZipping,
  params: URLSearchParams,
  typeguard?: (x) => x is T,
  signal?: AbortSignal,
): Promise<[T[], number | undefined]> => {
  const queryString = params.toString()
  const url = `${API_ROOT_URL}${endpoint.path}?${queryString}`
  const { data: parsedResponse } = await fetchWithAuth<BSApiResponse<T>>({
    url,
    options: { mode: 'cors', signal },
  })
  const items = parsedResponse?.items
  if (!items) {
    throw new Error('No results for query')
  }
  if (typeguard && !items.every(typeguard)) {
    throw new Error('Response does not match expected structure')
  }
  return [
    items,
    parsedResponse?.timestamp ? parsedResponse?.timestamp / 1000000 : undefined,
  ]
}
export async function getBSData<
  T,
  U extends EndpointDefinitionRequiringZipping,
>(
  endpoint: U,
  params: URLSearchParams,
  typeguard?: (x) => x is T,
  signal?: AbortSignal,
): Promise<[T[][], number | undefined]>
export async function getBSData<
  T,
  U extends EndpointDefinitionNotRequiringZipping,
>(
  endpoint: U,
  params: URLSearchParams,
  typeguard?: (x) => x is T,
  signal?: AbortSignal,
): Promise<[T[], number | undefined]>
export async function getBSData<T, U extends EndpointDefinition>(
  endpoint: U,
  params: URLSearchParams,
  typeguard?: (x) => x is T,
  signal?: AbortSignal,
) {
  if (endpoint.itemsRequireZipping) {
    return fetchUnzippedItems(
      endpoint,
      params,
      !!endpoint.singularTimestamp,
      typeguard,
      signal,
    )
  }
  return fetchItems(endpoint, params, typeguard, signal)
}
