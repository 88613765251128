import { DashboardChart } from 'types/dashboard'

const DEFAULT_CARD_SIZE = { width: 4, height: 16 }
export const getLayoutForCard = ({
  card,
  isStatic,
  isDraggable,
}: {
  card: Pick<DashboardChart, 'layout' | 'id'>
  isStatic: boolean
  isDraggable?: boolean
}) => {
  const { width, height, x, y } = card.layout
  const initialSize = DEFAULT_CARD_SIZE

  return {
    i: String(card.id),
    x: x || 0,
    y: y || 0,
    w: width || initialSize.width,
    h: height || initialSize.height,
    minH: Math.min(5, height ? height / 3 : initialSize.height / 3),
    minW: Math.min(2, width ? width / 2 : initialSize.width / 2),
    static: isStatic,
    isDraggable,
  }
}
