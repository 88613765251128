import * as React from 'react'
import { Dropdown } from 'components/atoms'
import { Currency } from 'types'
import { classNames } from '@progress/kendo-react-common'
import { useMemo } from 'react'

export interface ChangeCurrencyProps {
  selected?: Currency
  handleSelection: (currency: Currency) => void
  className?: string
  labelClassName?: string
  supportedCurrencies?: Array<Currency>
}

const DEFAULT_OPTIONS = Object.values(Currency)

export const ChangeCurrency = ({
  selected,
  handleSelection,
  className,
  labelClassName,
  supportedCurrencies,
}: ChangeCurrencyProps): React.ReactElement => {
  const options = useMemo(() => {
    const items = supportedCurrencies?.length
      ? supportedCurrencies
      : DEFAULT_OPTIONS
    return items.map((item) => ({
      name: item,
      value: item,
    }))
  }, [supportedCurrencies])
  return (
    <Dropdown
      showLabel
      current={options.find(({ value }) => value === selected)}
      options={options}
      onChange={handleSelection}
      className={className}
      labelClassName={classNames('k-mr-2', labelClassName)}
      label="Currency"
    />
  )
}
