import {
  DatePicker,
  DatePickerChangeEvent,
  DateTimePicker,
  DateTimePickerChangeEvent,
} from '@progress/kendo-react-dateinputs'
import {
  DropDownList,
  DropDownListChangeEvent,
} from '@progress/kendo-react-dropdowns'
import { GridCellProps } from '@progress/kendo-react-grid'
import { Loader } from '@progress/kendo-react-indicators'
import {
  NumericTextBox,
  RadioGroup,
  RadioGroupChangeEvent,
} from '@progress/kendo-react-inputs'
import { TrashIcon } from '@radix-ui/react-icons'
import {
  DATETIME_FORMAT,
  DATE_FORMAT,
  NOW_UTC,
  SEVEN_DAYS_AHEAD,
  START_OF_BLOCKSCHOLES_TIME,
  TWO_YEARS_AHEAD,
  getXDaysAhead,
} from 'consts'
import { add, format, isValid, sub } from 'date-fns'
import { OptionsPricerOutput } from 'services'
import { useDefaultStore } from 'stores'
import styled, { css } from 'styled-components'
import { EditableOptionPriceQuery } from 'types/queries'

import { usePermissions } from 'hooks/usePermissions'
import { MODEL_OPTIONS } from './consts'
import { changeTimeZone, convertDateToUTCOrLocal } from 'utils/date-formatter'
import { TimeZone } from 'types'
import CurrencyLogo from 'components/charts/common/CurrencyLogo'

const StyledDropDownList = styled(DropDownList)`
  & .k-input-inner {
    padding: 0.2rem !important;
  }
`

const baseDateCss = css`
  border: 1px solid #44494f;
  & {
    font-size: 1em;
  }
  .k-input-button {
    background-color: inherit;
    border: none;
    color: white;
    padding: 0.2rem 0.5rem;
  }
  .k-input:focus {
    border: none !important;
  }
`
export const StyledDatePicker = styled(DatePicker)`
  ${baseDateCss}
`
const StyledDateTimePicker = styled(DateTimePicker)`
  ${baseDateCss}
`

export const ModelDropdownCell = (props: GridCellProps): React.ReactElement => {
  const { onChange, dataItem, field = '' } = props
  const handleChange = (e: DropDownListChangeEvent) => {
    if (onChange) {
      onChange({
        dataIndex: 0,
        dataItem,
        field,
        syntheticEvent: e.syntheticEvent,
        value: e.target.value.value,
      })
    }
  }
  const dataValue = dataItem[field] === null ? '' : dataItem[field]

  if (dataItem.isLoading) return <Loader size="small" type="pulsing" />
  return (
    <td>
      <StyledDropDownList
        style={{ width: '100%', fontSize: '1em' }}
        onChange={handleChange}
        value={MODEL_OPTIONS.find((c) => c.value === dataValue)}
        data={MODEL_OPTIONS}
        textField="name"
        size="small"
      />
    </td>
  )
}

interface Cell extends GridCellProps {
  data: { label: string; value: string }[]
}

export const RadioOrDropDownCell = (props: Cell): React.ReactElement => {
  const { onChange, dataItem, data, field = '' } = props
  const handleChange = (e: RadioGroupChangeEvent | DropDownListChangeEvent) => {
    if (onChange) {
      onChange({
        dataIndex: 0,
        dataItem,
        field,
        syntheticEvent: e.syntheticEvent,
        value: e.value?.value || e.value,
      })
    }
  }
  const dataValue =
    dataItem[field] === null ? '' : dataItem[field]?.value || dataItem[field]

  return (
    <td>
      {data.length === 2 ? (
        <RadioGroup
          layout="horizontal"
          data={data}
          defaultValue={dataValue}
          onChange={handleChange}
        />
      ) : (
        <StyledDropDownList
          style={{ width: '100%', fontSize: '1em' }}
          onChange={handleChange}
          value={data?.find((c) => c.value === dataValue)}
          data={data}
          textField="label"
          size="small"
        />
      )}
    </td>
  )
}

type HybridCell = Omit<Cell, 'dataItem'> & {
  dataItem: OptionsPricerOutput['data'] & EditableOptionPriceQuery
}
export const RadioAndDropDownCell = (props: HybridCell): React.ReactElement => {
  const { onChange, dataItem, data, field = '' } = props
  const { hourlyLookBack, hasMinuteData } = usePermissions()
  const min = hourlyLookBack
    ? sub(NOW_UTC(hasMinuteData), { hours: hourlyLookBack })
    : START_OF_BLOCKSCHOLES_TIME

  const handleChange = (
    e: RadioGroupChangeEvent | DateTimePickerChangeEvent,
  ) => {
    if (onChange) {
      let val = e.value
      if (typeof e.value === 'object') {
        const date = format(e.value, 'yyyy-LL-dd')
        const time = format(e.value, 'HH:mm:ss.SSS')
        val = `${date}T${time}Z` // TODO revist this time changing hack when rebuild option pricer
      }

      onChange({
        dataIndex: 0,
        dataItem,
        field,
        syntheticEvent: e.syntheticEvent,
        value: val,
      })
    }
  }
  const dataValue = dataItem[field] === null ? '' : dataItem[field]
  const date = new Date(dataItem[field].slice(0, -1)) // TODO revist this time changing hack when rebuild option pricer, changes to local time for view
  const validDate = isValid(date)
  const now = changeTimeZone(NOW_UTC(hasMinuteData), TimeZone.UTC)

  return (
    <td>
      <RadioGroup
        layout="horizontal"
        data={data}
        defaultValue={
          data.find((d) => d.value === dataValue)?.value || data[1].value
        }
        onChange={handleChange}
      />
      {dataItem[field] !== data[0].value && (
        <StyledDateTimePicker
          value={validDate ? date : undefined}
          onChange={handleChange}
          format={DATETIME_FORMAT}
          min={changeTimeZone(min, TimeZone.UTC)}
          max={now}
          size="small"
        />
      )}
      {dataItem?.pricingTimestampRes &&
        dataItem?.pricingTimestamp === 'LATEST' && (
          <>
            <p
              style={{
                fontSize: '0.75rem',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              {format(
                convertDateToUTCOrLocal(
                  new Date(dataItem.pricingTimestampRes / 1000000),
                ),
                'dd MMM yy HH:mm',
              )}{' '}
              (UTC)
            </p>
          </>
        )}
    </td>
  )
}

export const DateDropdownCell = (props: GridCellProps): React.ReactElement => {
  const { maxDate } = usePermissions()

  const { onChange, dataItem, field = '' } = props
  const handleChange = (e: DatePickerChangeEvent) => {
    if (onChange && e.target.value) {
      onChange({
        dataIndex: 0,
        dataItem,
        field,
        syntheticEvent: e.syntheticEvent,
        value: e.target.value,
      })
    }
  }
  const dataValue = dataItem[field] === null ? '' : dataItem[field]
  const min = getXDaysAhead(1)
  return (
    <td>
      <StyledDatePicker
        onChange={handleChange}
        defaultValue={dataValue}
        format={DATE_FORMAT}
        min={min}
        max={maxDate}
        size="small"
      />
    </td>
  )
}

const CommandCell = styled.td<{ isMobile?: boolean }>`
  width: ${({ isMobile }) => (isMobile ? '100%' : undefined)};
  button {
    background-color: ${({ theme }) => theme.palette.common.blue4};
    width: 24px;
    height: 24px;
    :hover {
      background-color: ${({ theme }) => theme.palette.common.blue6};
    }
  }
`
export const OptionPricerCommandCell = (props): React.ReactElement => {
  const { dataItem, dataIndex, reset, isMobile } = props
  const removeOption = useDefaultStore((state) => state.options.deleteOption)

  return (
    <CommandCell isMobile={isMobile}>
      {isMobile && (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <b>
            {' '}
            {dataItem.model} {dataItem.type}{' '}
            <CurrencyLogo currency={dataItem.ccy} height={12} width={12} />
          </b>

          <span>Strike: ${dataItem.strike}</span>

          {dataItem.expiry && (
            <span>Expiry: {format(new Date(dataItem.expiry), 'dd/MM/yy')}</span>
          )}
          {/* <span>Timestamp: {dataItem.pricingTimestamp}</span> */}
          <span>Quantity: {dataItem.quantity}</span>
          {dataItem['implied vol'] && (
            <span>IV: {dataItem['implied vol'].toFixed(4)}</span>
          )}
        </div>
      )}
      <button
        type="button"
        style={{ fontSize: '0.8rem' }}
        className="k-button k-button-sm k-rounded-sm k-button-solid k-button-solid-base k-grid-cancel-command"
        onClick={() => removeOption(dataIndex)}
      >
        <TrashIcon />
      </button>
      {(dataItem?.userIv || dataItem?.userSpot) && (
        <button
          type="button"
          style={{ fontSize: '0.8rem' }}
          className="k-button k-button-sm k-rounded-sm k-button-solid k-button-solid-base k-grid-save-command"
          onClick={() => reset(dataItem)}
        >
          <span className="k-icon k-i-reload" />
        </button>
      )}
    </CommandCell>
  )
}

export const CustomInputCell = (props): React.ReactElement => {
  const { onChange, dataItem, field = '', update } = props
  const dataValue = dataItem[field] === null ? '' : dataItem[field]
  const handleBlur = (e: any) => {
    if (onChange && e.target.value !== dataValue) {
      onChange({
        dataIndex: 0,
        dataItem,
        field,
        syntheticEvent: e.syntheticEvent,
        value: e.target.value,
      })

      update(e.target.value)
    }
  }

  return (
    <td>
      {dataItem.isLoading && dataItem.isFetching ? (
        <Loader size="small" type="pulsing" />
      ) : (
        <NumericTextBox
          spinners={false}
          defaultValue={dataValue}
          onBlur={handleBlur}
        />
      )}
    </td>
  )
}
