import ToastAtom from 'components/atoms/Toast'

import { ExclamationTriangleIcon } from '@radix-ui/react-icons'
import { useTheme } from 'styled-components'

export const Toast = ({
  title,
  description,
  setOpen,
  isError,
}: {
  title: string
  description: string
  setOpen: (open: boolean) => void
  isError?: boolean
}) => {
  const theme = useTheme()
  return (
    <ToastAtom
      icon={
        <ExclamationTriangleIcon
          style={{
            width: '28px',
            height: '28px',
            color: isError
              ? theme.palette.common.yellow
              : theme.palette.common.yellow,
            paddingRight: '0.5rem',
          }}
        />
      }
      setOpen={setOpen}
      title={title}
      description={description}
      isError={isError}
    />
  )
}
