import { Input, InputChangeEvent } from '@progress/kendo-react-inputs'
import ErrorMessage from 'components/atoms/Typography/ErrorMessage'
import { FocusEventHandler, useState } from 'react'
import styled from 'styled-components'

const Button = styled.button`
  background-color: ${({ theme }) => theme.palette.common.lightBlack};
  padding-bottom: 1rem;
  border: 0;
  border-radius: 0 0.25rem 0.25rem 0;
  .colored-icon {
    color: ${({ theme }) => theme.palette.common.white};
  }
`

const InputPassword = styled(Input)`
  border: 0;
  border-radius: 0;
  padding-bottom: 1rem;
  margin-bottom: 0 !important;
  color: white;
`

type InputType = {
  name: string
  id: string
  handleChange: (event: InputChangeEvent) => void
  handleBlur: FocusEventHandler<HTMLInputElement>
  value: string
  placeholder: string
  showError: boolean | undefined
  error?: string
}
const PasswordInput = ({
  name,
  id,
  handleChange,
  handleBlur,
  value,
  placeholder,
  showError,
  error,
}: InputType): JSX.Element => {
  const [view, setView] = useState(false)
  return (
    <>
      <div
        style={{
          display: 'flex',
          borderRadius: '0.25rem',
          border: '1px solid grey',
          marginBottom: '1rem',
        }}
      >
        <InputPassword
          name={name}
          id={id}
          onChange={handleChange}
          onBlur={handleBlur}
          value={value}
          type={view ? 'text' : 'password'}
          placeholder={placeholder}
        />
        <Button onClick={() => setView((prev) => !prev)}>
          <span
            className={
              view
                ? 'k-icon k-i-lock colored-icon'
                : 'k-icon k-i-unlock colored-icon'
            }
          />
        </Button>
      </div>
      {showError ? <ErrorMessage>{error}</ErrorMessage> : null}
    </>
  )
}

export default PasswordInput
