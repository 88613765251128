import { useAuthenticator } from '@aws-amplify/ui-react'
import { useQuery } from '@tanstack/react-query'
import { usePermissions } from 'hooks/usePermissions'
import { TreeNode } from 'pages/HistoricalAnalyzer/types'
import React, { useEffect, createContext, useReducer } from 'react'
import { constructCatalog } from 'services/constructCatalog'
import { dataService, CatalogItem } from 'services/lambdaDataService'

type Action =
  | {
      type:
        | ActionTypes.SET_ACTIVE_FUTURES
        | ActionTypes.SET_ACTIVE_OPTIONS
        | ActionTypes.SET_EXPIRED_FUTURES
        | ActionTypes.SET_EXPIRED_OPTIONS
      data: CatalogItem[]
    }
  | { type: ActionTypes.SET_TREE_NODES; data: TreeNode[] }

export enum ActionTypes {
  SET_ACTIVE_FUTURES,
  SET_ACTIVE_OPTIONS,
  SET_EXPIRED_OPTIONS,
  SET_EXPIRED_FUTURES,
  SET_TREE_NODES,
  SET_ACTIVE_PERPS,
}
type State = {
  activeFutures: CatalogItem[]
  activeOptions: CatalogItem[]
  expiredFutures: CatalogItem[]
  expiredOptions: CatalogItem[]
  tree: TreeNode[]
}

const catalogReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ActionTypes.SET_ACTIVE_FUTURES: {
      return {
        ...state,
        activeFutures: action.data,
      }
    }
    case ActionTypes.SET_ACTIVE_OPTIONS: {
      return {
        ...state,
        activeOptions: action.data,
      }
    }
    case ActionTypes.SET_EXPIRED_FUTURES: {
      return {
        ...state,
        expiredFutures: action.data,
      }
    }
    case ActionTypes.SET_EXPIRED_OPTIONS: {
      return {
        ...state,
        expiredOptions: action.data,
      }
    }
    case ActionTypes.SET_TREE_NODES: {
      return {
        ...state,
        tree: action.data,
      }
    }
    default: {
      throw Error(`Unknown action`)
    }
  }
}

const initialState = {
  activeFutures: [],
  activeOptions: [],
  expiredFutures: [],
  expiredOptions: [],
  tree: [],
}
export const CatalogContext = createContext<{
  fetching: boolean
  state: State
  dispatch: (action: Action) => void
}>({ fetching: true, state: initialState, dispatch: () => {} })

export const CatalogProvider: React.FC = ({ children }) => {
  const { route } = useAuthenticator((context) => [context.route])
  const { hasValidLicense } = usePermissions()
  const authenticated = route === 'authenticated' && hasValidLicense

  const [state, dispatch] = useReducer(catalogReducer, initialState)
  const { isFetching: isFetchingOptions } = useQuery(
    ['catalog', 'active', 'option'],
    () => dataService.getCatalogData('option'),
    {
      refetchInterval: 1000 * 60 * 60, // every hour
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      retry: 3,
      enabled: authenticated,
      onSuccess: (d) => {
        dispatch({
          type: ActionTypes.SET_ACTIVE_OPTIONS,
          data: d.sort(
            (a, b) =>
              new Date(a.instrument.split('-')[1]).getTime() -
              new Date(b.instrument.split('-')[1]).getTime(),
          ),
        })
      },
    },
  )
  const { isFetching: isFetchingFutures } = useQuery(
    ['catalog', 'active', 'future'],
    () => dataService.getCatalogData('future'),
    {
      refetchInterval: 1000 * 60 * 60, // every hour
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      enabled: authenticated,
      retry: 3,
      onSuccess: (d) =>
        dispatch({ type: ActionTypes.SET_ACTIVE_FUTURES, data: d }),
    },
  )
  useQuery(
    ['catalog', 'expired', 'option'],
    () => dataService.getCatalogData('option', false),
    {
      enabled: state.activeOptions.length > 0,
      refetchInterval: 1000 * 60 * 60, // every hour
      refetchOnMount: true,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      onSuccess: (d) => {
        dispatch({ type: ActionTypes.SET_EXPIRED_OPTIONS, data: d })
      },
    },
  )
  useQuery(
    ['catalog', 'expired', 'future'],
    () => dataService.getCatalogData('future', false),
    {
      enabled: state.activeOptions.length > 0,
      refetchInterval: 1000 * 60 * 60, // every hour
      refetchOnMount: true,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      onSuccess: (d) => {
        dispatch({ type: ActionTypes.SET_EXPIRED_FUTURES, data: d })
      },
    },
  )

  useEffect(() => {
    if (
      state.activeFutures ||
      state.activeOptions ||
      state.expiredOptions ||
      state.expiredFutures
    ) {
      dispatch({
        type: ActionTypes.SET_TREE_NODES,
        data: constructCatalog([
          ...state.activeFutures,
          ...state.activeOptions,
          ...state.expiredOptions,
          ...state.expiredFutures,
        ]),
      })
    }
  }, [
    state.activeFutures,
    state.activeOptions,
    state.expiredOptions,
    state.expiredFutures,
  ])

  return (
    <CatalogContext.Provider
      value={{
        fetching: isFetchingFutures && isFetchingOptions,
        state,
        dispatch,
      }}
    >
      {children}
    </CatalogContext.Provider>
  )
}
