import { DateTimePickerChangeEvent } from '@progress/kendo-react-dateinputs'
import { DateTimePicker } from 'components/atoms/DatePickers'
import { DATETIME_FORMAT, START_OF_BLOCKSCHOLES_TIME, NOW_UTC } from 'consts'
import { format, isBefore } from 'date-fns'
import { useDebounce } from 'hooks/useDebounce'
import { Dispatch, SetStateAction } from 'react'
import styled from 'styled-components'
import { DateRange, IChartFrequencyData } from 'types'
import { convertDateToUTCOrLatestHour } from 'utils/convert-date-to-utc'

interface IDateRangeProps {
  timestampRange?: DateRange
  setTimestampRange?: Dispatch<SetStateAction<DateRange>>
  rootStyle?: Record<string, string>
  rowStyle?: Record<string, string>
  frequency?: IChartFrequencyData
}

const StyledLabel = styled.label`
  text-align: left;
`

const StyledWrapper = styled.div`
  display: flex;
  min-width: 275px;
  width: 100%;
  justify-content: flex-end;
`

const StyledRow = styled.div`
  display: flex;
  padding-right: 1rem;
  flex-direction: column;
  justify-content: space-around;
`

const DateRangeSelector: React.FC<IDateRangeProps> = (props) => {
  const { timestampRange, setTimestampRange, rootStyle, rowStyle, frequency } =
    props
  const changeHandler = (e: DateTimePickerChangeEvent) => {
    if (
      !e.value ||
      isBefore(e.value, START_OF_BLOCKSCHOLES_TIME) ||
      !setTimestampRange
    ) {
      return
    }
    if (e.target.name === 'to' && timestampRange) {
      setTimestampRange({ from: timestampRange.from, until: e.value })
    }
    if (e.target.name === 'from' && timestampRange) {
      setTimestampRange({
        until: convertDateToUTCOrLatestHour(timestampRange.until),
        from: e.value,
      })
    }
  }
  const debouncedChangeHandler = useDebounce<DateTimePickerChangeEvent>({
    fn: changeHandler,
  })
  const minuteData = frequency?.unit === 'minutes'
  if (minuteData && timestampRange) {
    return (
      <StyledWrapper style={rootStyle}>
        <StyledRow style={rowStyle}>
          {format(timestampRange?.from, 'E, dd MMM yy HH:mm')} -{' '}
          {format(timestampRange?.until, 'E, dd MMM yy HH:mm')}
        </StyledRow>
      </StyledWrapper>
    )
  }
  return (
    <StyledWrapper style={rootStyle}>
      {timestampRange?.from && (
        <StyledRow style={rowStyle}>
          <StyledLabel htmlFor="from">From</StyledLabel>
          <DateTimePicker
            name="from"
            value={timestampRange.from}
            format={DATETIME_FORMAT}
            min={START_OF_BLOCKSCHOLES_TIME}
            max={NOW_UTC()}
            fillMode="flat"
            onChange={(e: DateTimePickerChangeEvent) =>
              debouncedChangeHandler(e)
            }
          />
        </StyledRow>
      )}
      {timestampRange?.until && (
        <StyledRow style={rowStyle}>
          <StyledLabel htmlFor="to">To</StyledLabel>
          <DateTimePicker
            name="to"
            value={timestampRange.until}
            format={DATETIME_FORMAT}
            min={START_OF_BLOCKSCHOLES_TIME}
            max={NOW_UTC()}
            fillMode="flat"
            onChange={(e: DateTimePickerChangeEvent) =>
              debouncedChangeHandler(e)
            }
          />
        </StyledRow>
      )}
    </StyledWrapper>
  )
}

export default DateRangeSelector
