import { Responsive, WidthProvider } from 'react-grid-layout'

import OptionsImpliedVolatility from 'components/charts/options/OptionsImpliedVolatility'
import OptionsHistoricalModel from 'components/charts/options/OptionsHistoricalModel'
import OptionsSmile from 'components/charts/options/OptionsSmile'
import OptionsHeatMap from 'components/charts/options/OptionsHeatMap'
import OptionsPutCallSkew from 'components/charts/options/OptionsPutCallSkew'
import OptionsVolatilityRatio from 'components/charts/options/OptionsVolatilityRatio'
import OptionsButterfly from 'components/charts/options/OptionsButterfly'

import OptionOpenInterest from 'components/charts/options/OptionsOpenInterest'
import OptionVolume from 'components/charts/options/OptionsVolume'

import ItemContainer from './ItemContainer'

const ResponsiveGridLayout = WidthProvider(Responsive)

const OptionsDashBoard = () => {
  return (
    <>
      <ResponsiveGridLayout
        measureBeforeMount
        isDraggable={false}
        rowHeight={30}
        layouts={{
          lg: [
            { i: 'term-structure', x: 0, y: 0, w: 6, h: 18, static: true },
            { i: 'atm-vol', x: 6, y: 0, w: 10, h: 18, static: true },
            { i: 'smile', x: 0, y: 18, w: 5, h: 20, static: true },
            { i: 'vol-surface', x: 5, y: 18, w: 11, h: 20, static: true },
            { i: 'model-params', x: 0, y: 38, w: 5, h: 18, static: true },
            { i: 'butterfly', x: 5, y: 38, w: 6, h: 18, static: true },
            { i: 'skew', x: 11, y: 38, w: 5, h: 18, static: true },
            { i: 'oi', x: 2, y: 56, w: 6, h: 18, static: true },
            { i: 'vol', x: 8, y: 56, w: 6, h: 18, static: true },
          ],
          md: [
            { i: 'term-structure', x: 0, y: 0, w: 4, h: 16, static: true },
            { i: 'atm-vol', x: 5, y: 0, w: 8, h: 16, static: true },
            { i: 'smile', x: 0, y: 16, w: 5, h: 16, static: true },
            { i: 'vol-surface', x: 6, y: 16, w: 7, h: 16, static: true },
            { i: 'model-params', x: 0, y: 32, w: 6, h: 16, static: true },
            { i: 'butterfly', x: 6, y: 32, w: 6, h: 16, static: true },
            { i: 'skew', x: 0, y: 48, w: 6, h: 16, static: true },
            { i: 'oi', x: 6, y: 48, w: 6, h: 16, static: true },
            { i: 'vol', x: 2, y: 64, w: 8, h: 17, static: true },
          ],
          sm: [
            { i: 'term-structure', x: 0, y: 0, w: 4, h: 14, static: true },
            { i: 'atm-vol', x: 0, y: 14, w: 4, h: 14, static: true },
            { i: 'smile', x: 0, y: 28, w: 4, h: 14, static: true },
            { i: 'vol-surface', x: 0, y: 42, w: 4, h: 14, static: true },
            { i: 'model-params', x: 0, y: 56, w: 4, h: 14, static: true },
            { i: 'butterfly', x: 0, y: 70, w: 4, h: 14, static: true },
            { i: 'skew', x: 0, y: 84, w: 4, h: 14, static: true },
            { i: 'oi', x: 0, y: 98, w: 5, h: 14, static: true },
            { i: 'vol', x: 0, y: 112, w: 5, h: 15, static: true },
          ],
        }}
        breakpoints={{ lg: 2200, md: 1200, sm: 700, xs: 580, xxs: 0 }}
        cols={{ lg: 16, md: 12, sm: 4, xs: 1, xxs: 1 }}
      >
        <ItemContainer key="term-structure">
          <OptionsImpliedVolatility />
        </ItemContainer>
        <ItemContainer key="model-params">
          <OptionsHistoricalModel />
        </ItemContainer>
        <ItemContainer key="atm-vol">
          <OptionsVolatilityRatio />
        </ItemContainer>
        <ItemContainer key="smile">
          <OptionsSmile useLatestDatePicker />
        </ItemContainer>
        <ItemContainer key="vol-surface">
          <OptionsHeatMap />
        </ItemContainer>
        <ItemContainer key="butterfly">
          <OptionsButterfly />
        </ItemContainer>
        <ItemContainer key="skew">
          <OptionsPutCallSkew />
        </ItemContainer>
        <ItemContainer key="oi">
          <OptionOpenInterest />
        </ItemContainer>
        <ItemContainer key="vol">
          <OptionVolume />
        </ItemContainer>
      </ResponsiveGridLayout>
    </>
  )
}

export default OptionsDashBoard
