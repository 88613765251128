import * as Switch from '@radix-ui/react-switch'
import styled from 'styled-components'

const Label = styled.label`
  font-size: 0.8rem;
  line-height: 1;
  color: ${({ theme }) => theme.palette.common.blue12};
`
const SwitchRoot = styled(Switch.Root)`
  width: 36px;
  height: 22px;
  background-color: ${({ theme }) => theme.palette.common.blue7};
  border-radius: 9999px;
  position: relative;
  box-shadow: 0 2px 10px ${({ theme }) => theme.palette.common.blue6};
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  &[data-state='checked'] {
    background-color: ${({ theme }) => theme.palette.common.blue8};
    box-shadow: 0 2px 10px ${({ theme }) => theme.palette.common.blue8};
  }
`

const SwitchThumb = styled(Switch.Thumb)`
  display: block;
  width: 16px;
  height: 16px;
  background-color: white;
  border-radius: 9999px;
  box-shadow: 0 2px 2px ${({ theme }) => theme.palette.common.blue9}
  transition: transform 100ms;
  transform: translateX(2px);
  will-change: transform;

  &[data-state='checked'] {
    transform: translateX(19px);
  }
`
const SwitchAtom = ({
  labelOne,
  labelTwo,
  onChange,
}: {
  labelOne: string
  labelTwo: string
  onChange: (v: boolean) => void
}) => (
  <div style={{ display: 'flex', alignItems: 'center' }}>
    <Label
      onClick={(e) => e.preventDefault()}
      className="Label"
      htmlFor="airplane-mode"
      style={{ paddingRight: 5 }}
    >
      {labelOne}
    </Label>
    <SwitchRoot
      className="SwitchRoot"
      id="airplane-mode"
      onCheckedChange={onChange}
    >
      <SwitchThumb className="SwitchThumb" />
    </SwitchRoot>
    <Label
      className="Label"
      onClick={(e) => e.preventDefault()}
      htmlFor="airplane-mode"
      style={{ paddingLeft: 5 }}
    >
      {labelTwo}
    </Label>
  </div>
)

export default SwitchAtom
