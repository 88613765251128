import {
  API_ROOT_URL,
  MAX_TERM_DAYS,
  SMILE_CATEGORIES,
  TenorDays,
} from 'consts'
import { format, sub } from 'date-fns'
import { Catalog } from 'hooks/useCatalog'
import {
  ConstantTenor,
  ConstantTenorByDays,
  Either,
  Frequency,
  ModelParametersWithDate,
  OptionStyle,
  OptionType,
  Source,
  Ticker,
  TimeSeriesData,
  timeSeriesDataKey,
} from 'types'
import AssetClass from 'types/assetClass'
import {
  DerivedOptionQfn,
  Instrument,
  QuoteAsset,
  QuoteAssetPair,
} from 'types/catalog'
import {
  Model,
  SABRModelMap,
  SABRParam,
  SmileType,
  SVIModelMap,
  SVIParam,
} from 'types/models'
import { AnalyseScenario, PricerDataWithResults } from 'types/queries'
import deepMerge from 'utils/deep-merge'
import { BSApiResponse } from './common'
import { fetchWithAuth } from './fetch'
import {
  HistoricImpliedVolatilityResponseItemSabr,
  HistoricImpliedVolatilityResponseItemSvi,
} from './historicImpliedVolatility'
import { CatalogItem } from './lambdaDataService'
import { FutureTermStructureCacheObj } from './mutations'

export type DataFrequency = '1h' | '1m'
type HistoricTimeseriesDataResponse = {
  data: {
    qualifiedName: string
    count: number
    lastModified: string | null
    items: [{ timestamp: number[]; name: string[]; px: number[] }]
  }
  error: string
}

type OptionExpirysDataModelParamsResponse = {
  data: {
    qualifiedName: string
    count: number
    lastModified: string | null
    items: Array<{
      timestamp: number[]
      expiry_iso: string[]
      atm_vol: number[]
    }>
  }
  error: string
}

export type TenorOrExpiryKey =
  | `${Ticker}-${string}`
  | `${Ticker}.${ConstantTenor}`
  | `${Ticker}.${ConstantTenorByDays}`

type Timings = Either<
  { from: Date | number; until: Date | number },
  { timestamp: Date | 'LATEST' }
>

type OptionsPricerResponse = {
  price: number
  'implied vol': number
  spot: number
  delta: number
  gamma: number
  vega: number
  theta: number
  volga: number
  vanna: number
  pricing_timestamp: number
}

export type OptionsPricerOutput = {
  data: Omit<OptionsPricerResponse, 'pricing_timestamp'> & {
    pricingTimestampRes: number
    isLoading?: boolean
  }
}

export type SmileResponse = {
  data: {
    count: number
    timestamp: number
    items: Array<{
      timestamp: [number]
      atm: [number]
      '-50delta': [number]
      '-40delta': [number]
      '-25delta': [number]
      '-20delta': [number]
      '-10delta': [number]
      '-5delta': [number]
      '-1delta': [number]
      '1delta': [number]
      '5delta': [number]
      '10delta': [number]
      '20delta': [number]
      '25delta': [number]
      '40delta': [number]
      '50delta': [number]
    }>
  }
}
export type ListedSmileResponse = {
  data: {
    count: number
    timestamp: number
    items: Array<{
      timestamp: [number]
      atm: number[]
      tenor_days: number[]
      expiry_str: string[]
      '-50delta': number[]
      '-40delta': number[]
      '-25delta': number[]
      '-20delta': number[]
      '-10delta': number[]
      '-5delta': number[]
      '-1delta': number[]
      '1delta': number[]
      '5delta': number[]
      '10delta': number[]
      '20delta': number[]
      '25delta': number[]
      '40delta': number[]
      '50delta': number[]
    }>
  }
}

type FutureTermStructure = {
  exchange: Source
  currency: Ticker
  frequency: DataFrequency
  date: Date | 'LATEST'
}
type BaseTermStructureRes = {
  future: number
  yield: number
  expiry: number
  timestamp: number
  tenor: string
}

type FutureTermStructureResponse = {
  items: [
    {
      listed_expiry: BaseTermStructureRes[]
      constant_maturity: BaseTermStructureRes[]
    },
  ]
  timestamp: number
}

const optionsPricerEndpoint: string = process.env.REACT_APP_OPTIONS_PRICER || ''
if (!optionsPricerEndpoint) {
  throw new Error(
    'Options pricer endpoint is not defined in environment at REACT_APP_OPTIONS_PRICES',
  )
}

export const splitCurrency = (TenorOrExpiryKey: TenorOrExpiryKey): string[] =>
  TenorOrExpiryKey.includes('.')
    ? TenorOrExpiryKey.split('.')
    : TenorOrExpiryKey.split('-')

export const SETTLEMENT_OVERWRITE: Record<`${Source}-${string}`, QuoteAsset> = {
  [`${Source.BYBIT}-BTCPERP`]: Ticker.BTC,
  [`${Source.DERIBIT}-BTC_USDC-PERPETUAL`]: QuoteAssetPair.USDC,
  [`${Source.DERIBIT}-ETH_USDC-PERPETUAL`]: QuoteAssetPair.USDC,
}

const getListedIndexKey = (
  item: { underlying_index: string[] } | { expiry_iso: string[] },
) => {
  // TODO remove when possible, added as on staging theres only 1 version of data sources
  return 'underlying_index' in item ? 'underlying_index' : 'expiry_str'
}
export const dataService2 = {
  getHistoricTimeseriesData: async ({
    timings,
    signal,
    field,
    key,
  }: {
    timings?: Timings
    signal?: AbortSignal
    field?: string
    key: string
  }): Promise<TimeSeriesData> => {
    const [suffix, type] = key.split('.').reverse()
    const dataKey =
      timeSeriesDataKey[suffix] ||
      timeSeriesDataKey[`${type}.${suffix}`] ||
      suffix

    async function performRequest() {
      let URL = `${API_ROOT_URL}timeseries/getHistoricTimeseriesData?key=${key}`

      if (timings?.timestamp) {
        URL += `&timestamp=${
          timings.timestamp === 'LATEST'
            ? 'LATEST'
            : new Date(timings.timestamp).getTime() * 1000000
        }`
      } else if (timings?.from && timings?.until) {
        URL += `&startTime=${(() => {
          if (typeof timings?.from === 'number') {
            return timings.from * 1000000
          }
          if (timings?.from instanceof Date) {
            return timings?.from?.getTime() * 1000000
          }
          return new Date(timings?.from).getTime() * 1000000
        })()}&endTime=${(() => {
          if (typeof timings?.until === 'number') {
            return timings.until * 1000000
          }
          if (timings?.until instanceof Date) {
            return timings?.until?.getTime() * 1000000
          }
          return new Date(timings?.until).getTime() * 1000000
        })()}`
      }
      if (field) {
        URL += `&fields=${field}`
      }

      const { data: result } =
        await fetchWithAuth<HistoricTimeseriesDataResponse>({
          url: URL,
          customError: (status, reason) =>
            `getHistoricTimeseriesData returned ${status}: ${reason || ''}`,
          options: { mode: 'cors', signal },
        })

      let series
      if (field) {
        return (
          result.items?.[0]?.[field]?.map((item, i) => ({
            x: result.items?.[0].timestamp[i] / 1000000,
            y: item,
          })) || []
        )
      }

      // TEMP solution as timeseries is different
      if (dataKey === 'vol') {
        series = result.items?.[0]?.timestamp?.map((time, i) => ({
          y: +result.items?.[0]?.[dataKey][i] / 100,
          x: +time / 1000000,
        }))
      } else if (typeof dataKey !== 'string') {
        series =
          dataKey?.dataPoints?.map(({ key: k, resKey }) => ({
            key: k,
            dataPoints:
              result.items?.[0]?.[resKey]?.map((item, i) => ({
                x: result.items?.[0].timestamp[i] / 1000000,
                y: item,
              })) || [],
          })) || []
      } else {
        series = result.items?.[0]?.timestamp?.map((time, i) => ({
          y: +result.items?.[0]?.[dataKey][i],
          x: +time / 1000000,
        }))
      }
      if (!series) {
        return []
      }

      return series
    }
    try {
      const results = await performRequest()
      if (
        results[0]?.key &&
        typeof dataKey !== 'string' &&
        'keyByTenor' in dataKey &&
        !dataKey.keyByTenor
      ) {
        return results
      }
      return {
        key,
        dataPoints: results,
        field,
      }
    } catch (e) {
      return { key, dataPoints: [] }
    }
  },
  getHistoricalModelParams: async (
    source: Source,
    currency: Ticker,
    model: Model,
    tenor: ConstantTenor,
    from: Date,
    until: Date,
    param: SVIParam | SABRParam,
    frequency: Frequency,
  ): Promise<TimeSeriesData> => {
    const query = {
      model,
      exchange: source.toLowerCase(),
      currency,
      startTime: (from.getTime() * 1000000).toString(),
      endTime: (until.getTime() * 1000000).toString(),
      tenor: `${TenorDays[tenor]}d`,
      frequency,
    }
    const urlSearchParams = new URLSearchParams(query).toString()

    const URL = `${API_ROOT_URL}modelparams/getModelParameters?${urlSearchParams}`

    const { data: results } = await fetchWithAuth<
      BSApiResponse<
        | HistoricImpliedVolatilityResponseItemSvi
        | HistoricImpliedVolatilityResponseItemSabr
      >
    >({
      url: URL,
      customError: (status, reason) =>
        `getHistoricTimeseriesData returned ${status}: ${reason || ''}`,
      options: { mode: 'cors' },
    })
    if (!results) throw new Error('Couldnt get model params')

    let valParam = SVIModelMap[param]
    if (model === Model.SABR) {
      valParam = SABRModelMap[param]
    }

    const dataPoints = results.items.map((value) => ({
      x: new Date(value.timestamp[0] / 1000000),
      y: value[valParam][0],
    }))

    return {
      key: tenor,
      dataPoints,
    }
  },
  getOptionsPrice: async (
    source: Source,
    ticker: Ticker,
    model: Model,
    strike: number,
    expiry: Date,
    type: OptionType,
    impliedVol: number,
    spot: number,
    quantity: number,
    freq: DataFrequency,
    pricingTimestamp: string,
    isReload: boolean,
  ): Promise<OptionsPricerOutput> => {
    let exchangeParam = source.toLowerCase()
    if (source === Source.BLOCKSCHOLES) {
      exchangeParam = 'v2composite'
    } else if (source === Source.V2LYRA) {
      exchangeParam = 'v2lyra'
    }
    const tickerParam = ticker
    const currentExpiry = format(expiry, 'yyyy-LL-dd') // FIXME handle constant tenors
    const expiry8AM = `${currentExpiry}T08:00:00.000Z` // 99% expires at 8am UTC, more clicks to add expiry date for user
    let optionType = type
    let style: OptionStyle = OptionStyle.VANILLA
    if (optionType.split('.').length >= 2) {
      // TODO redo when changing option pricer table
      style = optionType.split('.')[0] as unknown as OptionStyle
      optionType = optionType.split('.')[1] as OptionType
    }
    let URL = `${API_ROOT_URL}option/pricer?exchange=${exchangeParam}&currency=${tickerParam}&strike=${strike}&expiry=${expiry8AM}&model=${model}&type=${optionType}&style=${style}&pricingTimestamp=${pricingTimestamp}&freq=${freq}&quantity=${quantity}`
    if (!isReload) {
      if (impliedVol) {
        URL += `&iv=${impliedVol}`
      }
      if (spot) {
        URL += `&spot=${spot}`
      }
    }
    const resp = await fetchWithAuth<OptionsPricerResponse>({
      url: URL,
    })
    const { pricing_timestamp, ...rest } = resp
    return { data: { ...rest, pricingTimestampRes: pricing_timestamp } }
  },
  getOptionsImpliedVol: async (
    source: Source,
    currency: Ticker,
    model: Model,
    date: 'LATEST' | Date,
    frequency: DataFrequency,
  ): Promise<[string, Array<ModelParametersWithDate>] | undefined> => {
    const tenors = [
      '7d',
      '14d',
      '30d',
      '60d',
      '90d',
      '120d',
      '180d',
      '270d',
      // '365d',
    ]
    type SVIItem = {
      svi_b: [number]
      svi_a: [number]
      svi_sigma: [number]
      tenor_days: [number]
      expiry: [number]
      svi_rho: [number]
      timestamp: [number]
      svi_m: [number]
    }
    type SABRItem = {
      timestamp: [number]
      expiry: [number]
      sabr_alpha: [number]
      sabr_rho: [number]
      sabr_volvol: [number]
      tenor_days: [number]
    }
    type ModelParamatersResWithTimestamp = {
      data: {
        qualifiedName: null | string
        count: number
        model: Model
        timestamp: 'LATEST' | number
        items: SVIItem[] | SABRItem[]
      }
      error: null
    }

    let exchangeParam = source.toLowerCase()
    if (source === Source.BLOCKSCHOLES) {
      exchangeParam = 'v2composite'
    } else if (source === Source.V2LYRA) {
      exchangeParam = 'v2lyra'
    }
    const { data: modelParamsData, error } = await fetchWithAuth<
      | ModelParamatersResWithTimestamp
      | { data: null; error: { code: number; message: string } }
    >({
      url: `${API_ROOT_URL}v1/model/parameters`,
      options: {
        mode: 'cors',
        method: 'POST',
        body: JSON.stringify({
          exchange: exchangeParam,
          model,
          currency,
          tenors,
          frequency,
          timestamp:
            date === 'LATEST' ? date : new Date(date).getTime() * 1000000,
        }),
        headers: {
          'Content-Type': 'application/json',
        },
      },
    })
    if (error) {
      return undefined
    }

    const queriesForTenorVol = tenors.map(
      (tenor) =>
        `${API_ROOT_URL}timeseries/getHistoricTimeseriesData?key=${exchangeParam}.option.${currency}.${model}.${tenor}.${frequency}.smile&timestamp=${
          date === 'LATEST'
            ? 'LATEST'
            : String(new Date(date).getTime() * 1000000)
        }`,
    )

    const fetchVols = async (url): Promise<number> => {
      const { data } = await fetchWithAuth<{
        data: {
          items: Array<{ atm: Array<number> }>
        }
      }>({
        url,
        options: { mode: 'cors' },
      })
      return data?.items?.[0]?.atm[0]
    }

    const resultsOfVol = await Promise.allSettled(
      queriesForTenorVol.map(fetchVols),
    )

    const resolvedVols = resultsOfVol
      .filter(({ status }) => status === 'fulfilled')
      .map((p) => (p as PromiseFulfilledResult<number>).value)
    let res: ModelParametersWithDate[]
    const populatedItems: SABRItem[] | SVIItem[] = (
      modelParamsData.items as Array<SVIItem | SABRItem>
    ) // TODO: sort out hack to filter
      .filter((i) => Object.keys(i).length > 0) as SABRItem[] | SVIItem[]

    if ('svi_a' in modelParamsData.items[0]) {
      res = populatedItems.map((i, indx) => {
        return {
          expiry: i.expiry[0],
          Alpha: i.svi_a[0],
          Beta: i.svi_b[0],
          Mu: i.svi_m[0],
          Rho: i.svi_rho[0],
          Sigma: i.svi_sigma[0],
          timestamp: new Date(+i.timestamp[0] / 1000000),
          dateOffset: i.tenor_days[0],
          Vol: resolvedVols[indx],
        }
      })
    } else {
      res = populatedItems.map((i, indx: number) => {
        return {
          expiry: i.expiry[0],
          Alpha: i.sabr_alpha[0],
          Rho: i.sabr_rho[0],
          VolVol: i.sabr_volvol[0],
          timestamp: new Date(+i.timestamp[0] / 1000000),
          dateOffset: i.tenor_days[0],
          Vol: resolvedVols[indx],
        }
      })
    }
    return [res?.[0]?.timestamp?.toISOString(), res]
  },
  getOptionSmile: async ({
    timestamp,
    key,
    smileType = SmileType.SMILE,
  }: {
    timestamp: 'LATEST' | Date
    smileType: SmileType
    key: string
  }): Promise<{
    series: [string, Array<number | string[] | number[]>]
    categories: string[]
    timestamp?: string
  }> => {
    async function performFetch() {
      const URL = `${API_ROOT_URL}timeseries/getHistoricTimeseriesData?key=${key}&timestamp=${
        timestamp === 'LATEST'
          ? 'LATEST'
          : new Date(timestamp).getTime() * 1000000
      }`
      const { data } = await fetchWithAuth<SmileResponse | ListedSmileResponse>(
        {
          url: URL,
          options: { mode: 'cors' },
        },
      )

      const series = data?.items?.[0]
      if (!series) {
        return {
          data: [],
        }
      }
      const vals: Array<number | string[] | number[]> = []
      const categories: string[] = []
      let sorted: [string, number[] | string[] | [number]][] = []

      const withoutTimeStamps = Object.entries(series).filter(
        ([k]) =>
          k !== 'tenor_days' &&
          k !== 'timestamp' &&
          k !== 'atm' &&
          k !== 'expiry_str' &&
          k !== 'expiry' &&
          k !== 'intermediate',
      )

      if (smileType === SmileType.SMILE) {
        const sortByObject = SMILE_CATEGORIES.reduce((obj, item, index) => {
          return {
            ...obj,
            [item]: index,
          }
        }, {})
        const selectedDeltas = withoutTimeStamps.filter(([a]) =>
          SMILE_CATEGORIES.includes(a),
        )
        sorted = selectedDeltas.sort(
          ([a], [b]) =>
            sortByObject[a.toLowerCase()] - sortByObject[b.toLowerCase()],
        )
      }
      if (smileType === SmileType.MONEYNESS) {
        const allowedMoneyLevels = [
          10, 20, 30, 40, 50, 60, 70, 80, 90, 95, 100, 105, 110, 120, 130, 140,
          150, 160, 170, 180, 190, 200, 210, 220, 230, 240, 250, 260, 270, 280,
          290, 300,
        ].map((m) => `${m}money`)
        const filtered = withoutTimeStamps.filter(([a]) =>
          allowedMoneyLevels.includes(a),
        )
        sorted = filtered.sort(
          ([a], [b]) =>
            Number(a.toLowerCase().replace('money', '')) -
            Number(b.toLowerCase().replace('money', '')),
        )
      }
      sorted.forEach(([k, v], i) => {
        categories.push(k)
        if (v.length > 1) {
          vals.push(v)
        } else {
          vals.push(v[0] as number)
        }
      })
      if (Array.isArray(vals[0])) {
        categories.push('expiry_str')
        vals.push(
          (series as ListedSmileResponse['data']['items'][0]).expiry_str,
        )
      }

      return {
        timestamp: new Date(data.timestamp / 1000000).toISOString(),
        data: vals,
        categories,
      }
    }

    const results = await performFetch()
    return {
      series: [key, results.data],
      categories: results.categories || [],
      timestamp: results.timestamp,
    }
  },
  getFuturesTermStructure: async ({
    exchange,
    currency,
    date,
    frequency,
  }: FutureTermStructure): Promise<FutureTermStructureCacheObj> => {
    const { data } = await fetchWithAuth<{
      data: FutureTermStructureResponse
    }>({
      url: `${API_ROOT_URL}timeseries/retrieveFutureTermStructure`,
      options: {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          exchange: exchange.toLowerCase(),
          currency,
          frequency,
          timestamp:
            date === 'LATEST' ? date : new Date(date).getTime() * 1000000,
        }),
      },
    })

    const listedExpiries = data.items[0].listed_expiry.map((i) => ({
      yield: +i.yield,
      price: i.future,
      dateOffset: Math.ceil(i.expiry * MAX_TERM_DAYS),
      instrument: i.tenor,
    }))

    const constantMaturities = data.items[0].constant_maturity.map((i) => ({
      yield: +i.yield,
      price: i.future,
      dateOffset: Math.ceil(i.expiry * MAX_TERM_DAYS),
      instrument: i.tenor,
    }))
    const markerTenors = ['1d', '7d', '30d', '90d', '180d', '365d']

    return {
      source: exchange,
      currency,
      timestamp: new Date(data.timestamp / 1000000),
      seriesPoints: [...listedExpiries, ...constantMaturities].sort(
        (a, b) => a.dateOffset - b.dateOffset,
      ),
      markers: [
        ...listedExpiries,
        ...constantMaturities.filter(
          (d) => !!markerTenors.find((m) => d.instrument === m),
        ),
      ],
    }
  },
  getOptionsScenarioAnalysis: async ({
    options,
    analyse,
    exchange,
    currency,
    freq,
  }: {
    options: PricerDataWithResults[]
    analyse: AnalyseScenario
    exchange: Source
    currency: Ticker
    freq: DataFrequency
  }) => {
    type ScenarioAnalysisRes = {
      price: number[]
      vol: number[]
      spot: number[]
      delta: number[]
      gamma: number[]
      vega: number[]
      theta: number[]
      volga: number[]
      vanna: number[]
    }

    type ScenarioOption = {
      exchange: string
      model: Model
      currency: Ticker
      strike?: number
      expiry?: Date
      quantity: number
      type: OptionType
      freq: DataFrequency
      pricing_timestamp?: string
      iv: number
      spot: number
      style: OptionStyle
    }
    const scenarioOptions = options.reduce((acc, o) => {
      if (o.ccy !== currency) return acc
      let type = o.type
      let style = OptionStyle.VANILLA
      if (o.type.split('.').length > 1) {
        // TODO Redesign when kendo table removed
        type = o.type.split('.')[1] as OptionType
        style = o.type.split('.')[0] as unknown as OptionStyle
      }
      let exchangeParam = exchange.toLowerCase()
      if (exchange === Source.BLOCKSCHOLES) {
        exchangeParam = 'v2composite'
      } else if (exchange === Source.V2LYRA) {
        exchangeParam = 'v2lyra'
      }
      acc.push({
        exchange: exchangeParam,
        model: o.model,
        currency: o.ccy,
        strike: o.strike,
        expiry: o.expiry,
        type,
        style,
        freq,
        quantity: o.quantity,
        iv: o.userIv || (o['implied vol'] as number),
        spot: o.userSpot || (o.spot as number),
        pricing_timestamp: o.pricingTimestampRes
          ? new Date(o.pricingTimestampRes / 1000000).toISOString()
          : undefined,
      })
      return acc
    }, [] as ScenarioOption[])
    const data = await fetchWithAuth<ScenarioAnalysisRes>({
      url: `${API_ROOT_URL}option/analysis`,
      options: {
        mode: 'cors',
        method: 'POST',
        body: JSON.stringify({
          options: scenarioOptions,
          analyse,
        }),
        headers: {
          'Content-Type': 'application/json',
        },
      },
    })

    return data
  },
  getCatalogData: async <T extends boolean>({
    asset,
    source,
    active = true,
    generateCurrencyPairs,
    listInstruments,
  }: {
    asset: AssetClass
    source: Source
    active: boolean
    generateCurrencyPairs?: T
    listInstruments?: boolean
  }): Promise<Catalog> => {
    type CatalogResponse = {
      data: {
        count: number
        items: Array<{
          active: Array<boolean>
          availableSince: Array<string>
          baseAsset: Array<Ticker>
          expiry: Array<string>
          index: Array<string>
          instrument: Array<Instrument>
          listing: Array<string>
          quoteAsset: Array<QuoteAsset>
          type?: Array<string>
          strike?: Array<number>
          settlementAsset?: Array<QuoteAsset>
        }>
      }
      error?: string
    }
    async function performRequest(
      currency: string,
      assetType: AssetClass,
      exchange: Source,
    ) {
      const URL = `${API_ROOT_URL}catalog/getData?exchange=${exchange}&currency=${currency}&assetType=${assetType.toLowerCase()}&active=${active.toString()}${
        active === false
          ? `&startTime=${
              sub(new Date(), {
                months: 1,
              }).getTime() * 1000000
            }&endTime=${new Date().getTime() * 1000000}`
          : ``
      }`
      const { data } = await fetchWithAuth<CatalogResponse>({
        url: URL,
        options: {
          mode: 'cors',
        },
      })

      const item = data.items[0]
      let catalogItems = item.instrument?.reduce(
        (
          acc: Partial<
            Record<Ticker, Record<'active' | 'expired', CatalogItem[]>>
          >,
          instrument,
          i,
        ) => {
          if (instrument.includes('-SYN')) return acc
          if (new Date(item.expiry[i]) < new Date('01/01/2020')) return acc
          const settlementAsset =
            item.settlementAsset?.[i] ||
            SETTLEMENT_OVERWRITE[`${exchange}-${instrument}`]
          if (!settlementAsset) return acc // Important to know how settled sometimes comes as null
          const activeLabel =
            new Date(item.expiry[i]) > new Date() ? 'active' : 'expired'
          if (!acc?.[item?.baseAsset[i]]?.[activeLabel]) {
            acc = {
              ...acc,
              [item.baseAsset[i]]: {
                [activeLabel]: [
                  {
                    availableSince: item.availableSince?.[i],
                    baseAsset: item.baseAsset[i],
                    expiry: item.expiry[i],
                    instrument,
                    listing: item.listing?.[i],
                    quoteAsset: item.quoteAsset?.[i],
                    type: item.type?.[i],
                    exchange,
                    strike: item.strike?.[i],
                    settlementAsset,
                  },
                ],
              },
            }
          } else {
            acc[item.baseAsset[i]]?.[activeLabel].push({
              availableSince: item.availableSince?.[i],
              baseAsset: item.baseAsset[i],
              expiry: item.expiry[i],
              instrument,
              listing: item.listing?.[i],
              quoteAsset: item.quoteAsset?.[i],
              type: item.type?.[i],
              exchange,
              strike: item.strike?.[i],
              settlementAsset,
            })
          }
          return acc
        },
        {},
      )

      let extras = {}
      if (listInstruments === true && catalogItems) {
        catalogItems = {
          ...catalogItems,
          [currency]: {
            ...catalogItems[currency],
            instrument: item.instrument
              .filter((i) => i.includes(currency))
              ?.sort(
                (a, b) =>
                  new Date(a.split('-')[1] as unknown as Date).getTime() -
                  new Date(b.split('-')[1] as unknown as Date).getTime(),
              ),
          },
        }
      }

      if (generateCurrencyPairs === true) {
        const pairsSet = new Set(
          item.baseAsset?.reduce<string[]>((acc, base, i) => {
            const settlementAsset =
              item.settlementAsset?.[i] ||
              SETTLEMENT_OVERWRITE[`${exchange}-${item.instrument[i]}`]
            if (!settlementAsset) return acc
            acc.push(`${base}/${item.quoteAsset[i]}`)
            return acc
          }, []),
        )
        extras = { pairs: Array.from(pairsSet) }
        // return {
        //   [source]: { [asset]: catalogItems, pairs: Array.from(pairsSet) },
        // }
      }
      return { [source]: { [asset]: catalogItems, ...extras } }
    }

    const results = (await Promise.all([
      ...['BTC', 'ETH'].map((i) => performRequest(i, asset, source)),
    ])) as Catalog[]

    return deepMerge(...results)
  },
  getActiveListedExpiry: async ({
    timings,
    signal,
    key,
  }: {
    timings?: Timings
    signal?: AbortSignal
    key: DerivedOptionQfn
  }): Promise<Record<string, Record<string, number[]>>> => {
    let parsedKey = key
    if (parsedKey.endsWith('vol.atm')) {
      parsedKey = `${parsedKey
        .split('.')
        .splice(0, 6)
        .join('.')}.smile` as DerivedOptionQfn
    }
    let URL = `${API_ROOT_URL}timeseries/getHistoricTimeseriesData?key=${parsedKey}`

    if (timings?.timestamp) {
      URL += `&timestamp=${
        timings.timestamp === 'LATEST'
          ? 'LATEST'
          : new Date(timings.timestamp).getTime() * 1000000
      }`
    } else if (timings?.from && timings?.until) {
      URL += `&startTime=${(() => {
        if (typeof timings?.from === 'number') {
          return timings.from * 1000000
        }
        if (timings?.from instanceof Date) {
          return timings?.from?.getTime() * 1000000
        }
        return new Date(timings?.from).getTime() * 1000000
      })()}&endTime=${(() => {
        if (typeof timings?.until === 'number') {
          return timings.until * 1000000
        }
        if (timings?.until instanceof Date) {
          return timings?.until?.getTime() * 1000000
        }
        return new Date(timings?.until).getTime() * 1000000
      })()}`
    }

    const { data: result } =
      await fetchWithAuth<OptionExpirysDataModelParamsResponse>({
        url: URL,
        customError: (status, reason) =>
          `getHistoricTimeseriesData returned ${status}: ${reason || ''}`,
        options: { mode: 'cors', signal },
      })

    if (!result.items?.[0]) {
      return {}
    }
    const { items } = result
    const expiryKey = getListedIndexKey(items[items.length - 1])
    const latestContracts = items[items.length - 1]?.[expiryKey]
    let activeContractMap: Record<string, Record<string, number[]>> = {}
    for (let i = 0; i < latestContracts?.length; i++) {
      activeContractMap[latestContracts[i]] = { timestamp: [] }
    }

    const keys = Object.keys(items[items.length - 1]).filter(
      (k) => k !== 'strikescalib' && k !== 'underlying_index',
    )

    for (let i = 0; i < items.length; i++) {
      const expiryKey = getListedIndexKey(items[i])
      activeContractMap =
        items[i]?.[expiryKey]?.reduce((acc, underlying, indx) => {
          if (!acc[underlying]) return acc
          keys.forEach((k) => {
            if (items[i]?.[k]) {
              if (k === 'timestamp') {
                acc[underlying][k].push(items[i].timestamp[0] / 1000000)
              } else if (!acc[underlying]?.[k]) {
                acc[underlying][k] = [items[i][k][indx]]
              } else {
                acc[underlying][k].push(items[i][k][indx])
              }
            }
          })
          return acc
        }, activeContractMap) || activeContractMap
    }

    return activeContractMap
  },
}
