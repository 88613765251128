import { DaysTenor, getXDaysAgo, NOW_UTC } from 'consts'
import { DataFrequency } from 'services'
import { ChartState } from 'stores/types'
import { DateRange, LookBackWindow, PriceType } from 'types'

export const getModelParamLabelFormat = (selectedField?: string) => {
  if (!selectedField) return 'n2'
  if (selectedField === PriceType.PRICE) return 'c0'
  return ['yield', 'vol', 'rho', 'volvol', 'vv'].includes(
    selectedField.toLowerCase(),
  )
    ? 'p2'
    : 'n2'
}

const convertLookbackToRange = (
  r: LookBackWindow,
  freq: DataFrequency,
): DateRange => {
  /* API Does not take lookback range so have to send date range */
  let from: DateRange['from']
  switch (r) {
    case '1Y':
      from = getXDaysAgo(365)
      break
    case '6M':
      from = getXDaysAgo(180)
      break
    case '3M':
      from = getXDaysAgo(90)
      break
    default:
    case '1M':
      from = getXDaysAgo(30)
      break
    case '7d':
      from = getXDaysAgo(7)
      break
    case '1d':
      from = getXDaysAgo(1)
      break
  }
  return {
    from,
    until: NOW_UTC(freq === '1m'),
  }
}

export const getChartTimeStampRange = (
  timings: ChartState['timings'],
  freq: DataFrequency = '1h',
): DateRange => {
  if (timings?.range) {
    if (typeof timings.range !== 'string' && 'from' in timings?.range) {
      return timings.range
    }
    return convertLookbackToRange(timings.range, freq)
  }
  return convertLookbackToRange('1M', freq)
}

export const daysToTenor = (d: `${number}d`) => {
  const days = Number(d.replace('d', ''))
  return DaysTenor[days]
}
