import React, { useState } from 'react'
import { useQueries } from '@tanstack/react-query'
import { max } from 'date-fns'
import { dataService2 as dataService, getRefetchInterval } from 'services'

import ChartWrapper from 'components/common/ChartWrapper'
import { usePermissions } from 'hooks/usePermissions'
import { Chart, Source, Ticker, YAxis } from 'types'
import AssetClass from 'types/assetClass'
import {
  Model,
  SABRParam,
  SABRParamWithVol,
  SVIParam,
  SVIParamWithVol,
} from 'types/models'
import { useDefaultStore } from 'stores'
import { OptionSingleSeries, OptionTermStructure } from 'stores/types'
import { initialOptionTermStructure } from 'stores/slices/default-charts'
import Modal from 'components/atoms/Modal'
import { getModelParamLabelFormat } from 'utils/charts'
import useToasts from 'hooks/useToasts'

import TermStructureChart from '../TermStructureChart'
import ChartLoader from '../ChartLoader'
import { TermStructureSeriesPills } from '../common/series/Selector'
import { getSeriesAxis } from '../common/series/utils'
import { ConstructKey } from '../common/util'
import AddSeries from '../common/series/AddSeries'

const checkSameSeries = (s, model, arr: OptionSingleSeries[]) =>
  !!arr.find(
    (a) =>
      a.currency === s.middle.currency &&
      a.source === s.source &&
      a.timestamp === s.middle.timestamp &&
      a.model.param === model.param &&
      a.model.type === model.type,
  )

const OptionsImpliedVolatility: React.FC = () => {
  const { hasMinuteData } = usePermissions()
  const { openToast, ToastComponent, ToastType } = useToasts()
  const [highlightedSeries, setHighlightedSeries] = useState<
    number | undefined
  >()
  const title = 'Volatility Term Structure'
  const chart =
    useDefaultStore(
      (state) => state.defaultCharts?.[title] as OptionTermStructure,
    ) || initialOptionTermStructure

  const queries = chart.series.map((s) => ({
    queryKey: [
      s.source,
      s.currency,
      'options',
      s.model.type,
      'termStructure',
      s.timestamp,
      hasMinuteData,
    ],
    queryFn: () =>
      dataService.getOptionsImpliedVol(
        s.source,
        s.currency,
        s.model.type,
        s.timestamp,
        hasMinuteData && s.timestamp === 'LATEST' ? '1m' : '1h',
      ),
    refetchInterval: getRefetchInterval(s.timestamp, hasMinuteData),
    refetchOnWindowFocus: s.timestamp === 'LATEST',
    cacheTime: 60 * 1000,
    retry: 1,
  }))

  const results = useQueries({
    queries,
  })
  const isLoading = results.some((result) => result.isLoading)
  const dataMapping = results.reduce(
    (
      acc: {
        data: Record<
          string,
          {
            seriesPoints: { dateOffset: number; val: number }[]
            currency: Ticker
            source: Source
            model: Model
            timestamp: Date
            param: SVIParamWithVol | SABRParamWithVol
            axis: {
              yAxis: YAxis
            }
            color: string
          }
        >

        dates: string[]
      },
      res,
      index,
    ) => {
      const series = chart.series[index]
      if (!res.data || !series) return acc
      const [key, val] = res.data

      acc.data[
        `${series.source}.${series.currency}.${series.model.type}.${series.model.param}.${key}`
      ] = {
        seriesPoints: val.map((o) => ({
          dateOffset: o.dateOffset,
          val: o[series.model.param] as number,
        })),
        timestamp: new Date(key),
        param: series.model.param,
        source: series.source,
        currency: series.currency,
        model: series.model.type,
        axis: {
          yAxis: series.axis?.yAxis || YAxis.LEFT,
        },
        color: series.color,
      }

      acc.dates.push(key)
      return acc
    },
    { data: {}, dates: [] },
  )
  const updateChart = useDefaultStore((state) => state.updateTermStructure)

  const updateSeries = (series: ConstructKey) => {
    if (
      'currency' in series.middle &&
      'param' in series.middle &&
      'model' in series.middle &&
      'timestamp' in series.middle &&
      'type' in series.middle &&
      !series.middle.type &&
      series.middle.timestamp
    ) {
      let model
      if (series.middle.model === Model.SABR) {
        model = {
          type: series.middle.model,
          param: series.middle.param as SABRParam,
        }
      } else {
        model = {
          type: series.middle.model,
          param: series.middle.param as SVIParam,
        }
      }
      const existingSeries = checkSameSeries(series, model, chart.series)
      if (existingSeries) {
        return openToast([{ type: ToastType.EXISTS }])
      }
      const format = getModelParamLabelFormat(model.param)
      const seriesAxis = getSeriesAxis({
        id: title,
        format,
        updateAxis: (a: OptionTermStructure['axis']) =>
          updateChart(title, 'axis', a),
        chartAxis: chart.axis,
        axisToAddTo: series.axis?.yAxis,
      })

      if (seriesAxis === 'NONE') {
        return openToast([{ type: ToastType.AXIS }])
      }

      if (seriesAxis) {
        updateChart(title, 'series', [
          ...chart.series,
          {
            source: series.source,
            timestamp: series.middle.timestamp,
            currency: series.middle.currency,
            model,
            axis: {
              yAxis: seriesAxis,
            },
            color: series.color,
          } as OptionSingleSeries,
        ])
      }
    }
  }
  const enlargedChart = useDefaultStore((state) => state.expandedId)
  const setExpandedChart = useDefaultStore((state) => state.setExpandedChart)
  return (
    <Modal
      open={enlargedChart === title}
      onClose={() => setExpandedChart(null)}
      square
    >
      <ChartWrapper
        id={title}
        updatedStamp={max(dataMapping.dates.map((s) => new Date(s)))}
        title={title}
        timeZone={chart.timeZone}
        type={Chart.TERM_STRUCTURE}
        assetClass={AssetClass.OPTION}
        axis={{ LEFT: 'p2' }}
        addSeries={
          <AddSeries
            color={{ index: dataMapping.dates?.length || 0 }}
            currencies={[Ticker.BTC, Ticker.ETH]}
            assetType={AssetClass.OPTION}
            updateSeries={updateSeries}
            useModelParam
            useLatestDatePicker
            useTenors={false}
            listedExpiry={{ enabled: false }}
          />
        }
        seriesPills={
          <TermStructureSeriesPills
            chartSeries={chart.series}
            dates={dataMapping.dates}
            id={title}
            setHighlightedSeries={setHighlightedSeries}
          />
        }
        chart={
          <>
            {isLoading ? (
              <ChartLoader />
            ) : (
              <TermStructureChart
                dataMapping={dataMapping.data}
                axis={chart.axis}
                xAxisTitle="Tenor"
                highlighted={highlightedSeries}
              />
            )}
            <ToastComponent />
          </>
        }
      />
    </Modal>
  )
}

export default OptionsImpliedVolatility
