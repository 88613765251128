import { create } from 'zustand'
import { immer } from 'zustand/middleware/immer'
import { mutations } from './live'

import createDefaultChartSlice, {
  createNewChartSlice,
} from './slices/default-charts'
import createExpandedChart from './slices/expanded-chart'
import createOptionPricer from './slices/option-pricer'
import createMonitorsSlice from './slices/monitors'

import {
  DefaultChartSlice,
  ExpandedChartSlice,
  LiveState,
  MonitorsSlice,
  OptionPricerSlice,
  NewChartSlice,
  TooltipSlice,
} from './types'
import createTooltipSlice from './slices/tooltip'

export const useDefaultStore = create<
  DefaultChartSlice &
    ExpandedChartSlice &
    OptionPricerSlice &
    MonitorsSlice &
    TooltipSlice
>()(
  immer((...a) => ({
    ...createDefaultChartSlice(...a),
    ...createExpandedChart(...a),
    ...createOptionPricer(...a),
    ...createMonitorsSlice(...a),
    ...createTooltipSlice(...a),
  })),
)

export const useLiveStore = create<LiveState>()(
  immer((...a) => ({
    ...mutations(...a),
  })),
)

export const useChartStore = create<NewChartSlice>()(
  immer((...a) => ({
    ...createNewChartSlice(...a),
  })),
)
