import { debounce, DebouncedFunc } from 'lodash'
import { useEffect, useMemo } from 'react'

export const useDebounce = <T extends unknown>({
  fn,
  time = 400,
  timeCondition,
}: {
  fn: (x: T) => void
  time?: number
  timeCondition?: boolean
}): DebouncedFunc<(x: T) => void> => {
  const debouncedChangeHandler = useMemo(
    () => debounce(fn, timeCondition ? 0 : time),
    [timeCondition, fn, time],
  )
  useEffect(() => {
    return () => {
      debouncedChangeHandler?.cancel()
    }
  }, [debouncedChangeHandler])

  return debouncedChangeHandler
}
