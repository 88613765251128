import { formatNumber } from '@telerik/kendo-intl'
import { format, max } from 'date-fns'
import { usePermissions } from 'hooks/usePermissions'
import { capitalize } from 'lodash'
import { useEffect } from 'react'
import { splitCurrency, TenorOrExpiryKey } from 'services'
import { useDefaultStore, useLiveStore, useChartStore } from 'stores'
import { ChartState } from 'stores/types'
import styled, { useTheme } from 'styled-components'
import { Ticker, TimeZone } from 'types'

import { daysToTenor } from 'utils/charts'
import {
  convertDateToUTCOrLocal,
  underlyingOrIsoToReadableFormat,
} from 'utils/date-formatter'
import CurrencyLogo from './CurrencyLogo'
import { getLiveKey } from './series/live'
import { deconstructKey } from './util'
import { useMediaQuery } from 'hooks/useMediaQuery'

const Table = styled.table`
  td {
    min-width: 1vw;
    padding-right: 0.5vw;
    padding-bottom: 0.3rem;
  }

  .date {
    font-weight: bold;
    text-transform: uppercase;
  }

  .value {
    font-weight: bold;
    font-size: min(0.85rem, 18px);
  }
`
const AppToolTip = () => {
  const { hasMinuteData } = usePermissions()
  const isMobile = useMediaQuery('(max-width: 768px)')

  const chartTooltip = useDefaultStore((s) => s.tooltip.hoveredSeries)
  const resetTooltip = useDefaultStore((s) => s.tooltip.setHoveredSeries)
  const livePoint = useLiveStore((s) =>
    chartTooltip?.isLivePoint && getLiveKey(chartTooltip.series, hasMinuteData)
      ? s.data[getLiveKey(chartTooltip.series, hasMinuteData)]?.p
      : undefined,
  )

  const chart = useChartStore(
    (s) => s.defaultCharts?.[chartTooltip?.chartId || ''],
  ) as ChartState

  const theme = useTheme()
  useEffect(() => {
    const mouseOverCallback = (e) => {
      if (chartTooltip) {
        if (
          Math.abs(chartTooltip.clientX - e.clientX) > 10 ||
          Math.abs(chartTooltip.clientY - e.clientY) > 10
        ) {
          resetTooltip(null)
        }
      }
    }
    const scrollCallback = (e) => {
      e.preventDefault()
      if (chartTooltip) {
        resetTooltip(null)
      }
    }
    document.addEventListener('mousemove', mouseOverCallback)
    document.addEventListener('scroll', scrollCallback)
    return () => {
      document.removeEventListener('mousemove', mouseOverCallback)
      document.removeEventListener('scroll', scrollCallback)
    }
  })
  const dk = deconstructKey(chartTooltip?.series?.replace('SYN.', ''))

  if (!chartTooltip || !dk) {
    return <></>
  }
  const currency = (() => {
    if (dk && 'listed' in dk.middle) {
      return splitCurrency(dk.middle.listed as TenorOrExpiryKey)[0]
    }
    if (dk && 'currency' in dk.middle) {
      return dk?.middle.currency
    }
    if (dk && 'perp' in dk.middle) {
      return splitCurrency(dk.middle.perp as TenorOrExpiryKey)[0]
    }
  })()
  if (!currency) throw new Error('No Currency found for tooltip')
  let label = ''
  if ('tenor' in dk.middle && dk.middle.tenor) {
    label = label.concat(
      `${daysToTenor(dk.middle.tenor) || dk.middle.tenor.replace('d', '')}`,
    )
  } else if ('listed' in dk.middle) {
    label = label.concat(
      `${splitCurrency(dk.middle.listed as TenorOrExpiryKey)[1]}`,
    )
    if (dk.middle.listed.split('-').length > 2) {
      //option contract
      label = label.concat(
        ` ${dk.middle.listed.split('-')[2]} ${dk.middle.listed.split('-')[3]}`,
      )
    }
  }
  if ('expiry' in dk.middle && dk.middle.expiry) {
    label = label.concat(underlyingOrIsoToReadableFormat(dk.middle.expiry))
  }
  if ('model' in dk.middle && dk.middle.model) {
    label = label.concat(` ${dk.middle.model}`)
    if ('param' in dk.middle && dk.middle.param) {
      label = label.concat(` ${dk.middle.param}`)
    }
  }
  if ('type' in dk.middle && dk.middle.type) {
    label = label.concat(` ${dk.suffix.toUpperCase()} `)
  }
  if ('series' in dk.middle && dk.middle.series) {
    label = label.concat(` ${capitalize(dk.middle.series)}`)
  }
  const TOOLTIP_HEIGHT_ADJUSTMENT = isMobile ? 0.5 : 1
  return (
    <div
      style={{
        position: 'fixed',
        top:
          window?.innerWidth - chartTooltip.clientX < 200
            ? `${Math.max(
                chartTooltip.clientY - 120 * TOOLTIP_HEIGHT_ADJUSTMENT,
                0,
              )}px`
            : `${Math.max(
                chartTooltip.clientY - 90 * TOOLTIP_HEIGHT_ADJUSTMENT,
                0,
              )}px`,
        left:
          window?.innerWidth - chartTooltip.clientX < 200
            ? `${chartTooltip.clientX - 100}px`
            : `${chartTooltip.clientX - 20}px`,
        width: 'fit-content',
        height: 'fit-content',
        backgroundColor: theme.palette.common.blue4,
        color: theme.palette.common.blue12,
        zIndex: 1000,
        padding: '0.5rem 0.7rem',
        borderRadius: '6px',
        marginRight: '1rem',
        fontSize: 'min(0.75rem,16px)',
        textTransform: 'capitalize',
      }}
    >
      <Table>
        <tbody>
          <tr>
            <td>
              <CurrencyLogo currency={currency as Ticker} />
              {dk?.source}
            </td>
            <td className="date">
              {format(
                livePoint
                  ? convertDateToUTCOrLocal(
                      max([livePoint.t / 1000000, chartTooltip.category]),
                      chart?.timeZone === TimeZone.LOCAL,
                    )
                  : new Date(chartTooltip.category),
                "d LLL yy' 'HH:mm:ss",
              )}{' '}
              (
              {livePoint
                ? 'LIVE'
                : (chart?.timeZone === TimeZone.UTC
                    ? TimeZone.UTC
                    : Intl.DateTimeFormat().resolvedOptions().timeZone
                  ).split('/')[1]}
              )
            </td>
          </tr>
          <tr>
            <td>{label}</td>
            <td className="value">
              {formatNumber(
                livePoint ? livePoint.v : chartTooltip?.value,
                chartTooltip?.format ??
                  Object.values(chart?.axis)[0].replace('0', '2'),
              )}
            </td>
          </tr>
        </tbody>
      </Table>
    </div>
  )
}

export default AppToolTip
