import React from 'react'

import useCatalog, { Catalog } from 'hooks/useCatalog'
import useDefaultSeries from 'hooks/useDefaultSeries'
import { AssetClassSource, QuoteAssetPair, YAxis } from 'types'
import AssetClass from 'types/assetClass'
import ChartWrapper from '../common/BsLangChartWrapper'

const PerpetualSwapsFundingRate: React.FC = () => {
  const title = 'Perpetual swap funding rate'
  const sources = AssetClassSource[AssetClass.PERP]
  const { data: catalog, isLoading: catalogLoading } = useCatalog({
    assets: [AssetClass.PERP],
    active: [true],
    sources,
    generateCurrencyPairs: true,
  })

  const deribitBTC = catalog?.deribit?.PERPETUAL?.BTC?.active.find(
    (c) => c.quoteAsset === QuoteAssetPair.USD,
  )
  const deribitETH = catalog?.deribit?.PERPETUAL?.ETH?.active.find(
    (c) => c.quoteAsset === QuoteAssetPair.USD,
  )

  const defaults = useDefaultSeries({
    catalogLoading,
    items: [deribitBTC, deribitETH],
    assetClass: AssetClass.PERP,
    path: 'funding.rate',
  })

  if (!catalogLoading && !catalog) {
    return <div>An Error occurred, please refresh.</div>
  }
  return (
    <ChartWrapper
      catalog={catalog as Catalog}
      defaultSeries={defaults}
      id={title}
      title={title}
      axisDefault={{ [YAxis.LEFT]: 'p2' }}
      assetClass={AssetClass.PERP}
      suffix="rate"
      seriesType="funding"
      sources={sources}
    />
  )
}

export default PerpetualSwapsFundingRate
