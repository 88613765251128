import {
  Chart,
  ChartArea,
  ChartLegend,
  ChartSeries,
  ChartSeriesItem,
  ChartSeriesItemTooltip,
  ChartXAxis,
  ChartYAxis,
  ChartTooltip as KendoTooltip,
  ChartYAxisItem,
  ChartXAxisItem,
  TooltipPoint,
  ChartTitle,
} from '@progress/kendo-react-charts'
import { formatNumber } from '@telerik/kendo-intl'
import { ChartNoSeriesData } from 'components/charts/ChartLoader'
import CurrencyLogo from 'components/charts/common/CurrencyLogo'
import WaterMark from 'components/charts/Watermark'
import { capitalize } from 'lodash'
import { useMemo } from 'react'
import { useTheme } from 'styled-components'
import { Ticker } from 'types'
import { AnalyseScenario } from 'types/queries'

const ChartTooltip = ({
  currency,
  point,
  activeLadder,
  current,
  format,
}: {
  currency: Ticker
  point: TooltipPoint
  activeLadder: AnalyseScenario
  current?: boolean
  format: string
}) => {
  const theme = useTheme()
  return (
    <table>
      <tbody>
        {current && (
          <tr style={{ color: theme.palette.common.blue9 }}>CURRENT</tr>
        )}
        <tr>
          <td style={{ textTransform: 'capitalize' }}>
            <CurrencyLogo currency={currency} />
            {point.series.name}
          </td>
          <td>{formatNumber(point.value.y, format)}</td>
        </tr>
        <tr>
          <td>{activeLadder}</td>
          <td>
            {formatNumber(
              point.value.x,
              activeLadder === AnalyseScenario.SPOT ? 'c2' : 'n2',
            )}
          </td>
        </tr>
      </tbody>
    </table>
  )
}
const AnalysisChart = ({
  title,
  data,
  activeLadder,
  ladderData,
  currentVal,
  currentAnalysis,
  currency,
}: {
  title: string
  data: number[] | null
  activeLadder: AnalyseScenario
  ladderData: number[] | null
  currentVal?: number
  currentAnalysis?: number
  currency: Ticker
}) => {
  const theme = useTheme()
  const format = useMemo(() => {
    if (title === 'price') return 'c2'
    if (!data) return 'n2'
    const max = Math.max(...(data || []))
    const min = Math.min(...(data || []))
    const getZeroLength = (x) => -Math.floor(Math.log10(x) + 1)
    const zeroLength = getZeroLength(max - min)
    return max - min > 2
      ? format
      : `n${Math.abs(zeroLength < 0 ? zeroLength - 2 : zeroLength + 3 || 3)}`
  }, [data, title])

  if (!data || data.length === 0 || !ladderData) return <ChartNoSeriesData />
  const objData = data.map((val, i) => ({ y: val, x: ladderData[i] }))
  return (
    <WaterMark>
      <Chart
        renderAs="svg"
        style={{
          width: '100%',
          height: '100%',
          overscrollBehavior: 'contain',
        }}
        zoomable={false}
        transitions={false}
      >
        <ChartTitle
          position="top"
          align="center"
          visible
          text={capitalize(title)}
        />
        <ChartArea opacity={0} />
        <ChartLegend visible={false} />
        <KendoTooltip />
        <ChartYAxis>
          <ChartYAxisItem
            narrowRange
            labels={{
              format,
              font: '0.7em "OpenSans"',
            }}
            crosshair={{
              visible: true,
              tooltip: {
                visible: true,
                format,
                background: theme.palette.common.blue4,
              },
            }}
            title={{ text: title }}
            axisCrossingValue={-Infinity}
          />
        </ChartYAxis>
        <ChartSeries>
          <ChartSeriesItem
            type="scatterLine"
            width={2}
            data={objData}
            xField="x"
            yField="y"
            name={title}
            markers={{ visible: false }}
          >
            <ChartSeriesItemTooltip
              render={({ point }) => (
                <ChartTooltip
                  format={format}
                  point={point}
                  activeLadder={activeLadder}
                  currency={currency}
                />
              )}
              color="white"
              background={theme.palette.common.blue4}
            />
          </ChartSeriesItem>
          <ChartSeriesItem
            type="scatter"
            xField="x"
            yField="y"
            color="blue"
            categoryField="x"
            data={[{ y: currentVal, x: currentAnalysis }]}
            zIndex={100}
            markers={{
              type: 'circle',
              background: theme.palette.common.blue9,
              border: { color: theme.palette.common.blue11 },
              visible: true,
              size: 12,
            }}
          >
            <ChartSeriesItemTooltip
              render={({ point }) => (
                <ChartTooltip
                  format={format}
                  point={point}
                  activeLadder={activeLadder}
                  currency={currency}
                  current
                />
              )}
              color="white"
              background={theme.palette.common.blue4}
            />
          </ChartSeriesItem>
        </ChartSeries>
        <ChartXAxis>
          <ChartXAxisItem
            title={{ text: activeLadder }}
            min={objData[0].x}
            max={objData[objData.length - 1].x}
            crosshair={{
              visible: true,
              tooltip: {
                visible: true,
                format,
                background: theme.palette.common.blue4,
              },
            }}
            labels={{
              rotation: 'auto',
              font: '0.7em "OpenSans"',
              // step: activeLadder === 'spot' ? 40 : 5,
              format: activeLadder === 'spot' ? 'c2' : 'n3',
            }}
          />
        </ChartXAxis>
      </Chart>
    </WaterMark>
  )
}
export default AnalysisChart
