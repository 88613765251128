import Button from 'components/atoms/Button'
import { format, isValid } from 'date-fns'
import { TimeZone } from 'types'
import { changeTimeZone } from 'utils/date-formatter'

const LastUpdated = ({
  onClick,
  timeZone,
  updatedStamp,
  shouldFormat,
}: {
  onClick?: () => void
  timeZone: TimeZone
  updatedStamp: Date
  shouldFormat?: boolean // TO DO - remove when all using bslang chart
}) => {
  if (!isValid(updatedStamp)) return <></>
  const date = shouldFormat
    ? format(changeTimeZone(updatedStamp, timeZone), "d LLL yy' 'HH:mm:ss")
    : format(updatedStamp, "d LLL yy' 'HH:mm:ss")

  return onClick ? (
    <Button type="button" variant="neutral" onClick={onClick}>
      {date} (
      {
        (timeZone === TimeZone.UTC
          ? TimeZone.UTC
          : Intl.DateTimeFormat().resolvedOptions().timeZone
        ).split('/')[1]
      }
      )
    </Button>
  ) : (
    <span>
      {date} (
      {
        (timeZone === TimeZone.UTC
          ? TimeZone.UTC
          : Intl.DateTimeFormat().resolvedOptions().timeZone
        ).split('/')[1]
      }
      )
    </span>
  )
}

export default LastUpdated
