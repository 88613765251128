import { LockClosedIcon } from '@radix-ui/react-icons'
import { useQuery } from '@tanstack/react-query'
import styled, { useTheme } from 'styled-components'
import { H4 } from 'components/atoms/Typography/styles'
import { getDbUser, getStripePortal } from 'services'

const Upgrade = styled.a`
  background-color: ${({ theme }) => theme.palette.common.blue11};
  border-radius: 4px;
  padding: 1rem;
  cursor: pointer;

  &:hover {
    filter: brightness(120%);
    transition: filter 150ms;
  }
`
const LockedFeature = () => {
  const theme = useTheme()
  const { data: dbUser } = useQuery({ ...getDbUser() })

  const { data: stripePortal } = useQuery({
    ...getStripePortal(dbUser?.metadata?.['custom:oauth_sign_up']),
  })

  return (
    <div
      style={{
        margin: 'auto',
        maxWidth: '600px',
        padding: '1rem',
        borderRadius: '4px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        alignItems: 'center',
        backgroundColor: theme.palette.common.blue5,
        boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
        marginTop: '2rem',
      }}
    >
      <LockClosedIcon height={52} width={52} style={{ marginBottom: '1rem' }} />
      <H4 style={{ textAlign: 'center' }}>
        This feature is not part of your subscription
      </H4>
      <br />
      {stripePortal?.url ? (
        <>
          <p style={{ textAlign: 'center', paddingTop: '2rem' }}>
            Interested in becoming a Pro user? Upgrade your subscription now.
          </p>
          <Upgrade
            href={stripePortal.url}
            target="_blank"
            rel="noreferrer noopener"
          >
            Upgrade Membership
          </Upgrade>
        </>
      ) : (
        <p style={{ textAlign: 'center', paddingTop: '2rem' }}>
          Interested in becoming a Pro user? send an email to{' '}
          <a
            style={{ textDecoration: 'underline' }}
            href="mailto:support@blockscholes.com"
          >
            support@blockscholes.com
          </a>{' '}
        </p>
      )}
    </div>
  )
}

export default LockedFeature
