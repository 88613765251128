import { toString } from '@telerik/kendo-intl'
import Page from 'components/atoms/Page'
import { H3 } from 'components/atoms/Typography/styles'
import { useEffect } from 'react'
import { useDefaultStore } from 'stores'
import styled from 'styled-components'

import { useQuery } from '@tanstack/react-query'
import ToggleGroup, { ToggleGroupItem } from 'components/atoms/ToggleGroup'
import ChartLoader, { ChartNoSeriesData } from 'components/charts/ChartLoader'
import CurrencyLogo from 'components/charts/common/CurrencyLogo'
import { format } from 'date-fns'
import { usePermissions } from 'hooks/usePermissions'
import { getOptionsScenarioAnalysis } from 'services'
import { Source, Ticker } from 'types'
import { AnalyseScenario, PricerDataWithResults } from 'types/queries'
import { OptionPriceQueryNumberKey } from '../types'
import { queryIsEnriched } from '../utils'
import AnalysisChart from './AnalysisChart'
import { convertDateToUTCOrLocal } from 'utils/date-formatter'

const Tabs = styled.nav`
  color: white;
  padding-top: 2rem;
  height: 5rem;
  margin-left: 0.5rem;
  button {
    margin: 0.2rem;
    background: #16203a;
    height: 3rem;
    display: inline-flex;
    width: 6rem;
    place-content: center;
    align-items: center;

    &.active {
      border: 1px solid;
      border-bottom: none;
      border-radius: 4px 4px 0 0;
      background-color: ${({ theme }) => theme.palette.common.blue11};
    }
  }
`
const FieldWrapper = styled.div`
  text-transform: uppercase;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  justify-content: space-between;
  background-color: ${({ theme }) => theme.palette.common.blue4};
  border-radius: 4px 4px 0 0;
  padding: 0.8rem 1rem;
  font-size: minmax(0.6rem, 16px);
  .field-key {
    color: white;
    font-weight: bold;
  }
`

const AnalyseContainer = styled.div`
  background-color: ${({ theme }) => theme.palette.common.blue1};
  display: flex;
  align-items: center;
  padding: 1rem;
  border-radius: 12px;
  margin-bottom: 1rem;
  color: ${({ theme }) => theme.palette.common.blue12};
  text-transform: uppercase;

  span {
    margin-right: 1rem;
    font-weight: bold;
  }
`
const Field = styled.div``
const ScenarioAnalysis = ({
  optionData,
  fieldSums,
  exchange,
}: {
  optionData: PricerDataWithResults[]
  fieldSums: Record<Ticker, Record<OptionPriceQueryNumberKey, number>>
  exchange: Source
}) => {
  const { hasMinuteData } = usePermissions()
  const pricesFetched = optionData.filter(queryIsEnriched)
  const currencies = Array.from(new Set(pricesFetched.map((d) => d.ccy)))
  const analyse = useDefaultStore((state) => state.options.analysis.ladder)
  const currency = useDefaultStore((state) => state.options.analysis.currency)
  const fetched = useDefaultStore((state) => state.options.analysis.fetched)
  const setAnalyse = useDefaultStore((state) => state.options.setAnalysisLadder)
  const setFetched = useDefaultStore(
    (state) => state.options.setAnalysisFetched,
  )
  const setCurrency = useDefaultStore(
    (state) => state.options.setAnalysisCurrency,
  )

  useEffect(() => {
    if (pricesFetched.length > 0) {
      setCurrency(pricesFetched[0].ccy)
    }
  }, [pricesFetched.length > 0])

  const { data, isLoading, isFetching, isError, dataUpdatedAt } = useQuery({
    ...getOptionsScenarioAnalysis(
      optionData,
      analyse,
      exchange,
      currency || currencies[0],
      hasMinuteData && optionData.find((o) => o.pricingTimestamp === 'LATEST')
        ? '1m'
        : '1h',
    ),
    onSuccess: setFetched,
  })
  return (
    <>
      <Tabs>
        {currencies.length > 1 &&
          currencies.map((c) => (
            <button
              key={c}
              type="button"
              onClick={() => setCurrency(c)}
              className={(currency || currencies[0]) === c ? 'active' : ''}
            >
              <CurrencyLogo currency={c} />
              {c}
            </button>
          ))}
      </Tabs>
      <Page>
        <div style={{ minHeight: '200px' }}>
          {' '}
          <div
            style={{
              justifyContent: 'space-between',
              display: 'grid',
              gridTemplateColumns: 'repeat(auto-fit, minmax(240px, 1fr))',
            }}
          >
            <H3
              style={{
                alignSelf: 'center',
                padding: '0 0 1rem 0',
              }}
            >
              <CurrencyLogo
                currency={currency || currencies[0]}
                height={24}
                width={24}
                mb={6}
                mr={6}
              />
              Scenario Analysis{' '}
            </H3>
            <p style={{ alignSelf: 'center' }}>
              {new Date(dataUpdatedAt) > new Date('01/01/2000') &&
                `(Last updated: ${format(
                  convertDateToUTCOrLocal(dataUpdatedAt),
                  'dd MMM yy HH:mm',
                )} UTC)`}
            </p>
            <AnalyseContainer>
              <span>Variable: </span>
              <ToggleGroup
                value={analyse}
                onValueChange={(v) => {
                  if (!v) return
                  setAnalyse(v as AnalyseScenario)
                }}
              >
                <ToggleGroupItem width={70} key="spot" value="spot">
                  Spot
                </ToggleGroupItem>
                <ToggleGroupItem width={120} key="vol" value="vol">
                  Implied Vol
                </ToggleGroupItem>
              </ToggleGroup>
            </AnalyseContainer>
          </div>
          {!currency ||
          Object.entries(fieldSums?.[currency] || {}).length === 0 ? (
            <ChartNoSeriesData text="Get started by using the pricer above." />
          ) : (
            <>
              <FieldWrapper>
                {fieldSums &&
                  Object.entries(fieldSums?.[currency] || {})
                    .sort(([k1], [k2]) => k1.localeCompare(k2))
                    .map(([k, v]) => (
                      <Field key={k}>
                        <span className="field-key">{k}: </span>
                        <span className="field-val">
                          {k === 'price' || k === 'spot'
                            ? toString(v, 'c2')
                            : v.toPrecision(3)}
                        </span>
                      </Field>
                    ))}
              </FieldWrapper>
              <div
                style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  justifyContent: 'center',
                }}
              >
                {isLoading || !data || (isFetching && !fetched) || isError ? (
                  isError ? (
                    <p>
                      An Error has occurred, please try again or contact
                      support.
                    </p>
                  ) : (
                    <ChartLoader />
                  )
                ) : (
                  Object.entries(data)
                    ?.filter(
                      ([k]) =>
                        k !== 'spot' && k !== 'vol' && k !== 'implied vol',
                    )
                    ?.sort(([k1], [k2]) => k1.localeCompare(k2))
                    ?.map(([k, arr]) => {
                      return (
                        <div
                          key={k}
                          style={{
                            flex: '0.5 1 500px',
                            height: '350px',
                          }}
                        >
                          <AnalysisChart
                            currentVal={fieldSums?.[currency]?.[k]}
                            currentAnalysis={
                              fieldSums?.[currency]?.[
                                analyse === AnalyseScenario.SPOT
                                  ? AnalyseScenario.SPOT
                                  : 'implied vol'
                              ]
                            }
                            title={k}
                            data={arr}
                            activeLadder={analyse}
                            ladderData={data[analyse]}
                            currency={currency}
                          />
                        </div>
                      )
                    })
                )}
              </div>
            </>
          )}
        </div>
      </Page>
    </>
  )
}
export default ScenarioAnalysis
