export enum BitBnsApiPath {
  USER_DETAILS = 'me',
}

export type BitBnsTokenResponse = {
  [x: string]: any
  status: string
  access_token: {
    token: string
    expiry: number
  }
  refresh_token: {
    token: string
    expiry: number
  }
}
