import React, { useContext, useEffect, useRef, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import Button from 'components/atoms/Button'
import { useTheme } from 'styled-components'
import { IHistoricalAnalyzerDataSource } from './types'
import { Parser } from './parser'
import useCodeMirror from './useCodeMirror'
import DataSearchModal from '../../components/HistoricalAnalizer/DataSearchModal'
import { HistoricalAnalyzerContext } from '../../context/HistoricalAnalyzerContext'

interface ICodeMirrorComponentProps {
  value?: string
  buttonLabel?: string
  buttonStyles?: any
  style?: any
  handleChange: (value: string, parser: Parser) => void
  dataSources: Array<IHistoricalAnalyzerDataSource>
}

const CodeMirrorComponent: React.FC<ICodeMirrorComponentProps> = (props) => {
  const context = useContext(HistoricalAnalyzerContext)
  const [searchParams] = useSearchParams()
  const searchOpen = searchParams.get('searchOpen')
  const showDataSearch = searchOpen === 'true'

  const { value = '', dataSources, handleChange, style } = props
  const [localVal, setLocalVal] = useState(value)

  const {
    containerRef: codeMirrorRef,
    parsed,
    clearInput,
    setInput,
  } = useCodeMirror(localVal, dataSources)

  useEffect(() => {
    if (parsed?.inputString() && parsed?.isValid()) {
      clearInput()
      setLocalVal('')
      handleChange(parsed.inputString(), parsed)
    }
  }, [localVal])

  const theme = useTheme()

  const handleInstrumentSelection = (qn: string) => {
    setLocalVal(qn)
    setInput(qn)
  }

  return (
    <div
      style={{
        ...style,
        width: 400,
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
      }}
    >
      <div
        ref={codeMirrorRef}
        style={{
          color: theme.palette.common.grey1,
          background: 'white',
          display: 'none',
        }}
      />
      <div
        style={{
          display: 'inline-flex',
          width: '100%',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        {showDataSearch && (
          <DataSearchModal onSelect={handleInstrumentSelection} />
        )}
      </div>
    </div>
  )
}

export default CodeMirrorComponent
