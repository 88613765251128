import { useAuthenticator } from '@aws-amplify/ui-react'
import { API_ROOT_URL, integrationMap, TenorDays } from 'consts'
import { isValid } from 'date-fns'
import { queryIsEnriched } from 'pages/OptionPricer/utils'
import { Dispatch, SetStateAction } from 'react'
import { dataService2, DataFrequency } from 'services'
import {
  ConstantTenorByDays,
  OptionType,
  Source,
  Ticker,
  TimeSeriesData,
  Permissions,
  IChartFrequencyData,
  DateRange,
  DerivedOptionQfn,
} from 'types'
import AssetClass from 'types/assetClass'
import { Model, SABRParam, SmileType, SVIParam } from 'types/models'
import { DBUser } from 'types/permissions'
import {
  AnalyseScenario,
  EditableOptionPriceQuery,
  PricerDataWithResults,
} from 'types/queries'
import { fetchWithAuth, spotFetcher, stripePortalFetcher } from './fetch'

export interface QueryObject {
  queryKey: string[]
  fetcher: any
  successKey: string
}

type FetcherReturnType = {
  queryKey: string[]
  fetcher: (
    signal: AbortSignal,
    from: Date,
    until: Date,
  ) => Promise<TimeSeriesData | TimeSeriesData[]>
  successKey: string
  refetchInterval: number | undefined
}
interface DataFetcher {
  key: string
  field?: string
  refetchInterval: number | undefined
  refetchOnWindowFocus?: boolean
}

export const getRefetchInterval = (
  date: Date | string | 'LATEST' | DateRange | undefined,
  minuteData: boolean,
): number => {
  if (typeof date !== 'string') return Infinity
  return minuteData ? 20 * 1000 : 1000 * 60 * 30
}

export const historicTimeseriesDataFetcher = ({
  key,
  field,
  refetchInterval,
  refetchOnWindowFocus,
}: DataFetcher) => {
  let qk = key.split('.')
  if (key.endsWith('realized')) {
    // BIt of a hack for speed
    qk[qk.length - 4] = qk[qk.length - 3].includes('h') ? '7d' : '1h'
  }
  return {
    queryKey: [...qk, field ? field : ''],
    fetcher: (signal, from, until) =>
      dataService2.getHistoricTimeseriesData({
        timings: {
          from,
          until,
        },
        key: qk.join('.'),
        signal,
        field,
      }),
    successKey: qk.join('.'),
    refetchInterval,
    refetchOnWindowFocus,
  }
}
export const getModelParamsFetcher = (
  source: Source,
  asset: AssetClass,
  ticker: Ticker,
  model: Model,
  tenor: ConstantTenorByDays,
  param: SVIParam | SABRParam,
  frequency: IChartFrequencyData,
): FetcherReturnType => {
  const tenorDays = `${TenorDays[tenor]}d`
  return {
    queryKey: [
      source.toLowerCase(),
      asset.toLowerCase(),
      ticker,
      model,
      tenorDays,
      frequency.unit === 'minutes' ? '1m' : '1h',
      'params',
      model === Model.SABR
        ? param.replace('sabr', '').toLowerCase()
        : param.replace('svi', '').toLowerCase(),
    ],
    fetcher: (signal, from, until) =>
      dataService2.getHistoricalModelParams(
        source,
        ticker,
        model,
        tenor,
        from,
        until,
        param,
        frequency.unit === 'minutes' ? '1m' : '1h',
      ),
    successKey: tenor,
    refetchInterval: Infinity,
  }
}

export const getOptionPrice = (
  query: EditableOptionPriceQuery,
  source: Source,
  frequency: DataFrequency,
) => {
  const purchasedStamp = isValid(new Date(query.pricingTimestamp))
    ? query.pricingTimestamp
    : 'LATEST'
  return {
    queryKey: [
      source,
      query.ccy,
      query.model,
      query.strike,
      query.expiry,
      query.type,
      query.userIv,
      query.userSpot,
      query.quantity,
      purchasedStamp,
      frequency,
    ],
    queryFn: () =>
      dataService2.getOptionsPrice(
        source,
        query.ccy as Ticker,
        query.model as Model,
        query.strike as number,
        query.expiry as Date,
        query.type as OptionType,
        query.userIv as number,
        query.userSpot as number,
        query.quantity as number,
        frequency,
        purchasedStamp,
        false,
      ),
    enabled: !!(
      source &&
      query.ccy &&
      query.model &&
      query.strike &&
      query.expiry &&
      query.type &&
      query.pricingTimestamp &&
      query.quantity
    ),
    refetchOnMount: false,
    refetchOnWindowFocus: true,
    refetchInterval: getRefetchInterval(
      query.pricingTimestamp,
      frequency === '1m',
    ),
  }
}

export const getOptionsScenarioAnalysis = (
  options: PricerDataWithResults[],
  analyse: AnalyseScenario,
  exchange: Source,
  currency: Ticker,
  freq: DataFrequency,
) => {
  const enriched = options.filter(queryIsEnriched)
  return {
    queryKey: options.map(
      (o) =>
        `${exchange}.${o.ccy}.${o.model}.${o.type}.${o.pricingTimestampRes}.${o.strike}.${o.expiry}.${o.userIv}.${o.userSpot}.${o.quantity}.${analyse}.${currency}`,
    ),
    queryFn: () =>
      dataService2.getOptionsScenarioAnalysis({
        options,
        analyse,
        exchange,
        currency,
        freq,
      }),
    enabled: enriched.length > 0,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    refetchInterval: Infinity, // Refetch should be derived from the option object being updated
  }
}

export const getPermissionsQuery = ({
  refetchCondition,
  incrementRunCounter,
}: {
  refetchCondition?: boolean
  incrementRunCounter?: Dispatch<SetStateAction<number>>
}) => ({
  queryKey: ['permissions'],
  queryFn: () =>
    fetchWithAuth<Permissions>({
      url: `${API_ROOT_URL}permissions`,
    }),
  refetchInterval: (d?: Permissions) => {
    const shouldPollForExpiryDate =
      !d ||
      (refetchCondition &&
        (!d?.EXPIRY_DATE || new Date(d.EXPIRY_DATE) < new Date()))
    return shouldPollForExpiryDate ? 400 : 1000 * 60 * 60
  },
  onSettled: () => incrementRunCounter && incrementRunCounter((p) => p++),
  retryDelay: (attempt) => attempt * 1000,
  refetchOnWindowFocus: true,
  // refetchOnMount: false,
})

export const getStripeCheckoutUrl = (email?: string, isPro?: boolean) => ({
  // eslint-disable-next-line @tanstack/query/exhaustive-deps
  queryKey: ['stripe', 'checkout-url', isPro ? 'pro' : 'retail'],
  queryFn: () =>
    fetchWithAuth<{ url: string; percent_off?: number }>({
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      url: `${API_ROOT_URL}payments/stripe/${email}/checkout/url?version=${
        isPro ? 'pro' : 'retail'
      }`,
    }),
  enabled: typeof email === 'string',
  staleTime: 120000,
})

export const getOauthUrl = (oauthIntegration?: string, email?: string) => {
  const integration = integrationMap[oauthIntegration || '']
  return {
    queryKey: [oauthIntegration, 'payment', 'url'],
    queryFn: () =>
      fetchWithAuth<{ url: string; percent_off?: number }>({
        url: integration.paymentUrl,
      }),
    retry: 1,
    retryOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    enabled: !!integration && !!email,
    staleTime: 120000,
  }
}

type BitbnsPaymentStatusRes =
  | { http_code: 204 }
  | { status_code: 1; message: 'Payment successful.' }

export const getBitBnsPaymentStatus = (orderId: string | null) => ({
  queryKey: ['bitbns', 'payment', orderId],
  queryFn: async () =>
    fetchWithAuth<{ status_code: number; message: string } | undefined>({
      url: `${API_ROOT_URL}payments/bit-bns/payment-gateway/${
        orderId || ''
      }/status`,
    }),
  retry: 1,
  enabled: !!orderId,
  staleTime: 120000,
  retryOnMount: false,
  refetchOnWindowFocus: false,
  refetchOnReconnect: false,
  refetchInterval: (d?: BitbnsPaymentStatusRes) => {
    let pollStatus: number | false = false
    if (d && 'http_code' in d && d?.http_code === 204) {
      pollStatus = 500
    }
    return pollStatus
  },
})

export const getDbUser = (urlSub?: string | null) => {
  const { user } = useAuthenticator((context) => [context?.user])
  const sub = urlSub || user?.attributes?.sub
  return {
    queryKey: ['user', , sub],
    queryFn: async () =>
      fetchWithAuth<DBUser>({
        url: `${API_ROOT_URL}entity/${sub || ''}`,
      }),
    enabled: !!sub,
  }
}

export const getOptionsSmile = ({
  key,
  timestamp,
  smileType,
  hasMinuteData,
}: {
  key: string
  timestamp: 'LATEST' | Date
  smileType: SmileType
  hasMinuteData: boolean
}) => ({
  queryKey: [
    key,
    timestamp === 'LATEST' ? 'LATEST' : new Date(timestamp).toISOString(),
  ],
  queryFn: () =>
    dataService2.getOptionSmile({
      timestamp,
      key,
      smileType,
    }),
  refetchInterval: getRefetchInterval(timestamp, hasMinuteData),
  refetchOnWindowFocus: timestamp === 'LATEST',
})

export const getSpotPrice = ({
  exchange,
  currency,
}: {
  exchange: Source
  currency: Ticker
}) => ({
  queryKey: [`${exchange}.spot.${currency}USD.tick.index.px`],
  queryFn: () => spotFetcher({ exchange, currency }),
  refetchInterval: Infinity,
  refetchOnMount: false,
  refetchOnWindowFocus: false,
})

export const getStripePortal = (oauthConnection?: string) => ({
  queryKey: ['stripe', 'portal', oauthConnection],
  queryFn: () => stripePortalFetcher(),
  refetchInterval: 1000 * 60 * 15, // 15mins
  refetchOnMount: false,
  refetchOnWindowFocus: false,
  enabled: oauthConnection === '',
})

export const getActiveOptionListedExpiry = ({
  key,
  refetchInterval,
  refetchOnWindowFocus,
}: {
  key: DerivedOptionQfn
  refetchInterval: number | undefined
  refetchOnWindowFocus?: boolean
}) => ({
  queryKey: key.split('.'),
  fetcher: (signal, from, until) =>
    dataService2.getActiveListedExpiry({
      timings: {
        from,
        until,
      },
      key,
      signal,
    }),
  refetchOnWindowFocus: false,
  retry: 3,
  successKey: key,
  refetchInterval,
})
