import { useState } from 'react'

export const localStorageMap = {
  INTEGRATION_SETTING_UP: 'integration-setting-up',
  COOKIE_BANNER_INTERACTION: 'interacted-with-cookie-banner',
  OAUTH_VERIFIER: (integration: string) => `${integration}-code_verifier`,
  INTEGRATION_ACCESS_TOKEN: (integration: string) =>
    `${integration}_access-token`,
  INTEGRATION_REFRESH_TOKEN: (integration: string) =>
    `${integration}_refresh-token`,
  HOME: 'home-dashboard-id',
}
const useLocalStorage = <T,>(
  key,
  initialValue: T | false = false,
): [T, React.Dispatch<React.SetStateAction<T>>] => {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState<T | false>(() => {
    if (typeof window === 'undefined') {
      return initialValue
    }
    try {
      // Get from local storage by key
      const item = window?.localStorage.getItem(key)
      try {
        if (typeof item !== 'object') {
          return item || initialValue
        }
        return item ? JSON.parse(item) : initialValue
      } catch (e) {
        // Catch if can't parse json
        return item || initialValue
      }
    } catch (error) {
      // If error also return initialValue
      console.log(error)
      return initialValue
    }
  })
  const setValue = (value) => {
    try {
      // Save state
      setStoredValue(value)
      // Save to local storage
      if (typeof window !== 'undefined') {
        window?.localStorage.setItem(
          key,
          typeof value === 'string' ? value : JSON.stringify(value),
        )
      }
    } catch (error) {
      console.log(error)
    }
  }
  return [storedValue as T, setValue]
}

export default useLocalStorage
