import Page from 'components/atoms/Page'

import Button from 'components/atoms/Button'
import LockedFeature from 'components/molecules/LockedFeature'
import FrequencyChange from 'components/molecules/FrequencyChange'

import HistoricalAnalyzerTimeSeriesChart from './HistoricalAnalyzerTimeSeriesChart'
import {
  AddFunctionButton,
  AddNewRowButton,
  ButtonGroupMain,
  StyledControlBar,
  UpdateChartButton,
} from './styled'

// This mess has just taken the skeleton from the current design will be addressed when HA is redone
const EmptyState = () => {
  return (
    <Page style={{ position: 'relative' }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          maxWidth: '1000px',
          margin: 'auto',
        }}
      >
        <div
          style={{
            top: '0',
            left: '0',
            width: '80%',
            margin: 'auto',
            height: '100%',
            textAlign: 'center',
            backdropFilter: 'blur(2px)',
            color: 'white',
            zIndex: 10,
          }}
        >
          <LockedFeature />
        </div>
        <input
          size={50}
          style={{
            WebkitAppearance: 'none',
            background: 'none',
            color: 'rgb(228, 231, 235)',
            borderStyle: 'none',
            textAlign: 'center',
            fontSize: '1.2rem',
            fontWeight: 700,
            paddingLeft: '12px',
            paddingRight: '12px',
          }}
          className="histTitleClassTitle"
          placeholder="Click to edit the title"
          value=""
        />
        <HistoricalAnalyzerTimeSeriesChart
          data={[]}
          dateRange={{ from: new Date(), until: new Date() }}
          axis={{
            left: { label: '', format: 'n2' },
            right: { label: '', format: 'n2' },
          }}
          frequency="1h"
        />
        <StyledControlBar
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <div style={{ marginLeft: 'auto' }}>
            <FrequencyChange
              frequency="1h"
              updateFrequency={() => {}}
              disableMinutes
            />
          </div>
          <UpdateChartButton>
            <Button size="sm" type="button" onClick={() => {}}>
              Update chart
            </Button>
          </UpdateChartButton>
          <div className="button-group">
            <ButtonGroupMain>
              <AddNewRowButton>
                <Button size="sm" onClick={() => {}}>
                  Add +
                </Button>
              </AddNewRowButton>
              <AddFunctionButton>
                <Button size="sm" fullHeight onClick={() => {}}>
                  Functions
                </Button>
              </AddFunctionButton>
              <AddFunctionButton>
                <Button size="sm" onClick={() => {}}>
                  Open Data Search
                </Button>
              </AddFunctionButton>
            </ButtonGroupMain>
          </div>
        </StyledControlBar>
      </div>
    </Page>
  )
}

export default EmptyState
