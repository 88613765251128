import { Responsive, WidthProvider } from 'react-grid-layout'
import FutureHistoricalPrice from 'components/charts/futures/FutureHistoricalPrice'
import FutureImpliedYield from 'components/charts/futures/FutureImpliedYield'
import FutureHeatMap from 'components/charts/futures/FuturesHeatMap'
import FutureTermStructure from 'components/charts/futures/FutureTermStructure'
import FutureVolume from 'components/charts/futures/FutureVolume'
import FutureOpenInterest from 'components/charts/futures/FutureOpenInterest'
import ItemContainer from './ItemContainer'

const ResponsiveGridLayout = WidthProvider(Responsive)

const FuturesDashBoard = (p) => {
  return (
    <>
      <ResponsiveGridLayout
        isDraggable={false}
        measureBeforeMount
        rowHeight={30}
        layouts={{
          lg: [
            { i: '1', x: 0, y: 0, w: 8, h: 18, static: true },
            { i: '2', x: 8, y: 0, w: 8, h: 18, static: true },
            { i: '3', x: 0, y: 18, w: 5, h: 18, static: true },
            { i: '4', x: 5, y: 18, w: 6, h: 18, static: true },
            { i: '5', x: 11, y: 18, w: 5, h: 18, static: true },
            { i: '6', x: 5, y: 18, w: 6, h: 18, static: true },
          ],
          md: [
            { i: '1', x: 0, y: 0, w: 6, h: 15, static: true },
            { i: '2', x: 6, y: 0, w: 6, h: 15, static: true },
            { i: '3', x: 0, y: 15, w: 6, h: 15, static: true },
            { i: '4', x: 6, y: 15, w: 6, h: 15, static: true },
            { i: '5', x: 0, y: 30, w: 5, h: 15, static: true },
            { i: '6', x: 5, y: 30, w: 7, h: 15, static: true },
          ],
          sm: [
            { i: '1', x: 0, y: 0, w: 4, h: 14, static: true },
            { i: '2', x: 0, y: 14, w: 4, h: 14, static: true },
            { i: '3', x: 0, y: 28, w: 4, h: 14, static: true },
            { i: '4', x: 0, y: 42, w: 4, h: 14, static: true },
            { i: '5', x: 0, y: 56, w: 4, h: 14, static: true },
            { i: '6', x: 0, y: 70, w: 4, h: 14, static: true },
          ],
        }}
        breakpoints={{ lg: 2200, md: 1300, sm: 968, xs: 580, xxs: 0 }}
        cols={{ lg: 16, md: 12, sm: 4, xs: 1, xxs: 1 }}
      >
        <ItemContainer key="1">
          <FutureHistoricalPrice />
        </ItemContainer>
        <ItemContainer key="2">
          <FutureImpliedYield />
        </ItemContainer>
        <ItemContainer key="3">
          <FutureTermStructure />
        </ItemContainer>
        <ItemContainer key="4">
          <FutureVolume />
        </ItemContainer>
        <ItemContainer key="5">
          <FutureOpenInterest />
        </ItemContainer>
        <ItemContainer key="6">
          <FutureHeatMap />
        </ItemContainer>
      </ResponsiveGridLayout>
    </>
  )
}

export default FuturesDashBoard
