import { useState } from 'react'
import { Integration } from 'types/register'
import useLocalStorage, { localStorageMap } from './useLocalStorage'

const generateRandomString = (l = 28) => {
  const array = new Uint32Array(l)
  window?.crypto.getRandomValues(array)
  return Array.from(array, (dec) => ('0' + dec.toString(16)).substr(-2)).join(
    '',
  )
}

// Base64-urlencodes the input string
// Calculate the SHA256 hash of the input text.
function sha256(code_verifier) {
  const encoder = new TextEncoder()
  const data = encoder.encode(code_verifier)
  return window?.crypto.subtle.digest('SHA-256', data)
}

// Base64-urlencodes the input string
function base64urlencode(hashed) {
  return btoa(
    String.fromCharCode.apply(null, Array.from(new Uint8Array(hashed))),
  )
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=+$/, '')
}

// Return the base64-urlencoded sha256 hash for the PKCE challenge
async function generateCodeChallenge(code_verifier) {
  const hashed = await sha256(code_verifier)
  return base64urlencode(hashed)
}

type OauthReturn = {
  startIntegration: () => void
}

const useOauthLogIn = (integration: Integration): OauthReturn => {
  const [_, setIntegrationSettingUp] = useLocalStorage(
    localStorageMap.INTEGRATION_SETTING_UP,
  )
  const [__, setIntegrationCodeVerifier] = useLocalStorage(
    localStorageMap.OAUTH_VERIFIER(integration.id),
  )

  const [codeVerifier] = useState<string | null>(generateRandomString())
  const [codeChallenge, setCodeChallenge] = useState<string | null>(null)
  const state = generateRandomString(14)

  void generateCodeChallenge(codeVerifier).then((code) => {
    setCodeChallenge(code)
  })

  const startIntegration = () => {
    if (!process.env.REACT_APP_BASE_URL) {
      throw new Error('Cannot find base app url')
    }
    const url = integration.oauthUrl
      .replace('{{STATE}}', state)
      .replace('{{CODE_CHALLENGE}}', codeChallenge || '')
      .replace(
        '{{REDIRECT_URI}}',
        `${process.env.REACT_APP_BASE_URL}/register/oauth/callback`,
      )
    setIntegrationSettingUp(`${integration.id}|${state}`)
    setIntegrationCodeVerifier(`${codeVerifier || ''}`)
    if (window) {
      window.location.href = url
    }
  }
  return { startIntegration }
}

export default useOauthLogIn
