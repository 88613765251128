import { RefObject } from 'react'
import { DateRange } from 'types'
import { Parser } from './parser'
import Query from './query'
export interface IHistoricalAnalyzerDataSource {
  label: string
  type: ValueType
}

export type ParseTreeRoot = {
  program: ParseTreeNode
  treeInError: boolean
}

export type ParseTreeNode = {
  type: string
  isError: boolean
  children: Array<ParseTreeNode>
  content?: string
}

export interface ICodeMirrorState {
  containerRef: RefObject<HTMLDivElement>
  parsed: Parser | null
  clearInput: () => void
  setInput: (newInput: string) => void
}

export interface AST {
  start: string | DateRange['from']
  end: string | DateRange['until']
  parse_tree: Record<string, any>
}

export enum ValueType {
  none = 'none',
  vector = 'vector',
  scalar = 'scalar',
  identifier = 'identifier',
}

export type QueryRequest = {
  query: Query
  ast: AST
}

type AxisObj = { label: string; format: 'n2' | 'p2' | undefined }
export type Axis = { left: AxisObj; right: AxisObj }

export type HistChartTitle = string

export type TreeNode = {
  label: string
  children?: TreeNode[]
}
