import styled from 'styled-components'
import * as Tooltip from '@radix-ui/react-tooltip'

export interface ToolTipProps extends Tooltip.TooltipContentProps {
  width?: number
  colour?: string
  textColour?: string
}

const TooltipContent = styled(Tooltip.Content)`
  border-radius: 4px;
  padding: 10px 15px;
  font-size: 14px;
  font-weight: 200;
  line-height: 1;
  color: ${({ theme }) => theme.palette.common.blue12};
  background-color: ${({ theme }) => theme.palette.common.blue2};
  box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px,
    hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
  user-select: none;
  animation-duration: 400ms;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  will-change: transform, opacity;
  max-width: 200px;

  a {
    color: ${({ theme }) => theme.palette.common.blue11};
    text-decoration: underline;
  }
`

const ToolTip = ({
  children,
  content,
  onOpen,
  delayDuration = 400,
  style,
  enabled = true,
}: {
  children: JSX.Element
  content: JSX.Element
  delayDuration?: number
  onOpen?: () => void
  style?: Record<string, string>
  enabled?: boolean
}) => {
  if (!enabled) return children
  return (
    <Tooltip.Provider>
      <Tooltip.Root delayDuration={delayDuration} onOpenChange={onOpen}>
        <Tooltip.Trigger asChild>{children}</Tooltip.Trigger>
        <Tooltip.Portal>
          <TooltipContent sideOffset={5} style={style}>
            {content}
            <Tooltip.Arrow className="TooltipArrow" />
          </TooltipContent>
        </Tooltip.Portal>
      </Tooltip.Root>
    </Tooltip.Provider>
  )
}

export default ToolTip
