import { Model } from './models'
import { OptionType } from './options'
import { Ticker } from './ticker'

export interface OptionPriceQuery {
  id: string
  model: Model
  type: OptionType
  ccy: Ticker
  atm?: boolean
  strike?: number
  expiry?: Date
  price?: number
  'implied vol'?: number
  spot?: number
  delta?: number
  gamma?: number
  vega?: number
  theta?: number
  volga?: number
  vanna?: number
  quantity?: number
}

export interface EditableOptionPriceQuery extends OptionPriceQuery {
  inEdit: boolean
  quantity: number
  pricingTimestamp: 'LATEST' | string
  userIv?: number
  userSpot?: number
}

export enum AnalyseScenario {
  SPOT = 'spot',
  VOL = 'vol',
}

export type PricerDataWithResults = EditableOptionPriceQuery & {
  isLoading: boolean
  isFetching: boolean
  pricingTimestampRes: number
}

export interface SpotResponse {
  data: {
    qualifiedName: string
    count: number
    items: {
      px: [number]
      timestamp: [number]
    }[]
  }
  error: null
}
