import { DateTimePickerChangeEvent } from '@progress/kendo-react-dateinputs'
import { CalendarIcon } from '@radix-ui/react-icons'
import { DateTimePicker } from 'components/atoms/DatePickers'
import ToggleGroup, { ToggleGroupItem } from 'components/atoms/ToggleGroup'
import { DATETIME_FORMAT, NOW_UTC, START_OF_BLOCKSCHOLES_TIME } from 'consts'
import { sub } from 'date-fns'
import { usePermissions } from 'hooks/usePermissions'
import { useState } from 'react'

const DateLatestToggle = ({
  setDate,
  timestamp,
  date,
}: {
  setDate: (d: 'LATEST' | Date) => void
  timestamp?: number
  date: 'LATEST' | Date
}): JSX.Element => {
  const [isLatest, setIsLatest] = useState(date === 'LATEST')
  const { hourlyLookBack } = usePermissions()

  const min = hourlyLookBack
    ? sub(new Date(), { hours: hourlyLookBack })
    : START_OF_BLOCKSCHOLES_TIME

  return (
    <>
      <ToggleGroup
        value={isLatest ? 'LATEST' : 'PICKER'}
        onValueChange={(v) => {
          if (v === 'LATEST') {
            setDate('LATEST')
          }
          if (v) {
            setIsLatest(v === 'LATEST')
          }
        }}
      >
        <ToggleGroupItem width={60} key="LATEST" value="LATEST">
          Latest
        </ToggleGroupItem>
        <ToggleGroupItem key="PICKER" value="PICKER">
          <CalendarIcon />
        </ToggleGroupItem>
      </ToggleGroup>
      <p
        style={{
          color: 'white',
          margin: 0,
          alignSelf: 'center',
          paddingLeft: '0.5rem',
        }}
      >
        {!isLatest && (
          <DateTimePicker
            name="to"
            value={date === 'LATEST' ? undefined : date}
            format={DATETIME_FORMAT}
            min={min}
            max={NOW_UTC()}
            fillMode="flat"
            onChange={(e: DateTimePickerChangeEvent) =>
              setDate(e.value as Date)
            }
          />
        )}
      </p>
    </>
  )
}

export default DateLatestToggle
