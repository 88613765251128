import { useMediaQuery } from 'hooks/useMediaQuery'
import { ForwardedRef, ReactNode, forwardRef } from 'react'
import styled from 'styled-components'

interface ContainerProps {
  basis?: string
  opacity?: number
  padding?: number
  br?: number
  isMobile?: boolean
  overflow?: 'hidden'
  className?: string
}
const ItemContainer = styled.div<ContainerProps>`
  overflow: ${({ overflow }) => overflow || 'auto'};
  padding: ${({ padding, isMobile }) =>
    padding ?? (isMobile ? '0.5rem' : '2rem')};
  background: rgba(44, 48, 92, ${({ opacity }) => opacity || 0.22});
  border-radius: ${({ br }) => br ?? '16px'};
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(1px);
  border: 1px solid rgba(26, 0, 30, 0.14);
`
interface ForwardProps extends ContainerProps {
  children?: ReactNode
}
export default forwardRef<HTMLDivElement, ForwardProps>(
  (p, ref: ForwardedRef<HTMLDivElement>) => {
    const isMobile = useMediaQuery('(max-width: 768px)')
    return <ItemContainer ref={ref} {...p} isMobile={isMobile} />
  },
)
