import { MAX_TERM_DAYS } from 'consts'
import { ModelParametersSeries, ModelParametersWithDate } from 'types'
import {
  EndpointDefinitionRequiringZipping,
  getBSData,
  ModelParametersQueryParams,
  ModelParametersSpecifiedTimestampParams,
} from './common'
import {
  HistoricImpliedVolatilityResponseItemSvi,
  isHistoricImpliedVolatilityResponseItemSvi,
  HistoricImpliedVolatilityResponseItemSabr,
  isHistoricImpliedVolatilityResponseItemSabr,
} from './historicImpliedVolatility'

const MODEL_PARAMETERS_ENDPOINT: EndpointDefinitionRequiringZipping = {
  path: 'modelparams/getModelParameters',
  itemsRequireZipping: true,
  singularTimestamp: true,
}

type ModelParametersResponseItemSvi =
  HistoricImpliedVolatilityResponseItemSvi & {
    atm_vol: number
  }

const isImpliedVolatilityResponseItemSvi = (
  item: any,
): item is ModelParametersResponseItemSvi => {
  return !!item && isHistoricImpliedVolatilityResponseItemSvi(item)
}

type ModelParametersResponseItemSabr =
  HistoricImpliedVolatilityResponseItemSabr & {
    atm_vol: number
  }

const isImpliedVolatilityResponseItemSabr = (
  item: any,
): item is ModelParametersResponseItemSabr => {
  return (
    !!item &&
    item.atm_vol !== undefined &&
    isHistoricImpliedVolatilityResponseItemSabr(item)
  )
}

type ModelParametersResponseItem =
  | ModelParametersResponseItemSvi
  | ModelParametersResponseItemSabr

export const getModelParameters = async <T>(
  query: ModelParametersQueryParams,
  typeguard?: (x) => x is T,
): Promise<Array<Array<T>>> => {
  const urlSearchParams = new URLSearchParams(query)
  const [result] = await getBSData(
    MODEL_PARAMETERS_ENDPOINT,
    urlSearchParams,
    typeguard,
  )
  return result
}

const mapModelParametersResponseItemToSeries = (
  item: ModelParametersResponseItem,
  tenor: string,
): ModelParametersWithDate => {
  const timestamp = new Date(+item.timestamp / 1000000)
  if (isImpliedVolatilityResponseItemSvi(item)) {
    return {
      timestamp,
      dateOffset: +tenor.replace('d', ''),
      Alpha: item.svi_a,
      Beta: item.svi_b,
      Mu: item.svi_m,
      Rho: item.svi_rho,
      Sigma: item.svi_sigma,
      Vol: item.atm_vol,
    }
  }
  return {
    timestamp,
    dateOffset: +tenor.replace('d', ''),
    Alpha: item.sabr_alpha,
    Rho: item.sabr_rho,
    VolVol: item.sabr_volvol,
    Vol: item.atm_vol,
  }
}

export const getModelParametersData = async (
  query: ModelParametersSpecifiedTimestampParams & { tenor: string },
): Promise<ModelParametersSeries | Error> => {
  try {
    // TODO FIX WHEN LESS REQUESTS BEING MADE
    const data = (await getModelParameters(
      query,
    )) as unknown as ModelParametersResponseItem
    const series = data[0]
      .map((i) => mapModelParametersResponseItemToSeries(i, query.tenor))
      .sort((a, b) => a.dateOffset - b.dateOffset)
    return {
      key: series[0].timestamp.toISOString(),
      dataPoints: series,
    }
  } catch (err) {
    return err as Error
  }
}
