import { Auth } from 'aws-amplify'
import { API_ROOT_URL } from 'consts'
import { RequestOptions } from 'https'
import { Source, Ticker } from 'types'
import { SpotResponse } from 'types/queries'

interface Options {
  method?: RequestOptions['method']
  headers?: HeadersInit
  body?: XMLHttpRequestBodyInit
  mode?: RequestMode
}

export const fetchWithAuth = async <T>({
  url,
  options,
  customError,
}: {
  url: string
  options?: Options & { signal?: AbortSignal }
  customError?: (status: number, text?: string) => string
}): Promise<T> => {
  const headers = options?.headers || {}
  try {
    await Auth.currentAuthenticatedUser()
    const currentSession = await Auth.currentSession()
    const accessToken = currentSession?.getAccessToken()
    headers['Authorization'] = `Bearer ${accessToken.getJwtToken()}`
  } catch (e) {
    // Okay to display on FE. Permissions are restricted and DCS needs something to authorise against
    headers['X-Api-Key'] = process.env.REACT_APP_UNAUTHENTICATED_API_KEY
  }

  const res = await fetch(url, {
    method: 'GET',
    mode: 'cors',
    ...options,
    headers,
  })

  const data = await res.json()

  if (data?.errors || data?.error || !res.ok) {
    if (data.error?.message) {
      throw new Error(
        customError?.(res.status, res.statusText) ??
          `${data?.error?.message} [${data?.error?.code}]` ??
          `Failed to fetch ${url}`,
      )
    } else {
      throw new Error(
        customError?.(res.status, res.statusText) ??
          data?.errors?.message ??
          data?.error ??
          data?.message ??
          `Failed to fetch ${url}`,
      )
    }
  }

  return data
  // return new Promise((res) => res({ data: {} })) as unknown as Promise<T>
}

export const spotFetcher = async ({
  exchange,
  currency,
}: {
  exchange: Source
  currency: Ticker
}) => {
  const { data: body } = await fetchWithAuth<SpotResponse>({
    url: `${API_ROOT_URL}timeseries/getHistoricTimeseriesData?key=${exchange.toLowerCase()}.spot.${currency}USD.tick.index.px`,
  })
  if (!body?.items) return undefined
  return body.items[0]?.px?.[0] || null
}

export const stripePortalFetcher = async () => {
  const res = await fetchWithAuth<{
    url?: string
    sub?: string
  }>({
    url: `${API_ROOT_URL}payments/stripe/customer/portal`,
    options: { method: 'POST' },
  })
  if (!res?.url) return undefined
  return res
}
