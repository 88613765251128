import { isValid, format, add } from 'date-fns'
import { useQuery } from '@tanstack/react-query'
import { MS_PER_DAY, NOW_UTC, TWO_YEARS_AHEAD } from 'consts'
import { getPermissionsQuery } from 'services/queries'
import { BannerPerm } from 'types'
import { useAuthenticator } from '@aws-amplify/ui-react'

interface Perms {
  [s: string]: string | number | boolean | undefined | Record<string, any>
}

interface PermsWithKeys extends Perms {
  hasMinuteData: boolean
  hasValidLicense: boolean
  HAEnabled: boolean
  bannerNotification:
    | false
    | { from: string; until: string; innerHtml: string; type: string }
  hourlyLookBack?: number
  minDataLookBack?: number
  maxHourlyDataLookForward?: number
  maxDate: Date
}
export const usePermissions = (): PermsWithKeys => {
  const { data: permissions } = useQuery({
    ...getPermissionsQuery({}),
  })
  const { user } = useAuthenticator((context) => [context?.user])

  const hasMinuteData =
    (permissions?.MINIMUM_FREQUENCY || MS_PER_DAY / 24) < 3600
  const hasValidLicense =
    !!permissions &&
    !!permissions?.EXPIRY_DATE &&
    new Date(permissions.EXPIRY_DATE) > new Date()
  const histAnalyserEnabled = permissions && permissions?.UI_HA_ENABLED

  // TODO: Move to another service, was used part of permissions for speed
  const banner: Partial<BannerPerm> | undefined =
    permissions && permissions?.BANNER
  const bannerNotification = !!banner &&
    !!banner.until &&
    !!banner.from &&
    !!banner.html &&
    !!banner.type &&
    !!isValid(new Date(banner.from)) &&
    new Date(banner.from) < new Date() &&
    isValid(new Date(banner.until)) &&
    new Date(banner.until) > new Date() && {
      from: format(new Date(banner.from), 'E, dd MMM yy HH:mm'),
      until: format(new Date(banner.until), 'E, dd MMM yy HH:mm'),
      innerHtml: banner.html,
      type: banner.type,
    }
  const hourlyLookBack =
    permissions && permissions?.HOURLY_HISTORY_SECONDS_ALLOWED
      ? permissions.HOURLY_HISTORY_SECONDS_ALLOWED / 3600
      : undefined

  const minDataLookBack =
    (hasMinuteData && permissions?.MINUTE_HISTORY_SECONDS_ALLOWED) || undefined
  const maxHourlyDataLookForward =
    permissions && permissions?.HOURLY_LOOK_FORWARD_SECONDS_ALLOWED
      ? permissions.HOURLY_LOOK_FORWARD_SECONDS_ALLOWED / 3600
      : undefined

  const maxDate = maxHourlyDataLookForward
    ? add(NOW_UTC(), { hours: maxHourlyDataLookForward })
    : TWO_YEARS_AHEAD

  return {
    ...permissions,
    hasMinuteData,
    hasValidLicense,
    HAEnabled: !!histAnalyserEnabled,
    bannerNotification,
    hourlyLookBack,
    minDataLookBack,
    maxHourlyDataLookForward,
    maxDate,
    validUser: !!user,
  }
}
