import styled, { css } from 'styled-components'

export const Indicator = styled.i`
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  font-size: 20px;
  width: 1em;
  height: 1em;
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.palette.common.white};
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &:before {
    display: inline-block;
    content: '✓';
    border-radius: 5px;
    font-size: 12px;
    width: 1em;
    height: 1em;
    background: ${({ theme }) => theme.palette.common.white};
    transition: all 0.25s linear 0s;
    opacity: 0;
  }
`

export interface LabelProps {
  hasLabel?: boolean
  disabled?: boolean
}

export const Label = styled.label<LabelProps>`
  display: inline-block;
  position: relative;
  margin: 0;
  font-size: 15px;
  line-height: 1;
  color: #333;
  padding: ${(props) => (props.hasLabel ? '0 1.6em' : '0 1em')};
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  ${(props) => props.disabled && `opacity: .5;`}

  ${(props) =>
    !props.hasLabel &&
    css`
      &:after {
        display: inline-block;
        content: ' ';
      }
    `}

  input {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    opacity: 0;
    z-index: -1;
    margin: 0;

    &:checked ~ ${Indicator}:before {
      opacity: 1;
    }

    &:focus ~ ${Indicator} {
      border: 2px solid ${({ theme }) => theme.palette.primary.light};
    }
  }
`

export const Input = styled.input``
