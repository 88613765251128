import styled from 'styled-components'
import { H3, H4 } from 'components/atoms/Typography/styles'

import Pricing from 'components/molecules/Pricing'
import smile from '../images/features/vol-smile.png'
import vol from '../images/features/vol-index.png'
import volSurface from '../images/features/vol-surface.png'
import optionPricer from '../images/features/option-pricer.png'
import haTool from '../images/features/ha-tool.png'
import FlexContainer from 'components/atoms/Flex'

const features = {
  smile: {
    src: smile,
    alt: 'smile feature',
    description:
      'Use our Smile Chart to Analyse volatility within spot deltas in detail.',
  },
  volIndex: {
    src: vol,
    alt: 'vol index feature',
    description: 'Check out our BSIV index to see market Volatility.',
  },
  volSurface: {
    src: volSurface,
    alt: 'vol surface feature',
    description:
      'Explore the volatility surface across different tenors for any given timestamp.',
  },
  optionPrice: {
    src: optionPricer,
    alt: 'Option Pricer',
    description:
      'Want to price an option? Use our option pricer to consider your options.',
  },
  ha: {
    src: haTool,
    alt: 'Historical Analyzer',
    description:
      "Can't find a graph you're looking for? Use our historical analyzer to pull in any of our timeseries and apply some of our helper functions to it, like regression or correlation.",
  },
}

const PricingPage = ({ hadLicense }: { hadLicense: boolean }) => {
  return (
    <>
      <H3 style={{ paddingBottom: '3rem', paddingTop: '1rem' }}>
        {hadLicense
          ? 'Looks like your subscription has ended.'
          : 'Almost there!'}
      </H3>
      <FlexContainer>
        <Pricing />
        <div
          style={{
            padding: '2rem',
            background: 'rgba(44, 48, 92, 0.22)',
            borderRadius: '16px',
            boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
            backdropFilter: 'blur(1px)',
            border: '1px solid rgba(26, 0, 30, 0.14)',
            flexGrow: 1,
            flexBasis: '575px',
          }}
        >
          <H4 style={{ paddingBottom: '1rem' }}>
            What Features do we provide?
          </H4>
          <p>
            Our Platform provides many Analytic tools which would be impossible
            to showcase here, but some of our customer favourites can be found
            below.
          </p>
          <iframe
            style={{ width: '100%', height: '450px' }}
            src="https://www.youtube.com/embed/R-oBNUKq2qY?mute=1&autoplay=1&loop=1&iv_load_policy=3&playlist=R-oBNUKq2qY"
            title="YouTube teaser player"
            allow=" autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share;"
            allowFullScreen
          />
        </div>
      </FlexContainer>
    </>
  )
}
export default PricingPage
