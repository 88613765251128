export enum BannerType {
  INFO = 'info',
  WARNING = 'warning',
  ANNOUNCEMENT = 'announcement',
}
export interface BannerPerm {
  from: string
  until: string
  html: string
  type: BannerType
}

export interface Permissions {
  EXPIRY_DATE?: string
  MINIMUM_FREQUENCY?: number
  UI_HA_ENABLED?: boolean
  BANNER?: BannerPerm
  HOURLY_HISTORY_SECONDS_ALLOWED?: number
  MINUTE_HISTORY_SECONDS_ALLOWED?: number
  HOURLY_LOOK_FORWARD_SECONDS_ALLOWED?: number
}

type JsonString = string

export interface DBUser {
  id: string
  created_on: string
  perms: Permissions
  show_to_members: true
  metadata: {
    'custom:custom:companyname': string
    'custom:oauth_sign_up'?: string
    location?: JsonString
    email: string
    email_verified: 'true' | 'false'
    family_name: string
    given_name: string
    name: string
    sub: string
  }
  parents: string[]
}
