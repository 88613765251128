import Button from 'components/atoms/Button'
import { TriangleDownIcon, TriangleUpIcon } from '@radix-ui/react-icons'

import posthog from 'posthog-js'
import styled from 'styled-components'

import { Switch } from '@progress/kendo-react-inputs'
import { H6 } from 'components/atoms/Typography/styles'
import { useState } from 'react'

const Banner = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 10000;
  background-color: black;
  border-top: 2px solid grey;
  padding: 1rem 4rem;
  color: white;
  width: 100%;
  min-height: 90px;
`

const BannerText = styled.div`
  display: flex;
  // flex-direction: column;
  flex-grow: 3;
  flex-basis: 650px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 16px;

  button:first-of-type {
    font-weight: 200;
    text-decoraton: underline;
    align-items: center;
  }
  h6 {
    padding-bottom: 0.5rem;
  }
`
const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;
  justify-content: flex-end;
  flex-basis: 350px;

  button {
    padding-right: 1rem;
    margin-right: 1rem;
  }
  button:nth-child(2) {
    font-weight: 200;
  }
`
const CookieSwitches = styled.span`
  display: flex;
  flex-direction: row;
  p {
    margin: 0;
    padding: 0 1rem;
  }
`

const CookieBanner = ({
  setCookiePreferences,
}: {
  setCookiePreferences: (b: boolean) => void
}): JSX.Element => {
  const [preferencesOpen, setPreferencesOpen] = useState(false)
  const [analytics, setAnalytics] = useState(false)
  const acceptAllCookies = () => {
    posthog.opt_in_capturing()

    setCookiePreferences(true)
  }
  const acceptCookies = () => {
    if (posthog) {
      if (analytics) {
        posthog.opt_in_capturing()
      } else {
        posthog.opt_out_capturing()
      }
    }
    setCookiePreferences(true)
  }

  const declineCookies = () => {
    if (posthog) {
      posthog.opt_out_capturing()
    }
    setCookiePreferences(true)
  }

  return (
    <Banner>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <H6>Cookie Consent</H6>
        <a
          target="_blank"
          rel="noreferrer noopener"
          href="https://www.blockscholes.com/terms/privacy-policy"
          style={{ textDecoration: 'underline' }}
        >
          Privacy Policy
        </a>
      </div>
      <BannerText>
        <p>
          We use necessary cookies across our application as well as cookies to
          gather information about how you use our platform so that we can
          improve it.
          <br /> By clicking ‘Accept All’ you consent to our full use of
          cookies. To customize your selection change your preferences below.
        </p>
        <div style={{ width: '50%' }}>
          <Button
            variant="inline"
            onClick={() => setPreferencesOpen((prev) => !prev)}
          >
            Preferences{' '}
            {preferencesOpen ? <TriangleUpIcon /> : <TriangleDownIcon />}
          </Button>
          {preferencesOpen && (
            <CookieSwitches>
              <span
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Switch
                  checked
                  offLabel="Off"
                  onLabel="On"
                  disabled
                  size="small"
                />{' '}
                <p>Required Cookies</p>
              </span>
              <span style={{ display: 'flex', alignItems: 'center' }}>
                <Switch
                  offLabel="Off"
                  onLabel="On"
                  size="small"
                  checked={analytics}
                  onChange={(e) => setAnalytics(e.value)}
                />{' '}
                <p>Tracking Cookies</p>
              </span>
              <Button variant="outlined" onClick={acceptCookies}>
                Save and close
              </Button>
            </CookieSwitches>
          )}
        </div>
        <ButtonContainer>
          <Button variant="contained" onClick={acceptAllCookies}>
            Accept All
          </Button>
          <Button variant="inline" size="sm" onClick={declineCookies}>
            Decline
          </Button>
        </ButtonContainer>
      </BannerText>
    </Banner>
  )
}

export default CookieBanner
