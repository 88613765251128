import { MAX_TERM_DAYS } from 'consts'
import {
  HistoricImpliedVolParamsWithDate,
  HistoricImpliedVolSeries,
} from 'types'
import { convertDateToUTCOrLocal } from 'utils/date-formatter'
import { ModelParametersDatetimeRangeParams } from './common'
import { getModelParameters } from './modelParameters'

export type HistoricImpliedVolatilityResponseItemSvi = {
  timestamp: number
  expiry: number
  svi_a: number
  svi_b: number
  svi_rho: number
  svi_m: number
  svi_sigma: number
}

export const isHistoricImpliedVolatilityResponseItemSvi = (
  item: any,
): item is HistoricImpliedVolatilityResponseItemSvi => {
  return (
    item &&
    item.timestamp !== undefined &&
    item.expiry !== undefined &&
    item.svi_a !== undefined &&
    item.svi_b !== undefined &&
    item.svi_rho !== undefined &&
    item.svi_m !== undefined &&
    item.svi_sigma !== undefined
  )
}

export type HistoricImpliedVolatilityResponseItemSabr = {
  timestamp: number
  expiry: number
  sabr_alpha: number
  sabr_rho: number
  sabr_volvol: number
}

export const isHistoricImpliedVolatilityResponseItemSabr = (
  item: any,
): item is HistoricImpliedVolatilityResponseItemSabr => {
  return (
    !!item &&
    item.timestamp !== undefined &&
    item.expiry !== undefined &&
    item.sabr_alpha !== undefined &&
    item.sabr_rho !== undefined &&
    item.sabr_volvol !== undefined
  )
}

export type HistoricImpliedVolatilityResponseItem =
  | HistoricImpliedVolatilityResponseItemSvi
  | HistoricImpliedVolatilityResponseItemSabr

export const isHistoricImpliedVolatilityResponseItem = (
  item: any,
): item is HistoricImpliedVolatilityResponseItem => {
  return (
    isHistoricImpliedVolatilityResponseItemSvi(item) ||
    isHistoricImpliedVolatilityResponseItemSabr(item)
  )
}

export const mapHistoricImpliedVolatilityResponseItemToSeries = (
  item: HistoricImpliedVolatilityResponseItem,
): HistoricImpliedVolParamsWithDate => {
  const dateOffset = item.expiry * MAX_TERM_DAYS
  const timestamp = convertDateToUTCOrLocal(new Date(+item.timestamp / 1000000))

  if (isHistoricImpliedVolatilityResponseItemSvi(item)) {
    return {
      timestamp,
      dateOffset,
      Alpha: item.svi_a,
      Beta: item.svi_b,
      Mu: item.svi_m,
      Rho: item.svi_rho,
      Sigma: item.svi_sigma,
    }
  }
  return {
    timestamp,
    dateOffset,
    Alpha: item.sabr_alpha,
    Rho: item.sabr_rho,
    VolVol: item.sabr_volvol,
  }
}

export const getHistoricalModelParams = async (
  query: ModelParametersDatetimeRangeParams,
): Promise<HistoricImpliedVolSeries | Error> => {
  try {
    const data = await getModelParameters(
      query,
      isHistoricImpliedVolatilityResponseItem,
    )
    const series = data
      .flatMap((x) => x)
      .map(mapHistoricImpliedVolatilityResponseItemToSeries)
      .sort((a, b) => a.dateOffset - b.dateOffset)

    return {
      key: query.tenor,
      dataPoints: series,
    }
  } catch (err) {
    return err as Error
  }
}
