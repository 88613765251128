import { Element, Path } from '@progress/kendo-drawing'
import { MarkersVisualArgs } from '@progress/kendo-react-charts'

export const customMarker = (
  e: MarkersVisualArgs,
  markers: { instrument: string }[],
): Element => {
  const { dataItem } = e
  const defaultVisual = e.createVisual()
  if (!markers.find((m) => m.instrument === dataItem.instrument)) {
    return new Path()
  }
  return defaultVisual
}
