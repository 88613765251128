import Modal from 'components/atoms/Modal'
import ChartUIWrapper from 'components/common/ChartUIWrapper'
import { useCallback } from 'react'
import {
  ExpiryAddSeries,
  InstrumentAddSeries,
  NewChartSeries,
  NewChartState,
} from 'stores/types'
import {
  AssetClassSource,
  Chart,
  IChartFrequencyData,
  Instrument,
  QuoteAsset,
  Source,
  Ticker,
  YAxis,
} from 'types'

import { Path } from '@progress/kendo-drawing'
import {
  ChartArea,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartLegend,
  ChartSeries,
  ChartSeriesItem,
  ChartValueAxis,
  ChartValueAxisItem,
  SeriesType,
} from '@progress/kendo-react-charts'
import { FREQUENCIES } from 'components/molecules/FrequencyChange'
import { add } from 'date-fns'
import { Catalog } from 'hooks/useCatalog'
import useChartActions from 'hooks/useChartActions'
import { useNewMultiSeriesChart } from 'hooks/useMultiSeriesChart'
import useToasts from 'hooks/useToasts'
import { useDefaultStore, useChartStore } from 'stores'
import AssetClass from 'types/assetClass'
import { changeTimeZone } from 'utils/date-formatter'
import ChartDecider from '../ChartDecider'
import ChartLoader, { ChartNoSeriesData } from '../ChartLoader'
import WaterMark from '../Watermark'
import liveMarker from './markers/live'
import { SeriesPillsNew } from './series/Selector'
import { getCrossingVals, getFormat } from './util'

import { AddSeriesNew } from './series/AddSeries'
import { getSeriesAxis } from './series/utils'

interface Base {
  catalog?: Catalog
  source: Source
  assetClass: AssetClass
  ccy: Ticker
  quote?: QuoteAsset
}
interface BaseWithInstrument extends Base {
  instrument?: Instrument
}
interface BaseWithExpiry extends Base {
  expiry?: string
}

const getCatalogItem = (obj: BaseWithInstrument | BaseWithExpiry) => {
  const { catalog, source, assetClass, ccy, quote } = obj

  if (!catalog) return undefined
  let items = catalog[source][assetClass][ccy].active
  if ('instrument' in obj && obj.instrument) {
    return items.find((i) => i.instrument === obj.instrument)
  }
  if ('expiry' in obj && obj.expiry) {
    items = items.filter((i) => i.expiry === obj.expiry)
  }
  items = items.filter((i) => i.quoteAsset === (quote || 'USD'))
  // .filter((i) => i.settlementAsset.includes('USD')) // TODO Handle conversion between ccys

  if (items.length > 1) {
    console.warn('Found multiple catalog items with same critera', items.length)
  }
  return items[0]
}

const ChartWrapper = ({
  defaultSeries,
  axisDefault,
  id,
  seriesLabelFormatter,
  isDelta,
  title,
  catalog,
  assetClass,
  sources,
  suffix,
  seriesType,
  disableMinutes,
  defaultChartType = Chart.TIMESERIES,
  toUsd,
  expression,
  useInstrument,
  useExpiries,
}: {
  defaultSeries: NewChartSeries[]
  axisDefault: NewChartState['axis']
  id: string
  seriesLabelFormatter?: (label: string) => string
  isDelta?: boolean
  title: string
  catalog?: Catalog
  assetClass: AssetClass.PERP | AssetClass.OPTION | AssetClass.FUTURE
  suffix: string
  seriesType?: string
  sources?: Source[]
  defaultChartType?: Chart
  disableMinutes?: boolean
  toUsd?: Source[]
  expression?: string
  useInstrument?: boolean
  useExpiries?: boolean
}) => {
  const { openToast, ToastComponent, ToastType } = useToasts()
  const {
    timeZoneData,
    type,
    timings,
    axis,
    frequency: storedFreq,
    series,
    timeZone,
    highlighted,
    setHighlighted,
    latestDate,
    isLoading,
  } = useNewMultiSeriesChart({
    id,
    defaultSeries,
    useLiveData: true,
    axisDefault,
  })

  const frequency = FREQUENCIES[storedFreq]
  const updateChart = useChartStore((state) => state.updateTimeSeriesChart)

  const { handleZoomStart, handleZoomEnd, localAxisState } = useChartActions({
    chartTimings: timings,
    unit: frequency.unit,
  })

  const enlargedChart = useDefaultStore((state) => state.expandedId)
  const setExpandedChart = useDefaultStore((state) => state.setExpandedChart)
  const update = useChartStore((s) => s.updateSeries)

  let sourceList = sources
  if (!sourceList) {
    sourceList = AssetClassSource[assetClass]
  }

  const hasInstrument = (s: NewChartState['add']): s is InstrumentAddSeries =>
    !!(s && 'instrument' in s)
  const hasExpiry = (s: NewChartState['add']): s is ExpiryAddSeries =>
    !!(s && 'expiry' in s)

  const addSeries = useCallback(
    (s: NewChartState['add']) => {
      if (!s) return
      let req: Parameters<typeof getCatalogItem>[0] = {
        catalog,
        source: s.source,
        ccy: (s.ccy.includes('/') ? s.ccy.split('/')[0] : s.ccy) as Ticker,
        quote: s.ccy.split('/')[1] as QuoteAsset,
        assetClass,
      }
      if (hasInstrument(s)) {
        req = { ...req, instrument: s.instrument }
      } else if (hasExpiry(s)) {
        req = { ...req, expiry: s.expiry }
      }

      const item = getCatalogItem(req)
      if (!item) return
      const qfn = `${s.source}.${assetClass.toLowerCase()}.${
        item?.instrument
      }.[FREQ].${seriesType ? `${seriesType}.${suffix}` : suffix}`

      if (series?.find(({ qualifiedName }) => qualifiedName === qfn)) {
        return openToast([{ type: ToastType.EXISTS }])
      }

      const seriesAxis = getSeriesAxis({
        id,
        format: getFormat(qfn), // TODO update to take expression
        updateAxis: (a: NewChartState['axis']) => updateChart(id, { axis: a }),
        chartAxis: axis,
        axisToAddTo: s.yAxis,
      })

      if (seriesAxis === 'NONE') {
        return openToast([{ type: ToastType.AXIS }])
      }

      if (seriesAxis) {
        updateChart(id, {
          series: [
            ...(series || []),
            {
              qualifiedName: qfn,
              color: s.colour,
              quote: item.quoteAsset,
              base: item.baseAsset,
              listed: true,
              axis: {
                yAxis: seriesAxis,
              },
              expiry: 'expiry' in s ? s.expiry : undefined,
              ...(toUsd && expression && toUsd.includes(s.source)
                ? { expression } // TODO increase complexity when usecase, changes vals into USD
                : {}),
            },
          ],
        })
      }
    },
    [
      catalog,
      assetClass,
      series,
      id,
      updateChart,
      axis,
      expression,
      toUsd,
      openToast,
      ToastType,
      seriesType,
      suffix,
    ],
  )
  const handleUpdate = useCallback(
    (s: Exclude<NewChartState['add'], undefined>, i: number) => {
      const current = series?.[i]
      if (!current) return
      const u: Partial<NewChartSeries> = {
        color: s.colour,
      }
      if (s.yAxis) {
        u.axis = { ...current.axis, yAxis: s.yAxis }
      }
      if ('expiry' in s) {
        u.expiry = s.expiry
      }
      if (toUsd && expression && toUsd.includes(s.source)) {
        u.expression = expression
      }
      let req: Parameters<typeof getCatalogItem>[0] = {
        catalog,
        source: s.source,
        ccy: (s.ccy.includes('/') ? s.ccy.split('/')[0] : s.ccy) as Ticker,
        quote: s.ccy.split('/')[1] as QuoteAsset,
        assetClass,
      }
      if (hasInstrument(s)) {
        req = { ...req, instrument: s.instrument }
      } else if (hasExpiry(s)) {
        req = { ...req, expiry: s.expiry }
      }

      const newItem = getCatalogItem(req)

      if (!newItem) return
      const exp = `${s.source}.${assetClass.toLowerCase()}.${
        newItem?.instrument
      }.[FREQ].${seriesType ? `${seriesType}.${suffix}` : suffix}`

      if (
        series?.find(
          ({ qualifiedName }, indx) => qualifiedName === exp && i !== indx,
        )
      ) {
        return openToast([{ type: ToastType.EXISTS }])
      }
      const seriesAxis = getSeriesAxis({
        id,
        format: getFormat(exp), // TODO update to take expression
        updateAxis: (a: NewChartState['axis']) => updateChart(id, { axis: a }),
        chartAxis: axis,
        axisToAddTo: s.yAxis,
      })
      if (seriesAxis === 'NONE') {
        return openToast([{ type: ToastType.AXIS }])
      }

      u.qualifiedName = exp
      u.base = newItem.baseAsset
      u.quote = newItem.quoteAsset
      if (seriesAxis) {
        update(id, i, u)
      }
    },
    [
      id,
      update,
      catalog,
      assetClass,
      series,
      seriesType,
      axis,
      updateChart,
      suffix,
      openToast,
      ToastType,
    ],
  )

  if (!catalog || !sourceList?.length) return <></>

  if (isLoading || defaultSeries.length === 0) {
    return <ChartLoader />
  }

  let chartToRender: SeriesType = 'line'
  if (type === Chart.TIMESERIES_BAR) {
    chartToRender = 'column'
  }
  if (type === Chart.TIMESERIES_AREA) {
    chartToRender = 'area'
  }

  return (
    <Modal open={enlargedChart === id} onClose={() => setExpandedChart(null)}>
      <ChartUIWrapper
        title={title}
        id={id}
        axis={axis}
        type={defaultChartType}
        chartSeries={series}
        timeZone={timeZone}
        disableMinutes={disableMinutes}
        updatedStamp={latestDate}
        addSeries={
          !isLoading ? (
            <AddSeriesNew
              id={id}
              catalog={catalog}
              assetClass={assetClass}
              sources={sources}
              addSeries={addSeries}
              useInstrument={useInstrument}
              useExpiries={useExpiries}
            />
          ) : (
            <></>
          )
        }
        seriesPills={
          <SeriesPillsNew
            handleUpdate={handleUpdate}
            chartSeries={series}
            id={id}
            catalog={catalog}
            sources={sourceList}
            assetClass={assetClass}
            setHighlighted={setHighlighted}
            useInstrument={useInstrument}
            useExpiries={useExpiries}
          />
        }
        chart={
          isLoading || defaultSeries.length === 0 ? (
            <ChartLoader />
          ) : (
            <>
              <ToastComponent />
              {Object.keys(timeZoneData).length === 0 ? (
                <ChartNoSeriesData />
              ) : (
                <>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      width: '100%',
                      alignItems: 'end',
                      position: 'relative',
                      top: '40px',
                      zIndex: 1,
                      height: 0,
                    }}
                  >
                    {isDelta && (
                      <p style={{ margin: 0, opacity: '75%' }}>Δ = delta</p>
                    )}
                  </div>
                  <WaterMark>
                    <MultiTimeSeries
                      handleZoomStart={handleZoomStart}
                      handleZoomEnd={handleZoomEnd}
                      timeZoneData={timeZoneData}
                      frequency={frequency}
                      localAxisState={localAxisState}
                      id={id}
                      axis={axis}
                      highlighted={highlighted}
                      series={series}
                      seriesLabelFormatter={seriesLabelFormatter}
                      timeZone={timeZone}
                      timings={timings}
                      chartToRender={chartToRender}
                    />
                  </WaterMark>
                </>
              )}
            </>
          )
        }
      />
    </Modal>
  )
}

export default ChartWrapper

// TODO MOVE BELOW TO FILES ONCE REPLACED OLD METHOD
interface ChartProps {
  handleZoomStart: ReturnType<typeof useChartActions>['handleZoomStart']
  handleZoomEnd: ReturnType<typeof useChartActions>['handleZoomEnd']
  timeZoneData: ReturnType<typeof useNewMultiSeriesChart>['timeZoneData']
  frequency: IChartFrequencyData
  localAxisState: ReturnType<typeof useChartActions>['localAxisState']
  id: string
  axis: ReturnType<typeof useNewMultiSeriesChart>['axis']
  highlighted: ReturnType<typeof useNewMultiSeriesChart>['highlighted']
  series: ReturnType<typeof useNewMultiSeriesChart>['series']
  seriesLabelFormatter?: (label: string) => string
  timeZone: ReturnType<typeof useNewMultiSeriesChart>['timeZone']
  timings: ReturnType<typeof useNewMultiSeriesChart>['timings']
  chartToRender: SeriesType
}
const MultiTimeSeries = ({
  handleZoomStart,
  handleZoomEnd,
  timeZoneData,
  frequency,
  localAxisState,
  id,
  axis,
  highlighted,
  series,
  seriesLabelFormatter,
  timeZone,
  timings,
  chartToRender,
}: ChartProps) => (
  <ChartDecider
    axis={axis}
    handleZoomStart={handleZoomStart}
    handleZoomEnd={handleZoomEnd}
    data={timeZoneData}
    frequency={frequency}
    localAxisState={localAxisState}
    id={id}
  >
    <ChartArea opacity={0} />
    <ChartLegend visible={false} />
    {/* <ChartTooltip /> */}
    <ChartValueAxis>
      {Object.entries(axis)
        .filter(([_, f]) => f)
        .map(([seriesLabel, f], i) => {
          if (!f) return <div key={seriesLabel} />
          return (
            <ChartValueAxisItem
              key={seriesLabel}
              name={seriesLabel}
              min={localAxisState?.[seriesLabel]?.min}
              max={localAxisState?.[seriesLabel]?.max}
              axisCrossingValue={-Infinity}
              labels={{
                format: f,
                font: '0.75em "OpenSans"',
              }}
              crosshair={{
                visible: true,
              }}
              narrowRange
            />
          )
        })}
    </ChartValueAxis>
    <ChartSeries>
      {timeZoneData?.map(({ dataPoints }, i) => {
        let opacity: number | undefined = undefined
        if (typeof highlighted === 'number') {
          if (highlighted !== i) {
            opacity = 0.3
          }
        }
        const showHoveredMarker =
          typeof highlighted === 'number' ? highlighted === i : true
        const lp = dataPoints[dataPoints.length - 1]
        const s = series?.[i]

        return (
          <ChartSeriesItem
            color={s?.color}
            key={s?.qualifiedName}
            type={chartToRender}
            opacity={opacity}
            style="normal"
            missingValues="gap"
            name={
              seriesLabelFormatter
                ? seriesLabelFormatter(s?.qualifiedName ?? '')
                : s?.qualifiedName
            }
            axis={s?.axis?.yAxis || YAxis.LEFT}
            width={
              frequency?.duration && frequency?.duration <= 3700000 ? 0.8 : 1
            }
            field="y"
            categoryAxis="timestamp"
            categoryField="x"
            data={dataPoints}
            markers={{
              visual:
                showHoveredMarker && lp && 'live' in lp && lp?.live
                  ? (e) => liveMarker(e, lp)
                  : () => new Path(),
            }}
            tooltip={{ visible: false }}
          />
        )
      })}
    </ChartSeries>
    <ChartCategoryAxis>
      <ChartCategoryAxisItem
        type="date"
        name="timestamp"
        baseUnit={frequency.unit}
        baseUnitStep={frequency.step}
        maxDivisions={15}
        axisCrossingValue={getCrossingVals(
          changeTimeZone(new Date(Date.now()), timeZone).getTime(),
          axis,
        )}
        max={
          typeof timings?.range !== 'object' &&
          (localAxisState?.timestamp?.max ||
            add(changeTimeZone(new Date(Date.now()), timeZone), {
              minutes: frequency.unit === 'minutes' ? 60 : 0,
              hours: frequency.unit === 'hours' ? 7 : 0,
              days: frequency.unit === 'days' ? 2 : 0,
              weeks: frequency.unit === 'weeks' ? 2 : 0,
              months: frequency.unit === 'months' ? 1 : 0,
            }))
        }
        min={localAxisState?.timestamp?.min}
        labels={{
          rotation: 'auto',
          font: '0.7em "OpenSans"',
          dateFormats: {
            weeks: 'd MMM yy',
            days: 'd MMM yy',
            months: 'MMM yy',
          },
        }}
        crosshair={{
          visible: true,
        }}
      />
    </ChartCategoryAxis>
  </ChartDecider>
)
