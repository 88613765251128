import { API_ROOT_URL } from 'consts'
import { AST, QueryRequest } from 'pages/HistoricalAnalyzer/types'
import { NewChartState } from 'stores/types'
import {
  DateRange,
  HistoricalAnalyzerData,
  HistoricalAnalyzerDataNew,
  ITimeSeriesDataPoint,
} from 'types'
import { fetchWithAuth } from './fetch'

type HistoricalAnalyzerVectorResponse = {
  type: 'vector'
  timestamps: Array<number>
  values: Array<number>
  message?: string
}

type HistoricalAnalyzerScalarResponse = {
  type: 'num'
  timestamps: Array<number>
  values: number
  message?: string
}

type HistoricalAnalyzerRegressionResponse = {
  type: 'regression'
  timestamps: Array<number>
  values: [
    Array<number>,
    Array<number>,
    Array<number>,
    number,
    number,
    number,
    number,
    number,
  ]
  message?: string
}

type HistoricalAnalyzerCdfResponse = {
  type: 'cdf'
  timestamps: Array<number>
  values: [Array<number>, Array<number>, Array<number>]
  message?: string
}

type HistoricalAnalyzerResponse =
  | HistoricalAnalyzerVectorResponse
  | HistoricalAnalyzerScalarResponse
  | HistoricalAnalyzerRegressionResponse
  | HistoricalAnalyzerCdfResponse

function isHaVectorResponse(
  resp: HistoricalAnalyzerResponse,
): resp is HistoricalAnalyzerVectorResponse {
  return resp.type === 'vector'
}

function isHaScalarResponse(
  resp: HistoricalAnalyzerResponse,
): resp is HistoricalAnalyzerScalarResponse {
  return resp.type === 'num'
}

function isHaRegressionResponse(
  resp: HistoricalAnalyzerResponse,
): resp is HistoricalAnalyzerRegressionResponse {
  return resp.type === 'regression'
}

function isHaCdfResponse(
  resp: HistoricalAnalyzerResponse,
): resp is HistoricalAnalyzerCdfResponse {
  return resp.type === 'cdf'
}

async function getHistoricalSeries(
  ast: AST,
  signal?: AbortSignal,
): Promise<
  | HistoricalAnalyzerDataNew
  | { dataPoints: [] }
  | { error: unknown; dataPoints: [] }
> {
  const url = `${API_ROOT_URL}bsLangEvaluator` || ''
  try {
    const data = await fetchWithAuth<HistoricalAnalyzerResponse>({
      url,
      options: {
        method: 'POST',
        body: JSON.stringify(ast),
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
        },
        signal,
      },
    })

    if (isHaRegressionResponse(data)) {
      return {
        kind: 'regression',
        dataPoints: {
          scatter: data.values[0].map((xVal, i) => ({
            x: xVal,
            y: data.values[1][i],
          })),
          line: data.values[0].map((xVal, i) => ({
            x: xVal,
            y: data.values[2][i],
          })),
          alpha: data.values[3],
          beta: data.values[4],
          rSquared: data.values[5],
        },
      }
    }
    if (isHaCdfResponse(data)) {
      return {
        kind: 'cdf',
        dataPoints: data.values[0].map((xVal, i) => ({
          x: xVal,
          y: data.values[1][i],
        })),
        percentiles: [
          data.values[2][4],
          data.values[2][24],
          data.values[2][49],
          data.values[2][74],
          data.values[2][94],
        ],
      }
    }
    if (isHaScalarResponse(data)) {
      if (data.timestamps.length) {
        return {
          kind: 'timeseries',
          dataPoints: data.timestamps.map((timestamp) => ({
            x: timestamp / 1000000,
            y: data.values,
          })) as Array<ITimeSeriesDataPoint>,
        }
      }
      return {
        kind: 'timeseries',
        dataPoints: [
          { x: ast.start, y: data.values },
          { x: ast.end, y: data.values },
        ] as Array<ITimeSeriesDataPoint>,
      }
    }
    if (isHaVectorResponse(data)) {
      if (!data.timestamps.length) {
        return { error: 'timeseries not found', dataPoints: [] }
      }
      return {
        kind: 'timeseries',
        dataPoints: (data.timestamps || []).map((timestamp, i) => ({
          x: timestamp / 1000000,
          y: data.values[i],
        })) as Array<ITimeSeriesDataPoint>,
      }
    }
    return { dataPoints: [] }
  } catch (error) {
    return { error, dataPoints: [] }
  }
}
export default getHistoricalSeries
