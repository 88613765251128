const structClone = (v) => {
  if (typeof v !== 'object') return v
  if ('structuredClone' in global) {
    return structuredClone(v)
  }

  return JSON.parse(JSON.stringify(v))
}

/* eslint-disable no-restricted-syntax */
function deepMerge<T>(...objs: T[]): T {
  function getType(obj) {
    return Object.prototype.toString.call(obj).slice(8, -1).toLowerCase()
  }

  function mergeObj<O extends {}>(clone: O, obj: O) {
    const c = clone
    for (const [key, value] of Object.entries(obj)) {
      if (value === undefined) break
      const type = getType(value)

      if (
        c[key] !== undefined &&
        getType(clone[key]) === type &&
        ['array', 'object'].includes(type)
      ) {
        c[key] = deepMerge(clone[key], value)
      } else {
        c[key] = structClone(value)
      }
    }
    return c
  }

  // Create a clone of the first item in the objs array
  let clone = structClone(objs.shift())

  // Loop through each item
  for (const obj of objs) {
    // Get the object type
    const type = getType(obj)

    // If the current item isn't the same type as the clone, replace it
    if (getType(clone) !== type) {
      clone = structClone(obj)
      continue
    }

    // Otherwise, merge
    if (type === 'array') {
      clone = Array.from(new Set([...clone, ...structClone(obj)]))
    } else if (type === 'object') {
      mergeObj(clone, obj)
    } else {
      clone = obj
    }
  }

  return clone
}

export default deepMerge
