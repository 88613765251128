import { CrossCircledIcon } from '@radix-ui/react-icons'

import { useQuery } from '@tanstack/react-query'
import Card, { CardContainer } from 'components/atoms/Card'
import { H4, H6 } from 'components/atoms/Typography/styles'
import ChartLoader from 'components/charts/ChartLoader'
import Background from 'components/common/Background'
import { useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { getBitBnsPaymentStatus } from 'services/queries'

const PaymentCallback = () => {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const orderID = searchParams.get('oid')
  const { data, isError, error, isFetching } = useQuery({
    ...getBitBnsPaymentStatus(orderID),
  })
  useEffect(() => {
    if (!orderID) {
      navigate('/')
    }
  }, [orderID])

  useEffect(() => {
    if (data && 'status_code' in data && data?.status_code === 1) {
      navigate('/')
    }
  }, [data])

  return (
    <>
      <Background />
      <CardContainer justifyContent="center">
        {isFetching && <ChartLoader />}
        {isError && (
          <Card width="500px">
            <H4>An Error Occurred.</H4>
            <CrossCircledIcon
              style={{ fontSize: '82px', width: '122px', height: '122px' }}
            />
            {error instanceof Error && <H6>{error.message}</H6>}
            <p style={{ textAlign: 'center', paddingTop: '2rem' }}>
              If you think this is a mistake get in contact with{' '}
              <a href="mailto:support@blockscholes.com">
                support@blockscholes.com
              </a>{' '}
            </p>
          </Card>
        )}
      </CardContainer>
    </>
  )
}

export default PaymentCallback
