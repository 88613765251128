import { useEffect } from 'react'
import { H3, H6 } from 'components/atoms/Typography/styles'
import styled from 'styled-components'

import Layout from 'components/register/Layout'
import { integrationMap } from 'consts'
import useLocalStorage, { localStorageMap } from 'hooks/useLocalStorage'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { CrossCircledIcon, CheckCircledIcon } from '@radix-ui/react-icons'

import { useBitBnsApi, useGetBitBnsToken } from 'services/mutations'
import { BitBnsApiPath } from 'types'

import RegisterForm from 'components/register/Form'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  > * {
    padding-bottom: 1rem;
  }
`

const OauthCallback = (): JSX.Element => {
  const [integrationAndState] = useLocalStorage<string>(
    localStorageMap.INTEGRATION_SETTING_UP,
    false,
  )
  let integration, storedState
  if (integrationAndState) {
    integration = integrationAndState.split('|')?.[0]
    storedState = integrationAndState.split('|')?.[1]
  }
  const [codeVerifier] = useLocalStorage<string>(
    localStorageMap.OAUTH_VERIFIER(integration as string),
  )
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const code = searchParams.get('code')
  const msg = searchParams.get('msg')
  const state = searchParams.get('state')
  const referee = integrationMap[integration]
  const { mutate: getToken, data: token } = useGetBitBnsToken() // TODO in future make more generic depending on integration
  const { mutate: getBitBnsUserDetails, data: userData } = useBitBnsApi(
    // TODO in future make more generic depending on integration
    BitBnsApiPath.USER_DETAILS,
  )

  useEffect(() => {
    if (
      (integration && !referee) ||
      storedState !== state ||
      !code ||
      !codeVerifier
    ) {
      navigate('/')
    } else {
      getToken({ code, codeVerifier })
    }
  }, [integration, referee, navigate])

  useEffect(() => {
    if (token) {
      getBitBnsUserDetails({ token: token?.access_token?.token })
    }
  }, [token])

  return (
    <Layout
      LHSContent={
        <>
          {referee?.logo ? (
            <>
              <h3>X</h3>
              <img
                src={referee?.logo}
                alt={`${referee.name || 'external'} logo`}
                style={{ width: '80%', maxHeight: '300px', zIndex: 10 }}
              />
            </>
          ) : undefined}
          {referee && referee?.pricing && (
            <p style={{ textAlign: 'center' }}>
              Our subscription is{' '}
              <b style={{ color: 'white' }}>{referee?.pricing}</b>
              <br />
              You can cancel at anytime.
            </p>
          )}
        </>
      }
    >
      {code && code?.length > 5 ? (
        userData && Object.keys(userData)?.length > 0 ? (
          <>
            <H3 style={{ textAlign: 'center' }}>Create a password</H3>
            <RegisterForm
              persistedEmail={userData?.email}
              name={userData.name}
              oauth={integration}
            />
          </>
        ) : (
          <Container>
            <H3>Success!</H3>
            <CheckCircledIcon
              style={{ fontSize: '82px', width: '122px', height: '122px' }}
            />
            <H6>Getting account details...</H6>
          </Container>
        )
      ) : (
        <Container>
          <H3>An Error Occurred</H3>
          <CrossCircledIcon
            style={{ fontSize: '82px', width: '122px', height: '122px' }}
          />
          <H6>{msg}</H6>
        </Container>
      )}
    </Layout>
  )
}

export default OauthCallback
