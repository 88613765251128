import { Ticker } from 'types'
import { Model } from 'types/models'

export const MODEL_OPTIONS = [
  {
    label: 'SABR',
    value: Model.SABR,
  },
  {
    label: 'SVI',
    value: Model.SVI,
  },
]

export const TICKER_OPTIONS = [
  {
    label: 'BTC',
    value: Ticker.BTC,
  },
  {
    label: 'ETH',
    value: Ticker.ETH,
  },
]

export const TYPE_OPTIONS_STANDARD = [
  {
    label: 'Put',
    value: 'P',
  },
  {
    label: 'Call',
    value: 'C',
  },
]
export const TYPE_OPTIONS_PRO = [
  {
    label: 'Digital Put',
    value: 'digital.P',
  },
  {
    label: 'Digital Call',
    value: 'digital.C',
  },
]

export const PURCHASE_OPTIONS = [
  {
    label: 'latest',
    value: 'LATEST',
  },
  {
    label: 'UTC Date',
    value: 'date',
  },
]
