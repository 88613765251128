import {
  Chart,
  ChartArea,
  ChartLegend,
  ChartSeries,
  ChartSeriesItem,
  ChartTooltip,
  ChartXAxis,
  ChartXAxisItem,
  ChartYAxis,
  ChartYAxisItem,
} from '@progress/kendo-react-charts'
import { HistoricalAnalyzerCdfData } from 'types'
import { useTheme } from 'styled-components'
import { useMemo } from 'react'
import WaterMark from 'components/charts/Watermark'

import 'hammerjs'
import Query from './query'

interface IHistoricalAnalyzerCdfChartProps {
  data: Array<[Query, HistoricalAnalyzerCdfData]>
}

const PERCENTILE_WIDTH = 0.3
const PERCENTILES = ['5th', '25th', 'median', '75th', '95th']

const HistoricalAnalyzerCdfChart: React.FC<IHistoricalAnalyzerCdfChartProps> =
  ({ data }) => {
    const theme = useTheme()
    const xRange = useMemo(() => {
      const min = Math.min(...data[0][1].data.map((d) => d.x))
      const max = Math.max(...data[0][1].data.map((d) => d.x))

      return { min, max }
    }, [data])
    // FIXME: extract to hook
    const minYValue = useMemo(() => {
      const allSeriesData = data.flatMap(([query, series]) => {
        return query.isActive ? series.data.map((el) => el.y) : []
      })
      const min = Math.min(...allSeriesData)
      return Number.isNaN(min) ? 0 : min
    }, [data])

    return (
      <>
        <WaterMark>
          <Chart
            renderAs="svg"
            style={{
              width: '100%',
              height: 400,
              overscrollBehavior: 'contain',
            }}
            transitions={false}
          >
            <ChartArea opacity={0} />
            <ChartLegend position="bottom" orientation="horizontal" />
            <ChartTooltip />
            <ChartYAxis>
              <ChartYAxisItem
                name="left"
                labels={{ format: 'n2', font: '0.7em "OpenSans"' }}
                min={0}
                max={1}
                axisCrossingValue={minYValue}
                plotBands={data[0][1].percentiles.map((percentile, i) => ({
                  from: Math.max(0, percentile - PERCENTILE_WIDTH / 200),
                  to: Math.min(1.0, percentile + PERCENTILE_WIDTH / 200),
                  color: 'white',
                  opacity: 0.5,
                  label: {
                    text: `${PERCENTILES[i]} percentile`,
                    font: '0.7em "OpenSans"',
                    color: theme.palette.primary.light,
                  },
                }))}
              />
            </ChartYAxis>
            <ChartSeries>
              <ChartSeriesItem
                type="scatterLine"
                xField="x"
                yField="y"
                width={2}
                data={data[0][1].data}
                markers={{ visible: false }}
              />
            </ChartSeries>
            <ChartXAxis>
              <ChartXAxisItem
                name="number"
                min={xRange.min}
                max={xRange.max}
                axisCrossingValue={xRange.min}
                labels={{
                  rotation: 'auto',
                  font: '0.7em "OpenSans"',
                  format: 'n2',
                }}
              />
            </ChartXAxis>
          </Chart>
        </WaterMark>
      </>
    )
  }

export default HistoricalAnalyzerCdfChart
