import { Authenticator } from '@aws-amplify/ui-react'
import styled from 'styled-components'

const StyledAuthenticator = styled(Authenticator)<{ isMobile?: boolean }>`
  height: ${({ isMobile }) => (isMobile ? '45rem' : '80%')};
  padding: 1.25rem;
  [data-amplify-router-content] {
    padding: 16px;
  }
  [data-amplify-router] {
    border: 0;
    box-shadow: 0px 0px 30px rgba(255, 255, 255, 0.1);
  }

  [data-amplify-container],
  [data-amplify-router],
  [data-amplify-router-content] {
    border-radius: 4px;
    height: 100%;
    background: ${(props) => props.theme.palette.background.base} !important;
    border: 0.5px solid ${(props) => props.theme.palette.background.base};
  }

  [data-amplify-router-content],
  input,
  .amplify-icon,
  form,
  h3,
  h4,
  p {
    height: 100%;
    background: ${(props) => props.theme.palette.background.base} !important;
    color: white;
  }

  & .amplify-button[data-variation='primary'],
  amplify-checkbox {
    background: ${(props) => props.theme.palette.common.series1};
    color: white;
    &:hover {
      background: ${(props) => props.theme.palette.common.series1}ee;
      color: white;
    }
  }
  & .amplify-button[data-variation='link'] {
    &:hover {
      background: ${(props) => props.theme.palette.common.series1};
      color: white;
    }
  }
  & .amplify-button[data-variation='menu'] {
    &:hover {
      background: ${(props) => props.theme.palette.common.series1};
      color: white;
    }
  }
  & .amplify-button {
    color: #757575;
    &:hover,
    &:focus {
      background: inherit;
      color: white;
    }
  }

  .amplify-tabs {
    display: none;
  }
  .amplify-field__error-message {
    color: orange;
  }
  .amplify-checkbox__label {
    color: #757575;
  }

  .amplify-alert {
    background-color: inherit;
    border: 1px solid red;
    color: red;
  }
`

export default StyledAuthenticator
