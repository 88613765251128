import Page from 'components/atoms/Page'
import { H3 } from 'components/atoms/Typography/styles'
import LockedFeature from 'components/molecules/LockedFeature'
import { useTheme } from 'styled-components'
import { Ticker } from 'types'
import { AnalyseScenario } from 'types/queries'
import AnalysisChart from './AnalysisChart'

import fakeData from './fakeData.json'

const GetScenarioAnalysis = () => {
  const theme = useTheme()
  return (
    <Page mt={3}>
      <div style={{ minHeight: '200px' }}>
        <H3
          style={{
            alignSelf: 'center',
            padding: '0 0 1rem 0',
          }}
        >
          Scenario Analysis
        </H3>
        <div
          style={{
            width: '100%',
            position: 'relative',
          }}
        >
          <div
            style={{
              position: 'absolute',
              top: '0',
              left: '0',
              width: '100%',
              height: '100%',
              textAlign: 'center',
              backdropFilter: 'blur(2px)',
              color: 'white',
            }}
          >
            <LockedFeature />
          </div>
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'center',
            }}
          >
            {Object.entries(fakeData).map(([k, arr]) => {
              return (
                <div
                  key={k}
                  style={{
                    flex: '0.5 1 500px',
                    height: '350px',
                    zIndex: -1,
                  }}
                >
                  <AnalysisChart
                    title={k}
                    data={arr}
                    activeLadder={AnalyseScenario.VOL}
                    ladderData={fakeData['implied vol']}
                    currency={Ticker.BTC}
                  />
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </Page>
  )
}

export default GetScenarioAnalysis
