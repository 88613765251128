import { useAuthenticator } from '@aws-amplify/ui-react'
import React from 'react'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'

import oscillate from '../../images/oscillate.svg'
import whirl from '../../images/whirl.svg'
import bsWhite from '../../images/blockscholes-white.svg'

const LHS = styled.div`
  width: 50vw;
  height: 95vh;
  position: absolute;
  left: 0;
  z-index: 1;
  box-shadow: 10px 0 20px -14px #000000;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
`
const RHS = styled.div`
  width: 50vw;
  height: 100%;
  padding: 3rem;
  position: absolute;
  right: 0;
  background-color: ${({ theme }) => theme.palette.common.lightBlack};
  display: flex;
  align-items: center;
  justify-content: center;
`
const Container = styled.div<{ isMobile?: boolean }>`
  display: flex;
  justify-content: center;
  padding: ${({ isMobile }) => isMobile && '1rem'};
}
`

const RegisterModal = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2rem;
  box-shadow: 10px 0 20px -14px #000000;
  border-radius: 1rem;
  background-color: ${({ theme }) => theme.palette.common.black};
  width: 70%;
  min-width: 400px;
  input {
    margin-bottom: 1rem;
    line-height: 1.5rem;
  }

  .amplify-field-group,
  .amplify-input {
    color: white;
    margin-bottom: 0;
  }

  select {
    align-items: center;
    color: white;
  }
`
type LayoutProps = {
  children: React.ReactElement
  LHSContent?: JSX.Element
  isMobile?: boolean
}
const Layout: React.FC<LayoutProps> = ({ LHSContent, children, isMobile }) => {
  const navigate = useNavigate()
  const { user } = useAuthenticator((context) => [context?.user])
  if (user) navigate('/')

  return isMobile ? (
    <Container isMobile>
      <RegisterModal>{children}</RegisterModal>
    </Container>
  ) : (
    <Container className="container-fluid">
      <LHS>
        <div
          style={{
            position: 'absolute',
            bottom: '-300px',
            transform: 'rotate(345deg)',
          }}
        >
          <img
            alt="analytics curve"
            src={whirl}
            style={{
              height: '100%',
              width: '60vw',
              transform: 'rotate(345deg)',
            }}
          />
        </div>
        <div
          style={{
            position: 'absolute',
            top: '-225px',
            right: '-70px',
            transform: 'rotate(345deg)',
          }}
        >
          <img
            alt="analytics spiral"
            src={oscillate}
            style={{
              width: '65vw',
            }}
          />
        </div>
        <div
          style={{
            margin: 'auto',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            width: '100%',
          }}
        >
          <img
            src={bsWhite}
            alt="Block Scholes logo"
            style={{ width: '80%' }}
          />
          {LHSContent}
        </div>
      </LHS>
      <RHS>
        <RegisterModal>{children}</RegisterModal>
      </RHS>
    </Container>
  )
}

export default Layout
