import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query'
import { API_ROOT_URL } from 'consts'
import { CatalogItem } from 'services'
import { fetchWithAuth } from 'services/fetch'
import { Ticker } from 'types'
import { SpotResponse } from 'types/queries'

const fetcher = async (key: string) => {
  const { data: body } = await fetchWithAuth<SpotResponse>({
    url: `${API_ROOT_URL}timeseries/getHistoricTimeseriesData?key=${key}`,
  })
  if (!body?.items) return undefined
  return body.items[0]?.px?.[0] || null
}

export const usePolling = (
  key: string,
  options?: Omit<
    UseQueryOptions<
      Promise<number | null | undefined>,
      Error,
      number,
      string[]
    >,
    'queryKey' | 'queryFn'
  >,
): UseQueryResult<number, Error> =>
  useQuery({
    queryKey: [key],
    queryFn: () => fetcher(key),
    refetchInterval: 3000,
    ...options,
  })

const isCall = (s: CatalogItem) => s.type === 'C' || s.type === 'call'
const isPut = (s: CatalogItem) => s.type === 'P' || s.type === 'put'
const getStrikePrice = (s: CatalogItem) =>
  Number(s?.instrument.split('-')[2]) || 0

export const getOptionContract = (
  data: Array<CatalogItem>,
  ticker: Ticker,
  spot: number | undefined,
): { call?: string; put?: string } => {
  if (!spot) return {}
  const relevantContracts = data.filter(
    (d) => d.baseAsset === ticker && d.exchange === 'deribit',
  )
  const latestDate = relevantContracts[0]?.expiry
  const toFindStrike = relevantContracts.filter((d) => d.expiry === latestDate)
  let call = toFindStrike.find((s) => isCall(s)) as CatalogItem
  let put = toFindStrike.find((s) => isPut(s)) as CatalogItem
  for (let i = 0; i < toFindStrike.length; i++) {
    if (
      getStrikePrice(toFindStrike[i]) > spot &&
      Math.abs(spot - getStrikePrice(call)) >
        Math.abs(spot - getStrikePrice(toFindStrike[i])) &&
      isCall(toFindStrike[i])
    ) {
      call = toFindStrike[i]
    }

    if (
      getStrikePrice(toFindStrike[i]) < spot &&
      Math.abs(spot - getStrikePrice(put)) >
        Math.abs(spot - getStrikePrice(toFindStrike[i])) &&
      isPut(toFindStrike[i])
    ) {
      put = toFindStrike[i]
    }
  }

  return { call: call?.instrument || '', put: put?.instrument || '' }
}
