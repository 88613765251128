import React from 'react'
import { Source, Ticker } from 'types'
import AssetClass from 'types/assetClass'
import { SeriesPills } from 'components/charts/common/series/Selector'
import MultiLineSeriesWrapper from 'components/charts/common/series/MultiSeriesWrapper'
import useMultiSeriesChart from 'hooks/useMultiSeriesChart'

const FutureHistoricalPrice: React.FC = () => {
  const title = 'Prices'
  const { chartSeries, highlighted, setHighlighted } = useMultiSeriesChart(
    title,
    [
      {
        assetClass: AssetClass.FUTURE,
        middle: {
          currency: Ticker.BTC,
          tenor: '7d',
        },
        suffix: 'curve',
        source: Source.DERIBIT,
      },
      {
        assetClass: AssetClass.FUTURE,
        middle: {
          currency: Ticker.BTC,
          tenor: '30d',
        },
        suffix: 'curve',
        source: Source.DERIBIT,
      },
      {
        assetClass: AssetClass.FUTURE,
        middle: {
          currency: Ticker.BTC,
          tenor: '90d',
        },
        suffix: 'curve',
        source: Source.DERIBIT,
      },
    ],
  )

  return (
    <MultiLineSeriesWrapper
      chartSeries={chartSeries}
      title={title}
      id={title}
      axis={{ LEFT: 'c0' }}
      field="future"
      axisLabel="USD"
      listedExpiry={{ enabled: true, only: false }}
      assetType={AssetClass.FUTURE}
      suffix="curve"
      useTenors
      seriesPills={
        <SeriesPills
          chartSeries={chartSeries}
          id={title}
          setHighlighted={setHighlighted}
        />
      }
      highlightedIndx={highlighted}
    />
  )
}

export default FutureHistoricalPrice
