import {
  Chart,
  ChartArea,
  ChartSeries,
  ChartSeriesItem,
  ChartSeriesLabels,
  ChartTooltip,
  ChartXAxis,
  ChartXAxisItem,
  ChartYAxis,
  ChartYAxisItem,
} from '@progress/kendo-react-charts'
import { DaysTenor } from 'consts'
import { HeatMapData } from 'types'

import { formatNumber } from '@telerik/kendo-intl'
import 'hammerjs'
import { ReactNode } from 'react'
import { useTheme } from 'styled-components'
import { ChartNoSeriesData } from './ChartLoader'
import WaterMark from './Watermark'
import { usePermissions } from 'hooks/usePermissions'

interface IHeatMapChartProps {
  data: Array<HeatMapData>
  color: (dataItem: HeatMapData) => string
  categories: { x: Array<string>; y: Array<string> }
  tooltipRenderer?: (props: any) => ReactNode // workaround broken Kendo types
  xAxisTitle?: string
  yAxisTitle?: string
  onCellClick: (dataItem: HeatMapData) => void
  isOneMinData?: boolean
}

const HeatMapChart: React.FC<IHeatMapChartProps> = (
  props: IHeatMapChartProps,
) => {
  const {
    data,
    color,
    categories,
    onCellClick,
    tooltipRenderer,
    xAxisTitle,
    yAxisTitle,
    isOneMinData,
  } = props
  const theme = useTheme()
  const { HAEnabled } = usePermissions()
  return (
    <>
      {!data.length ? (
        <ChartNoSeriesData />
      ) : (
        <WaterMark heightAsPercentage="95%">
          <Chart
            renderAs="svg"
            style={{
              height: '100%',
              cursor: isOneMinData ? 'not-allowed' : 'pointer',
              touchAction: 'auto',
            }}
            transitions={false}
            onSeriesClick={
              isOneMinData || !HAEnabled
                ? () => {}
                : (e) => {
                    onCellClick(e.dataItem)
                  }
            }
          >
            <ChartArea opacity={0} />
            <ChartTooltip
              render={tooltipRenderer}
              color="white"
              background={theme.palette.common.blue4}
            />
            <ChartSeries>
              <ChartSeriesItem
                type="heatmap"
                xField="expiry"
                yField="strike"
                field="value"
                data={data}
                color={(e) => color(e.dataItem)}
              >
                <ChartSeriesLabels
                  content={({ value }) => formatNumber(value, 'p2')}
                  font='0.7em "OpenSans"'
                />
              </ChartSeriesItem>
            </ChartSeries>
            <ChartYAxis>
              <ChartYAxisItem
                categories={categories.y}
                labels={{
                  content: ({ value }) =>
                    value.toLowerCase() === 'atm'
                      ? value
                      : (Number(value.replace('delta', '')) / 100).toString(),
                  font: '0.7em "OpenSans"',
                }}
                title={{ text: yAxisTitle, font: '0.7em "OpenSans"' }}
              />
            </ChartYAxis>
            <ChartXAxis>
              <ChartXAxisItem
                labels={{
                  content: ({ value }) => DaysTenor[value],
                  rotation: 'auto',
                  font: '0.7em "OpenSans"',
                }}
                title={{ text: xAxisTitle, font: '0.7em "OpenSans"' }}
              />
            </ChartXAxis>
          </Chart>
        </WaterMark>
      )}
    </>
  )
}

export default HeatMapChart
