import { useQuery } from '@tanstack/react-query'
import { CatalogContext } from 'context/CatalogContext'
import { addDays, endOfQuarter, format, previousFriday } from 'date-fns'
import { useContext, useMemo } from 'react'
import { getSpotPrice } from 'services'
import { Source, Ticker } from 'types'

const useQuarterlyListed = ({
  exchange,
  currency,
}: {
  exchange: Source
  currency: Ticker
}) => {
  const catalog = useContext(CatalogContext)
  const { data } = useQuery({
    ...getSpotPrice({ exchange, currency }),
  })

  const spot = data || 0
  const delta = Math.floor(spot * 0.05)
  const quarterDate =
    format(new Date(), 'dLLLyy').toUpperCase() ===
    format(endOfQuarter(new Date()), 'dLLLyy').toUpperCase()
      ? format(
          previousFriday(endOfQuarter(addDays(new Date(), 1))),
          'dLLLyy',
        ).toUpperCase()
      : format(previousFriday(endOfQuarter(new Date())), 'dLLLyy').toUpperCase()
  const listOfActiveExpires = useMemo(() => {
    return catalog.state.activeOptions
      .filter(
        (item) =>
          item.baseAsset === currency &&
          item.exchange === exchange.toLowerCase() &&
          item.instrument.includes(quarterDate) &&
          item.strike &&
          item.strike - spot <= delta &&
          item.strike - spot >= -delta,
      )
      .sort(
        (a, b) =>
          new Date(a.instrument.split('-')[1]).getTime() -
          new Date(b.instrument.split('-')[1]).getTime(),
      )
  }, [
    catalog.state.activeOptions,
    quarterDate,
    spot,
    currency,
    exchange,
    delta,
  ])

  return listOfActiveExpires.slice(0, 8)
}

export default useQuarterlyListed
