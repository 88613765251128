import btcLogo from 'images/currency/bitcoin.svg'
import ethLogo from 'images/currency/eth.svg'
import { Ticker } from 'types'

export const SVG_MAP: Record<Ticker, string> = {
  [Ticker.BTC]: btcLogo,
  [Ticker.ETH]: ethLogo,
}

export const CHART_COLORS = [
  '#f4f4b1',
  '#3392ff',
  '#ffcd00',
  '#ffa5c0',
  '#1f51ff',
  '#FF0099',
  '#e16100',
  '#893DFF',
  '#C5C5C5',
  '#00ffa2',
  '#43ff64',
  '#FF9E80',
  '#92BEFF',
  '#8EDA88',
  '#ED80FF',
  '#FFBD80',
  '#FF80CC',
  '#C49EFF',
]
