import {
  Chart,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartLegend,
  ChartSeries,
  ChartSeriesItem,
  ChartLegendItem,
  ChartSeriesItemTooltip,
  ChartTooltip,
  ChartValueAxis,
  ChartValueAxisItem,
  DragEndEvent,
  ZoomEndEvent,
  ZoomStartEvent,
  ChartArea,
} from '@progress/kendo-react-charts'
import { useTheme } from 'styled-components'
import { DateRange, Frequency, HistoricalAnalyzerTimeSeriesData } from 'types'
import 'hammerjs'
import { formatDate, formatNumber } from '@telerik/kendo-intl'
import { useEffect, useState } from 'react'
import { compareAsc } from 'date-fns'
import { customLabel } from 'components/charts/customLabel'
import WaterMark from 'components/charts/Watermark'

import Query from './query'
import { Axis } from './types'
import { FREQUENCIES } from 'components/molecules/FrequencyChange'

interface IHistoricalAnalyzerChartProps {
  data: Array<[Query, HistoricalAnalyzerTimeSeriesData]>
  dateRange: DateRange
  frequency: Frequency
  axis: Axis
}

const tooltipRenderer = ({ point, labelFormat }) => {
  return (
    <table>
      <tr>
        <td>{formatDate(point.category, { skeleton: 'yMMMdEHm' })}</td>
      </tr>
      <tr>
        <td>{`${point?.series?.name || ''}: ${formatNumber(
          point.value,
          labelFormat || 'n2',
        )}`}</td>
      </tr>
    </table>
  )
}

const AXIS_LABEL_LOOKUP = {
  left: 'LHS',
  right: 'RHS',
}

// TODO: implement onLegendItemClick and connect to query active status
const HistoricalAnalyzerTimeSeriesChart: React.FC<IHistoricalAnalyzerChartProps> =
  ({ data, dateRange, frequency, axis }) => {
    const theme = useTheme()

    const [timestampRange, setTimestampRange] = useState<DateRange>(() => {
      const allSeriesDates = data
        .flatMap(([_, series]) => series.data.map((el) => el.x))
        .sort((a, b) => compareAsc(new Date(a), new Date(b)))
      return {
        from: new Date(allSeriesDates[0]),
        until: new Date(allSeriesDates[allSeriesDates.length - 1]),
      }
    })

    useEffect(() => {
      setTimestampRange(dateRange)
    }, [dateRange])

    useEffect(() => {
      const allSeriesDates = data
        .flatMap(([_, series]) => series.data.map((el) => el.x))
        .sort((a, b) => compareAsc(new Date(a), new Date(b)))
      setTimestampRange({
        from: new Date(allSeriesDates[0]),
        until: new Date(allSeriesDates[allSeriesDates.length - 1]),
      })
    }, [data])

    const handleZoomEnd = (e: ZoomEndEvent) => {
      if (
        e.axisRanges.timestamp.min instanceof Date &&
        e.axisRanges.timestamp.max instanceof Date
      ) {
        setTimestampRange({
          from: e.axisRanges.timestamp.min,
          until: e.axisRanges.timestamp.max,
        })
      }
    }

    const handleZoomStart = (e: ZoomStartEvent) => {
      if (!e.nativeEvent.shiftKey) {
        e.preventDefault()
      }
    }

    const handleDragEnd = (e: DragEndEvent) => {
      if (e.nativeEvent.event.shiftKey) {
        // Shift-drag is used for zooming, so we do not want to handle here
        return
      }
      if (
        e.axisRanges.timestamp.min instanceof Date &&
        e.axisRanges.timestamp.max instanceof Date
      ) {
        setTimestampRange({
          from: e.axisRanges.timestamp.min,
          until: e.axisRanges.timestamp.max,
        })
      }
    }

    return (
      <>
        <WaterMark>
          <Chart
            pannable
            renderAs="svg"
            style={{
              width: '100%',
              height: 400,
              overscrollBehavior: 'contain',
            }}
            zoomable={{ mousewheel: { rate: 0.1 } }}
            onZoomStart={handleZoomStart}
            onZoomEnd={handleZoomEnd}
            onDragEnd={handleDragEnd}
            transitions={false}
          >
            <ChartArea opacity={0} />
            <ChartLegend position="bottom" orientation="horizontal">
              <ChartLegendItem cursor="cursor" visual={customLabel} />
            </ChartLegend>
            <ChartTooltip />
            <ChartValueAxis>
              <ChartValueAxisItem
                name="left"
                labels={{ format: axis.left.format, font: '0.7em "OpenSans"' }}
                axisCrossingValue={-Infinity}
                narrowRange
              />
              <ChartValueAxisItem
                name="right"
                labels={{
                  format: axis.right.format,
                  font: '0.7em "OpenSans"',
                }}
                axisCrossingValue={-Infinity}
                narrowRange
              />
            </ChartValueAxis>
            <ChartSeries>
              {data.map(([query, seriesData]) => {
                return (
                  query.isActive && (
                    <ChartSeriesItem
                      key={query.id}
                      type="line"
                      missingValues="gap"
                      name={`${query.userFacingIdent} (${
                        AXIS_LABEL_LOOKUP[query.axis]
                      })`}
                      axis={query.axis}
                      width={1}
                      field="y"
                      categoryAxis="timestamp"
                      categoryField="x"
                      data={seriesData.data}
                      markers={{ visible: false }}
                      color={query.histSeriesColor}
                    >
                      <ChartSeriesItemTooltip
                        render={({ point }) =>
                          tooltipRenderer({
                            point,
                            labelFormat: axis[query.axis]?.format,
                          })
                        }
                        color="white"
                        background={theme.palette.common.blue4}
                      />
                    </ChartSeriesItem>
                  )
                )
              })}
            </ChartSeries>
            <ChartCategoryAxis>
              <ChartCategoryAxisItem
                name="timestamp"
                baseUnit={FREQUENCIES[frequency].unit}
                baseUnitStep={FREQUENCIES[frequency].step}
                min={timestampRange.from}
                max={timestampRange.until}
                maxDivisions={20}
                axisCrossingValue={[0, Date.now()]}
                labels={{
                  rotation: 'auto',
                  font: '0.7em "OpenSans"',
                  dateFormats: {
                    weeks: 'd MMM yy',
                    days: 'd MMM yy',
                    months: 'd MMM yy',
                  },
                }}
                crosshair={{
                  visible: true,
                }}
              />
            </ChartCategoryAxis>
          </Chart>
        </WaterMark>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'baseline',
          }}
        >
          <p style={{ opacity: '50%', fontSize: '0.9rem' }}>
            Shift + scroll to zoom
          </p>
        </div>
      </>
    )
  }

export default HistoricalAnalyzerTimeSeriesChart
