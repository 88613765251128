import { ExpandedChartSlice } from 'stores/types'
import { StateCreator } from 'zustand'

const createExpandedChart: StateCreator<
  ExpandedChartSlice,
  [],
  [['zustand/immer', never]],
  ExpandedChartSlice
> = (set) => ({
  expandedId: null,
  setExpandedChart: (id) => set(() => ({ expandedId: id })),
})

export default createExpandedChart
