import { Responsive, WidthProvider } from 'react-grid-layout'

import PerpetualSwapsMidPrice from 'components/charts/perpetuals/PerpetualSwapsMidPrice'
import PerpetualSwapsOpenInterest from 'components/charts/perpetuals/PerpetualSwapsOpenInterest'
import PerpetualVolume from 'components/charts/perpetuals/PerpetualVolume'
import PerpetualSwapsFundingRate from 'components/charts/perpetuals/PerpetualSwapsFunding'

import ItemContainer from './ItemContainer'

const ResponsiveGridLayout = WidthProvider(Responsive)

const PerpetualsDashBoard = (p) => {
  return (
    <ResponsiveGridLayout
      measureBeforeMount
      useCSSTransforms
      transformScale={0}
      rowHeight={30}
      isDraggable={false}
      layouts={{
        lg: [
          { i: 'mid-price', x: 0, y: 0, w: 8, h: 18, static: true },
          { i: 'funding-rate', x: 8, y: 0, w: 8, h: 18, static: true },
          { i: 'oi', x: 2, y: 18, w: 5, h: 18, static: true },
          { i: 'vol', x: 7, y: 18, w: 6, h: 18, static: true },
        ],
        md: [
          { i: 'mid-price', x: 0, y: 0, w: 5, h: 15, static: true },
          { i: 'funding-rate', x: 5, y: 0, w: 5, h: 15, static: true },
          { i: 'oi', x: 0, y: 15, w: 5, h: 15, static: true },
          { i: 'vol', x: 5, y: 15, w: 5, h: 15, static: true },
        ],
        sm: [
          { i: 'mid-price', x: 0, y: 0, w: 4, h: 14, static: true },
          { i: 'funding-rate', x: 0, y: 14, w: 4, h: 14, static: true },
          { i: 'oi', x: 0, y: 28, w: 4, h: 14, static: true },
          { i: 'vol', x: 0, y: 42, w: 4, h: 14, static: true },
        ],
      }}
      breakpoints={{ lg: 2200, md: 1300, sm: 968, xs: 580, xxs: 0 }}
      cols={{ lg: 16, md: 10, sm: 4, xs: 1, xxs: 1 }}
    >
      <ItemContainer key="mid-price">
        <PerpetualSwapsMidPrice />
      </ItemContainer>
      <ItemContainer key="funding-rate">
        <PerpetualSwapsFundingRate />
      </ItemContainer>
      <ItemContainer key="oi">
        <PerpetualSwapsOpenInterest />
      </ItemContainer>
      <ItemContainer key="vol">
        <PerpetualVolume />
      </ItemContainer>
    </ResponsiveGridLayout>
  )
}

export default PerpetualsDashBoard
