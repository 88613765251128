import { useMemo } from 'react'
import {
  Chart,
  ChartArea,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartLegend,
  ChartSeries,
  ChartSeriesItem,
  ChartSeriesItemTooltip,
  ChartTooltip,
  ChartValueAxis,
  ChartValueAxisItem,
  ZoomStartEvent,
} from '@progress/kendo-react-charts'
import { useTheme } from 'styled-components'
import { AxisFormat, YAxis } from 'types'

import { formatNumber } from '@telerik/kendo-intl'
import 'hammerjs'
import { dateFormatter } from 'utils/date-formatter'
import { SmileType } from 'types/models'
import { capitalize } from 'lodash'
import { ChartNoSeriesData } from './ChartLoader'
import WaterMark from './Watermark'
import { deconstructKey, getCrossingVals, SmileKey } from './common/util'
import CurrencyLogo from './common/CurrencyLogo'
import { SChart } from './ChartDecider'

const tooltipRenderer = ({ point, timestamp }) => {
  const dk = deconstructKey(
    point.point.series.name.endsWith('Z')
      ? point.point.series.name.replace(`.${timestamp as string}`, '')
      : point.point.series.name,
  ) as SmileKey
  if (!dk || !timestamp) return <></>
  return (
    <table>
      <tr>
        <td>{point.category.toUpperCase()}</td>
      </tr>
      <tr>
        <td>
          <CurrencyLogo currency={dk.middle.currency} />[{dk?.source}]{' '}
          {dk.middle.model}{' '}
          {'tenor' in dk.middle ? dk.middle.tenor : dk.middle.expiry}
        </td>
        <td>{formatNumber(point.dataItem, 'p2')}</td>
      </tr>
      <tr>{dateFormatter.format(new Date(timestamp))} (UTC)</tr>
    </table>
  )
}

interface ISmileChartProps {
  data: Record<
    string,
    {
      series: number[]
      categories: string[]
      timestamp: string
      color: string
      axis: YAxis
    }
  > | null
  title: SmileType
  axis: Partial<Record<YAxis, AxisFormat>>
  highlightedSeries?: number
}

const SmileChart = (props: ISmileChartProps) => {
  const { data, title, axis = {}, highlightedSeries } = props

  const theme = useTheme()

  const handleZoomStart = (e: ZoomStartEvent) => {
    if (!e.nativeEvent.shiftKey) {
      e.preventDefault()
    }
  }

  const minYValue = useMemo(() => {
    if (!data) {
      return { rightMin: 0, leftMin: 0 }
    }
    let leftMin = Infinity
    let rightMin = Infinity
    Object.entries(data || {})?.forEach(([seriesLabel, seriesData]) => {
      if (seriesData.axis === YAxis.RIGHT) {
        rightMin = Math.min(rightMin, ...seriesData?.series)
      } else {
        leftMin = Math.min(leftMin, ...seriesData?.series)
      }
    })

    leftMin = Math.floor(leftMin / 0.05) * 0.05
    rightMin = Math.floor(rightMin / 0.05) * 0.05

    return { leftMin, rightMin }
  }, [data])
  return (
    <>
      {!data ? (
        <ChartNoSeriesData />
      ) : (
        <WaterMark>
          <SChart
            renderAs="svg"
            pannable={false}
            zoomable={false}
            onZoomStart={handleZoomStart}
            transitions={false}
            style={{ height: '100%', touchAction: 'auto' }}
          >
            <ChartArea opacity={0} />
            <ChartLegend visible={false} />
            <ChartTooltip />
            <ChartValueAxis>
              {Object.entries(axis || {})
                .filter(([_, f]) => f)
                .map(([seriesLabel, f], i) => {
                  return (
                    <ChartValueAxisItem
                      key={seriesLabel}
                      name={seriesLabel}
                      min={
                        seriesLabel === YAxis.RIGHT
                          ? minYValue.rightMin
                          : minYValue.leftMin
                      }
                      axisCrossingValue={-Infinity}
                      labels={{
                        format: f,
                        font: '0.7em "OpenSans"',
                        rotation: 'auto',
                        margin: 13,
                      }}
                      crosshair={{
                        visible: true,
                        tooltip: {
                          visible: true,
                          format: f || 'n2',
                          background: theme.palette.common.blue4,
                        },
                      }}
                      narrowRange
                    />
                  )
                })}
            </ChartValueAxis>
            <ChartSeries>
              {Object.entries(data || {}).map(
                ([seriesLabel, seriesData], indx) => {
                  let opacity = 1
                  if (typeof highlightedSeries === 'number') {
                    if (highlightedSeries !== indx) {
                      opacity = 0.3
                    }
                  }

                  return (
                    <ChartSeriesItem
                      key={seriesLabel}
                      type="line"
                      style="smooth"
                      name={seriesLabel}
                      width={2}
                      data={seriesData.series}
                      color={seriesData.color}
                      axis={seriesData.axis}
                      opacity={opacity}
                    >
                      <ChartSeriesItemTooltip
                        render={({ point }) =>
                          tooltipRenderer({
                            point,
                            timestamp: seriesData.timestamp,
                          })
                        }
                        color="white"
                        background={theme.palette.common.blue4}
                      />
                    </ChartSeriesItem>
                  )
                },
              )}
            </ChartSeries>
            <ChartCategoryAxis>
              <ChartCategoryAxisItem
                title={{
                  text:
                    title === SmileType.SMILE
                      ? 'Spot Delta'
                      : capitalize(title),
                }}
                categories={Object.values(data)?.[0]?.categories || []}
                axisCrossingValue={getCrossingVals(Infinity, axis)}
                type="category"
                labels={{
                  rotation: 'auto',
                  content: ({ value }) => {
                    const numb = Math.abs(
                      Number(value.replace('delta', '').replace('money', '')),
                    )

                    return Number.isNaN(numb) ? value : numb.toString()
                  },
                  format: 'p0',
                  font: '0.7em "OpenSans"',
                }}
              />
            </ChartCategoryAxis>
          </SChart>
        </WaterMark>
      )}
    </>
  )
}

export default SmileChart
