import { Responsive, WidthProvider } from 'react-grid-layout'

import CryptoVIX from 'components/charts/indices/CryptoVIX'
import ItemContainer from './ItemContainer'

const ResponsiveGridLayout = WidthProvider(Responsive)

const IndicesDashBoard = (p) => {
  return (
    <>
      <ResponsiveGridLayout
        measureBeforeMount
        isDraggable={false}
        layouts={{
          lg: [{ i: 'implied-vol', x: 4, y: 0, w: 8, h: 5, static: true }],
          md: [{ i: 'implied-vol', x: 1, y: 0, w: 8, h: 4, static: true }],
          sm: [{ i: 'implied-vol', x: 0, y: 0, w: 4, h: 3.5, static: true }],
        }}
        breakpoints={{ lg: 2200, md: 1300, sm: 968, xs: 580, xxs: 0 }}
        cols={{ lg: 16, md: 10, sm: 4, xs: 1, xxs: 1 }}
      >
        <ItemContainer key="implied-vol">
          <CryptoVIX />
        </ItemContainer>
      </ResponsiveGridLayout>
    </>
  )
}

export default IndicesDashBoard
