import React from 'react'
import { Source, Ticker, YAxis } from 'types'

import AssetClass from 'types/assetClass'

import useMultiSeriesChart from 'hooks/useMultiSeriesChart'
import MultiLineSeriesWrapper from '../common/series/MultiSeriesWrapper'
import { SeriesPills } from '../common/series/Selector'

const CryptoVIX: React.FC = () => {
  const title = 'Implied Volatility Index'
  const { chartSeries, highlighted, setHighlighted } = useMultiSeriesChart(
    title,
    [
      {
        assetClass: AssetClass.OPTION,
        middle: {
          currency: Ticker.BTC,
          tenor: '7d',
          type: 'index',
        },
        suffix: 'vol',
        source: Source.COMPOSITE,
      },
      {
        assetClass: AssetClass.OPTION,
        middle: {
          currency: Ticker.BTC,
          tenor: '30d',
          type: 'index',
        },
        suffix: 'vol',
        source: Source.COMPOSITE,
      },
      {
        assetClass: AssetClass.OPTION,
        middle: {
          currency: Ticker.ETH,
          tenor: '7d',
          type: 'index',
        },
        suffix: 'vol',
        source: Source.COMPOSITE,
      },
      {
        assetClass: AssetClass.OPTION,
        middle: {
          currency: Ticker.ETH,
          tenor: '30d',
          type: 'index',
        },
        suffix: 'vol',
        source: Source.COMPOSITE,
        axis: {
          yAxis: YAxis.LEFT,
        },
      },
    ],
  )

  return (
    <MultiLineSeriesWrapper
      chartSeries={chartSeries}
      title={title}
      id={title}
      axisLabel="Vol"
      axis={{ LEFT: 'p2' }}
      assetType={AssetClass.OPTION}
      sources={[Source.COMPOSITE]}
      suffix="vol"
      useTenors
      defaultMiddle={{ type: ['index'] }}
      seriesPills={
        <SeriesPills
          chartSeries={chartSeries}
          id={title}
          setHighlighted={setHighlighted}
        />
      }
      highlightedIndx={highlighted}
    />
  )
}

export default CryptoVIX
