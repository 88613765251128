import { HEATMAP_COLORS, HEATMAP_ZERO_COLOR } from 'consts'

type HeatMapLegendProps = {
  valueColorList: Array<{
    value: string | number
    color: string
  }>
  title?: string
}

export const HeatMapLegend: React.FC<HeatMapLegendProps> = ({
  valueColorList,
  title,
}) => {
  const gradientCssString = `linear-gradient(90deg, ${valueColorList
    .map((valueColor) => valueColor.color)
    .join(', ')})`
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '30%',
        color: 'white',
        paddingTop: '0.2rem',
        fontSize: '0.8rem',
      }}
    >
      {title && <span>{title}</span>}
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          width: '100%',
          paddingTop: '0.5rem',
          margin: 0,
        }}
      >
        <span
          style={{
            height: '1.5rem',
            width: '100%',
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            background: gradientCssString,
            margin: 0,
          }}
        />
      </div>
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          paddingTop: '0.5rem',
        }}
      >
        {valueColorList.map((valueColor) => (
          <div
            key={JSON.stringify(valueColor)}
            style={{
              textAlign: 'center',
              width: 0,
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <span>{valueColor.value}</span>
          </div>
        ))}
      </div>
    </div>
  )
}

export const getCssColorValueForHeatmapCell = (
  value: number,
  minValue: number,
  maxValue: number,
): string => {
  if (value === 0) {
    return `rgb(${HEATMAP_ZERO_COLOR.red}, ${HEATMAP_ZERO_COLOR.green}, ${HEATMAP_ZERO_COLOR.blue})`
  }
  if (value === undefined) {
    return 'black'
  }
  const valueIsNegative = value < 0
  const valueScaled = valueIsNegative
    ? (minValue - value) / minValue
    : value / maxValue
  function lerp(a: number, b: number, t: number) {
    return a + (b - a) * t
  }
  const start = [
    HEATMAP_COLORS.R_MIN,
    HEATMAP_COLORS.G_MIN,
    HEATMAP_COLORS.B_MIN,
  ]
  const mid = [HEATMAP_COLORS.R_MID, HEATMAP_COLORS.G_MID, HEATMAP_COLORS.B_MID]
  const end = [HEATMAP_COLORS.R_MAX, HEATMAP_COLORS.G_MAX, HEATMAP_COLORS.B_MAX]

  function lerpColors(t: number) {
    const [minRgb, maxRbg] = valueIsNegative ? [start, mid] : [mid, end]
    return [
      lerp(minRgb[0], maxRbg[0], t),
      lerp(minRgb[1], maxRbg[1], t),
      lerp(minRgb[2], maxRbg[2], t),
    ]
  }
  const [r, g, b] = lerpColors(valueScaled)
  return `rgb(${r}, ${g}, ${b})`
}
