import {
  UseQueryResult,
  useQueries,
  useQueryClient,
} from '@tanstack/react-query'
import { isBefore, isAfter, isEqual } from 'date-fns'
import { af } from 'date-fns/locale'
import { useEffect } from 'react'
import { DataFrequency, getRefetchInterval, QueryObject } from 'services'
import { DateRange, TimeSeriesData } from 'types'

interface TimeSeriesQueryArgs<T> {
  queries: QueryObject[]
  timestamp: DateRange
  transformer?: (x: T) => T[]
  frequency?: DataFrequency
  enabled?: boolean
}

const isOnOrAfter = (date: Date | number, dateToCompareTo: Date) =>
  isAfter(date, dateToCompareTo) || isEqual(date, dateToCompareTo)

const isOnOrBefore = (date: Date | number, dateToCompareTo: Date) =>
  isBefore(date, dateToCompareTo) || isEqual(date, dateToCompareTo)

const equalDates = ({ cachedFrom, cachedTo, from, until }) =>
  isEqual(cachedFrom, from) && isEqual(cachedTo, until)
const timeRangeIsInsideCache = ({ cachedFrom, cachedTo, from, until }) => {
  const after = isOnOrAfter(from, cachedFrom)
  const before = isOnOrBefore(until, cachedTo)

  return after && before
}

const alreadyHasNeededData = ({
  cachedFrom,
  cachedTo,
  from,
  until,
}: {
  cachedFrom: Date | number
  cachedTo: Date | number
  from: Date
  until: Date
}) => {
  if (!cachedFrom || !cachedTo) return false
  return (
    equalDates({
      cachedFrom,
      cachedTo,
      from,
      until,
    }) ||
    timeRangeIsInsideCache({
      cachedFrom,
      cachedTo,
      from,
      until,
    })
  )
}

const isNestedObject = (obj: Record<string, any>): boolean => {
  if (typeof obj !== 'object' || obj === null) {
    return false
  }

  return Object.keys(obj).some((key) => {
    // Check if the key is an ISO date string
    const isISOString = key.endsWith('Z')
    return isISOString && typeof obj[key] === 'object'
  })
}
/** TIMESERIES QUERY
 * This Hook is an implementation on top of react-queries hook.
 * WHY?
 * React-query has excellent caching logic, but it needs to be stored by keys.
 * therefore every time a user tries to load data for a smaller date range it refetches
 * under a new key, this stores the previous points in cache memory and then slices it accordingly
 *
 * Logic:
 * - If a date range is in the cache we return it
 * - Else we fetch the extra slither needed to merge the datapoints
 */
const useTimeSeriesQuery = <K = TimeSeriesData,>({
  queries,
  timestamp,
  transformer,
  frequency,
  enabled,
}: TimeSeriesQueryArgs<K>): {
  data: K[] | undefined
  isFetching: boolean
  isLoading: boolean
  errors: UseQueryResult[]
} => {
  const qc = useQueryClient()
  const queriesRes = useQueries({
    queries: queries.map(({ queryKey, fetcher }) => ({
      queryKey,
      queryFn: async ({ signal }: { signal?: AbortSignal }) => {
        const cached: TimeSeriesData | undefined =
          qc.getQueryData<TimeSeriesData>([...queryKey, 'totalPoints'])
        let earliestTimestamp = new Date().getTime() // Max date
        let latestTimestamp = new Date(-8640000000000000).getTime() // Min date
        let cachedFrom: Date | number = new Date()
        let cachedTo: Date | number = new Date()
        let newCache = cached

        if (cached) {
          if (isNestedObject(cached)) {
            Object.values(cached).forEach((value) => {
              // eslint-disable-next-line @typescript-eslint/dot-notation
              const timestamps: number[] = value['timestamp']
              if (timestamps[0] < earliestTimestamp) {
                // eslint-disable-next-line prefer-destructuring
                earliestTimestamp = timestamps[0]
              }
              if (timestamps[timestamps.length - 1] > latestTimestamp) {
                latestTimestamp = timestamps[timestamps.length - 1]
              }
              cachedFrom = earliestTimestamp
              cachedTo = latestTimestamp
            })
          } else if (cached?.dataPoints?.length) {
            cachedFrom = cached.dataPoints[0]?.x
            cachedTo = cached.dataPoints[cached.dataPoints.length - 1]?.x
          }

          if (
            !alreadyHasNeededData({
              cachedFrom,
              cachedTo,
              from: timestamp.from,
              until: timestamp.until,
            })
          ) {
            const fetchNewTimestampData =
              isAfter(timestamp.from, cachedFrom) ||
              isEqual(timestamp.from, cachedFrom)

            let newData = await fetcher(
              signal,
              fetchNewTimestampData // is timestamp after cache
                ? cachedTo // new data at recent end of data
                : timestamp.from, // new  historical data
              fetchNewTimestampData ? timestamp.until : cachedFrom,
            )

            if (transformer) {
              newData = transformer(newData)
            }

            // Decide where to merge the two
            let total: TimeSeriesData = cached
            if (isNestedObject(cached)) {
              const newTotal: TimeSeriesData = {
                ...cached,
              }
              Object.entries(newData).forEach(([exp, expData]) => {
                newTotal[exp] = {}
                Object.entries(expData as Record<string, any>).forEach(
                  ([key, value]) => {
                    newTotal[exp][key] = !fetchNewTimestampData
                      ? [
                          ...(value as number[]),
                          ...(cached?.[exp]?.[key] ?? ([] as number[])),
                        ]
                      : [
                          ...(cached?.[exp]?.[key] ?? ([] as number[])).slice(
                            0,
                            -1,
                          ),
                          ...(value as number[]),
                        ]
                  },
                )
              })
              total = newTotal
            } else {
              total = {
                key: cached.key,
                dataPoints: !fetchNewTimestampData
                  ? [...newData.dataPoints, ...cached.dataPoints]
                  : [...cached.dataPoints.slice(0, -1), ...newData.dataPoints], // remove last as will be fetched again
                field: cached.field,
              }
            }
            // set for future requests
            qc.setQueryData([...queryKey, 'totalPoints'], total)
            newCache = total
          }

          const ret = {
            ...cached,
            ...(newCache && isNestedObject(newCache)
              ? { key: queryKey.filter(Boolean).join('.') }
              : {}),
            ...(newCache && isNestedObject(newCache)
              ? Object.fromEntries(
                  Object.entries(newCache)
                    .map(([key, value]) => {
                      if (
                        typeof value === 'object' &&
                        value !== null &&
                        'timestamp' in value
                      ) {
                        const timestampArray = Array.isArray(value.timestamp)
                          ? value.timestamp
                          : []
                        const indices = timestampArray.reduce<number[]>(
                          (acc, t, index) => {
                            if (
                              isOnOrAfter(t, timestamp.from) &&
                              isOnOrBefore(t, timestamp.until)
                            ) {
                              acc.push(index)
                            }
                            return acc
                          },
                          [],
                        )

                        if (indices.length === 0) return null

                        const filteredValue = Object.fromEntries(
                          Object.entries(value).map(
                            ([fieldKey, fieldValue]) => [
                              fieldKey,
                              Array.isArray(fieldValue)
                                ? indices.map((i) => fieldValue[i])
                                : fieldValue,
                            ],
                          ),
                        )

                        return [key, filteredValue]
                      }
                      return null
                    })
                    .filter((entry): entry is [string, any] => entry !== null),
                )
              : {
                  dataPoints: newCache?.dataPoints?.filter(
                    (p) =>
                      isOnOrAfter(p.x, timestamp.from) &&
                      isOnOrBefore(p.x, timestamp.until),
                  ),
                }),
          }
          return ret
        }
        let res = await fetcher(signal, timestamp.from, timestamp.until)

        if (transformer) res = transformer(res)
        qc.setQueryData(
          [...queryKey, 'totalPoints'],
          Array.isArray(res) && res.length === 1 ? res[0] : res,
        )

        return { ...res, key: queryKey.filter(Boolean).join('.') }
      },
      cacheTime: 60 * 1000,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      retry: false,
      refetchInterval: getRefetchInterval('LATEST', frequency === '1m'),
      keepPreviousData: true,
      enabled,
    })),
  })

  useEffect(() => {
    if (queriesRes) queriesRes.map((q) => q.refetch())
  }, [
    timestamp?.from?.toISOString(),
    timestamp?.until?.toISOString(),
    queries.reduce((acc, { queryKey }) => `${acc},${queryKey.join('.')}`, ''),
  ])

  return {
    data: queriesRes.flatMap((q) => q.data || []),
    isFetching: !!queriesRes.find((q) => q.isFetching),
    isLoading: !!queriesRes.find((q) => q.isLoading),
    errors: queriesRes.filter((q) => q.status === 'error'),
  }
}

export default useTimeSeriesQuery
