import * as React from 'react'
import { Dropdown } from 'components/atoms'
import { Series } from 'types'
import { classNames } from '@progress/kendo-react-common'
import RadioButtonComponent from 'components/atoms/RadioButton'


export interface ChangeSeriesProps {
  selected?: Series
  handleSelection: (Series: Series) => void
  className?: string
  labelClassName?: string
  supportedSeries?: Array<Series>
}

const DEFAULT_OPTIONS = Object.values(Series)

export const ChangeSeries = ({
  className,
  selected,
  handleSelection,
  labelClassName,
  supportedSeries,
}: ChangeSeriesProps): React.ReactElement => {
  const options = React.useMemo(() => {
    const items = supportedSeries?.length ? supportedSeries : DEFAULT_OPTIONS
    return items.map((item) => ({
      name: item,
      value: item,
    }))
  }, [supportedSeries])
  return (
    <RadioButtonComponent
      showLabel
      current={options.find(({ value }) => value === selected)}
      options={options}
      onChange={handleSelection}
      className={className}
      labelClassName={classNames('k-mr-2', labelClassName)}
      label="Term"
    />
  )
}
