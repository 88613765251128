import { useCallback } from 'react'
import { classNames } from '@progress/kendo-react-common'
import { DropDownList } from '@progress/kendo-react-dropdowns'
import { Label } from '@progress/kendo-react-labels'
import styled from 'styled-components'

type CallbackType = (...args: any[]) => void
export interface IDropDownOption {
  name: string
  value: any
}

export type TDropDownOption = IDropDownOption

export type IDropDownProps = {
  /**
   * current selected value
   */
  current: TDropDownOption | undefined
  options: TDropDownOption[]
  required?: boolean
  className?: string
  label: string
  showLabel?: boolean
  labelClassName?: string
  size?: 'small' | 'medium' | 'normal' | 'large'
  /**
   * Callback function fired when a menu item is selected.
   *
   * @param {string} item The selected id or item.
   * @document
   */
  onChange?: CallbackType
}

const DropdownWrapper = styled.div`
  margin-right: 2.5rem !important;
  .k-list-item:hover {
    background-color: #46484c !important;
  }
`

const DropdownContainer = styled.div`
  display: flex;
  flex-direction: column;
  .k-input-md .k-input-inner,
  .k-picker-md .k-input-inner {
    padding: 0;
  }
`

const StyledDropdownList = styled(DropDownList)`
  background-color: inherit;
  color: ${(props) => props.theme.palette.common.grey6};
  border-color: transparent;
  width: auto;
  transition: color 300ms;
  border-bottom: 1px solid white;
  border-radius: 0;
  :hover {
    border-color: transparent;
    border-bottom: 1px solid white;
  }
  &.k-input-button {
    padding-right: 0;
  }
  &:hover,
  & .k-button:hover {
    
    background: none;
    color: ${(props) => props.theme.palette.primary.contrastText};
    transition: color 150ms;
  }
  & .k-button {
    transition: color 300ms;
  }
  &:focus,
  &:focus-within {
    box-shadow: none;
    background-color: ${(props) => props.theme.palette.background.toolbarGrey};
    border-color: ${(props) => props.theme.palette.background.toolbarGrey};
    border-bottom: 1px solid white;
  }
  &.k-picker:focus-within {
    box-shadow: none;
  }
  .k-dropdownlist {
    background-color: inherit;
  }
}
`

const StyledLabel = styled(Label)`
  font-size: 12px;
  font-weight: 600;
`

const Dropdown = ({
  label,
  current,
  options,
  className,
  required,
  showLabel = true,
  labelClassName = '',
  size = 'medium',
  onChange,
}: IDropDownProps) => {
  const editorId = 'dummy'

  const handleChange = useCallback(
    (event) => {
      if (onChange) {
        // event.target.value.currency e.g.
        onChange.call(undefined, event.target.value.value)
      }
    },
    [onChange],
  )

  const DropdownListContent = (
    <StyledDropdownList
      textField="name"
      value={options.find((s) => s.value === current?.value)}
      onChange={handleChange}
      data={options}
      popupSettings={{
        animate: { closeDuration: 0 },
        width: 'auto',
      }}
      className={classNames(size)}
    />
  )

  return (
    <DropdownWrapper className={classNames(className)}>
      <DropdownContainer
        className="dropdownContainer"
        style={{ width: '100%' }}
      >
        {showLabel ? (
          <>
            <StyledLabel
              editorId={editorId}
              className={classNames(labelClassName)}
              style={{ width: 'auto' }}
            >
              {label}
            </StyledLabel>
            {DropdownListContent}
          </>
        ) : (
          DropdownListContent
        )}
      </DropdownContainer>
    </DropdownWrapper>
  )
}

export default Dropdown
