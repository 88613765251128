/* eslint-disable no-param-reassign */
import { MonitorsSlice } from 'stores/types'
import { Source, Ticker } from 'types'
import { StateCreator } from 'zustand'
import { immer } from 'zustand/middleware/immer'

const createMonitorsSlice: StateCreator<
  MonitorsSlice,
  [],
  [['zustand/immer', never]],
  MonitorsSlice
> = immer((set) => ({
  monitors: {
    currency: Ticker.BTC,
    exchange: Source.DERIBIT,
    setCurrency: (ccy) =>
      set((state) => {
        state.monitors.currency = ccy
      }),
    setExchange: (e) =>
      set((state) => {
        state.monitors.exchange = e
      }),
  },
}))

export default createMonitorsSlice
