const convertDateToUTC = (date: Date, isHour): Date => {
  return new Date(
    Date.UTC(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      date.getHours(),
      isHour ? 0 : date.getMinutes(),
      isHour ? 0 : date.getSeconds(),
      isHour ? 0 : date.getMilliseconds(),
    ),
  )
}

export const convertDateToUTCOrLatestHour = (
  date: Date,
  isHour = true,
): Date => {
  return convertDateToUTC(date, isHour)
}
