import { useMutation } from '@tanstack/react-query'
import { API_ROOT_URL } from 'consts'
import useLocalStorage, { localStorageMap } from 'hooks/useLocalStorage'
import { BitBnsApiPath, BitBnsTokenResponse, Source, Ticker } from 'types'
import { DataFrequency } from './dataService'

export interface YieldAndPriceObj {
  instrument: string
  yield: number
  price: number
  dateOffset: number
}

export type FutureTermStructureCacheObj = {
  timestamp: Date
  source: Source
  currency: Ticker
  seriesPoints: YieldAndPriceObj[]
  markers: YieldAndPriceObj[]
}

export const useGetBitBnsToken = () =>
  useMutation(
    async ({ code, codeVerifier }: { code: string; codeVerifier: string }) => {
      const res = await fetch(`${API_ROOT_URL}payments/bit-bns/token`, {
        method: 'POST',
        body: JSON.stringify({ code, code_verifier: codeVerifier }),
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
        },
      })

      return res.json()
    },
    {
      onSuccess: async (res: BitBnsTokenResponse) => {
        if (typeof window !== 'undefined') {
          if (res?.access_token?.token) {
            window?.localStorage.setItem(
              localStorageMap.INTEGRATION_ACCESS_TOKEN('bit-bns'),
              res?.access_token?.token,
            )
          }
          if (res?.refresh_token?.token) {
            window?.localStorage.setItem(
              localStorageMap.INTEGRATION_REFRESH_TOKEN('bit-bns'),
              res?.refresh_token?.token,
            )
          }
        }
      },
    },
  )

export const useBitBnsApi = (path: BitBnsApiPath) => {
  const [BitBnsAccessToken] = useLocalStorage<string>(
    localStorageMap.INTEGRATION_ACCESS_TOKEN('bit-bns'),
  )
  return useMutation(
    async ({
      token,
      body = {},
    }: {
      token?: string
      body?: Record<string, string>
    }) => {
      const res = await fetch(`${API_ROOT_URL}payments/bit-bns/details`, {
        method: 'POST',
        mode: 'cors',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          token: token || BitBnsAccessToken,
          api_body: { function: path, ...body },
        }),
      })
      const b = await res.json()
      if (b.status === 1) {
        // success
        return b.data
      }

      return body
    },
    {
      retry: 3,
      retryDelay: 100,
    },
  )
}
