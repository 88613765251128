import React from 'react'

import useCatalog from 'hooks/useCatalog'
import useDefaultSeries from 'hooks/useDefaultSeries'
import { AssetClassSource, Chart, QuoteAssetPair, Source, YAxis } from 'types'
import AssetClass from 'types/assetClass'
import ChartWrapper from '../common/BsLangChartWrapper'

const PerpetualVolume: React.FC = () => {
  const title = 'Perpetual volume'
  const id = `${AssetClass.PERP}-${title}`

  const sources = AssetClassSource[AssetClass.PERP]
  const { data: catalog, isLoading: catalogLoading } = useCatalog({
    assets: [AssetClass.PERP],
    active: [true],
    sources,
    generateCurrencyPairs: true,
  })

  const deribitBTC = catalog?.deribit?.PERPETUAL?.BTC?.active.find(
    (c) => c.quoteAsset === QuoteAssetPair.USD,
  )
  const deribitETH = catalog?.deribit?.PERPETUAL?.ETH?.active.find(
    (c) => c.quoteAsset === QuoteAssetPair.USD,
  )

  const defaults = useDefaultSeries({
    catalogLoading,
    items: [deribitBTC, deribitETH],
    assetClass: AssetClass.PERP,
    path: 'volume.sum',
  })

  if (!catalogLoading && !catalog) {
    return <div>An Error occurred, please refresh.</div>
  }

  return (
    <ChartWrapper
      catalog={catalog}
      defaultSeries={defaults}
      id={id}
      title={title}
      axisDefault={{ [YAxis.LEFT]: 'c0' }}
      assetClass={AssetClass.PERP}
      suffix="sum"
      seriesType="volume"
      sources={sources}
      defaultChartType={Chart.TIMESERIES_BAR}
      toUsd={[Source.BYBIT]}
      expression="[QFN] * deribit.spot.[CCY]USD.[FREQ].index.px"
      disableMinutes
    />
  )
}

export default PerpetualVolume
