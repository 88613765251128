import { MS_PER_DAY } from 'consts'
import { usePermissions } from 'hooks/usePermissions'

import { FrequencyMap, Frequency } from 'types'
import styled from 'styled-components'
import { CheckIcon, InfoCircledIcon } from '@radix-ui/react-icons'
import * as Select from '@radix-ui/react-select'
import ControlledSelect, {
  SelectLabel,
  SelectItem,
  SelectItemIndicator,
  SelectSeparator,
} from 'components/atoms/Select/Index'
import ToolTip from 'components/atoms/tooltip'

interface IChartFrequencyPickerProps {
  frequency: Frequency
  updateFrequency: (f: Frequency) => void
  disableMinutes?: boolean
}

export const FREQUENCIES: FrequencyMap = {
  '1m': {
    label: '1 Minute',
    unit: 'minutes',
    step: 1,
    duration: MS_PER_DAY / 24 / 60,
  },
  '10m': {
    label: '10 Minutes',
    unit: 'minutes',
    step: 10,
    duration: MS_PER_DAY / 24 / 6,
  },
  '15m': {
    label: '15 Minutes',
    unit: 'minutes',
    step: 15,
    duration: MS_PER_DAY / 24 / 4,
  },
  '30m': {
    label: '30 Minutes',
    unit: 'minutes',
    step: 30,
    duration: MS_PER_DAY / 24 / 2,
  },
  '1h': { label: '1 Hour', unit: 'hours', step: 1, duration: MS_PER_DAY / 24 },
  '4h': { label: '4 Hours', unit: 'hours', step: 4, duration: MS_PER_DAY / 6 },
  '8h': { label: '8 Hours', unit: 'hours', step: 8, duration: MS_PER_DAY / 3 },
  '12h': {
    label: '12 Hours',
    unit: 'hours',
    step: 12,
    duration: MS_PER_DAY / 2,
  },
  '1D': { label: '1 Day', unit: 'days', step: 1, duration: MS_PER_DAY },
  '1W': { label: '1 Week', unit: 'weeks', step: 1, duration: MS_PER_DAY * 7 },
  '1M': {
    label: '1 Month',
    unit: 'months',
    step: 1,
    duration: MS_PER_DAY * 7 * 30,
  },
}

const InfoCircle = styled(InfoCircledIcon)`
  width: 16px;
  height: 16px;
  padding-left: 0.2rem;
`

const FrequencyChange: React.FC<IChartFrequencyPickerProps> = (
  props: IChartFrequencyPickerProps,
) => {
  const { frequency, updateFrequency, disableMinutes } = props
  const { hasMinuteData } = usePermissions()
  return (
    <ControlledSelect
      value={frequency}
      onValueChange={(v) => updateFrequency(v)}
    >
      <Select.Group>
        <SelectLabel>
          Minutes
          {!hasMinuteData && (
            <ToolTip content={<span>Available with a PRO subscription</span>}>
              <InfoCircle />
            </ToolTip>
          )}
        </SelectLabel>
        {!disableMinutes ? (
          [1, 10, 15, 30].map((i) => (
            <SelectItem disabled={!hasMinuteData} value={`${i}m`} key={i}>
              <SelectItemIndicator>
                <CheckIcon />
              </SelectItemIndicator>
              <Select.ItemText>{FREQUENCIES[`${i}m`].label}</Select.ItemText>
            </SelectItem>
          ))
        ) : (
          <SelectItem disabled value="unavailable">
            <Select.ItemText>Unavailable</Select.ItemText>
          </SelectItem>
        )}
      </Select.Group>
      <SelectSeparator />
      <Select.Group>
        <SelectLabel>Hours</SelectLabel>
        {[1, 4, 8, 12].map((i) => (
          <SelectItem value={`${i}h`} key={i}>
            <SelectItemIndicator>
              <CheckIcon />
            </SelectItemIndicator>
            <Select.ItemText>{FREQUENCIES[`${i}h`].label}</Select.ItemText>
          </SelectItem>
        ))}
      </Select.Group>
      <SelectSeparator />
      <Select.Group>
        <SelectLabel>Days</SelectLabel>
        <SelectItem value="1D">
          <SelectItemIndicator>
            <CheckIcon />
          </SelectItemIndicator>
          <Select.ItemText>{FREQUENCIES['1D'].label}</Select.ItemText>
        </SelectItem>
        <SelectItem value="1W">
          <SelectItemIndicator>
            <CheckIcon />
          </SelectItemIndicator>
          <Select.ItemText>{FREQUENCIES['1W'].label}</Select.ItemText>
        </SelectItem>
        <SelectItem value="1M">
          <SelectItemIndicator>
            <CheckIcon />
          </SelectItemIndicator>
          <Select.ItemText>{FREQUENCIES['1M'].label}</Select.ItemText>
        </SelectItem>
      </Select.Group>
    </ControlledSelect>
  )
}

export default FrequencyChange
