import { flatten } from 'lodash'
import { FutureHeatMapDataPoint, Source, Ticker } from 'types'
import AssetClass from 'types/assetClass'
import { EndpointDefinitionNotRequiringZipping, getBSData } from './common'
import { DataFrequency } from './dataService'

const OPTIONS_HEATMAP_ENDPOINT: EndpointDefinitionNotRequiringZipping = {
  path: 'timeseries/getActiveFutureHeatMapData',
  itemsRequireZipping: false,
}

type FuturesHeatmapSpotItem = {
  instrument: 'spot'
  expiry: number
}

const isFuturesHeatmapSpotItem = (
  item: any,
): item is FuturesHeatmapSpotItem => {
  return !!item && item.instrument === 'spot'
}

type FuturesHeatmapFutureItem = {
  cells: number[]
  availableSince: string
  timeseries: {
    series: string
    start: string
    end: string
  }
  px: number
  active: boolean
  instrument: string
  expiry: string
  zscore: number[]
}

const isFuturesHeatmapFutureItem = (
  item: any,
): item is FuturesHeatmapFutureItem => {
  return (
    !!item &&
    !isFuturesHeatmapSpotItem(item) &&
    Array.isArray(item.cells) &&
    item.cells.every((cell) => cell !== undefined && !Number.isNaN(cell)) &&
    item.availableSince !== undefined &&
    typeof item.availableSince === 'string' &&
    item.timeseries?.series !== undefined &&
    typeof item.timeseries.series === 'string' &&
    item.timeseries?.start !== undefined &&
    typeof item.timeseries.start === 'string' &&
    item.timeseries?.end !== undefined &&
    typeof item.timeseries.end === 'string' &&
    item.px !== undefined &&
    !Number.isNaN(item.px) &&
    item.instrument !== undefined &&
    typeof item.instrument === 'string' &&
    item.expiry !== undefined &&
    typeof item.expiry === 'string'
  )
}

type FuturesHeatmapItem = FuturesHeatmapSpotItem | FuturesHeatmapFutureItem

const isFuturesHeatmapItem = (item: any): item is FuturesHeatmapItem => {
  return isFuturesHeatmapSpotItem(item) || isFuturesHeatmapFutureItem(item)
}

export type FuturesHeatMapEntry = {
  xInstrument: string
  expiry: Date | null
  yInstrument: string
  value: number
  zScore: number
}

const mapFuturesHeatmapItemToFuturesHeatMapEntry = (
  item: FuturesHeatmapItem,
  instruments: string[],
): FuturesHeatMapEntry[] => {
  if (isFuturesHeatmapSpotItem(item)) {
    return [
      {
        xInstrument: item.instrument,
        yInstrument: item.instrument,
        value: 0,
        expiry: null,
        zScore: 0,
      },
    ]
  }
  return item.cells.map((cell, i) => ({
    xInstrument: item.instrument,
    yInstrument: instruments[i],
    value: cell,
    expiry: new Date(item.expiry),
    zScore: item.zscore[i],
  }))
}

type FuturesHeatmapQuery = {
  currency: Ticker
  exchange: Source
  assetType: AssetClass
  active: boolean
  timestamp: number | 'LATEST'
  frequency: '1h' | '1m'
}

export const getFuturesHeatmapData = async (
  source: Source,
  ticker: Ticker,
  date: Date | 'LATEST' | undefined,
  frequency: DataFrequency,
): Promise<[FutureHeatMapDataPoint[], number]> => {
  const query: FuturesHeatmapQuery = {
    currency: ticker,
    exchange: source,
    assetType: AssetClass.FUTURE,
    timestamp: date && date !== 'LATEST' ? date.getTime() * 1000000 : 'LATEST',
    active: true,
    frequency,
  }
  const urlSearchParams = new URLSearchParams({
    currency: query.currency,
    exchange: query.exchange.toLowerCase(),
    assetType: query.assetType.toLowerCase(),
    active: query.active.toString(),
    timestamp: query.timestamp.toString(),
    frequency: query.frequency.toString(),
  })
  const [futuresHeatmapItems, timestamp] = await getBSData(
    OPTIONS_HEATMAP_ENDPOINT,
    urlSearchParams,
    isFuturesHeatmapItem,
  )

  const instruments = futuresHeatmapItems.map((r) => r.instrument)
  const result = futuresHeatmapItems.map((i) =>
    mapFuturesHeatmapItemToFuturesHeatMapEntry(i, instruments),
  )
  if (!timestamp) {
    throw Error('Unable to get timestamp for query')
  }
  return [
    flatten(result).sort(
      (a, b) => (a.expiry?.getTime() || 0) - (b.expiry?.getTime() || 0),
    ),
    timestamp,
  ]
}
