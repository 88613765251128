import styled from 'styled-components'

export const ButtonGroupMain = styled.div`
  display: flex;
  text-align: right;
  align-items: center;
  justify-content: center;
  height: 100%;
`

export const AddNewRowButton = styled.div`
  display: block;
`

export const AddFunctionButton = styled.div`
  margin-left: 10px;
  height: 100%;
  display: block;
`

export const StyledControlBar = styled.div`
  background: ${({ theme }) => theme.palette.common.blue1};
  display: flex;
  margin-bottom: auto;
  width: 100%;
  height: 80px;
  margin-bottom: 20px;
  column-gap: 1em;
  align-items: center;
  border-radius: 4px;
  padding-left: 20px;
  padding-right: 20px;
  column-gap: 1em;
  justify-content: center;
  align-items: center;
`

export const UpdateChartButton = styled.div`
  margin-left: 20px;
`
