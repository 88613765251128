import { BSLANG } from '@blockscholes/ql'
import { autocompletion, completionKeymap } from '@codemirror/autocomplete'
import { closeBrackets } from '@codemirror/closebrackets'
import { defaultKeymap } from '@codemirror/commands'
import { bracketMatching } from '@codemirror/matchbrackets'
import { Compartment, EditorState, Prec } from '@codemirror/state'
import {
  EditorView,
  highlightSpecialChars,
  keymap,
  ViewUpdate,
  placeholder
} from '@codemirror/view'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { baseTheme } from './CodeMirrorTheme'
import { bsLangLinter } from './linter'
import { Parser } from './parser'
import { ICodeMirrorState, IHistoricalAnalyzerDataSource } from './types'

// NOTE: the insert at point functionality does not always work when the user
// tries to insert an instrument into a new expression after saving the same
// instrument in a previous expression. I think the useEffect on line 38 does
// not always run correctly
const useCodeMirror = (
  input: string,
  dataSources: Array<IHistoricalAnalyzerDataSource>,
  // handleQuerySubmit: (
  //   expression: string | undefined | null,
  //   parser: Parser,
  // ) => void,
): ICodeMirrorState => {
  const [value, setValue] = useState(input)
  const [editorState, setEditorState] = useState<EditorState | null>(null)
  const viewRef = useRef<EditorView | null>(null)
  const containerRef = useRef<HTMLDivElement | any>(null)
  const dynamicLanguageCompartment = useRef(new Compartment())
  const autocompletionCompartment = useRef(new Compartment())
  const sourcesRef = useRef(dataSources)

  const setInput = (newInput: string) => {
    setValue(newInput)
    if (viewRef.current) {
      const cursor = viewRef.current.state.selection.main
      const update = viewRef.current.state.update({
        changes: { from: cursor.from, insert: newInput },
      })
      viewRef.current.update([update])
    }
  }

  const clearInput = () => {
    if (viewRef.current) {
      const update = viewRef.current.state.update({
        changes: {
          from: 0,
          to: viewRef.current.state.doc.length,
          insert: '',
        },
      })
      viewRef.current.update([update])
    }
  }
  const parsed = useMemo(() => {
    if (editorState !== null) {
      return new Parser(editorState, dataSources)
    }
    return null
  }, [dataSources, editorState])

  useEffect(() => {
    sourcesRef.current = dataSources
    if (viewRef.current) {
      const autocompletionSources = dataSources.map((source) => {
        return { label: source.label, type: 'variable' }
      })
      viewRef.current.dispatch({
        effects: dynamicLanguageCompartment.current.reconfigure(
          BSLANG(autocompletionSources),
        ),
      })
      viewRef.current.dispatch({
        effects: autocompletionCompartment.current.reconfigure(
          bsLangLinter(dataSources),
        ),
      })
    } else {
      // if (!containerRef.current) {
      //   throw new Error('CodeMirror container element should already exist')
      // }

      const autocompletionSources = dataSources.map((source) => {
        return { label: source.label, type: 'variable' }
      })
      const startState = EditorState.create({
        doc: input,
        extensions: [
          dynamicLanguageCompartment.current.of(BSLANG(autocompletionSources)),
          baseTheme,
          autocompletion({ activateOnTyping: true, maxRenderedOptions: 100 }),
          highlightSpecialChars(),
          bracketMatching(),
          closeBrackets(),
          placeholder('Click to add expression'),
          autocompletionCompartment.current.of(bsLangLinter(dataSources)),
          keymap.of([...completionKeymap, ...defaultKeymap]),
          // TODO: bsLangLinter should be renamed parser and output parse tree to state
          // https://codemirror.net/6/docs/ref/#state.StateField
          EditorView.updateListener.of((update: ViewUpdate): void => {
            setEditorState(update.state)
          }),
          // Prec.override(
          //   keymap.of([
          //     {
          //       key: 'Enter',
          //       run: (v: EditorView): boolean => {
          //         const parser = new Parser(v.state, sourcesRef.current)
          //         handleQuerySubmit(parser.inputString(), parser)
          //         return true
          //       },
          //     },
          //   ]),
          // ),
        ],
      })

      const view = new EditorView({
        state: startState,
        parent: containerRef.current,
      })

      viewRef.current = view

      // viewRef.current.focus()
    }
  }, [dataSources, input, value])

  return { containerRef, parsed, clearInput, setInput }
}

export default useCodeMirror
