import { ReactText, Attributes } from 'react'
import styled, { css, DefaultTheme } from 'styled-components'
import { VariantType } from 'styles/theme'
import { typographyCSS } from '../Typography/styles'

export interface ButtonProps extends Attributes {
  variant?: 'outlined' | 'contained' | 'inline' | 'neutral' | 'menu'
  size?: 'sm' | 'md'
  fullWidth?: boolean
  fullHeight?: boolean
  color?: string
  children: React.ReactNode | React.ReactElement | ReactText
  pulse?: boolean
  underline?: boolean
}

const variantStyles = (theme: DefaultTheme, variant = 'contained', color) =>
  ({
    outlined: css`
      border: 3px solid transparent;
      border-radius: 4px;
      transition: border-color 0.3s, color 0.3s, background 0.3s;
      font-weight: 600 !important;
      border-color: ${theme.palette.primary.contrastText};
      color: ${theme.palette.primary.contrastText};
      transition: filter 300ms;
      &:hover {
        color: ${theme.palette.primary.contrastText};
        background: transparent;
        border-color: ${theme.palette.primary.contrastText};
        filter: brightness(140%);
        transition: filter 150ms;
      }
      &:focus {
        color: ${theme.palette.primary.contrastText};
        background: transparent;
        border-color: ${theme.palette.primary.contrastText};
        filter: brightness(150%);
        transition: filter 50ms;
      }
      &:disabled {
        border-color: ${theme.palette.primary.dark};
        color: ${theme.palette.common.darkGrey};
        transition: color 150ms;
      }
    `,
    contained: css`
      border: 3px solid transparent;
      border-radius: 4px;
      transition: border-color 0.3s, color 0.3s, background 0.3s;
      font-weight: 600 !important;
      background-color: ${color || theme.palette.common.blue9};
      color: ${theme.palette.common.blue12};
      transition: filter 300ms;
      &:hover {
        filter: brightness(140%);
        transition: filter 150ms;
      }
      &:focus {
        filter: brightness(150%);
        transition: filter 50ms;
      }
      &:disabled {
        filter: contrast(50%);
        transition: filter 150ms;
      }
    `,
    inline: css`
      background-color: inherit;
      color: inherit;
      border: none;
      padding: 0 0 0;
      display: inline-flex;
      text-transformation: none;
      font-weight: 300;

      &:hover {
        filter: brightness(140%);
        transition: filter 150ms;
      }
    `,
    neutral: css`
      background-color: ${theme.palette.common.blue3};
      border-radius: 0 10px 10px 0;
      color: ${theme.palette.common[color] || color};
      display: flex;
      font-weight: 300;
      flex-direction: column;
      border: none;
      padding: 1rem;
      display: inline-flex;
      text-transformation: none;

      &:hover {
        filter: brightness(140%);
        transition: filter 150ms;
      }

      &:disabled {
        filter: contrast(50%);
        transition: filter 150ms;
      }
    `,
    menu: css`
      background: inherit;
      border: none;
      padding: 0;
      width: 100px;
      color: white;
      display: flex;
      align-items: baseline;
      justify-content: space-around;
    `,
  }[variant])

const Button = styled.button<ButtonProps>`
  background: none;
  border: none;
  outline: none;
  text-align: center;
  cursor: pointer;
  padding: 50px 30px 50px 80px;

  &:disabled {
    cursor: not-allowed;
  }

  ${({ underline }) => underline && 'text-decoration: underline;'}
  ${({ variant, size }) => {
    if (variant === 'inline' || variant === 'menu') return undefined
    return size === 'sm'
      ? css`
          border-width: 2px;
          ${typographyCSS(VariantType.buttonSmall)}
          padding: 0.95em 2em;
        `
      : css`
          ${typographyCSS(VariantType.buttonMedium)}
          padding: 0.95em 2em;
        `
  }}
  ${({ theme, variant, color }) => variantStyles(theme, variant, color)}

  ${({ fullWidth }) =>
    fullWidth &&
    `
  display: block;
  width: 100%;
  `}
  ${({ fullHeight }) =>
    fullHeight &&
    `
  display: block;
  height: 100%;
  `}
  
  ${({ pulse, theme }) =>
    pulse
      ? `
      animation: pulse 4s infinite;

      @keyframes pulse {
        0% {
          background-color: inherit;
        }

        50% {
          opacity; 0.2;
          background-color: ${theme.palette.common.grey1};
        }

        100% {
          background-color: inherit;
        }
      }
  `
      : ''}
`

export default Button
