import { H3, H4 } from 'components/atoms/Typography/styles'
import { StyledBasePill } from 'components/charts/common/series/Pills'

const MonetaryValue = ({
  percentageOff,
  value,
  nonDiscountedColor,
  pillColor,
  pillBg,
}) => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'baseline',
        fontSize: '15px',
        flexDirection: 'column',
        paddingTop: '0.5rem',
        margin: 0,
      }}
    >
      {percentageOff ? (
        <>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              width: '100%',
              justifyContent: 'space-around',
            }}
          >
            <H4 style={{ color: nonDiscountedColor }}>
              <s>${value}</s>
            </H4>{' '}
            <StyledBasePill
              bgColor={pillBg}
              color={pillColor}
              padding="0.3rem 1rem"
              fs="14px"
            >
              <b>{percentageOff * 100}% off</b>
            </StyledBasePill>
          </div>
          <div style={{ display: 'inline-flex', alignItems: 'baseline' }}>
            {' '}
            <H3>${(value * (1 - percentageOff)).toFixed(2)}</H3>/ Month
          </div>
        </>
      ) : (
        <div style={{ display: 'inline-flex', alignItems: 'baseline' }}>
          <H3>${value}</H3> / Month
        </div>
      )}
    </div>
  )
}

export default MonetaryValue
