import { ChevronDownIcon, ChevronUpIcon } from '@radix-ui/react-icons'
import * as Select from '@radix-ui/react-select'
import styled from 'styled-components'

const SelectTrigger = styled(Select.Trigger)`
  display: inline-flex;
  align-items: center;
  align-self: center;
  justify-content: center;
  border-radius: 4px;
  padding: 0 0.35rem;
  font-size: 12px;
  line-height: 1;
  height: 35px;
  gap: 5px;
  color: ${({ theme }) => theme.palette.common.blue12};
  text-transform: capitalize;

  &:hover {
    background-color: ${({ theme }) => theme.palette.common.blue3};
  }
  &:focus {
    box-shadow: 0 0 0 2px black;
  }
  &[data-placeholder] {
    color: ${({ theme }) => theme.palette.common.blue9};
  }
`
const SelectIcon = styled(Select.Icon)`
  color: ${({ theme }) => theme.palette.common.blue12};
`

const SelectContent = styled(Select.Content)<{ $colorShade?: `blue${number}` }>`
  overflow: hidden;
  background-color: ${({ theme, $colorShade }) =>
    theme.palette.common[$colorShade || 'blue5']};
  border-radius: 6px;
  height: 250px
  box-shadow: 0px 10px 38px -10px rgba(22, 23, 24, 0.35),
    0px 10px 20px -15px rgba(22, 23, 24, 0.2);
`
const SelectScrollUpButton = styled(Select.ScrollUpButton)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 25px;
  background-color: ${({ theme }) => theme.palette.common.blue5};
  color: ${({ theme }) => theme.palette.common.blue12};
  cursor: default;
`

export const SelectItemIndicator = styled(Select.ItemIndicator)`
  position: absolute;
  left: 0;
  width: 25px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
`

export const SelectSeparator = styled(Select.Separator)`
  height: 1px;
  background-color: ${({ theme }) => theme.palette.common.blue6};
  margin: 5px;
`

export const SelectItem = styled(Select.Item)`
  font-size: 13px;
  line-height: 1;
  color: ${({ theme }) => theme.palette.common.blue12};
  border-radius: 3px;
  display: flex;
  align-items: center;
  align-self: center;
  height: 25px;
  width: 100%;
  padding: 0 35px 0 25px;
  position: relative;
  user-select: none;
  text-transform: capitalize;

  &[data-disabled] {
    color: ${({ theme }) => theme.palette.common.blue8};
    pointer-events: none;
    cursor: not-allowed;
  }

  &[data-highlighted] {
    outline: none;
    background-color: ${({ theme }) => theme.palette.common.blue9};
    color: ${({ theme }) => theme.palette.common.blue1};
  }
`
export const SelectLabel = styled(Select.Label)`
  padding: 0 8px;
  font-size: 14px;
  line-height: 25px;
  font-size: 800;
  text-transform: uppercase;
  color: ${({ theme }) => theme.palette.common.blue11};
`

export const { ItemText } = Select

const ControlledSelect = <T extends string>({
  children,
  value,
  valueDisplay,
  onValueChange,
  colorShade,
  placeHolder,
  defaultValue,
  disabled,
  alignSelf,
}: {
  children: JSX.Element | JSX.Element[]
  value?: T | undefined
  valueDisplay?: string | JSX.Element
  onValueChange: (v: T) => void
  colorShade?: `blue${number}`
  placeHolder?: string
  defaultValue?: string
  disabled?: boolean
  alignSelf?: 'flex-start'
}): JSX.Element => {
  return (
    <Select.Root
      disabled={disabled}
      value={value}
      defaultValue={defaultValue}
      onValueChange={(v) => onValueChange(v as T)}
    >
      <SelectTrigger style={{ alignSelf: alignSelf || 'center' }}>
        <Select.Value
          style={{ textTransform: 'capitalize' }}
          placeholder={placeHolder}
        >
          {valueDisplay || value}
        </Select.Value>
        <SelectIcon>
          <ChevronDownIcon />
        </SelectIcon>
      </SelectTrigger>
      <Select.Portal>
        <SelectContent $colorShade={colorShade} position="popper">
          <SelectScrollUpButton>
            <ChevronUpIcon />
          </SelectScrollUpButton>
          <Select.Viewport>{children}</Select.Viewport>
          <Select.ScrollDownButton>
            <ChevronDownIcon />
          </Select.ScrollDownButton>
          <Select.Arrow />
        </SelectContent>
      </Select.Portal>
    </Select.Root>
  )
}

export default ControlledSelect
