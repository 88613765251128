import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useSearchParams } from 'react-router-dom'
import { useTheme } from 'styled-components'
import { Fade } from '@progress/kendo-react-animation'
import { dataService } from 'services'
import Page from 'components/atoms/Page'
import DateRangeSelector from 'components/common/DateRangeSelector'
import Button from 'components/atoms/Button'
import FrequencyChange, {
  FREQUENCIES,
} from 'components/molecules/FrequencyChange'
import { HistoricalAnalyzerContext } from 'context/HistoricalAnalyzerContext'
import {
  DateRange,
  HistoricalAnalyzerCdfData,
  HistoricalAnalyzerRegressionData,
  HistoricalAnalyzerTimeSeriesData,
} from 'types'
import ChartLoader from 'components/charts/ChartLoader'
import { convertDateToUTCOrLocal } from 'utils/date-formatter'
import { usePermissions } from 'hooks/usePermissions'
import useToasts from 'hooks/useToasts'

import QueryWrapper from './QueryWrapper'
import Query from './query'
import AxisNamesModal from './AxisNamesModal'
import { AST, Axis, ParseTreeRoot, QueryRequest } from './types'
import { parseTreeToAst } from './language'
import { Parser } from './parser'
import HelpModal from './HelpModal'
import { AnalyzerSection } from './AnalyzerSection'
import HistoricalAnalyzerTimeSeriesChart from './HistoricalAnalyzerTimeSeriesChart'
import HistoricalAnalyzerScatterChart from './HistoricalAnalyzerScatterChart'
import HistoricalAnalyzerCdfChart from './HistoricalAnalyzerCdfChart'
import useCodeMirror from './useCodeMirror'
import {
  AddFunctionButton,
  AddNewRowButton,
  ButtonGroupMain,
  StyledControlBar,
  UpdateChartButton,
} from './styled'
import EmptyState from './EmptyState'
import { useMediaQuery } from 'hooks/useMediaQuery'
import { Body1, H4 } from 'components/atoms/Typography/styles'

export const labelForQueryNum = (queryNum: number): string => {
  if (queryNum >= 26) {
    const number = Math.floor(queryNum / 26)
    queryNum = queryNum - 26 * number
    const alpha = String.fromCharCode(queryNum + 65)
    return `${alpha}${number}`
  } else {
    return String.fromCharCode(queryNum + 65)
  }
}

enum ChartRenderType {
  TIMESERIES = 'TIMESERIES',
  SCATTER = 'SCATTER',
  CDF = 'CDF',
  MIXED = 'MIXED',
}

const createAST = (
  parseTree: ParseTreeRoot,
  parsedQueries: Record<string, Query>,
  timestampRange: DateRange,
): AST => {
  const ast = parseTreeToAst(parseTree, parsedQueries)
  const output = {
    start: convertDateToUTCOrLocal(timestampRange.from).toISOString(),
    end: convertDateToUTCOrLocal(timestampRange.until).toISOString(),
    parse_tree: ast as Record<string, any>,
  }
  return output
}

const HistoricalAnalyzer: React.FC = () => {
  const context = useContext(HistoricalAnalyzerContext)
  const { HAEnabled } = usePermissions()
  const {
    chartAxis,
    chartFrequency,
    queries,
    querySeriesMapping,
    sources,
    timestampRange,
    queryCount,
    histChartTitle,
    updateChartBtnStatus,
  } = context.state
  const {
    createQuery,
    editQuery,
    deleteQuery,
    setQuerySeriesMapping,
    setTimestampRange,
    setChartFrequency,
    setChartAxis,
    setHistChartTitle,
  } = context

  const [historicalAnalyzerTitle, setHistoricalAnalyzerTitle] = useState('')
  const { openToast, ToastComponent, ToastType } = useToasts()

  useEffect(() => {
    const isQueriesEmpty = Object.keys(queries).length === 0
    if (isQueriesEmpty) {
      setHistoricalAnalyzerTitle('')
    }
    const similarTitles = Object.values(queries).reduce<
      Record<string, string[]>
    >((acc, item) => {
      if (!item.isActive) return acc
      const name = item.name || item.label
      const splitTitle = name.split('(') // queries are constructed like BTC - Futures (1M)
      const tenorAndExpiry = splitTitle[1]?.slice(0, -1) // remove closing bracket
      if (acc[splitTitle[0]]?.length > 0) {
        if (tenorAndExpiry) {
          acc[splitTitle[0]].push(tenorAndExpiry)
        }
        return acc
      }
      acc[splitTitle[0]] = [tenorAndExpiry || '']
      return acc
    }, {})

    const title = Object.entries(similarTitles).reduce(
      (acc, [t, tenors], index) => {
        const tenorPart = `${t} ${
          tenors.length > 0 ? `(${[...new Set(tenors)].join(',')})` : ''
        }`
        return index === 0 ? `${tenorPart}` : `${acc} - ${tenorPart}`
      },
      '',
    )
    setHistoricalAnalyzerTitle(title)
  }, [queries])

  const handleTitleChange = (event) => {
    setHistChartTitle(event.target.value)
    setHistoricalAnalyzerTitle(event.target.value)
  }

  const [showHelpModal, setShowHelpModal] = useState(false)
  const [errorMap, setErrorMap] = useState<Map<Query, string>>(new Map())
  const [isLoading, setIsLoading] = useState(false)
  const [showAxisNamesModal, setShowAxisNamesModal] = useState(false)
  const [globalError, setGlobalError] = useState<string | null>(null)
  const [inBuiltFunc, setInBuiltFunc] = useState<any>(null)
  const [localVal, setLocalVal] = useState<any>('')
  const [updateChart, setUpdateChart] = useState<boolean>(false)
  const isMobile = useMediaQuery('(max-width: 768px)')

  const { parsed, setInput, clearInput } = useCodeMirror(localVal, sources)
  const [searchParams, setSearchParams] = useSearchParams()
  const theme = useTheme()

  useEffect(() => {
    if (globalError) {
      openToast([
        {
          type: ToastType.ERROR,
          title: 'Sorry, something went wrong',
          description: globalError,
        },
      ])
      setGlobalError(null)
    }
    if (errorMap.size > 0) {
      const errs = Array.from(errorMap.entries())
      const toasts = errs.map((e) => ({
        type: ToastType.ERROR,
        title: `Error in query ${e[0].label}`,
        description: `${e[1]}`,
      }))

      openToast(toasts)
      setErrorMap(new Map())
    }
  }, [errorMap, globalError])

  // Add logic for avoiding empty queries
  const fetchQueries = useCallback(() => {
    setIsLoading(true)
    const parsedQueries = Object.values(queries).reduce((acc, curr) => {
      return {
        ...acc,
        [curr.label]: curr,
      }
    }, {})

    const requests = Object.values(queries).reduce(
      (acc: QueryRequest[], query) => {
        if (!query.expression) return acc

        acc.push({
          query,
          ast: createAST(query.tree, parsedQueries, timestampRange),
        })
        return acc
      },
      [],
    )

    dataService
      .getHistoricalSeries(requests, timestampRange)
      .then(({ successes, errors }) => {
        setQuerySeriesMapping(successes)
        setErrorMap(new Map([...errors].filter(([k]) => k.isActive)))
      })
      .catch((e) => {
        // TODO: log unexpected error
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [createAST, setIsLoading, queries, timestampRange, setQuerySeriesMapping])

  const handleQueryCreate = (
    expression: string | undefined | null,
    parsed: Parser | null,
  ) => {
    if (expression && parsed?.isValid()) {
      const query = new Query({
        expression,
        label: labelForQueryNum(queryCount),
        tree: parsed.parseTree,
      })
      return createQuery(query, parsed)
    }
    if (parsed && parsed.parseTree) {
      const query = new Query({
        expression: '',
        label: labelForQueryNum(queryCount),
        tree: parsed.parseTree,
      })
      createQuery(query, parsed)
    }
  }
  const handleQueryEdit = (query: Query) => {
    editQuery(query)
  }

  // TODO: warn about removing dependent queries
  const handleQueryDelete = (query: Query) => {
    deleteQuery(query)
  }

  // FIXME: this is truly awful code
  const chartRenderType = useMemo(() => {
    const kinds: string[] = []
    const timeseriesOutput: Array<[Query, HistoricalAnalyzerTimeSeriesData]> =
      []
    const regressionOutput: Array<[Query, HistoricalAnalyzerRegressionData]> =
      []
    const cdfOutput: Array<[Query, HistoricalAnalyzerCdfData]> = []

    for (const [query, querySeries] of querySeriesMapping) {
      if (query.isActive) {
        kinds.push(querySeries.kind)
        if (querySeries.kind === 'regression') {
          regressionOutput.push([query, querySeries])
        }
        if (querySeries.kind === 'timeseries') {
          timeseriesOutput.push([query, querySeries])
        }
        if (querySeries.kind === 'cdf') {
          cdfOutput.push([query, querySeries])
        }
      }
    }
    if (kinds.every((k) => k === 'timeseries')) {
      return { type: ChartRenderType.TIMESERIES, data: timeseriesOutput }
    }
    if (kinds.length === 1 && kinds[0] === 'regression') {
      return { type: ChartRenderType.SCATTER, data: regressionOutput }
    }
    if (kinds.length === 1 && kinds[0] === 'cdf') {
      return { type: ChartRenderType.CDF, data: cdfOutput }
    }
    if (kinds.length > 1 && kinds.every((k) => k === 'regression')) {
      setGlobalError(
        'Cannot render multiple regressions. Please select only one',
      )
      return { type: ChartRenderType.MIXED, data: [] }
    }
    if (kinds.length > 1 && kinds.every((k) => k === 'cdf')) {
      setGlobalError('Cannot render multiple CDFs. Please select only one')
      return { type: ChartRenderType.MIXED, data: [] }
    }
    setGlobalError(
      'Cannot combine timeseries, CDF and regression charts. Please select only one type',
    )
    return { type: ChartRenderType.MIXED, data: [] }
  }, [querySeriesMapping])

  const addNewRow = () => {
    handleQueryCreate('', parsed)
  }

  useEffect(() => {
    if (updateChart) {
      fetchQueries()
    }
    setUpdateChart(false)
  }, [updateChart])

  useEffect(() => {
    if (localVal && localVal !== '') {
      handleQueryCreate(localVal, parsed)
      setLocalVal('')
      clearInput()
    }
  }, [localVal])

  useEffect(() => {
    setLocalVal(inBuiltFunc)
    setInput(inBuiltFunc)
  }, [inBuiltFunc])

  if (!HAEnabled) {
    return <EmptyState />
  }
  return isMobile ? (
    <section
      style={{
        textAlign: 'center',
        height: '100%',
        maxWidth: '80%',
        margin: 'auto',
        padding: '2rem',
      }}
    >
      <H4 style={{ paddingBottom: '2rem' }}>
        Sorry, we currently only support this feature on Desktops & Tablets.
      </H4>
      <Body1>
        We apologize for the inconvenience. Due to the complexity of our
        historical analyzer tool we currently only support a limited amount of
        devices, whilst we actively look to improve the tool on smaller devices
        please use a larger device.
      </Body1>
    </section>
  ) : (
    <Page style={{ position: 'relative' }}>
      {showHelpModal && (
        <HelpModal
          handleClose={() => setShowHelpModal(false)}
          setFunctionName={setInBuiltFunc}
        />
      )}
      {showAxisNamesModal && (
        <AxisNamesModal
          axis={chartAxis}
          handleClose={(updated: Axis) => {
            if (
              updated.left.label !== chartAxis.left.label ||
              updated.right.label !== chartAxis.right.label ||
              updated.left.format !== chartAxis.left.format ||
              updated.right.format !== chartAxis.right.format
            ) {
              setChartAxis(updated)
            }
            setShowAxisNamesModal(false)
          }}
        />
      )}
      {isLoading && (
        <div
          style={{
            position: 'absolute',
            display: 'flex',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 1000,
            color: theme.palette.primary.contrastText,
            background:
              'radial-gradient(circle at 50% 50%, rgb(0, 0, 0) 0%, rgba(0, 0, 0, 0) 100%)',
          }}
        >
          <ChartLoader />
        </div>
      )}
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          maxWidth: '1800px',
          margin: 'auto',
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            paddingTop: '5rem',
            width: '30px',
          }}
        >
          <span
            style={{
              transform: 'rotate(-90deg)',
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <input
              size={50}
              style={{
                WebkitAppearance: 'none',
                background: 'none',
                color: 'rgb(228, 231, 235)',
                borderStyle: 'none',
                textAlign: 'center',
              }}
              placeholder="Click to edit axis name"
              value={chartAxis.left.label || undefined}
              onChange={(event) =>
                setChartAxis({
                  ...chartAxis,
                  left: { ...chartAxis.left, label: event.target.value },
                })
              }
            />
          </span>
        </div>
        <div
          style={{
            display: 'flex',
            width: '100%',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <input
            size={50}
            style={{
              WebkitAppearance: 'none',
              background: 'none',
              color: 'rgb(228, 231, 235)',
              borderStyle: 'none',
              textAlign: 'center',
              fontSize: '1.2rem',
              fontWeight: 700,
              paddingLeft: '12px',
              paddingRight: '12px',
            }}
            className="histTitleClassTitle"
            placeholder="Click to edit the title"
            value={histChartTitle || historicalAnalyzerTitle}
            onChange={(event) => handleTitleChange(event)}
          />
          {chartRenderType.type === ChartRenderType.TIMESERIES ? (
            <HistoricalAnalyzerTimeSeriesChart
              data={
                chartRenderType.data as Array<
                  [Query, HistoricalAnalyzerTimeSeriesData]
                >
              }
              dateRange={timestampRange}
              axis={chartAxis}
              frequency={chartFrequency}
            />
          ) : chartRenderType.type === ChartRenderType.SCATTER ? (
            <HistoricalAnalyzerScatterChart
              data={
                chartRenderType.data as Array<
                  [Query, HistoricalAnalyzerRegressionData]
                >
              }
            />
          ) : chartRenderType.type === ChartRenderType.CDF ? (
            <HistoricalAnalyzerCdfChart
              data={
                chartRenderType.data as Array<
                  [Query, HistoricalAnalyzerCdfData]
                >
              }
            />
          ) : (
            <HistoricalAnalyzerTimeSeriesChart
              data={[]}
              dateRange={timestampRange}
              axis={chartAxis}
              frequency={chartFrequency}
            />
          )}
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            paddingTop: '5rem',
            width: '30px',
          }}
        >
          <span
            style={{
              transform: 'rotate(90deg)',
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <input
              size={50}
              style={{
                WebkitAppearance: 'none',
                background: 'none',
                color: 'rgb(228, 231, 235)',
                borderStyle: 'none',
                textAlign: 'center',
              }}
              placeholder="Click to edit axis name"
              value={chartAxis.right.label || undefined}
              onChange={(event) =>
                setChartAxis({
                  ...chartAxis,
                  right: { ...chartAxis.right, label: event.target.value },
                })
              }
            />
          </span>
        </div>
      </div>
      <AnalyzerSection>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            maxWidth: '2400px',
          }}
        >
          <p>
            You can create expressions using the{' '}
            <span
              className="clickable"
              onKeyPress={() => setShowHelpModal(true)}
              onClick={() => setShowHelpModal(true)}
            >
              built-in functions
            </span>{' '}
            and{' '}
            <span
              className="clickable"
              onKeyPress={() => setShowAxisNamesModal(true)}
              onClick={() => setShowAxisNamesModal(true)}
            >
              name the axes
            </span>
            .
          </p>
          <StyledControlBar
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <div style={{ marginLeft: 'auto' }}>
              <FrequencyChange
                frequency={chartFrequency}
                updateFrequency={setChartFrequency}
                disableMinutes
              />
            </div>
            <DateRangeSelector
              frequency={FREQUENCIES[chartFrequency]}
              timestampRange={timestampRange}
              setTimestampRange={
                setTimestampRange as Dispatch<SetStateAction<DateRange>>
              }
              rootStyle={{ width: 'auto' }}
            />
            <UpdateChartButton>
              <Button
                size="sm"
                type="button"
                disabled={
                  isLoading ||
                  Object.keys(queries).length === 0 ||
                  updateChartBtnStatus
                }
                onClick={fetchQueries}
              >
                Update chart
              </Button>
            </UpdateChartButton>
            <div className="button-group">
              <ButtonGroupMain>
                <AddNewRowButton>
                  <Button size="sm" onClick={addNewRow}>
                    Add +
                  </Button>
                </AddNewRowButton>
                <AddFunctionButton>
                  <Button
                    size="sm"
                    fullHeight
                    onClick={() => setShowHelpModal(true)}
                  >
                    Functions
                  </Button>
                </AddFunctionButton>
                <AddFunctionButton>
                  <Button
                    size="sm"
                    onClick={() =>
                      setSearchParams({
                        searchOpen: 'true',
                        search: searchParams.get('search') || '',
                      })
                    }
                  >
                    Open Data Search
                  </Button>
                </AddFunctionButton>
              </ButtonGroupMain>
            </div>
          </StyledControlBar>

          <QueryWrapper
            queries={Object.values(queries)}
            dataSources={sources}
            handleQueryCreate={handleQueryCreate}
            handleQueryEdit={handleQueryEdit}
            handleQueryDelete={handleQueryDelete}
            updateChart={setUpdateChart}
          />
        </div>
      </AnalyzerSection>
      <ToastComponent />
    </Page>
  )
}

export default HistoricalAnalyzer
