import React from 'react'

import useMultiSeriesChart from 'hooks/useMultiSeriesChart'
import { Source, Ticker } from 'types'
import AssetClass from 'types/assetClass'
import { Model, SABRParam } from 'types/models'

import useDerivedActiveListedExpiry from 'hooks/useDerivedActiveListedExpiry'
import ChartLoader from '../ChartLoader'
import MultiLineSeriesWrapper from '../common/series/MultiSeriesWrapper'
import { SeriesPills } from '../common/series/Selector'

const OptionsHistoricalModel: React.FC = () => {
  const title = 'Implied Vol Model Parameters'

  const id = `${AssetClass.OPTION}-${title}`
  // const { listedExpiries, isLoading } = useDerivedActiveListedExpiry({
  //   queryKeys: [`deribit.option.BTC.SABR.1h.params`],
  //   refetchInterval: Infinity,
  //   timestampRange: { range: '1d' }, // Short range just to get the possible expiries
  //   enabled: true,
  //   dataFreq: '1h',
  //   refetchOnWindowFocus: false,
  // })

  const { chartSeries, highlighted, setHighlighted } = useMultiSeriesChart(id, [
    {
      assetClass: AssetClass.OPTION,
      middle: {
        model: Model.SVI,
        currency: Ticker.BTC,
        tenor: '30d',
        param: SABRParam.Rho,
      },
      suffix: 'params',
      source: Source.DERIBIT,
    },
    {
      assetClass: AssetClass.OPTION,
      middle: {
        model: Model.SVI,
        currency: Ticker.ETH,
        tenor: '30d',
        param: SABRParam.Rho,
      },
      suffix: 'params',
      source: Source.DERIBIT,
    },
    // {
    //   isListedExpiry: true,
    //   expiry: listedExpiries[0],
    //   source: Source.DERIBIT,
    //   baseAsset: Ticker.BTC,
    //   model: Model.SVI,
    //   suffix: 'params',
    //   field: 'Rho',
    // },
    // {
    //   isListedExpiry: true,
    //   expiry: listedExpiries[2],
    //   source: Source.DERIBIT,
    //   baseAsset: Ticker.BTC,
    //   model: Model.SVI,
    //   suffix: 'params',
    //   field: 'Rho',
    // },
  ])

  //  isLoading ? (
  //   <ChartLoader />
  // ) : (
  return (
    <MultiLineSeriesWrapper
      chartSeries={chartSeries}
      title={title}
      id={id}
      axisLabel=""
      axis={{ LEFT: 'n2' }}
      assetType={AssetClass.OPTION}
      useModelParam
      suffix="params"
      addVol={false}
      useTenors
      seriesPills={
        <SeriesPills
          chartSeries={chartSeries}
          id={id}
          setHighlighted={setHighlighted}
        />
      }
      highlightedIndx={highlighted}
      // listedExpiry={{ enabled: true }}
      // fetchListedExpiries
    />
  )
  //   )
}

export default OptionsHistoricalModel
