import React, { useCallback } from 'react'
import { CheckIcon } from '@radix-ui/react-icons'
import { ItemText } from '@radix-ui/react-select'

import ChartWrapper from 'components/common/ChartWrapper'
import {
  getCssColorValueForHeatmapCell,
  HeatMapLegend,
} from 'components/common/HeatMapLegend'
import InfoTooltip from 'components/molecules/InfoTooltip'
import { NOW_UTC } from 'consts'

import { Chart, FutureHeatMapDataPoint, Source, Ticker } from 'types'
import { getFuturesHeatmapData } from 'services/futuresHeatmap'
import useImportToAnalyzer from 'hooks/useImportToAnalyzer'
import { formatNumber } from '@telerik/kendo-intl'
import { TooltipContext } from '@progress/kendo-react-charts'
import { useQuery } from '@tanstack/react-query'
import { getRefetchInterval } from 'services'

import { usePermissions } from 'hooks/usePermissions'
import { useDefaultStore } from 'stores'
import ControlledSelect, {
  SelectItem,
  SelectItemIndicator,
} from 'components/atoms/Select/Index'
import Modal from 'components/atoms/Modal'
import { HeatMapState } from 'stores/types'
import AssetClass from 'types/assetClass'
import { initialHeatmapChartState } from 'stores/slices/default-charts'
import FutureHeatMapChart from './FuturesHeatMapChart'
import ChartLoader from '../ChartLoader'
import DateLatestToggle from '../common/DateLatestToggle'
import { useMediaQuery } from 'hooks/useMediaQuery'

const tooltipRenderer = (props: TooltipContext) => {
  if (!props.point) {
    return <></>
  }
  const { dataItem } = props.point
  return (
    <table>
      <tbody>
        <tr>
          <td>X-Instrument</td>
          <td>{dataItem.xInstrument}</td>
        </tr>
        <tr>
          <td>Y-Instrument</td>
          <td>{dataItem.yInstrument}</td>
        </tr>
        <tr>
          <td>Spread</td>
          <td>{formatNumber(dataItem.value, 'n4')}</td>
        </tr>
        <tr>
          <td>Z-Score</td>
          <td>{formatNumber(dataItem.zScore, 'n2')}</td>
        </tr>
      </tbody>
    </table>
  )
}
const FuturesHeatMapChart: React.FC = () => {
  const { hasMinuteData } = usePermissions()
  const isMobile = useMediaQuery('(max-width: 768px)')

  const title = 'Spreads Heatmap'
  const currencies = [Ticker.BTC, Ticker.ETH]
  const sources = [Source.DERIBIT]
  const chart =
    useDefaultStore((state) => state.defaultCharts?.[title] as HeatMapState) ||
    initialHeatmapChartState

  const updateChart = useDefaultStore((s) => s.updateHeatMap)

  const updateTiming = (single: 'LATEST' | Date) =>
    updateChart(title, 'timestamp', single)
  const updateHeatMap = (key: 'currency' | 'source', val) =>
    updateChart(title, key, val)

  const isOneMinData = hasMinuteData && chart?.timestamp === 'LATEST'
  const frequency = isOneMinData ? '1m' : '1h'
  const importToAnalyzer = useImportToAnalyzer({
    frequency,
  })
  const { data, isLoading } = useQuery({
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: [
      'futures',
      'heatmap',
      chart.source || Source.DERIBIT,
      chart.currency || Ticker.BTC,
      chart.timestamp === 'LATEST' ? NOW_UTC() : chart.timestamp,
    ],
    queryFn: () =>
      getFuturesHeatmapData(
        chart.source || Source.DERIBIT,
        chart.currency || Ticker.BTC,
        chart.timestamp || 'LATEST',
        frequency,
      ),
    keepPreviousData: chart.timestamp === 'LATEST',
    refetchInterval: getRefetchInterval(chart.timestamp, hasMinuteData),
  })
  const [series, timestamp] = data || []
  const handleCellClick = (item: FutureHeatMapDataPoint) => {
    const yInstrument =
      item.yInstrument === 'spot'
        ? `${chart.source.toLowerCase()}.spot.${
            chart.currency
          }USD.${frequency}.index.px`
        : `${chart.source.toLowerCase()}.future.${
            item.yInstrument
          }.${frequency}.px`
    const cellQuery = `${chart.source.toLowerCase()}.future.${
      item.xInstrument
    }.${frequency}.px - ${yInstrument}`
    importToAnalyzer([cellQuery], 'Spreads')
  }
  const values = series?.map((item) => item.zScore) || []
  const min = Math.min(...values)
  const max = Math.max(...values)

  const colorCell = useCallback(
    (cell) => getCssColorValueForHeatmapCell(cell.zScore, min, max),
    [min, max],
  )
  const enlargedChart = useDefaultStore((state) => state.expandedId)
  const setExpandedChart = useDefaultStore((state) => state.setExpandedChart)
  const middle = Math.min(0, Number(((max + min) / 2).toPrecision(3)))
  return (
    <Modal
      open={enlargedChart === title}
      onClose={() => setExpandedChart(null)}
    >
      <ChartWrapper
        id={title}
        title={title}
        updatedStamp={timestamp ? new Date(timestamp) : undefined}
        timeZone={chart.timeZone}
        type={Chart.HEATMAP}
        assetClass={AssetClass.FUTURE}
        axis={{ LEFT: 'n2' }}
        legend={
          isLoading ? undefined : (
            <HeatMapLegend
              valueColorList={[
                {
                  value: formatNumber(min, 'n2'),
                  color: getCssColorValueForHeatmapCell(min, min, max),
                },
                {
                  value: middle,
                  color: getCssColorValueForHeatmapCell(0, min, max),
                },
                {
                  value: formatNumber(max, 'n2'),
                  color: getCssColorValueForHeatmapCell(max, min, max),
                },
              ]}
              title="Z Score"
            />
          )
        }
        topBarContent={
          <div style={{ display: 'flex' }}>
            <ControlledSelect
              value={chart.source}
              disabled={sources && sources.length <= 1}
              onValueChange={(v) => updateHeatMap('source', v)}
            >
              {sources.map((s) => (
                <SelectItem value={s} key={s}>
                  <SelectItemIndicator>
                    <CheckIcon />
                  </SelectItemIndicator>
                  <ItemText>{s}</ItemText>
                </SelectItem>
              ))}
            </ControlledSelect>
            <ControlledSelect
              defaultValue={chart.currency}
              onValueChange={(v) => updateHeatMap('currency', v)}
            >
              {(currencies || []).map((s) => (
                <SelectItem value={s} key={s}>
                  <SelectItemIndicator>
                    <CheckIcon />
                  </SelectItemIndicator>
                  <ItemText>{s}</ItemText>
                </SelectItem>
              ))}
            </ControlledSelect>
            {!isMobile && (
              <DateLatestToggle
                setDate={updateTiming}
                date={chart.timestamp}
                timestamp={timestamp}
              />
            )}
          </div>
        }
        infoButton={
          <InfoTooltip
            title={`Click on a cell to explore the underlying timeseries in our Analyzer${
              isOneMinData ? '(1min data is currently unavailable)' : ''
            }.`}
          />
        }
        chart={
          <>
            {isLoading && <ChartLoader />}
            {series && (
              <FutureHeatMapChart
                data={series}
                color={colorCell}
                onCellClick={handleCellClick}
                tooltipRenderer={tooltipRenderer}
                isOneMinData={isOneMinData}
              />
            )}
          </>
        }
      />
    </Modal>
  )
}

export default FuturesHeatMapChart
