import React, { useEffect, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'

import { Button, Image, useAuthenticator, View } from '@aws-amplify/ui-react'
import {
  QueryClient,
  QueryClientProvider,
  useQuery,
} from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Outlet,
  redirect,
  Route,
  RouterProvider,
  ScrollRestoration,
  useLoaderData,
  useNavigate,
  useNavigation,
  useOutlet,
  useSearchParams,
} from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import theme from 'styles/theme'
// eslint-disable-next-line import/no-unresolved, import/extensions
import '@aws-amplify/ui-react/styles.css'
import { Provider as ToastProvider } from '@radix-ui/react-toast'
import CookieBanner from 'components/common/CookieBanner'
import MenuNavContainer from 'components/navigation/MenuNavContainer'
import { CatalogProvider } from 'context/CatalogContext'
import { HistoricalAnalyzerProvider } from 'context/HistoricalAnalyzerContext'
import HistoricalAnalyzer from 'pages/HistoricalAnalyzer/HistoricalAnalyzer'
import OptionPricer from 'pages/OptionPricer/OptionPricer'

import { Amplify, Hub } from 'aws-amplify'
import Authenticator from 'components/molecules/Authenticator'
import useLocalStorage, { localStorageMap } from 'hooks/useLocalStorage'
import {
  FuturesDashBoard,
  IndicesDashBoard,
  OptionsDashBoard,
  PerpetualsDashBoard,
} from 'pages/dashboards'
import Register from 'pages/Register'
import posthog from 'posthog-js'

import ChartLoader from 'components/charts/ChartLoader'
import AppToolTip from 'components/charts/common/AppToolTip'
import IntegrationJwtFetcher from 'components/integrations/Fetcher'
import OauthCallback from 'components/integrations/OauthCallback'
import PaymentCallback from 'components/integrations/PaymentCallback'
import { usePermissions } from 'hooks/usePermissions'
import MonitorDashboard from 'pages/dashboards/Monitors'
import Pricing from 'pages/Pricing'
import { getPermissionsQuery } from 'services/queries'
import { useLiveStore } from 'stores'
import { LiveActionTypes } from 'stores/types'

import ResearchDashBoard from 'pages/Research'
import { Chart, Source, Ticker, YAxis } from 'types'
import AssetClass from 'types/assetClass'
import { Dashboard, DefaultCharts } from 'types/dashboard'
import awsconfig from './aws-exports'
import whiteLogo from './images/blockscholes-white.svg'
import rectLight from './images/rect-light.svg'
import { useMediaQuery } from 'hooks/useMediaQuery'
import { Model } from 'types/models'
import { CHART_COLORS } from 'components/charts/common/consts'
import { isProd } from 'consts'

Amplify.configure(awsconfig)
const loginComponents = {
  ForceNewPassword: {
    FormFields() {
      return (
        <>
          <View textAlign="center" paddingLeft={24} paddingRight={24}>
            <Image alt="Blockscholes logo" src={whiteLogo} />
          </View>
          <p>
            Passwords must be at 10 characters long and require numbers,
            uppercase letters and lowercase letters
          </p>
          <Authenticator.ForceNewPassword.FormFields />
        </>
      )
    },
  },
  ConfirmResetPassword: {
    Header() {
      return (
        <>
          <View textAlign="center" paddingLeft={24} paddingRight={24}>
            <Image alt="Blockscholes logo" src={whiteLogo} />
          </View>
          <p>
            Passwords must be at 10 characters long and require numbers,
            uppercase letters and lowercase letters
          </p>
        </>
      )
    },
  },
  SignIn: {
    Header() {
      return (
        <View textAlign="center" paddingLeft={24} paddingRight={24}>
          <Image alt="Blockscholes logo" src={whiteLogo} />
        </View>
      )
    },
    Footer() {
      const { toResetPassword } = useAuthenticator((c) => [c.toResetPassword])
      const navigate = useNavigate()
      const goToRegister = () => navigate('/register')

      return (
        <View textAlign="center">
          <Button
            fontWeight="normal"
            onClick={toResetPassword}
            variation="link"
          >
            Reset Password
          </Button>
          <Button
            fontWeight="normal"
            style={{
              color: 'white',
              border: '1px solid white',
              marginLeft: '4px',
            }}
            variation="menu"
            onClick={goToRegister}
          >
            Register
          </Button>
        </View>
      )
    },
  },
  ResetPassword: {
    Header() {
      return (
        <View textAlign="center" paddingLeft={24} paddingRight={24}>
          <Image alt="Blockscholes logo" src={whiteLogo} />
        </View>
      )
    },
  },
}

const Login = () => {
  const isMobile = useMediaQuery('(max-width: 768px)')
  return (
    <>
      <Authenticator
        hideSignUp
        loginMechanisms={['email']}
        components={loginComponents}
        isMobile={isMobile}
      />

      {!isMobile && (
        <img
          alt="background peaks"
          src={rectLight}
          style={{
            transform: 'scaleX(-1)',
            position: 'absolute',
            bottom: 0,
            left: 0,
            width: '100%',
            height: '100%',
            zIndex: -1,
          }}
        />
      )}
    </>
  )
}
const queryClient = new QueryClient({
  defaultOptions: { queries: { cacheTime: 60 * 1000 } },
})

const QueryResetter = () => {
  useEffect(() => {
    const interval = setInterval(() => {
      queryClient.removeQueries({
        predicate: (q) => q.queryKey.includes('totalPoints'),
      })
    }, 1000 * 60 * 60 * 24) // RESET CUSTOM CACHE every 24 hours
    return () => clearInterval(interval)
  }, [])
  return <></>
}

const WebSocketAuth = () => {
  const { hasValidLicense } = usePermissions()
  const dispatch = useLiveStore((s) => s.actions.dispatch)
  useEffect(() => {
    if (hasValidLicense) {
      dispatch({ type: LiveActionTypes.SET_READY })
    }
    return () => {
      dispatch({ type: LiveActionTypes.CLOSE })
    }
  }, [hasValidLicense, dispatch])
  return <></>
}

const RequireValidLicense: React.FC<{ hasAuth: boolean }> = ({ hasAuth }) => {
  const [searchParams] = useSearchParams()
  const { route } = useAuthenticator((context) => [context?.route])

  const [fetchCounter, setFetchCounter] = useState(0)
  const StripeId = searchParams.get('id')
  const { data: res, isLoading } = useQuery({
    ...getPermissionsQuery({
      refetchCondition: !!StripeId,
      incrementRunCounter: setFetchCounter,
    }),
  })

  if (route !== 'authenticated' && !hasAuth) {
    return <Login />
  }

  if ((!res && fetchCounter < 4) || isLoading) return <ChartLoader />
  if (
    (res && !res?.EXPIRY_DATE) ||
    (res?.EXPIRY_DATE && new Date(res.EXPIRY_DATE) < new Date())
  ) {
    return <Pricing hadLicense={!!res?.EXPIRY_DATE} />
  }
  return <Outlet />
}

const HomeLayout = () => {
  const location = useNavigation()
  const outlet = useOutlet()
  useEffect(() => {
    posthog.capture('$pageview')
  }, [location])

  return (
    <>
      <main>
        <MenuNavContainer />
        <ScrollRestoration />
        {outlet}
      </main>
    </>
  )
}

const App: React.FC = () => {
  const [hasSetCookiePreferences, setCookiePreferences] =
    useLocalStorage<boolean>(localStorageMap.COOKIE_BANNER_INTERACTION, false)

  const [auth, setAuth] = useState(false)

  Hub.listen('auth', (e) => {
    if (e.payload.event === 'autoSignIn' || e.payload.event === 'signIn') {
      setAuth(true)
    }
    if (e.payload.event === 'signOut') {
      setAuth(false)
    }
  })

  useEffect(() => {
    posthog.init('phc_22446wQsntSXlOUXIAkrVBmwvJQSSEeCFAvoqo3mGsM', {
      api_host: 'https://eu.posthog.com',
      opt_out_capturing_by_default: true,
    })
  }, [])

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route
        loader={({ params }) => {
          // TODO this is a hack and should all come from the DB, including research component below
          if (params?.researchId === '3d47d8ef-c7c7-4590-83eb-e22604c1ae1d') {
            return {
              signUpPath: '/register?referral=PARADIGM',
              displayApiLink: true,
            }
          }
          return {}
        }}
        shouldRevalidate={({ nextParams, currentParams }) => {
          return currentParams?.researchId !== nextParams?.researchId
        }}
        element={<HomeLayout />}
      >
        <Route element={<RequireValidLicense hasAuth={auth} />}>
          <Route index element={<Navigate to="/dashboard/futures" replace />} />
          <Route
            path="/"
            element={<Navigate to="/dashboard/futures" replace />}
          />
          <Route path="dashboard">
            <Route path="futures" element={<FuturesDashBoard />} />
            <Route path="options" element={<OptionsDashBoard />} />
            <Route path="perpetual-swaps" element={<PerpetualsDashBoard />} />
            <Route path="indices" element={<IndicesDashBoard />} />
          </Route>
          <Route
            path="/option-pricer"
            element={<OptionPricer useScenarioAnalysis />}
          />
          <Route path="/historical-analyzer" element={<HistoricalAnalyzer />} />
        </Route>
        <Route
          path="research/:researchId"
          element={<ResearchDashBoard />}
          loader={async ({ params }): Promise<Dashboard | {}> => {
            // TODO move to a server side fetch using object store also store defaults in object below
            if (params?.researchId === '3d47d8ef-c7c7-4590-83eb-e22604c1ae1d') {
              return {
                id: 'paradigm',
                items: [
                  {
                    id: uuidv4(),
                    type: Chart.TIMESERIES,
                    availableTenors: ['7d', '30d', '90d'],
                    useLatestDatePicker: false,
                    assetClass: AssetClass.OPTION,
                    defaultId: DefaultCharts.OPTION_SMILE,
                    layout: { x: 0, y: 0, width: 5 },
                  },
                  {
                    id: uuidv4(),
                    type: Chart.TIMESERIES,
                    defaultId: DefaultCharts.HEATMAP_OPTION_IMPLIED_VOL,
                    availableTenors: ['7d', '30d', '90d'],
                    layout: { x: 6, y: 0, width: 7 },
                  },
                  {
                    id: uuidv4(),
                    type: Chart.TIMESERIES,
                    availableTenors: ['7d', '30d', '90d'],
                    useLatestDatePicker: false,
                    assetClass: AssetClass.OPTION,
                    defaultId: DefaultCharts.OPTION_RATIO,
                    layout: { x: 0, y: 6, width: 12, height: 22 },
                    defaultSeries: [
                      {
                        assetClass: AssetClass.OPTION,
                        middle: {
                          currency: Ticker.BTC,
                          tenor: '',
                          type: 'vol',
                        },
                        suffix: 'realized',
                        source: Source.COMPOSITE,
                      },
                      {
                        assetClass: AssetClass.OPTION,
                        middle: {
                          model: Model.SABR,
                          currency: Ticker.BTC,
                          tenor: '30d',
                          type: 'vol',
                        },
                        suffix: 'atm',
                        source: Source.DERIBIT,
                      },
                    ],
                  },
                  {
                    id: uuidv4(),
                    type: Chart.TIMESERIES,
                    tenors: ['7d', '30d', '90d'],
                    defaultId: DefaultCharts.OPTION_SKEW,
                    availableTenors: ['7d', '30d', '90d'],
                    layout: {
                      x: 0,
                      y: 12,
                      width: 6,
                    },
                  },
                  {
                    id: uuidv4(),
                    type: Chart.TIMESERIES,
                    defaultId: DefaultCharts.PERP_FUNDING_RATE,
                    layout: {
                      x: 6,
                      y: 12,
                      width: 6,
                    },
                  },
                  {
                    id: uuidv4(),
                    type: 'table',
                    defaultId: 'option-pricer',
                    layout: {
                      x: 0,
                      y: 18,
                      width: 12,
                      height: 14,
                    },
                  },
                ],
              }
            }

            return {}
          }}
        />
        <Route
          path="/login"
          element={<Login />}
          loader={() => {
            if (auth) return redirect('/')
            return null
          }}
        />
        <Route path="/register/oauth/callback" element={<OauthCallback />} />
        <Route path="/oauth/payment" element={<PaymentCallback />} />
        <Route path="/register" element={<Register />}>
          <Route path=":referral" element={<Register />} />
        </Route>
      </Route>,
    ),
  )
  return (
    <div id="app" className="scroll-fix">
      <ThemeProvider theme={theme}>
        <Authenticator.Provider>
          <QueryClientProvider client={queryClient}>
            <QueryResetter />
            <WebSocketAuth />
            <ReactQueryDevtools />
            <HistoricalAnalyzerProvider>
              <IntegrationJwtFetcher />
              <AppToolTip />
              <CatalogProvider>
                <ToastProvider>
                  <RouterProvider router={router} />

                  {hasSetCookiePreferences ? null : (
                    <CookieBanner setCookiePreferences={setCookiePreferences} />
                  )}
                </ToastProvider>
              </CatalogProvider>
            </HistoricalAnalyzerProvider>
          </QueryClientProvider>
        </Authenticator.Provider>
      </ThemeProvider>
    </div>
  )
}

export default App
