import { OptionPriceQuery } from 'types/queries'
import { EnrichedOptionPriceQuery, CompleteOptionPriceQuery } from './types'

export const queryIsComplete = (
  query: OptionPriceQuery,
): query is CompleteOptionPriceQuery => {
  return (
    query.model !== undefined &&
    query.type !== undefined &&
    query.ccy !== undefined &&
    query.strike !== undefined &&
    query.type !== undefined
  )
}

export const queryIsEnriched = (
  query: OptionPriceQuery,
): query is EnrichedOptionPriceQuery => {
  return (
    queryIsComplete(query) &&
    query.price !== undefined &&
    query['implied vol'] !== undefined &&
    query.spot !== undefined &&
    query.delta !== undefined &&
    query.gamma !== undefined &&
    query.vega !== undefined &&
    query.theta !== undefined &&
    query.volga !== undefined &&
    query.vanna !== undefined
  )
}
