import { useAuthenticator } from '@aws-amplify/ui-react'
import { Menu, MenuItem, MenuSelectEvent } from '@progress/kendo-react-layout'
import LiveDataFeed from 'components/live'
import * as NavigationMenu from '@radix-ui/react-navigation-menu'
import posthog from 'posthog-js'
import { deleteDB } from 'idb'
import {
  KeyboardEvent,
  MouseEvent,
  MouseEventHandler,
  forwardRef,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { NavLink, useLoaderData, useNavigate } from 'react-router-dom'
import styled, { useTheme } from 'styled-components'
import { HamburgerMenuIcon } from '@radix-ui/react-icons'
import { Dialog } from '@progress/kendo-react-dialogs'
import { useQuery } from '@tanstack/react-query'
import { useLivePrices } from 'components/live/useLivePrices'
import useLocalStorage, { localStorageMap } from 'hooks/useLocalStorage'
import logo from 'images/logo.svg'
import postman from 'images/postman.svg'
import { getDbUser, getStripePortal } from 'services'
import './styles.css'
import BannerNotification from './BannerNotification'
import { useMediaQuery } from 'hooks/useMediaQuery'
import VerticalDivider from 'components/atoms/Divider'
import Button from 'components/atoms/Button'
import { usePermissions } from 'hooks/usePermissions'

const StyledMenu = styled(Menu)`
  color: ${(props) => props.theme.palette.common.blue12};
  .k-menu {
    font-weight: 700;
  }
  .k-menu:not(.k-context-menu) > li.k-item {
    color: ${(props) => props.theme.palette.common.blue12};
    transition: color 300ms;
  }
  .k-menu:not(.k-context-menu) > li.k-item:hover {
    color: ${(props) => props.theme.palette.primary.contrastText};
    transition: color 150ms;
  }
  border-bottom: 2px solid ${(props) => props.theme.palette.common.kendoGrey};
  & > ul.k-menu.k-menu-horizontal:not(.k-context-menu) {
    padding-left: 1.5rem;
  }
  & span.k-menu-expand-arrow {
    padding-top: 4px;
  }
  .k-menu:not(.k-context-menu) > .k-item:focus {
    box-shadow: none;
  }
  .high-light {
    color: ${(props) => props.theme.palette.primary.contrastText} !important;
  }
`
const SubMenuItem = styled(NavLink)<{ isactive: string }>`
  display: flex;
  align-items: center;
  padding: 0.5rem 0.7rem;
  text-transform: capitalize;
  margin-top: 14px;
  font-size: 0.8rem;
  ${({ isactive }) =>
    isactive === 'true' &&
    `
    font-size: 1rem;
    border: 2px solid;
    border-bottom: 3px solid black;
    border-radius: 12px 12px 0 0;
 `};
`

// TODO once design system is transferred and not a mess create a atom/component for lists
const ListItem = forwardRef<
  HTMLAnchorElement,
  {
    className?: string
    children?: string
    title: string
    href?: string
    disabled?: boolean
    newTab?: boolean
    onClick?: MouseEventHandler<HTMLAnchorElement>
  }
>(
  (
    { className, children, title, href, onClick = () => {}, newTab, disabled },
    forwardedRef,
  ) => {
    const theme = useTheme()
    return (
      <li>
        <NavigationMenu.Link className="hoverItem" asChild>
          <a
            // className={classNames('ListItemLink', className)}
            className={`ListItemLink ${className} ${
              window?.location.pathname.includes(href || '') && !disabled
                ? 'HighlightItem'
                : ''
            }`}
            href={href}
            target={newTab ? '_blank' : ''}
            rel={newTab ? 'noreferrer noopener' : ''}
            onClick={onClick}
            ref={forwardedRef}
          >
            <div
              className="ListItemHeading"
              style={{
                color: theme.palette.common.blue12,
                textTransform: 'capitalize',
              }}
            >
              {title}
            </div>
            <p
              className="ListItemText"
              style={{
                color: theme.palette.common.blue11,
              }}
            >
              {children}
            </p>
          </a>
        </NavigationMenu.Link>
      </li>
    )
  },
)

const MobileMenu = ({
  homeRoute,
  haRoute,
  optionPricerRoute,
  stripeUrl,
  username,
  handleSignOutClick,
  logIn,
  signUpPath,
}: {
  homeRoute: string
  haRoute: string
  optionPricerRoute: string
  stripeUrl?: string
  username?: string
  handleSignOutClick: (e: any) => void
  logIn: (e: any) => void
  signUpPath?: string
}) => {
  const theme = useTheme()
  const { HAEnabled } = usePermissions()
  return (
    <NavigationMenu.Root className="NavigationMenuRoot">
      <NavigationMenu.List className="NavigationMenuList">
        <NavigationMenu.Item>
          <NavigationMenu.Trigger className="NavigationMenuTrigger">
            <HamburgerMenuIcon color="white"></HamburgerMenuIcon>
          </NavigationMenu.Trigger>
          <NavigationMenu.Content
            className="NavigationMenuContent"
            style={{
              backgroundColor: theme.palette.common.blue4,
            }}
          >
            <ul className="List one">
              <ListItem title="home" href={homeRoute}>
                Explore our dashboards to gain a competitive advantage in
                futures, options, perpetuals, and our proprietary indices.
              </ListItem>
              {HAEnabled && (
                <ListItem title="Historical Analyzer" href={haRoute}>
                  Pull in any of our timeseries and apply statistical analysis
                  to it, like regression or correlation.
                </ListItem>
              )}
              <ListItem title="Option Pricer" href={optionPricerRoute}>
                Calculate option values using our models for any expiration date
                and get detailed pricing and Greek metrics.
              </ListItem>
              <ListItem
                title="API Documentation"
                href="https://apidocs.blockscholes.com"
                newTab
              ></ListItem>
            </ul>
            <VerticalDivider colour="blue11" />
            <ul className="List one">
              {username ? (
                <>
                  <ListItem
                    title={username}
                    disabled
                    onClick={(e) => e.stopPropagation()}
                  />
                  {stripeUrl && (
                    <ListItem title="Subscription" href={stripeUrl}>
                      Handle invoices, subscription type and cards.
                    </ListItem>
                  )}
                  <ListItem title="Sign Out" onClick={handleSignOutClick}>
                    {' '}
                  </ListItem>
                </>
              ) : (
                <>
                  <ListItem
                    title={signUpPath ? 'Sign Up' : 'Log in'}
                    onClick={logIn}
                  >
                    {' '}
                  </ListItem>
                </>
              )}
            </ul>
          </NavigationMenu.Content>
        </NavigationMenu.Item>
      </NavigationMenu.List>

      <div className="ViewportPosition">
        <NavigationMenu.Viewport className="NavigationMenuViewport" />
      </div>
    </NavigationMenu.Root>
  )
}

const HomeSubNav = ({ setLatestDashboard }) => {
  const theme = useTheme()
  const { user } = useAuthenticator((context) => [context?.user])

  return window && window?.location.pathname.includes(`/dashboard`) && user ? (
    <div
      style={{
        width: '100%',
        height: '50px',
        backgroundColor: theme.palette.background.base,
        borderBottom: '1px solid',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      {['futures', 'options', 'perpetual-swaps', 'indices'].map((s) => (
        <SubMenuItem
          key={s}
          to={`/dashboard/${s}`}
          onClick={() => setLatestDashboard(s)}
          isactive={String(
            window && window?.location.pathname === `/dashboard/${s}`,
          )}
        >
          {s}
        </SubMenuItem>
      ))}
    </div>
  ) : (
    <></>
  )
}
const MenuNavContainer = () => {
  const data = useLoaderData() as { signUpPath?: string }
  const signUpPath = data?.signUpPath
  const [aboutModalOpen, setAboutModalOpen] = useState(false)
  const [homeDashboardId, setHomeDashboardId] = useLocalStorage<string>(
    localStorageMap.HOME,
    'futures',
  )
  const navigate = useNavigate()
  const { data: dbUser } = useQuery({ ...getDbUser() })

  const { data: stripePortal } = useQuery({
    ...getStripePortal(dbUser?.metadata?.['custom:oauth_sign_up']),
  })

  const { user, signOut } = useAuthenticator((context) => [context?.user])
  const userMemo = useMemo(() => user, [user]) // liveData polling makes component rerender so calls posthog api lots
  const isMobile = useMediaQuery('(max-width: 768px)')
  useEffect(() => {
    if (userMemo) {
      if (userMemo?.attributes?.sub) {
        posthog.identify(userMemo.attributes.sub, {
          email: userMemo.attributes.email,
          userName: userMemo.attributes.preferred_username,
          name: userMemo.attributes.name,
        })
      }
    }
  }, [userMemo])

  const liveData = useLivePrices()
  const onSelect = (event: MenuSelectEvent) => {
    if (event.item.data) {
      navigate(event.item.data.route)
    }
  }

  const handleSignOutClick = (
    e:
      | MouseEvent<HTMLSpanElement | HTMLAnchorElement>
      | KeyboardEvent<HTMLSpanElement | HTMLAnchorElement>,
  ) => {
    e.stopPropagation()
    signOut()
    deleteDB('OptionsDB').catch(console.error)
    navigate('/login')
  }
  const logIn = () => (signUpPath ? navigate(signUpPath) : navigate('/login'))

  const theme = useTheme()
  const homeRoute = `/dashboard/${homeDashboardId}`
  const haRoute = '/historical-analyzer'
  const optionPricerRoute = '/option-pricer'
  const setLatestDashboard = (id) => setHomeDashboardId(id)
  const { HAEnabled } = usePermissions()
  return isMobile ? (
    <>
      <div
        style={{
          display: 'flex',
          padding: '0.5rem 1rem',
          alignItems: 'center',
          justifyContent: 'space-between',
          backgroundColor: theme.palette.common.blue2,
        }}
      >
        <img
          src={logo}
          onClick={() => navigate('/')}
          alt="logo"
          style={{ height: '32px', width: '32px', padding: 0 }}
        />
        {!user ? (
          <div>
            <Button
              style={{
                backgroundColor: theme.palette.common.blue5,
                marginLeft: 'auto',
              }}
              onClick={logIn}
            >
              {signUpPath ? 'Sign Up' : 'Log in'}
            </Button>
          </div>
        ) : (
          <MobileMenu
            homeRoute={homeRoute}
            haRoute={haRoute}
            optionPricerRoute={optionPricerRoute}
            stripeUrl={stripePortal?.url}
            username={user?.attributes?.name || user?.username}
            handleSignOutClick={handleSignOutClick}
            logIn={logIn}
            signUpPath={signUpPath}
          />
        )}
      </div>
      <BannerNotification />
      <HomeSubNav setLatestDashboard={setLatestDashboard} />
    </>
  ) : (
    <div className="row justify-content-center">
      {aboutModalOpen && (
        <Dialog
          onClose={() => setAboutModalOpen(false)}
          title="About"
          width="300px"
        >
          <p>
            <b>App Version:</b> {process.env.REACT_APP_VERSION}
          </p>
          <p>Have a question or need some help? </p>

          <a
            style={{ textDecoration: 'underline' }}
            href="mailto:support@blockscholes.com"
          >
            Send us an Email.
          </a>
        </Dialog>
      )}
      <div className="col-24 flex-grow-1 text-center">
        <StyledMenu onSelect={onSelect} openOnClick>
          <MenuItem
            data={{ route: homeRoute }}
            render={() => (
              <img
                src={logo}
                alt="logo"
                style={{ height: '32px', width: '32px', padding: 0 }}
              />
            )}
          />
          {user && (
            <MenuItem
              text="Home"
              data={{ route: homeRoute }}
              cssClass={
                window?.location.pathname.includes(homeRoute)
                  ? 'high-light'
                  : ''
              }
            />
          )}
          {user && HAEnabled && (
            <MenuItem
              text="Historical Analyzer"
              data={{ route: haRoute }}
              cssClass={
                window?.location.pathname === haRoute ? 'high-light' : ''
              }
            />
          )}
          {user && (
            <MenuItem
              text="Option Pricer"
              data={{ route: optionPricerRoute }}
              cssClass={
                window?.location.pathname === optionPricerRoute
                  ? 'high-light'
                  : ''
              }
            />
          )}
          {Object.keys(liveData).length > 4 && (
            <MenuItem
              key="live-data"
              disabled
              cssClass="nav-live-feed"
              render={() => (
                <span
                  style={{
                    width: document.body.clientWidth < 1600 ? '10vw' : '50vw',
                    left:
                      document.body.clientWidth < 1500 ? '-250px' : '-300px',
                  }}
                >
                  <LiveDataFeed prices={liveData} />
                </span>
              )}
            />
          )}
          {user ? (
            <MenuItem
              cssStyle={{ marginLeft: 'auto', fontSize: '12px' }}
              icon="user"
            >
              <MenuItem
                text={user?.attributes?.name || user.username}
                disabled
              />
              {stripePortal && stripePortal?.url && (
                <MenuItem
                  render={() => {
                    return (
                      <a
                        href={stripePortal.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Subscription
                      </a>
                    )
                  }}
                />
              )}
              <MenuItem
                render={() => (
                  <span role="menuitem" onClick={() => setAboutModalOpen(true)}>
                    About
                  </span>
                )}
              />
              <MenuItem
                text="Sign out"
                render={({ item }) => (
                  <span role="menuitem" onClick={handleSignOutClick}>
                    {item.text}
                  </span>
                )}
              />
            </MenuItem>
          ) : (
            <MenuItem
              cssStyle={{
                marginLeft: 'auto',
                fontSize: '12px',
                backgroundColor: theme.palette.common.blue5,
                borderRadius: '4px',
                padding: '0 1rem',
              }}
              text={signUpPath ? 'Sign Up' : 'Log in'}
              render={({ item }) => (
                <span role="menuitem" onClick={logIn}>
                  {item.text}
                </span>
              )}
            />
          )}
        </StyledMenu>
      </div>
      <BannerNotification />
      <HomeSubNav setLatestDashboard={setLatestDashboard} />
    </div>
  )
}

export default MenuNavContainer
