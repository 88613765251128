import {
  ExclamationTriangleIcon,
  InfoCircledIcon,
  SpeakerLoudIcon,
} from '@radix-ui/react-icons'
import { usePermissions } from 'hooks/usePermissions'
import { useTheme } from 'styled-components'
import { BannerType } from 'types'

const BannerNotification = () => {
  const { bannerNotification } = usePermissions()
  const theme = useTheme()

  if (!bannerNotification) return <></>
  // DEFAULT TO WARNING
  let bgColor = theme.palette.common.red
  let Icon = ExclamationTriangleIcon
  let iconColour = theme.palette.common.yellow
  let textColour = theme.palette.common.blue12

  if (bannerNotification.type === BannerType.INFO) {
    bgColor = theme.palette.common.blue11
    iconColour = theme.palette.common.blue1
    textColour = theme.palette.common.blue1
    Icon = InfoCircledIcon
  }
  if (bannerNotification.type === BannerType.ANNOUNCEMENT) {
    bgColor = theme.palette.common.blue12
    iconColour = theme.palette.common.blue1
    textColour = theme.palette.common.blue1
    Icon = SpeakerLoudIcon
  }
  return (
    <div
      style={{
        width: '100%',
        backgroundColor: bgColor,
        borderBottom: '1px solid',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0.75rem 1.75rem',
      }}
    >
      <p
        style={{
          color: textColour,
          textAlign: 'center',
          margin: 0,
          padding: '0 0.5rem',
        }}
      >
        <Icon
          style={{
            width: '28px',
            height: '28px',
            color: iconColour,
            marginRight: '1rem',
            transform: 'scale(1.1)',
          }}
        />
        <span
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: bannerNotification.innerHtml }} // From us internally so its ok for flexibility
        />
      </p>
    </div>
  )
}

export default BannerNotification
