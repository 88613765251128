import { KeyData, IVData } from 'stores/types'
import { Ticker } from './ticker'

export type DataValue = {
  value: string | number | null
  useFilter?: boolean
  percentage?: boolean
}
export enum Movement {
  UP,
  DOWN,
  EQUAL,
}

export enum Monitors {
  MARKET_FUTURES = 'future.market',
  MARKET_OPTIONS = 'option.market',
  PARAM_OPTIONS = 'option.params',
  VOL_OPTIONS = 'option.vol',
}

type StaticData = Record<string, string | number>
export type RowData = [string, KeyData]
export type OptionRowData = [string, { C?: KeyData; P?: KeyData; IV?: IVData }]
