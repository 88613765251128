import { useTheme } from 'styled-components'
import { QuoteAsset, Ticker } from 'types'
import { SVG_MAP } from './consts'

const CurrencyLogo = ({
  currency,
  height,
  width,
  mr,
  mb,
}: {
  currency?: QuoteAsset
  height?: number
  width?: number
  mr?: number
  mb?: number
}) => {
  const theme = useTheme()
  if (!currency) return <></>
  if (currency.startsWith('USD')) return <>($)</>
  let hackCurrency = currency
  if (hackCurrency.startsWith('XBT') || hackCurrency.startsWith('BTC')) {
    hackCurrency = Ticker.BTC
  }
  if (hackCurrency.startsWith('ETH')) hackCurrency = Ticker.ETH

  return (
    <img
      style={{
        height: `${height || 18}px`,
        width: `${width || 18}px`,
        marginRight: `${mr ?? 2}px`,
        marginBottom: `${mb ?? 0}px`,
        backgroundColor: theme.palette.common.blue5,
        borderRadius: '20px',
      }}
      src={SVG_MAP[hackCurrency]}
      alt={`${currency}`}
    />
  )
}
export default CurrencyLogo
