import { CalendarIcon, Cross2Icon } from '@radix-ui/react-icons'
import ToggleGroup, { ToggleGroupItem } from 'components/atoms/ToggleGroup'
import * as Popover from '@radix-ui/react-popover'
import { ChartState } from 'stores/types'
import { DateRange, LookBackWindow } from 'types'
import styled from 'styled-components'
import { Form, Formik } from 'formik'
import {
  DATETIME_FORMAT,
  NOW_UTC,
  START_OF_BLOCKSCHOLES_TIME,
  TenorDays,
  THREE_MONTHS_AGO,
} from 'consts'
import { DateTimePicker } from 'components/atoms/DatePickers'
import { DateTimePickerChangeEvent } from '@progress/kendo-react-dateinputs'
import { convertDateToUTCOrLatestHour } from 'utils/convert-date-to-utc'
import { usePermissions } from 'hooks/usePermissions'
import { sub, max } from 'date-fns'
import ToolTip from 'components/atoms/tooltip'

const Submit = styled.button`
  background-color: ${({ theme }) => theme.palette.common.blue9};
  color: ${({ theme }) => theme.palette.common.blue12};
  padding: 0.3rem;
  box-shadow: 0 2px 10px ${({ theme }) => theme.palette.common.blue7};
  border-radius: 0.25rem;
  margin: 0.2rem;
`
const PopoverContent = styled(Popover.Content)`
  border-radius: 4px;
  padding: 20px;
  width: 260px;
  background-color: ${({ theme }) => theme.palette.common.blue3};
  color: ${({ theme }) => theme.palette.common.blue12};
  box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px,
    hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
  animation-duration: 400ms;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  will-change: transform, opacity;

  .Fieldset {
    display: flex;
    gap: 20px;
    align-items: center;
  }

  .Label {
    font-size: 13px;
    color: ${({ theme }) => theme.palette.common.blue11};
    width: 75px;
  }

  &.PopoverArrow {
    background-color: ${({ theme }) => theme.palette.common.blue3};
  }

  &.PopoverClose,
  .PopoverClose {
    float: right;
  }
`

interface ISingleDateLatestPicker {
  timeRange?: LookBackWindow | DateRange
  updateTimeRange: (range: LookBackWindow | DateRange) => void
  frequency: ChartState['frequency']
}

const SingleDateLatestPicker = ({
  timeRange,
  updateTimeRange,
  frequency,
}: ISingleDateLatestPicker): JSX.Element => {
  const { hourlyLookBack } = usePermissions()
  const POSSIBLE_LOOK_BACKS = ['1Y', '6M', '3M', '1M', '7d', '1d']
  const min = hourlyLookBack
    ? sub(NOW_UTC(), { hours: hourlyLookBack })
    : START_OF_BLOCKSCHOLES_TIME

  const LOOKBACKS = POSSIBLE_LOOK_BACKS.filter((l) => {
    if (!hourlyLookBack) return true
    const days = TenorDays[l]
    return days / (hourlyLookBack / 24) <= 1
  })

  return (
    <ToggleGroup
      value={typeof timeRange === 'string' ? timeRange : 'PICKER'}
      onValueChange={(v) => {
        if (!v && typeof timeRange === 'string') {
          updateTimeRange(timeRange)
        }
        if (v && v !== 'PICKER') updateTimeRange(v as LookBackWindow)
      }}
    >
      <>
        {POSSIBLE_LOOK_BACKS.map((range) => (
          <ToolTip
            key={range}
            content={<div>Sign up and gain immediate access to this data.</div>}
            enabled={!LOOKBACKS.includes(range)}
          >
            <ToggleGroupItem
              className="ToggleGroupItem"
              key={range}
              disabled={
                (frequency?.endsWith('m') && range !== '1d') ||
                !LOOKBACKS.includes(range)
              }
              value={range}
            >
              {range}
            </ToggleGroupItem>
          </ToolTip>
        ))}
      </>
      <ToggleGroupItem
        className="ToggleGroupItem"
        disabled={frequency?.endsWith('m')}
        value="PICKER"
      >
        <Popover.Root>
          <Popover.Trigger asChild>
            <CalendarIcon />
          </Popover.Trigger>
          <Popover.Portal>
            <PopoverContent sideOffset={5} side="top">
              <Popover.Close className="PopoverClose" aria-label="Close">
                <Cross2Icon />
              </Popover.Close>
              <Formik
                initialValues={{
                  from: max([min, THREE_MONTHS_AGO]),
                  until: convertDateToUTCOrLatestHour(NOW_UTC(), true),
                }}
                onSubmit={(values) => {
                  if (values.from && values.until) {
                    updateTimeRange({
                      from: values.from,
                      until: values.until,
                    })
                  }
                }}
              >
                {({ setFieldValue, values }) => {
                  return (
                    <Form style={{ textAlign: 'center' }}>
                      <div
                        style={{
                          display: 'flex',
                          padding: '0.2rem',
                          width: '200px',
                        }}
                      >
                        <div style={{ width: '50px' }}>From:</div>
                        <DateTimePicker
                          name="from"
                          id="from"
                          value={values.from}
                          format={DATETIME_FORMAT}
                          min={min}
                          max={NOW_UTC()}
                          fillMode="flat"
                          onChange={(e: DateTimePickerChangeEvent) => {
                            setFieldValue('from', e.value)
                          }}
                        />
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          padding: '0.2rem',
                          width: '200px',
                        }}
                      >
                        <div style={{ width: '50px' }}>To:</div>
                        <DateTimePicker
                          name="until"
                          id="until"
                          value={values.until}
                          format={DATETIME_FORMAT}
                          min={min}
                          max={NOW_UTC()}
                          fillMode="flat"
                          onChange={(e: DateTimePickerChangeEvent) =>
                            setFieldValue('until', e.value)
                          }
                        />
                      </div>
                      <Submit type="submit">Select</Submit>
                    </Form>
                  )
                }}
              </Formik>
              <Popover.Arrow className="PopoverArrow" />
            </PopoverContent>
          </Popover.Portal>
        </Popover.Root>
      </ToggleGroupItem>
    </ToggleGroup>
  )
}

export default SingleDateLatestPicker
