/* eslint-disable no-param-reassign */
import { TooltipSlice } from 'stores/types'

import { StateCreator } from 'zustand'
import { immer } from 'zustand/middleware/immer'

const createTooltipSlice: StateCreator<
  TooltipSlice,
  [],
  [['zustand/immer', never]],
  TooltipSlice
> = immer((set) => ({
  tooltip: {
    hoveredSeries: null,
    setHoveredSeries: (s) =>
      set((state) => {
        state.tooltip.hoveredSeries = s
      }),
  },
}))

export default createTooltipSlice
