import styled from 'styled-components'
import * as Toast from '@radix-ui/react-toast'

const ToastViewport = styled(Toast.Viewport)`
  position: fixed;
  left: 50%;
  bottom: 0px;
  transform: translate(-50%, 0%);
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  padding: 25px;
  gap: 10px;
  width: fit-content;
  max-width: 40vw;
  margin: 0;
  list-style: none;
  z-index: 2147483647;
  outline: none;
`

const ToastRoot = styled(Toast.Root)<{ isError?: boolean }>`
  background-color: ${({ theme, isError }) =>
    isError ? theme.palette.common.red : theme.palette.common.blue5};
  border-radius: 6px;
  box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px,
    hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
  padding: 15px;
  display: grid;
  grid-template-areas: 'title action' 'description action';
  grid-template-columns: auto max-content;
  column-gap: 15px;
  align-items: center;

  @keyframes hide {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }

  @keyframes slideIn {
    from {
      transform: translateX(calc(100% + var(--viewport-padding)));
    }
    to {
      transform: translateX(0);
    }
  }

  @keyframes swipeOut {
    from {
      transform: translateX(var(--radix-toast-swipe-end-x));
    }
    to {
      transform: translateX(calc(100% + var(--viewport-padding)));
    }
  }

  &[data-state='open'] {
    animation: slideIn 150ms cubic-bezier(0.16, 1, 0.3, 1);
  }
  &[data-state='closed'] {
    animation: hide 100ms ease-in;
  }
  &[data-swipe='move'] {
    transform: translateX(10);
  }
  &[data-swipe='cancel'] {
    transform: translateX(0);
    transition: transform 200ms ease-out;
  }
  &[data-swipe='end'] {
    animation: swipeOut 100ms ease-out;
  }
`

const ToastTitle = styled(Toast.Title)<{ isError?: boolean }>`
  align-items: center;
  display: flex;
  grid-area: title;
  margin-bottom: 5px;
  font-weight: 500;
  color: ${({ theme }) => theme.palette.common.blue12};
  font-size: 15px;
`
const ToastDescription = styled(Toast.Description)<{ isError?: boolean }>`
  grid-area: description;
  margin: 0;
  color: ${({ theme, isError }) =>
    isError ? theme.palette.common.blue12 : theme.palette.common.blue11};
  font-size: 13px;
  line-height: 1.3;
`

interface IToast {
  setOpen: (b: boolean) => void
  title: string
  description: string
  icon?: JSX.Element
  isError?: boolean
}
const ToastAtom = ({ setOpen, title, description, icon, isError }: IToast) => {
  return (
    <>
      <ToastRoot
        type="foreground"
        isError={isError}
        onOpenChange={setOpen}
        duration={3000}
      >
        <ToastTitle isError={isError}>
          {icon}
          {title}
        </ToastTitle>
        <ToastDescription isError={isError} asChild>
          <p>{description}</p>
        </ToastDescription>
      </ToastRoot>
      <ToastViewport />
    </>
  )
}

export default ToastAtom
