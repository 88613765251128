export enum SVIParam {
  Alpha = 'Alpha',
  Beta = 'Beta',
  Rho = 'Rho',
  Mu = 'Mu',
  Sigma = 'Sigma',
}
export enum SVIParamWithVol {
  Alpha = 'Alpha',
  Beta = 'Beta',
  Rho = 'Rho',
  Mu = 'Mu',
  Sigma = 'Sigma',
  Vol = 'Vol',
}

export enum SABRParam {
  Rho = 'Rho',
  Alpha = 'Alpha',
  VolVol = 'VolVol',
}

export enum SABRParamWithVol {
  Rho = 'Rho',
  Alpha = 'Alpha',
  VolVol = 'VolVol',
  Vol = 'Vol',
}

export const SVIModelMap = {
  Alpha: 'svi_a',
  Beta: 'svi_b',
  Rho: 'svi_rho',
  Mu: 'svi_m',
  Sigma: 'svi_sigma',
  Vol: 'svi_vol',
}
export const reverseSVIMap = Object.fromEntries(
  Object.entries(SVIModelMap).map((a) => a.reverse()),
)
export const SABRModelMap = {
  Rho: 'sabr_rho',
  Alpha: 'sabr_alpha',
  VolVol: 'sabr_volvol',
  Vol: 'sabr_vol',
}
export const reverseSABRMap = Object.fromEntries(
  Object.entries(SABRModelMap).map((a) => a.reverse()),
)

export enum Model {
  SVI = 'SVI',
  SABR = 'SABR',
}

export enum SmileType {
  SMILE = 'smile',
  MONEYNESS = 'moneyness',
}
