import { useQuery } from '@tanstack/react-query'
import { Auth, Hub } from 'aws-amplify'
import Button from 'components/atoms/Button'
import Card, { CardProps } from 'components/atoms/Card'
import Checkbox from 'components/atoms/Checkbox'
import FlexContainer from 'components/atoms/Flex'
import ErrorMessage from 'components/atoms/Typography/ErrorMessage'
import { H4, H5, Body1 } from 'components/atoms/Typography/styles'
import ChartLoader from 'components/charts/ChartLoader'
import { StyledBasePill } from 'components/charts/common/series/Pills'
import { IntegrationButton } from 'components/register/IntegrationButton'
import { integrationMap } from 'consts'
import { useEffect, useState } from 'react'
import { getDbUser, getOauthUrl, getStripeCheckoutUrl } from 'services'
import styled, { useTheme } from 'styled-components'

import { Integration } from 'types/register'
import MonetaryValue from './MonetaryValue'

interface IPricingCard extends CardProps {
  styling: 'light' | 'dark'
  height?: string
}
const StyledPricingCard = styled(Card)<IPricingCard>`
  ${({ height }) => height && `height: ${height};`}
  align-self: flex-start;
  justify-content: space-between;
  background-color: ${({ theme, styling }) =>
    styling === 'light'
      ? theme.palette.common.white
      : theme.palette.common.blue4};
  color: ${({ theme, styling }) =>
    styling === 'light'
      ? theme.palette.common.blue2
      : theme.palette.common.white};
  max-width: 800px;
  font-size: 13px;
  p {
    font-size: 13px;
  }
  a {
    text-decoration: underline;
    color: ${({ theme, styling }) =>
      styling === 'light'
        ? theme.palette.common.blue2
        : theme.palette.common.white};
  }

  h3,
  h4,
  h5,
  h6 {
    color: ${({ theme, styling }) =>
      styling === 'light'
        ? theme.palette.common.blue2
        : theme.palette.common.white};
  }

  i {
    background-color: ${({ theme, styling }) =>
      styling === 'light'
        ? theme.palette.common.blue2
        : theme.palette.common.white};
  }
  i::before {
    background-color: ${({ theme, styling }) =>
      styling === 'light'
        ? theme.palette.common.white
        : theme.palette.common.blue2};
    color: ${({ theme, styling }) =>
      styling === 'light'
        ? theme.palette.common.blue2
        : theme.palette.common.white};
  }
`
const List = styled.ul`
  list-style: none;
  margin-bottom: 0.4rem;
  li {
    display: flex;
  }
  li:before {
    content: '✓';
    padding-right: 0.2rem;
  }

  p {
    padding: 0;
    margin-bottom: 0;
  }
`

const RetailFeatures = () => (
  <List style={{ padding: '0.5rem 0 0.5rem' }}>
    <li>
      <p>Historical Data from January 2020.</p>
    </li>
    <li>
      <p>Data at an hourly frequency.</p>
    </li>
    <li>
      <p>
        Futures, options, perpetual swaps, and our proprietary indices data.
      </p>
    </li>
    <li>
      <p>
        Derived data: yields, implied volatility smiles, smile calibration
        parameters, term structures, butterfly spreads, put/call skews.
      </p>
    </li>

    <li>
      <p>
        Access to new Standard tier features and exchanges as soon as
        they&apos;re added.
      </p>
    </li>
  </List>
)

const PricingCard = ({
  children,
  styling,
  button,
  accepted,
  setAccepted,
  height,
}: {
  children: JSX.Element | JSX.Element[]
  styling: IPricingCard['styling']
  button: JSX.Element
  accepted: boolean
  setAccepted: (f: (prev: boolean) => boolean) => void
  height?: string
}) => {
  return (
    <StyledPricingCard
      styling={styling}
      height={height}
      flexBasis="350px"
      flexShrink={0}
      role="dialog"
    >
      <div
        style={{
          display: 'flex',
          height: '100%',
          flexDirection: 'column',
          justifyContent: 'space-around',
        }}
      >
        {children}
      </div>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <label
          htmlFor="checked"
          style={{
            height: '30px',
            paddingBottom: '0.5rem',
            width: 'fit-content',
          }}
        >
          <Checkbox
            type="checkbox"
            name="checked"
            id="checked"
            checked={accepted}
            onChange={() => setAccepted((prev: boolean) => !prev)}
          />{' '}
          I agree to the{' '}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.blockscholes.com/terms/terms-conditions"
          >
            terms of service
          </a>
        </label>
        {button}
      </div>
    </StyledPricingCard>
  )
}

const IntegrationCard = ({
  error,
  url,
  integration,
}: {
  error?: unknown
  url?: string
  integration: Integration
}) => {
  const [accepted, setAccepted] = useState(false)
  const handleClick = () => {
    if (url && window) {
      window.location.href = url
    }
  }
  return (
    <PricingCard
      styling="dark"
      accepted={accepted}
      setAccepted={setAccepted}
      button={
        error instanceof Error ? (
          <ErrorMessage fontSize="24px">{error.toString()}</ErrorMessage>
        ) : (
          <IntegrationButton
            integration={integration}
            disabled={!!error || !url || !accepted}
            onClick={handleClick}
            text="Pay with"
          />
        )
      }
    >
      <H4 style={{ paddingBottom: '1rem' }}>Pricing</H4>
      <p>
        We offer an exclusive subscription price for {integration.name} members,
        providing access to the same high-quality data and tools as other Retail
        Block Scholes users at an 96% discount. So, which tools are included?
      </p>
      <RetailFeatures />
    </PricingCard>
  )
}
const StripeCard = ({
  children,
  styling,
  buttonText,
  email,
  isPro,
}: {
  children: JSX.Element | JSX.Element[]
  styling: IPricingCard['styling']
  buttonText: string
  email?: string
  isPro: boolean
}) => {
  const theme = useTheme()
  const [accepted, setAccepted] = useState(false)
  const {
    data: res,
    isFetching,
    error,
  } = useQuery({ ...getStripeCheckoutUrl(email, isPro) })
  const handleClick = () => {
    if (res?.url && window) {
      window.location.href = res?.url
    }
  }
  return (
    <PricingCard
      styling={styling}
      height="600px"
      button={
        <Button
          variant="contained"
          color={
            styling === 'light'
              ? theme.palette.common.blue3
              : theme.palette.common.blue11
          }
          disabled={!accepted || isFetching || !!error}
          style={{ margin: '1rem' }}
          onClick={handleClick}
        >
          {buttonText}
        </Button>
      }
      accepted={accepted}
      setAccepted={setAccepted}
    >
      {children}
    </PricingCard>
  )
}
const Pricing = () => {
  const theme = useTheme()
  const [user, setCurrentUser] = useState<{
    attributes?: { email?: string; sub?: string }
  }>({})

  useEffect(() => {
    const getCurrentUser = async () => {
      try {
        return await Auth.currentAuthenticatedUser()
      } catch {
        // currentAuthenticatedUser throws an Error if not signed in
        return null
      }
    }
    const updateUser = async () => {
      setCurrentUser(await getCurrentUser())
    }
    Hub.listen('auth', updateUser)
    updateUser()
  }, [])

  const { data: dbUser, isLoading } = useQuery({
    ...getDbUser(user?.attributes?.sub),
  })

  const integration: Integration | undefined = dbUser?.metadata?.[
    'custom:oauth_sign_up'
  ]
    ? integrationMap[dbUser?.metadata?.['custom:oauth_sign_up']]
    : undefined

  const {
    data: res,
    isFetching,
    error,
  } = useQuery(
    integration
      ? {
          ...getOauthUrl(integration?.id, user?.attributes?.email),
        }
      : { ...getStripeCheckoutUrl(user?.attributes?.email) },
  )

  if (isLoading) {
    return <ChartLoader />
  }
  if (integration) {
    return (
      <IntegrationCard error={error} url={res?.url} integration={integration} />
    )
  }

  return (
    <>
      <StripeCard
        styling="dark"
        email={user?.attributes?.email}
        isPro={false}
        buttonText="TRIAL NOW"
      >
        <FlexContainer width="100%" justifyContent="space-between">
          <H5>STANDARD</H5>
          <StyledBasePill
            bgColor={theme.palette.common.blue12}
            color={theme.palette.common.blue2}
            padding="0.3rem 1rem"
            fs="14px"
          >
            Free Trial
          </StyledBasePill>
        </FlexContainer>
        <FlexContainer width="100%" justifyContent="center" direction="column">
          <MonetaryValue
            value={25}
            percentageOff={res?.percent_off}
            nonDiscountedColor={theme.palette.common.blue11}
            pillBg={theme.palette.common.blue9}
            pillColor={theme.palette.common.blue12}
          />
          <StyledBasePill
            bgColor={theme.palette.common.blue12}
            color={theme.palette.common.blue2}
            padding="0.3rem 1rem"
            fs="14px"
          >
            <Body1 style={{ color: theme.palette.common.blue2 }}>
              7 Day Trial, cancel anytime.
            </Body1>
          </StyledBasePill>
        </FlexContainer>

        <RetailFeatures />
      </StripeCard>
      <StripeCard
        styling="light"
        email={user?.attributes?.email}
        isPro
        buttonText="PAY NOW"
      >
        <FlexContainer width="100%" justifyContent="space-between">
          <H5>PRO</H5>
          <StyledBasePill
            bgColor={theme.palette.common.blue1}
            color={theme.palette.common.blue12}
            padding="0.3rem 1rem"
            fs="14px"
          >
            Recommended
          </StyledBasePill>
        </FlexContainer>
        <FlexContainer width="100%" justifyContent="center">
          <MonetaryValue
            value={125}
            percentageOff={res?.percent_off}
            nonDiscountedColor={theme.palette.common.blue6}
            pillBg={theme.palette.common.blue11}
            pillColor={theme.palette.common.blue2}
          />
        </FlexContainer>
        <b>
          All the great features included in the Standard tier, in addition to:
        </b>
        <List style={{ padding: '0.5rem 0 0.5rem' }}>
          <li>
            <p>Live data (including live calibrated implied vol surfaces).</p>
          </li>
          <li>
            <p>Data at a one-minute frequency.</p>
          </li>
          <li>
            <p>Access to advanced tools such as option scenario analysis.</p>
          </li>
          <li>
            <p>
              Historical analyser tool that allows for bespoke backtesting and
              analysis.
            </p>
          </li>
          <li>
            <p>Live option pricing.</p>
          </li>
        </List>
        <b>
          Want to gain access to our underlying API data? Email us at{' '}
          <a href="mailto:support@blockscholes.com">support@blockscholes.com</a>{' '}
          to find out more.
        </b>
      </StripeCard>
    </>
  )
  //   return <></>
}
export default Pricing
