import { useMemo } from 'react'
import { useChartStore } from 'stores'
import { NewChartState } from 'stores/types'
import { Chart, TimeZone, YAxis } from 'types'
import { shallow } from 'zustand/shallow'

const getDefault = ({
  axisDefault,
  defaultSeries,
  type,
}): Omit<NewChartState, 'add'> => ({
  timings: { single: 'LATEST' },
  axis: axisDefault || { [YAxis.LEFT]: 'p2' },
  timeZone: TimeZone.UTC,
  frequency: '1h',
  series: defaultSeries,
  type,
})
export const useChartStoreChart = ({
  id,
  defaultSeries,
  type = Chart.TIMESERIES,
  axisDefault,
}: {
  id: string
  defaultSeries: NewChartState['series']
  type?: Chart
  axisDefault?: NewChartState['axis']
}) => {
  const def = useMemo(
    () => getDefault({ defaultSeries, axisDefault, type }),
    [defaultSeries, axisDefault, type],
  )
  return useChartStore((s) => s.defaultCharts[id], shallow) || def
}
