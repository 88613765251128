import styled from 'styled-components'

export interface CardProps {
  flexBasis?: string
  flexShrink?: number
  width?: string
}
const Card = styled.div<CardProps>`
  padding: 1rem;
  background: rgba(44, 48, 92, 0.22);
  border-radius: 16px;
  // box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  border: 1px solid ${({ theme }) => theme.palette.common.blue3}
  backdrop-filter: blur(1px);
  border: 1px solid rgba(26, 0, 30, 0.14);
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: baseline;
  flex-grow: ${({ width }) => (width ? 0 : 1)};
  flex-shrink: ${({ flexShrink }) => flexShrink || 1};
  justify-content: flex-end;
  line-height: 22px;
  flex-basis: ${({ flexBasis }) => flexBasis || 'auto'};
  ${({ width }) => width && `width: ${width}`};
`

interface CardContainerProps {
  justifyContent?: 'center' | 'flex-end' | 'flex-start'
  width?: string
}
export const CardContainer = styled.div<CardContainerProps>`
  display: flex;
  align-items: center;
  justify-content: ${({ justifyContent }) => justifyContent || 'flex-start'};
  flex-wrap: wrap;
  gap: 16px;
  padding: 2rem;
`

export default Card
