import React from 'react'
import { Chart, Source, Ticker, YAxis } from 'types'

import useQuarterlyListed from 'hooks/useQuarterlyListed'
import AssetClass from 'types/assetClass'

import useCatalog from 'hooks/useCatalog'
import useDefaultSeries from 'hooks/useDefaultSeries'
import ChartWrapper from '../common/BsLangChartWrapper'

const OptionOpenInterest: React.FC = () => {
  const title = 'Open interest'
  const id = `${AssetClass.OPTION}-${title}`
  const listOfActiveExpires = useQuarterlyListed({
    exchange: Source.DERIBIT,
    currency: Ticker.BTC,
  })

  const sources = [Source.DERIBIT, Source.BYBIT]
  const { data: catalog, isLoading: catalogLoading } = useCatalog({
    assets: [AssetClass.OPTION],
    active: [true],
    sources,
    generateCurrencyPairs: false,
    listInstruments: true,
  })

  const defaults = useDefaultSeries({
    catalogLoading,
    items: listOfActiveExpires,
    assetClass: AssetClass.OPTION,
    path: 'oi',
  })

  if (!catalogLoading && !catalog) {
    return <div>An Error occurred, please refresh.</div>
  }

  return (
    <ChartWrapper
      catalog={catalog}
      defaultSeries={defaults}
      id={id}
      title={title}
      axisDefault={{ [YAxis.LEFT]: 'n2' }}
      assetClass={AssetClass.OPTION}
      suffix="oi"
      sources={sources}
      defaultChartType={Chart.TIMESERIES}
      disableMinutes
      useInstrument
    />
  )
}

export default OptionOpenInterest
