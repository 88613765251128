import React, { useState } from 'react'
import { useQueries } from '@tanstack/react-query'
import { max } from 'date-fns'

import { getRefetchInterval, dataService2 } from 'services'
import ChartWrapper from 'components/common/ChartWrapper'
import { Chart, PriceType, Source, Ticker, YAxis } from 'types'

import { usePermissions } from 'hooks/usePermissions'
import AssetClass from 'types/assetClass'

import { useDefaultStore } from 'stores'
import Modal from 'components/atoms/Modal'
import { initialFutureTermStructure } from 'stores/slices/default-charts'
import {
  FutureTermStructure as IFutureTermStructure,
  FutureTermStructureSeries,
} from 'stores/types'
import { getModelParamLabelFormat } from 'utils/charts'
import useToasts from 'hooks/useToasts'

import TermStructureChart from '../TermStructureChart'
import ChartLoader from '../ChartLoader'
import { TermStructureSeriesPills } from '../common/series/Selector'
import AddSeries from '../common/series/AddSeries'
import { ConstructKey } from '../common/util'
import { getSeriesAxis } from '../common/series/utils'

const checkSameSeries = (s, arr: FutureTermStructureSeries[]) =>
  !!arr.find(
    (a) =>
      a.currency === s.middle.currency &&
      a.source === s.source &&
      a.timestamp === s.middle.timestamp &&
      a.type === s.middle.type,
  )

const FutureTermStructure: React.FC = () => {
  const title = 'Term structure'
  const { hasMinuteData } = usePermissions()
  const { openToast, ToastComponent, ToastType } = useToasts()
  const [highlightedSeries, setHighlightedSeries] = useState<
    number | undefined
  >()
  const chart =
    useDefaultStore(
      (state) => state.defaultCharts?.[title] as IFutureTermStructure,
    ) || initialFutureTermStructure

  const queries = chart.series.map((s) => ({
    queryKey: [
      s.source,
      s.currency,
      'futures',
      'termStructure',
      s.timestamp,
      hasMinuteData,
    ],
    queryFn: () =>
      dataService2.getFuturesTermStructure({
        exchange: s.source,
        currency: s.currency,
        date: s.timestamp,
        frequency: hasMinuteData && s.timestamp === 'LATEST' ? '1m' : '1h',
      }),
    refetchInterval: getRefetchInterval(s.timestamp, hasMinuteData),
    refetchOnWindowFocus: s.timestamp === 'LATEST',
    cacheTime: 60 * 1000,
  }))
  const results = useQueries({
    queries,
  })

  const isLoading = results.some((r) => r.isLoading)
  const dataMapping = results.reduce(
    (
      acc: {
        data: Record<
          string,
          {
            seriesPoints: {
              dateOffset: number
              val: number
              instrument: string
            }[]
            markers: {
              dateOffset: number
              val: number
              instrument: string
            }[]
            currency: Ticker
            source: Source
            timestamp: Date
            type: PriceType
            axis: {
              yAxis: YAxis
            }
            color: string
          }
        >

        dates: string[]
      },
      res,
      index,
    ) => {
      const series = chart.series[index]
      if (!res.data || !series) return acc
      const { timestamp, seriesPoints, markers } = res.data
      if (!timestamp || seriesPoints.length === 0) return acc
      acc.data[
        `${series.source}.${series.currency}.${series.type}.${new Date(
          timestamp,
        ).toUTCString()}`
      ] = {
        seriesPoints: seriesPoints.map((o) => ({
          dateOffset: o.dateOffset,
          val: o[series.type],
          instrument: o.instrument,
        })),
        markers: markers.map((o) => ({
          dateOffset: o.dateOffset,
          val: o[series.type],
          instrument: o.instrument,
        })),
        timestamp: new Date(timestamp),
        type: series.type,
        source: series.source,
        currency: series.currency,
        axis: {
          yAxis: series.axis?.yAxis || YAxis.LEFT,
        },
        color: series.color,
      }

      acc.dates.push(new Date(timestamp).toISOString())
      return acc
    },
    { data: {}, dates: [] },
  )

  const updateChart = useDefaultStore((state) => state.updateTermStructure)
  const updateSeries = (series: ConstructKey) => {
    if (
      'type' in series.middle &&
      series.middle.type &&
      'timestamp' in series.middle
    ) {
      const existingSeries = checkSameSeries(series, chart.series)
      if (existingSeries) {
        return openToast([{ type: ToastType.EXISTS }])
      }
      const format = getModelParamLabelFormat(series.middle.type) // returns c,n, or p
      const seriesAxis = getSeriesAxis({
        id: title,
        format,
        updateAxis: (a: IFutureTermStructure['axis']) =>
          updateChart(title, 'axis', a),
        chartAxis: chart.axis,
        axisToAddTo: series.axis?.yAxis,
      })
      if (seriesAxis === 'NONE') {
        return openToast([{ type: ToastType.AXIS }])
      }
      if (seriesAxis) {
        updateChart(title, 'series', [
          ...chart.series,
          {
            source: series.source,
            timestamp: series.middle.timestamp,
            currency: series.middle.currency,
            type: series.middle.type,
            axis: {
              yAxis: seriesAxis,
            },
            color: series.color,
          } as FutureTermStructureSeries,
        ])
      }
    }
  }

  const enlargedChart = useDefaultStore((state) => state.expandedId)
  const setExpandedChart = useDefaultStore((state) => state.setExpandedChart)
  return (
    <Modal
      open={enlargedChart === title}
      onClose={() => setExpandedChart(null)}
    >
      <ChartWrapper
        id={title}
        chartSeries={chart.series}
        title={title}
        type={Chart.TERM_STRUCTURE}
        assetClass={AssetClass.FUTURE}
        updatedStamp={max(dataMapping.dates.map((s) => new Date(s)))}
        timeZone={chart.timeZone}
        axis={chart.axis}
        addSeries={
          <AddSeries
            color={{ index: dataMapping.dates?.length || 0 }}
            currencies={[Ticker.BTC, Ticker.ETH]}
            assetType={AssetClass.FUTURE}
            updateSeries={updateSeries}
            useTypeToggle
            defaultMiddle={{
              type: ['price', 'yield'],
            }}
            useModelParam={false}
            listedExpiry={{ enabled: false }}
            useLatestDatePicker
          />
        }
        seriesPills={
          <TermStructureSeriesPills
            dates={dataMapping.dates}
            chartSeries={chart.series}
            id={title}
            setHighlightedSeries={setHighlightedSeries}
          />
        }
        chart={
          <>
            {isLoading ? (
              <ChartLoader />
            ) : (
              <TermStructureChart
                dataMapping={dataMapping.data}
                xAxisTitle="Tenor (days)"
                curveStyle="normal"
                axis={chart.axis}
                highlighted={highlightedSeries}
              />
            )}
          </>
        }
      />
      <ToastComponent />
    </Modal>
  )
}

export default FutureTermStructure
