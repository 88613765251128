import React from 'react'

import { Chart, Source, YAxis } from 'types'
import AssetClass from 'types/assetClass'

import useCatalog from 'hooks/useCatalog'
import useDefaultSeries from 'hooks/useDefaultSeries'
import ChartWrapper from '../common/BsLangChartWrapper'

const FutureOpenInterest: React.FC = () => {
  const title = 'Open interest'
  const id = `${AssetClass.FUTURE}-${title}`

  const sources = [Source.DERIBIT, Source.BYBIT, Source.BITMEX]
  const { data: catalog, isLoading: catalogLoading } = useCatalog({
    assets: [AssetClass.FUTURE],
    active: [true],
    sources,
    generateCurrencyPairs: true,
  })

  const sortedBTC = catalogLoading
    ? []
    : catalog?.deribit?.FUTURE?.BTC?.active.sort(
        (a, b) => new Date(a.listing).getTime() - new Date(b.listing).getTime(),
      )
  const sortedETH = catalogLoading
    ? []
    : catalog?.deribit?.FUTURE?.ETH?.active.sort(
        (a, b) => new Date(a.listing).getTime() - new Date(b.listing).getTime(),
      )

  const defaults = useDefaultSeries({
    catalogLoading,
    items: [
      ...(sortedBTC ? sortedBTC?.slice(0, 2) : []),
      ...(sortedETH ? sortedETH?.slice(0, 2) : []),
    ],
    assetClass: AssetClass.FUTURE,
    path: 'oi',
  })

  if (!catalogLoading && !catalog) {
    return <div>An Error occurred, please refresh.</div>
  }

  return (
    <ChartWrapper
      catalog={catalog}
      defaultSeries={defaults}
      id={id}
      title={title}
      axisDefault={{ [YAxis.LEFT]: 'c0' }}
      assetClass={AssetClass.FUTURE}
      suffix="oi"
      sources={sources}
      toUsd={[Source.BYBIT]}
      expression="[QFN] * deribit.spot.[CCY]USD.[FREQ].index.px"
      defaultChartType={Chart.TIMESERIES}
      disableMinutes
      useExpiries
    />
  )
}

export default FutureOpenInterest
