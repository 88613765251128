import React from 'react'

import { Source, Ticker, YAxis } from 'types'
import AssetClass from 'types/assetClass'
import { Model } from 'types/models'

import useMultiSeriesChart from 'hooks/useMultiSeriesChart'
import useCatalog from 'hooks/useCatalog'
import MultiLineSeriesWrapper from '../common/series/MultiSeriesWrapper'
import { SeriesPills } from '../common/series/Selector'
import { ConstructKey } from '../common/util'
import { CHART_COLORS } from '../common/consts'
import { getDefaultSeries } from '../common/series/utils'

const getListed = (listed: string[], def) => {
  // HACK
  if (def && def.length === 0) {
    let listedSeries: any = []
    listed.slice(0, 4).map((l, i) =>
      listedSeries.push({
        isListedExpiry: true,
        source: Source.BLOCKSCHOLES,
        model: Model.SVI,
        expiry: l,
        color: CHART_COLORS[i],
        baseAsset: Ticker.BTC,
        suffix: 'vol.atm',
        axis: {
          yAxis: YAxis.LEFT,
        },
      }),
    )
    return listedSeries
  }
  return getDefaultSeries(def)
}

const OptionVolatilityRatio: React.FC<{
  availableTenors?: `${number}d`[]
  defaultSeries?: Omit<ConstructKey, 'color' | 'axis'>[]
  defaultMinute?: boolean
  additionalIndex?: number
}> = ({ availableTenors, defaultSeries, defaultMinute, additionalIndex }) => {
  const title = `Volatility (Implied, Realized & Ratio) ${
    additionalIndex || ''
  }`
  const id = `${AssetClass.OPTION}-${title}`
  const {
    data: catalog,
    isLoading: catalogLoading,
    isError,
  } = useCatalog({
    assets: [AssetClass.OPTION],
    active: [true],
    sources: [Source.DERIBIT],
    generateCurrencyPairs: true,
  })

  const expiries = Array.from(
    new Set(catalog?.deribit?.OPTION?.BTC?.active?.map((i) => i.expiry) || []),
  ).sort((a, b) => new Date(a).getTime() - new Date(b).getTime())
  const listed = getListed(expiries, defaultSeries)

  const { chartSeries, highlighted, setHighlighted } = useMultiSeriesChart(
    id,
    defaultSeries ||
      ([
        {
          assetClass: AssetClass.OPTION,
          middle: {
            currency: Ticker.BTC,
            tenor: '',
            type: 'vol',
          },
          suffix: 'realized',
          source: Source.COMPOSITE,
        },
        {
          assetClass: AssetClass.OPTION,
          middle: {
            model: Model.SVI,
            currency: Ticker.BTC,
            tenor: availableTenors?.[0] ?? '30d',
            type: 'vol',
          },
          suffix: 'ratio',
          source: Source.DERIBIT,
          axis: {
            yAxis: YAxis.RIGHT,
          },
        },
        {
          assetClass: AssetClass.OPTION,
          middle: {
            model: Model.SVI,
            currency: Ticker.BTC,
            tenor: availableTenors?.[0] ?? '30d',
            type: 'vol',
          },
          suffix: 'atm',
          source: Source.DERIBIT,
        },
        {
          assetClass: AssetClass.OPTION,
          middle: {
            currency: Ticker.ETH,
            tenor: '',
            type: 'vol',
          },
          suffix: 'realized',
          source: Source.COMPOSITE,
        },
        {
          assetClass: AssetClass.OPTION,
          middle: {
            model: Model.SVI,
            currency: Ticker.ETH,
            tenor: availableTenors?.[0] ?? '30d',
            type: 'vol',
          },
          suffix: 'ratio',
          source: Source.DERIBIT,
          axis: {
            yAxis: YAxis.RIGHT,
          },
        },
        {
          assetClass: AssetClass.OPTION,
          middle: {
            model: Model.SVI,
            currency: Ticker.ETH,
            tenor: availableTenors?.[0] ?? '30d',
            type: 'vol',
          },
          suffix: 'atm',
          source: Source.DERIBIT,
        },
      ] as Omit<ConstructKey, 'color' | 'axis'>[]),
  )
  return (
    <MultiLineSeriesWrapper
      chartSeries={listed.length > 0 ? listed : chartSeries}
      title={title}
      id={id}
      axisLabel="%"
      axis={{ LEFT: 'p2', RIGHT: 'p2' }}
      assetType={AssetClass.OPTION}
      suffix={[
        'atm',
        'ratio',
        'realized',
        '50delta',
        '40delta',
        '25delta',
        '10delta',
        '-50delta',
        '-40delta',
        '-25delta',
        '-10delta',
      ]}
      useTenors
      availableTenors={availableTenors}
      fetchListedExpiries
      listedExpiry={{ enabled: !isError }}
      defaultMiddle={{
        type: ['vol'],
      }}
      seriesPills={
        <SeriesPills
          chartSeries={listed.length > 0 ? listed : chartSeries}
          id={id}
          setHighlighted={setHighlighted}
        />
      }
      highlightedIndx={highlighted}
    />
  )
}

export default OptionVolatilityRatio
