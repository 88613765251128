import AssetClass from './assetClass'

export enum Source {
  DERIBIT = 'deribit',
  BYBIT = 'bybit',
  COMPOSITE = 'composite',
  LYRA_OPTIMISM = 'lyra-optimism',
  LYRA_ARBITRUM = 'lyra-arbitrum',
  BITMEX = 'bitmex',
  AEVO = 'aevo',
  OKX = 'okx',
  V2LYRA = 'Derive (Lyra)',
  BLOCKSCHOLES = 'Block Scholes Composite',
  BYBIT_SUPERCOMPOSITE = 'bybit-supercomposite',
  V2LYRA_SUPERCOMPOSITE = 'v2lyra-supercomposite',
}

export const AssetClassSource: Record<AssetClass, Array<Source>> = {
  [AssetClass.FUTURE]: [Source.DERIBIT, Source.BYBIT, Source.OKX],
  [AssetClass.PERP]:
    process.env.REACT_APP_ENV === 'staging'
      ? [Source.DERIBIT, Source.BYBIT, Source.BITMEX, Source.AEVO, Source.OKX]
      : [Source.DERIBIT, Source.BYBIT, Source.BITMEX],
  [AssetClass.INDICES]: [Source.COMPOSITE],
  [AssetClass.OPTION]: [
    Source.DERIBIT,
    Source.BYBIT,
    Source.OKX,
    Source.V2LYRA,
    Source.BLOCKSCHOLES,
  ],
  // : [Source.DERIBIT],
}

export const DeribitOnly = [Source.DERIBIT]
