import { EditorView } from '@codemirror/view'
import { Diagnostic, linter } from '@codemirror/lint'
import { Extension } from '@codemirror/state'
import { IHistoricalAnalyzerDataSource } from './types'
import { Parser } from './parser'

// TODO: add parsed tree to state
export function lintBsLang(sources: Array<IHistoricalAnalyzerDataSource>) {
  return (view: EditorView): readonly Diagnostic[] => {
    const parser = new Parser(view.state, sources)
    return parser.getDiagnostics()
  }
}

export function bsLangLinter(
  sources: Array<IHistoricalAnalyzerDataSource>,
): Extension {
  return linter(lintBsLang(sources))
}
