import { Dialog } from '@progress/kendo-react-dialogs'
import { Input } from '@progress/kendo-react-inputs'
import { Label } from '@progress/kendo-react-labels'
import { useState } from 'react'
import styled from 'styled-components'
import RadioButtonComponent from 'components/atoms/RadioButton'
import { Axis } from './types'

const DialogWrapper = styled(Dialog)`
  .k-dialog {
    width: 644px;
    height: 395px;
    box-shadow: -4px 0px 55px rgb(255 255 255 / 10%);
  }
`
const Row = styled.div`
  display: flex;
`

interface IAxisNamesModalProps {
  axis: Axis
  handleClose: (updated: Axis) => void
}

const FORMAT_TYPES = [
  { name: 'Number', value: 'n2' },
  { name: '%', value: 'p2' },
]
const AxisNamesModal: React.FC<IAxisNamesModalProps> = (props) => {
  const { axis, handleClose } = props
  const [localState, setLocalState] = useState<Axis>(axis)
  return (
    <DialogWrapper title="Axis Names" onClose={() => handleClose(localState)}>
      <div>
        <Label editorId="left">Left axis: </Label>
        <Row>
          <Input
            id="left"
            type="text"
            onChange={(e) =>
              setLocalState({
                ...localState,
                left: {
                  ...localState.left,
                  label: e.target.value?.toString() || '',
                },
              })
            }
            value={localState.left.label}
          />
          <RadioButtonComponent
            showLabel
            current={FORMAT_TYPES.find(
              ({ value }) => value === localState.left.format,
            )}
            options={FORMAT_TYPES}
            onChange={(e) =>
              setLocalState({
                ...localState,
                left: {
                  ...localState.left,
                  format: e || '',
                },
              })
            }
            label="Format"
          />
        </Row>
        <Label editorId="right">Right axis: </Label>
        <Row>
          <Input
            id="right"
            type="text"
            onChange={(e) =>
              setLocalState({
                ...localState,
                right: {
                  ...localState.right,
                  label: e.target.value?.toString() || '',
                },
              })
            }
            value={localState.right.label}
          />
          <RadioButtonComponent
            showLabel
            current={FORMAT_TYPES.find(
              ({ value }) => value === localState.right.format,
            )}
            options={FORMAT_TYPES}
            onChange={(e) => {
              return setLocalState({
                ...localState,
                right: {
                  ...localState.right,
                  format: e || '',
                },
              })
            }}
            label="Format"
          />
        </Row>
      </div>
    </DialogWrapper>
  )
}

export default AxisNamesModal
