import AssetClass from 'types/assetClass'

import { Catalog } from 'hooks/useCatalog'
import { CatalogItem } from 'services'
import { useChartStore, useDefaultStore } from 'stores'
import {
  ChartState,
  FutureTermStructure,
  FutureTermStructureSeries,
  NewChartState,
  OptionSingleSeries,
  OptionTermStructure,
  SmileState,
  TermStructureState,
} from 'stores/types'
import { Instrument, QuoteAsset, Source, Ticker, YAxis } from 'types'
import { daysToTenor } from 'utils/charts'
import { CHART_COLORS } from '../consts'
import { SmileKey } from '../util'
import { Pill, PillNew, TermStructPill } from './Pills'

export const SeriesPills = ({
  id,
  chartSeries,
  setHighlighted,
}: {
  id: string
  chartSeries: ChartState['series']
  setHighlighted?: (n: number | undefined) => void
}): JSX.Element => {
  const updateChart = useDefaultStore((state) => state.updateTimeSeriesChart)
  const chart = useDefaultStore(
    (state) => state.defaultCharts?.[id] as ChartState,
  )

  const handleDelete = (i: number) => {
    if (chartSeries) {
      const newSeries = [...chartSeries]
      newSeries.splice(i, 1)
      const keepAxis = newSeries.find(
        (s) => s.axis?.yAxis === chartSeries[i].axis?.yAxis,
      )
      if (!keepAxis && chartSeries[i].axis?.yAxis) {
        const newAxis = { ...chart.axis }
        if (chartSeries[i].axis?.yAxis === YAxis.LEFT) {
          delete newAxis[YAxis.LEFT]
        } else {
          delete newAxis[YAxis.RIGHT]
        }
        updateChart(id, 'axis', newAxis)
      }
      updateChart(id, 'series', [...newSeries])
    }
  }
  const hasSameType =
    new Set(
      chartSeries?.map((s) =>
        // eslint-disable-next-line no-nested-ternary
        'middle' in s && 'type' in s.middle
          ? s.middle.type
          : 'field' in s
          ? s.field
          : '',
      ),
    ).size <= 1
  const hasSameSuffix = new Set(chartSeries?.map((s) => s.suffix)).size <= 1
  const hasSameAxis = new Set(chartSeries?.map((s) => s.axis.yAxis)).size <= 1
  return (
    <div
      style={{
        width: '100%',
        height: 'fit-content',
        display: 'flex',
        flexWrap: 'wrap',
      }}
    >
      {chartSeries?.map((s, indx) => {
        return (
          <Pill
            index={indx}
            key={
              'middle' in s
                ? `${s.source}.${Object.values(s.middle).join('.')}${
                    s.suffix || ''
                  }`
                : `${s.source}.${s.baseAsset}.${s.model}.${s.expiry}.${
                    s.suffix
                  }.${s.field || ''}`
            }
            series={s}
            seriesId={id}
            handleDelete={handleDelete}
            hasSameType={hasSameType}
            hasSameAxis={hasSameAxis}
            hasSameSuffix={hasSameSuffix}
            setHighlighted={setHighlighted}
          />
        )
      })}
    </div>
  )
}

export const SeriesPillsNew = ({
  id,
  chartSeries,
  setHighlighted,
  assetClass,
  handleUpdate,
  sources,
  catalog,
  useInstrument,
  useExpiries,
}: {
  id: string
  chartSeries: NewChartState['series']
  setHighlighted?: (n: number | undefined) => void
  assetClass: AssetClass
  handleUpdate: (a: any, i: number) => void
  sources: Source[]
  catalog: Catalog
  useInstrument?: boolean
  useExpiries?: boolean
}): JSX.Element => {
  const updateChart = useChartStore((state) => state.updateTimeSeriesChart)
  const chart = useChartStore((state) => state.defaultCharts?.[id])

  const handleDelete = (i: number) => {
    if (chartSeries) {
      const newSeries = [...chartSeries]
      newSeries.splice(i, 1)
      const keepAxis = newSeries.find(
        (s) => s.axis?.yAxis === chartSeries[i].axis?.yAxis,
      )
      if (!keepAxis && chartSeries[i].axis?.yAxis) {
        const newAxis = { ...chart.axis }
        if (chartSeries[i].axis?.yAxis === YAxis.LEFT) {
          delete newAxis[YAxis.LEFT]
        } else {
          delete newAxis[YAxis.RIGHT]
        }
        updateChart(id, { axis: newAxis })
      }
      updateChart(id, { series: [...newSeries] })
    }
  }
  const hasSameAxis = new Set(chartSeries?.map((s) => s.axis.yAxis)).size <= 1

  return (
    <div
      style={{
        width: '100%',
        height: 'fit-content',
        display: 'flex',
        flexWrap: 'wrap',
      }}
    >
      {chartSeries?.map((s, indx) => {
        let initial: NewChartState['add']
        if (s.listed === true) {
          initial = {
            ccy: s.base || Ticker.BTC,
            source: (s.qualifiedName.split('.')[0] as Source) || sources[0],
            colour: s.color || CHART_COLORS[indx],
            yAxis: s.axis.yAxis || YAxis.LEFT,
          }
          if (useInstrument) {
            initial = {
              ...initial,
              instrument: s.qualifiedName.split('.')[2] as Instrument,
            }
          } else if (useExpiries && s.expiry) {
            initial = {
              ...initial,
              expiry: s.expiry,
              ccy: `${s.base}/${s.quote}`,
            }
          } else {
            initial.ccy = `${s.base}/${s.quote}`
          }
        }

        if (!catalog || !sources?.length || !initial) return <></>

        return (
          <PillNew
            index={indx}
            key={`${s.qualifiedName}`}
            chartId={id}
            series={s}
            handleDelete={handleDelete}
            handleUpdate={handleUpdate}
            assetClass={assetClass}
            sources={sources}
            hasSameAxis={hasSameAxis}
            setHighlighted={setHighlighted}
            getAvailableSeries={(
              s: Source,
              c: Ticker | `${Ticker}/${QuoteAsset}`,
            ): [string[], 'expiry' | 'instrument' | undefined] => {
              if (useInstrument) {
                return [
                  catalog?.[s]?.[assetClass]?.[c.split('/')[0]]?.instrument ||
                    [],
                  'instrument',
                ]
              }
              if (useExpiries) {
                return [
                  catalog?.[s]?.[assetClass]?.[c.split('/')[0]]?.active.reduce(
                    (acc: string[], i: CatalogItem) => {
                      if (i.quoteAsset === c.split('/')[1]) {
                        acc.push(i.expiry)
                      }
                      return acc
                    },
                    [],
                  ) || [],
                  'expiry',
                ]
              }
              return [[], undefined]
            }}
            currencies={
              useInstrument
                ? (Object.keys(Ticker) as Ticker[])
                : catalog?.[initial.source]?.pairs || []
            }
            initial={initial}
          />
        )
      })}
    </div>
  )
}

export const TermStructureSeriesPills = ({
  id,
  chartSeries,
  dates,
  setHighlightedSeries,
}: {
  id: string
  chartSeries: OptionTermStructure['series'] | FutureTermStructure['series']
  dates: string[]
  setHighlightedSeries: (n: number | undefined) => void
}): JSX.Element => {
  const updateChart = useDefaultStore((state) => state.updateTermStructure)
  const chart = useDefaultStore(
    (state) => state.defaultCharts?.[id] as TermStructureState,
  )

  const handleDelete = (i: number) => {
    if (chartSeries) {
      const newSeries = [...chartSeries]
      newSeries.splice(i, 1)
      const keepAxis = newSeries.find(
        (s) => s.axis?.yAxis === chartSeries[i].axis?.yAxis,
      )

      if (!keepAxis && chartSeries[i].axis?.yAxis) {
        updateChart(id, 'axis', {
          ...chart.axis,
          [chartSeries[i].axis?.yAxis as string]: undefined,
        })
      }
      if (chart.assetClass === AssetClass.FUTURE) {
        updateChart(id, 'series', [
          ...(newSeries as FutureTermStructureSeries[]),
        ])
      } else {
        updateChart(id, 'series', [...(newSeries as OptionSingleSeries[])])
      }
    }
  }
  return (
    <div
      style={{
        width: '100%',
        height: 'fit-content',
        display: 'flex',
        flexWrap: 'wrap',
      }}
    >
      {chartSeries &&
        chartSeries.map(
          (
            series: OptionSingleSeries | FutureTermStructureSeries | SmileKey,
            indx,
          ) => {
            const hasSameAxis =
              new Set(chartSeries.map((s) => s.axis.yAxis)).size <= 1
            if ('model' in series) {
              return (
                <TermStructPill
                  index={indx}
                  seriesId={id}
                  key={`${series.source}.${series.currency}.${
                    series.model.type
                  }.${series.model.param}.${series.timestamp.toString()}`}
                  series={series}
                  date={dates[indx]}
                  handleDelete={handleDelete}
                  hasSameAxis={hasSameAxis}
                  setHighlightedSeries={setHighlightedSeries}
                />
              )
            }
            if ('middle' in series) {
              return (
                <TermStructPill
                  index={indx}
                  seriesId={id}
                  key={`${series.source}.${series.middle.currency}.${
                    series.middle.model
                  }.${
                    'tenor' in series.middle
                      ? daysToTenor(series.middle.tenor)
                      : series.middle.expiry
                  }.${series.timestamp ? series.timestamp.toString() : ''}`}
                  series={series}
                  date={dates[indx]}
                  handleDelete={handleDelete}
                  hasSameAxis={hasSameAxis}
                  setHighlightedSeries={setHighlightedSeries}
                />
              )
            }
            return (
              <TermStructPill
                index={indx}
                seriesId={id}
                key={`${series.source}.${series.currency}.${
                  series.type
                }.${series.timestamp.toString()}`}
                series={series}
                date={dates[indx]}
                handleDelete={handleDelete}
                hasSameAxis={hasSameAxis}
                setHighlightedSeries={setHighlightedSeries}
              />
            )
          },
        )}
    </div>
  )
}

export const SmileSeriesPills = ({
  id,
  chartSeries,
  dates,
  setHighlightedSeries,
}: {
  id: string
  chartSeries: SmileState['series']
  dates: string[]
  setHighlightedSeries: (n: number | undefined) => void
}): JSX.Element => {
  const updateChart = useDefaultStore((state) => state.updateSmile)
  const chart = useDefaultStore(
    (state) => state.defaultCharts?.[id] as SmileState,
  )

  const handleDelete = (i: number) => {
    if (chartSeries) {
      const newSeries = [...chartSeries]
      newSeries.splice(i, 1)
      const keepAxis = newSeries.find(
        (s) => s.axis?.yAxis === chartSeries[i].axis?.yAxis,
      )
      if (!keepAxis && chartSeries[i].axis?.yAxis) {
        updateChart(id, 'axis', {
          ...chart.axis,
          [chartSeries[i].axis.yAxis]: undefined,
        })
      }
      updateChart(id, 'series', [...newSeries])
    }
  }
  const hasSameAxis =
    !!chartSeries && new Set(chartSeries.map((s) => s.axis.yAxis)).size <= 1

  return (
    <div
      style={{
        width: '100%',
        height: 'fit-content',
        display: 'flex',
        flexWrap: 'wrap',
      }}
    >
      {chartSeries &&
        chartSeries.map((series: SmileKey, indx) => {
          return (
            <TermStructPill
              index={indx}
              seriesId={id}
              key={`${series.source}.${series.middle.currency}.${
                series.middle.model
              }.${
                'tenor' in series.middle
                  ? series.middle.tenor
                  : series.middle.expiry
              }.${series.timestamp?.toString() || ''}`}
              series={series}
              date={dates[indx]}
              handleDelete={handleDelete}
              hasSameAxis={hasSameAxis}
              setHighlightedSeries={setHighlightedSeries}
            />
          )
        })}
    </div>
  )
}
