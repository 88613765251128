import React from 'react'
import {
  RadioButton,
  RadioButtonChangeEvent,
} from '@progress/kendo-react-inputs'

export interface IRadioButton {
  name: string
  value: any
}
import styled from 'styled-components'
import RadioButtonSelectedIMG from '../../../images/RadioButtonSelectedIMG.svg'

const RadioButtonWrapper = styled.div`
  width: auto;
  .RadioBtnTitle {
    margin-left: 10px;
    font-size: 12px;
    font-weight: 600;
  }
`

const RadioBtnContainer = styled.div`
  margin-top: 6px;
  display: flex;
  width: 100%;
  height: 30px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  column-gap: 8px;
  .k-radio {
    background-color: inherit;
    border-color: white;
  }
  .k-radio-md {
    width: 24px;
    height: 24px;
    box-shadow: none;
  }
  .k-radio:checked,
  .k-radio.k-checked {
    background-image: url(${RadioButtonSelectedIMG});
    background-position: center;
    border: none;
    box-shadow: none;
    background-color: inherit;
  }
  .k-radio + .k-label,
  .k-radio-wrap + .k-label,
  .k-radio + .k-radio-label,
  .k-radio-wrap + .k-radio-label {
    font-size: 12px;
    font-weight: 400;
  }
`

type CallbackType = (...args: any[]) => void

export type TRadioButton = IRadioButton

export type IRadioButtonProps = {
  /**
   * current selected value
   */
  current: TRadioButton | undefined
  options: TRadioButton[]
  required?: boolean
  className?: string
  label: string
  showLabel?: boolean
  labelClassName?: string
  size?: 'small' | 'medium' | 'normal' | 'large'
  disabled?: boolean
  /**
   * Callback function fired when a menu item is selected.
   *
   * @param {string} item The selected id or item.
   * @document
   */
  onChange?: CallbackType
}

const RadioButtonComponent = ({
  label,
  current,
  options,
  onChange,
  disabled,
}: IRadioButtonProps) => {
  const [selectedValue, setSelectedValue] = React.useState(current?.value)

  const handleChange = React.useCallback(
    (e: RadioButtonChangeEvent) => {
      if (onChange) {
        onChange.call(undefined, e.target.element?.defaultValue)
        setSelectedValue(e.value)
      }
    },
    [setSelectedValue, onChange],
  )

  return (
    <>
      <RadioButtonWrapper>
        <div className="RadioBtnTitle">{label}</div>
        <RadioBtnContainer>
          {options.map((item) => {
            return (
              <RadioButton
                disabled={disabled}
                key={item.name}
                label={item.name}
                value={item.value}
                checked={selectedValue === item.value}
                onChange={handleChange}
              />
            )
          })}
        </RadioBtnContainer>
      </RadioButtonWrapper>
    </>
  )
}

export default RadioButtonComponent
