import {
  add,
  addDays,
  addMonths,
  addWeeks,
  startOfDay,
  startOfMonth,
  startOfWeek,
} from 'date-fns'
import { LivePoint } from 'stores/types'
import { IChartFrequencyData, ITimeSeriesDataPoint, TimeZone } from 'types'
import { convertDateToUTCOrLatestHour } from 'utils/convert-date-to-utc'
import { changeTimeZone, convertDateToUTCOrLocal } from 'utils/date-formatter'

export const getLiveTimestamp = (
  frequency: IChartFrequencyData,
  timezone: TimeZone,
  dps: ITimeSeriesDataPoint[],
  livePoint?: LivePoint,
) => {
  if (!livePoint) {
    return 0
  }
  const lastPoint = dps[dps.length - 1]
  let timestamp: number = livePoint?.t / 1000000
  //   return timestamp
  if (frequency.unit === 'minutes') {
    if (new Date(timestamp).getSeconds() !== 0) {
      // datefns.roundToNearestMinutes was buggy at time of below
      timestamp = new Date(timestamp).setMinutes(
        new Date(timestamp).getMinutes() + 1,
        0,
        0,
      )
    }
    if (frequency.step !== 1) {
      timestamp = add(new Date(timestamp), {
        minutes: frequency.step - 1,
      }).getTime()
    }

    if (
      Number(lastPoint?.y).toPrecision(1) ===
        Number(livePoint?.v).toPrecision(1) &&
      lastPoint.x >= timestamp
    ) {
      // live data is not forward filled like timeseries so optimistically put live point on the recent datapoint
      const date = new Date(lastPoint.x)
      timestamp = date.setMinutes(date.getMinutes() + 1, 0, 0)
    }
  }

  if (frequency.unit === 'months') {
    const local = startOfMonth(addMonths(new Date(timestamp), 1))
    timestamp =
      timezone === TimeZone.LOCAL
        ? local.getTime()
        : convertDateToUTCOrLatestHour(local).getTime()
  }
  if (frequency.unit === 'weeks') {
    const local = startOfWeek(addWeeks(new Date(timestamp), 1))
    timestamp =
      timezone === TimeZone.LOCAL
        ? local.getTime()
        : convertDateToUTCOrLatestHour(new Date(local)).getTime()
  }
  if (frequency.unit === 'days') {
    const local = startOfDay(addDays(new Date(timestamp), 1))
    timestamp =
      timezone === TimeZone.LOCAL
        ? local.getTime()
        : convertDateToUTCOrLatestHour(local).getTime()
  }
  if (frequency.unit === 'hours') {
    const local = new Date(timestamp).setHours(
      new Date(timestamp).getHours() + 1,
      0,
      0,
      0,
    )

    timestamp = local
    // Do lastly
    if (
      Number(lastPoint?.y).toPrecision(2) ===
        Number(livePoint?.v).toPrecision(2) &&
      lastPoint.x >= timestamp
    ) {
      // live data is not forward filled like timeseries so optimistically put live point on the recent datapoint
      const latest = new Date(lastPoint.x)
      timestamp = latest.getTime()
    }
  }
  return timestamp
}

export const getLiveKey = (key: string, hasMinuteData: boolean) => {
  // if (!hasMinuteData) {
  //   return ''
  // }
  if (key.toLowerCase().includes('perpetual') || key.endsWith('.oi')) {
    if (!key.endsWith('volume.sum')) {
      return key.replace(/.1h.|.1m.|.\[FREQ\]./g, '.tick.')
    }
    return ''
  }

  // TODO handle all keys
  return ''
}
