/* eslint-disable @typescript-eslint/no-misused-promises */
import { integrationMap } from 'consts'
import { localStorageMap } from 'hooks/useLocalStorage'
import { useEffect } from 'react'
import { fetchWithAuth } from 'services/fetch'
import { BitBnsTokenResponse } from 'types'

const REFRESH_TOKEN_TIME = 1000 * 60 * 55 // 55 minutes as lasts 1hr
const IntegrationJwtFetcher = () => {
  useEffect(() => {
    const interval = setInterval(async () => {
      const refreshTokenKeys = Object.keys(window?.localStorage || {})?.filter(
        (k) => k.includes('refresh-token'),
      )
      const res = await Promise.allSettled<BitBnsTokenResponse>(
        refreshTokenKeys.map((key) => {
          const integration = key.split('_')[0]
          const integrationInfo = integrationMap[integration]
          return fetchWithAuth({
            url: `${integrationInfo.refreshUrl}?refreshToken=${
              window?.localStorage.getItem(key) || ''
            }`,
          })
        }),
      )
      res.forEach((r, indx) => {
        if (r.status === 'fulfilled') {
          const integration = refreshTokenKeys[indx].split('_')[0]
          window?.localStorage.setItem(
            localStorageMap.INTEGRATION_ACCESS_TOKEN(integration),
            r.value.access_token.token,
          )
          window?.localStorage.setItem(
            localStorageMap.INTEGRATION_REFRESH_TOKEN(integration),
            r.value.refresh_token.token,
          )
        }
      })
    }, REFRESH_TOKEN_TIME)
    return () => {
      clearInterval(interval)
    }
  }, [])
  return <></>
}

export default IntegrationJwtFetcher
