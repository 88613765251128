import * as React from 'react'

import { Source } from 'types'
import ControlledSelect, {
  SelectItem,
  SelectItemIndicator,
} from 'components/atoms/Select/Index'
import { CheckIcon } from '@radix-ui/react-icons'
import * as Select from '@radix-ui/react-select'

export interface ChangeSourceProps {
  selected?: Source
  handleSelection: (source: Source) => void
  className?: string
  labelClassName?: string
  supportedSources?: Array<Source>
}

const DEFAULT_OPTIONS = Object.values(Source)

export const ChangeSource = ({
  className,
  selected,
  handleSelection,
  labelClassName,
  supportedSources,
}: ChangeSourceProps): React.ReactElement => {
  const options = React.useMemo(() => {
    const items = supportedSources?.length ? supportedSources : DEFAULT_OPTIONS
    return items.map((item) => ({
      name: item,
      value: item,
    }))
  }, [supportedSources])

  if (supportedSources?.length === 1)
    return (
      <div>
        <div
          style={{
            fontSize: 12,
            fontWeight: 600,
            paddingBottom: '0.75rem',
            paddingRight: '2rem',
          }}
        >
          Source
        </div>
        <div id="selected-source">{selected}</div>
      </div>
    )
  return (
    <ControlledSelect
      colorShade="blue6"
      alignSelf="flex-start"
      value={selected}
      onValueChange={handleSelection}
    >
      {options.map((s) => (
        <SelectItem value={s.value} key={s.name}>
          <SelectItemIndicator>
            <CheckIcon />
          </SelectItemIndicator>
          <Select.ItemText>{s.name}</Select.ItemText>
        </SelectItem>
      ))}
    </ControlledSelect>
  )
}
