import styled from 'styled-components'

const WaterMark = styled.div<{ heightAsPercentage?: string }>`
  background: url('/blockscholes-watermark.svg') 50%
    ${({ heightAsPercentage }) => heightAsPercentage || '50%'} / 25% no-repeat;
  // url('/blockscholes-watermark.svg') 50% 80% / 10% no-repeat,
  // url('/blockscholes-watermark.svg') 75% 25% / 10% no-repeat;
  width: 100%;
  height: 100%;
`
export default WaterMark
