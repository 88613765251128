import { Integration } from 'types/register'
import styled from 'styled-components'
import { useState } from 'react'
import useOauthLogIn from 'hooks/useOauthLogIn'

const Container = styled.div`
  padding: 1rem;
  text-align: center;
  p {
    color: ${({ theme }) => theme.palette.common.darkWhite};
  }
  button {
    margin-top: 1rem;

    &:hover {
      transform: scale(1.1);
    }

    &:disabled {
      transform: scale(1);
      cursor: not-allowed;
    }
  }
`
const IntButton = styled.button<{ colour: string }>`
  padding: 1rem;
  margin: 1rem;
  border-radius: 1rem;
  background-color: ${({ colour, theme }) =>
    colour || theme.palette.common.grey};
  color: white;
  border: 0;
  box-shadow: 0px 0px 10px 5px
    ${({ colour, theme }) => colour || theme.palette.common.grey};
`

type IntegrationButtonInput = {
  integration: Integration
  onClick: () => void
  disabled: boolean
  text: string
}
export const IntegrationButton = ({
  integration,
  onClick,
  disabled,
  text,
}: IntegrationButtonInput): JSX.Element => {
  return (
    <IntButton
      type="submit"
      colour={integration.colour}
      disabled={disabled}
      onClick={onClick}
    >
      {text}{' '}
      <img
        src={integration?.logo}
        alt={`${integration?.name || 'external'} logo`}
      />
    </IntButton>
  )
}

const IntegrationButtonRegister = ({
  integration,
}: {
  integration: Integration
}): JSX.Element => {
  const { startIntegration } = useOauthLogIn(integration)
  const [checked, setChecked] = useState(false)
  return (
    <Container>
      <p>
        Get started with our platform which offers institutional-grade tools to
        analyse BTC and ETH derivative markets by registering with{' '}
        {integration.name} below.
      </p>
      <label
        htmlFor="checked"
        style={{
          height: '30px',
          paddingBottom: '0.5rem',
          width: '100%',
        }}
      >
        <input
          type="checkbox"
          name="checked"
          checked={checked}
          onChange={() => setChecked((prev) => !prev)}
        />{' '}
        I agree to the{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.blockscholes.com/terms/terms-conditions"
        >
          terms of service
        </a>
      </label>
      <IntegrationButton
        integration={integration}
        disabled={!checked}
        onClick={startIntegration}
        text="Register with"
      />
      {/* </div> */}
    </Container>
  )
}

export default IntegrationButtonRegister
