import React from 'react'
import {
  ColorPicker,
  ColorPickerChangeEvent,
} from '@progress/kendo-react-inputs'
import styled from 'styled-components'
import { CHART_COLORS } from 'components/charts/common/consts'

interface StyledColorPickerPropsType {
  pickedColor: string
}

const ColorPickerWrapper = styled.div<StyledColorPickerPropsType>`
  width: 100%;
  background-color: inherit;
  margin: 0px;
  padding: 0.7rem;
  display: flex;
  .k-color-picker,
  .k-colorpicker {
    background-color: ${(props) => props.pickedColor};
  }
  .k-input,
  .k-picker {
    border: none;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    box-shadow: 0px 0px 5px ${(props) => props.pickedColor};
    margin-left: 6px;
  }
  .k-color-preview {
    border: none;
    border-radius: 0px;
  }
  .k-i-arrow-s {
    display: none;
  }
  .k-input-inner {
    display: none;
  }
  .k-button {
    box-shadow: 0px 0px 5px ${(props) => props.pickedColor};
  }
  .k-i-caret-alt-down {
    display: none;
  }
`

interface ColorPickerSeriesProps {
  func: (newColor: string) => void
  defaultValue: string
  id?: string
}
export const paletteSettings = {
  palette: CHART_COLORS,
  columns: 6,
  tileSize: 30,
}

const ColorPickerForCharts: React.FC<ColorPickerSeriesProps> = ({
  func,
  id,
  defaultValue,
}) => {
  const handleColorPickerChange = (event: ColorPickerChangeEvent) => {
    func(event.value)
  }

  return (
    <>
      <ColorPickerWrapper pickedColor={defaultValue}>
        <ColorPicker
          id={id}
          paletteSettings={paletteSettings}
          value={defaultValue}
          onChange={handleColorPickerChange}
          view="palette"
        />
      </ColorPickerWrapper>
    </>
  )
}

export default ColorPickerForCharts
