import * as ToggleGroup from '@radix-ui/react-toggle-group'
import styled from 'styled-components'

export const ToggleGroupItem = styled(ToggleGroup.Item)<{
  height?: number
  width?: number
}>`
  background-color: ${({ theme }) => theme.palette.common.blue3};
  color: ${({ theme }) => theme.palette.common.blue11};
  height: ${({ height }) => height || 35}px;
  width: ${({ width }) => width || 35}px;
  display: flex;
  font-size: 15px;
  line-height: 1;
  align-items: center;
  justify-content: center;
  margin-left: 1px;

  &:disabled {
    background-color: ${({ theme }) => theme.palette.common.blue2};
    color: ${({ theme }) => theme.palette.common.blue7};
  }
  &:hover {
    &:not(:disabled) {
      background-color: ${({ theme }) => theme.palette.common.blue6};
    }
  }
  &[data-state='on'] {
    background-color: ${({ theme }) => theme.palette.common.blue6};
    color: ${({ theme }) => theme.palette.common.blue12};
  }
  &:focus {
    position: relative;
    box-shadow: 0 0 0 2px ${({ theme }) => theme.palette.common.blue8};
  }
`

const ToggleGroupRoot = styled(ToggleGroup.Root)`
  display: inline-flex;
  width: fit-content;
  height: fit-content;
  background-color: ${({ theme }) => theme.palette.common.blue1};
  border-radius: 4px;
  box-shadow: 0 2px 10px ${({ theme }) => theme.palette.common.blue4};

  ${ToggleGroupItem}:first-child {
    margin-left: 0;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  ${ToggleGroupItem}:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
`

const AToggleGroup = <T extends string>({
  children,
  value,
  onValueChange,
}: {
  children: JSX.Element | React.ReactElement[]
  value?: string
  onValueChange?: (s: T) => void
}): JSX.Element => (
  <ToggleGroupRoot type="single" value={value} onValueChange={onValueChange}>
    {children}
  </ToggleGroupRoot>
)

export default AToggleGroup
