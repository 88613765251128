import Button from 'components/atoms/Button'
import { useState } from 'react'
import styled from 'styled-components'
import deleteIcon from '../../images/delete-icon.svg'
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";


interface IStyledButtonProps {
  marginTop?: string
}
// FIXME: make this a xs form of Button
const StyledButton = styled(Button) <IStyledButtonProps>`
  font-size: 0.8rem;
  padding: 2px 8px;
  height: 20px;
  margin-top: ${(props) => props.marginTop};
`

const StyledDeleteButton = styled.button`
  background: none;
  border: none;
  margin-left: 10px;
   onhover: {
    cursor: pointer;
   }
`

interface IHistoricalAnalyzerTextCellProps {
  value: string
  handleSubmit: (newName: string) => void
  inEdit: boolean
}

interface DependentId {
  value: string
}

export const HistoricalAnalyzerTextCell: React.FC<IHistoricalAnalyzerTextCellProps> =
  (props) => {
    const { value = '', handleSubmit, inEdit } = props

    const [localState, setLocalState] = useState(value)
    return (
      <td
        style={{
          display: inEdit ? 'flex' : 'table-cell',
          flexDirection: 'column',
        }}
      >
        <div
          style={{
            display: 'flex',

          }}
        >
          <span
            style={{
              width: '70%',
              display: 'flex',
            }}
          >
            <input
              size={50}
              style={{
                WebkitAppearance: 'none',
                background: 'none',
                color: 'rgb(228, 231, 235)',
                borderStyle: 'none',
                width: '100%'
              }}
              placeholder={'Click to add name'}
              value={localState}
              onChange={(event) => {
                setLocalState(event.target.value)
              }}
              onBlur={() => handleSubmit(localState)}
            />
          </span>
        </div>
      </td>
    )
  }

export const HistoricalAnalyzerCommandCell = (props) => {
  const { dataItem, discardChanges, edit, remove, queryEditState } = props
  const [visible, setVisible] = useState(false);
  const [dependantIds, setDependantIds] = useState<Array<string>>([])

  const toggleDialog = () => {
    setVisible(!visible);
  };

  const handleDelete = () => {
    let foundDependency = false
    const label = dataItem.label;
    queryEditState.map(v => {
      let precision = v.expression.match(/\(([^)]+)\)/);
      if(precision && precision.length > 1){
        if(precision[1].includes(label)) {
          setDependantIds(prevState => !prevState.includes(v.label) ? [...prevState, v.label] : [...prevState])
          foundDependency = true
        }
      }
    });
    if(foundDependency){
      setVisible(true)
    } else {
      remove(dataItem)
    }
  }

  return (
    <td className="k-command-cell">
      <StyledDeleteButton>
        <img src={deleteIcon} alt="delete" onClick={handleDelete} />
      </StyledDeleteButton>
      {visible && (
        <Dialog title={"Please confirm"} onClose={toggleDialog}>
          <div
            style={{
              margin: "25px",
              textAlign: "center",
            }}
          >
            This expression is dependent on ID ({dependantIds.join(', ')}).
            <div>Are you sure you want to delete?</div>
          </div>
          <DialogActionsBar>
            <button
              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
              onClick={toggleDialog}
            >
              No
            </button>
            <button
              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
              onClick={() => remove(dataItem)}
            >
              Yes
            </button>
          </DialogActionsBar>
        </Dialog>
      )}
    </td>
  )
}
