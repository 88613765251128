import { geometry, Path } from '@progress/kendo-drawing'
import { MarkersVisualArgs } from '@progress/kendo-react-charts'
import { LiveDataPoint } from 'types'

const liveMarker = (e: MarkersVisualArgs, lastPoint: LiveDataPoint) => {
  if (!lastPoint.liveTime) {
    return e.createVisual()
  }

  if (new Date(e.category).getTime() === new Date(lastPoint.x).getTime()) {
    const { transform } = geometry
    const origin = e.rect.origin
    const center = e.rect.center()
    const bottomRight = e.rect.bottomRight()
    const width = 2

    let path = new Path()
    if (!lastPoint.increase || lastPoint?.increase === 0) {
      path = new Path({
        stroke: { color: 'white', width: 1 },
        fill: { color: e.options.border.color, opacity: 0.5 },
        transform: transform().rotate(90, center),
        id: 'live',
      })
        .moveTo(origin.x, bottomRight.y)
        .lineTo(bottomRight.x, bottomRight.y)
        .lineTo(center.x, origin.y)
        .close()
    } else if (lastPoint.increase > 0) {
      path = new Path({
        stroke: { color: 'green', width },
        fill: { color: e.options.border.color, opacity: 0.5 },
        id: 'live',
      })
        .moveTo(origin.x, bottomRight.y)
        .lineTo(bottomRight.x, bottomRight.y)
        .lineTo(center.x, origin.y)
        .close()
    } else {
      path = new Path({
        stroke: { color: 'red', width },
        fill: { color: e.options.border.color, opacity: 0.5 },
        transform: transform().rotate(180, center).translate(0, -5),
        id: 'live',
      })
        .moveTo(origin.x, bottomRight.y)
        .lineTo(bottomRight.x, bottomRight.y)
        .lineTo(center.x, origin.y)
        .close()
    }
    return path
  }

  return new Path()
}

export default liveMarker
