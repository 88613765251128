export enum OptionType {
  PUT = 'P',
  CALL = 'C',
  DIGITAL_CALL = 'digital.C',
  DIGITAL_PUT = 'digital.P',
}

export enum OptionStyle {
  DIGITAL = 'digital',
  VANILLA = 'vanilla',
}
