import { H6 } from 'components/atoms/Typography/styles'
import {
  ChartBottomToolbar,
  ChartTopToolbar,
} from 'components/charts/common/toolbar'
import React, { useLayoutEffect } from 'react'
import { useChartStore } from 'stores'
import { NewChartState } from 'stores/types'
import styled, { useTheme } from 'styled-components'
import { AxisFormat, Chart, TimeZone, YAxis } from 'types'

import useImportToAnalyzer from 'hooks/useImportToAnalyzer'
import { generateExpression } from 'hooks/useMultiSeriesChart'
import LastUpdated from 'components/charts/common/series/LastUpdated'

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
`

const CenteredContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
`

interface IChartWrapperProps {
  title: string
  chart: React.ReactNode
  seriesPills?: React.ReactNode
  infoButton?: React.ReactNode
  type?: Chart
  disableMinutes?: boolean
  legend?: React.ReactNode
  topBarContent?: JSX.Element
  addSeries?: JSX.Element
  id: string
  timeZone?: TimeZone
  updatedStamp?: Date
  chartSeries?: NewChartState['series']
  axis: Partial<Record<YAxis, AxisFormat>>
}

const ChartWrapper: React.FC<IChartWrapperProps> = ({
  title,
  chart,
  infoButton,
  type,
  seriesPills,
  disableMinutes,
  legend,
  topBarContent,
  addSeries,
  id,
  timeZone,
  updatedStamp,
  chartSeries,
  axis,
}) => {
  const setUpChart = useChartStore((state) => state.setInitialChart)
  const updateChart = useChartStore((state) => state.updateTimeSeriesChart)
  const idKey = id || title
  const storedChartTimings = useChartStore((state) => {
    const c = state.defaultCharts?.[idKey]
    if (c && 'timings' in c && 'range' in c.timings) {
      return c.timings
    }
  })
  const storedChartFreq = useChartStore((state) => {
    const c = state.defaultCharts[idKey]
    return c?.frequency || '1h'
  })

  const updateTimeRange = (range) => updateChart(idKey, { timings: { range } })
  const updateFrequency = (f) => updateChart(idKey, { frequency: f })
  const toggleTimeZone = () =>
    updateChart(idKey, {
      timeZone: timeZone === TimeZone.UTC ? TimeZone.LOCAL : TimeZone.UTC,
    })

  useLayoutEffect(() => {
    const state = {
      key: idKey,
      type: type || Chart.TIMESERIES,
      series: chartSeries,
      axis,
    }

    setUpChart({ ...state })
  }, [])

  const importToAnalyzer = useImportToAnalyzer({
    frequency: storedChartFreq === '1m' ? '1m' : '1h',
  })
  const importSeriesToHA = () => {
    if (chartSeries) {
      importToAnalyzer(
        chartSeries.map(
          (s) =>
            s?.expression
              ? generateExpression(s, '1h')
              : `${s.qualifiedName
                  .replace('.1m.', '.1h.')
                  .replace('.[FREQ].', '.1h.')}
            `, // No minute data for now
          // ${
          //   field
          //     ? `.${field
          //         .toLowerCase()
          //         .replace('svi_', '')
          //         .replace('sabr_', '')
          //         .replace('alpha', 'a')
          //         .replace('future', 'px')}`
          //     : ''
          // }
        ),
        title,
      )
    }
  }
  const theme = useTheme()
  return (
    <FlexColumn>
      <ChartTopToolbar
        id={id}
        title={title}
        infoButton={infoButton}
        disableMinutes={disableMinutes}
        frequency={storedChartFreq}
        updateFrequency={updateFrequency}
        type={type}
        topBarContent={topBarContent}
        importSeriesToHA={importSeriesToHA}
        addSeries={addSeries}
      />
      {seriesPills}
      <CenteredContent>{chart}</CenteredContent>
      {type?.startsWith('TIMESERIES') && (
        <ChartBottomToolbar
          updatedStamp={updatedStamp}
          frequency={storedChartFreq}
          timings={storedChartTimings}
          updateTimeRange={updateTimeRange}
          timeZone={timeZone}
          toggleTimeZone={toggleTimeZone}
        />
      )}
      {legend}
      {!type?.startsWith('TIMESERIES') &&
        updatedStamp &&
        !Number.isNaN(updatedStamp.getTime()) &&
        timeZone && (
          <LastUpdated
            onClick={toggleTimeZone}
            timeZone={timeZone}
            updatedStamp={updatedStamp}
          />
        )}
    </FlexColumn>
  )
}

export default ChartWrapper
