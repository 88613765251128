import { BSLANG } from '@blockscholes/ql'
import { EditorState } from '@codemirror/state'
import {
  getLiveKey,
  getLiveTimestamp,
} from 'components/charts/common/series/live'
import { getDefaultSeries } from 'components/charts/common/series/utils'
import { ConstructKey } from 'components/charts/common/util'
import { FREQUENCIES } from 'components/molecules/FrequencyChange'
import { differenceInSeconds, max as maxDate } from 'date-fns'
import { parseTreeToAst } from 'pages/HistoricalAnalyzer/language'
import { Parser } from 'pages/HistoricalAnalyzer/parser'
import { useEffect, useMemo, useState } from 'react'
import getHistoricalSeries from 'services/bsLang'
import { useDefaultStore, useLiveStore } from 'stores'
import {
  ChartState,
  ListedSeries,
  LiveActionTypes,
  NewChartSeries,
  NewChartState,
} from 'stores/types'
import {
  AxisFormat,
  ChartKind,
  CHART_KIND,
  Frequency,
  TimeSeriesData,
  TimeZone,
} from 'types'
import { getChartTimeStampRange } from 'utils/charts'
import { changeTimeZone } from 'utils/date-formatter'
import { shallow } from 'zustand/shallow'
import { useChartStoreChart } from './useChartStore'
import { usePermissions } from './usePermissions'
import useTimeSeriesQuery from './useTimeSeriesQuery'

const useMultiSeriesChart = <T extends Omit<ConstructKey, 'color' | 'axis'>>(
  id: string,
  defaultSeries: (T | Omit<ListedSeries, 'color' | 'axis'>)[],
) => {
  const [highlighted, setHighlighted] = useState<number | undefined>()
  const series = getDefaultSeries(defaultSeries)

  const chartSeries =
    useDefaultStore((state) => {
      const c = state.defaultCharts?.[id] as ChartState
      return c?.series
    }) || series

  const chartTimings = useDefaultStore((state) => {
    const chart = state.defaultCharts?.[id]
    if (chart && 'timings' in chart && 'range' in chart.timings) {
      return chart.timings
    }

    return { single: 'LATEST' } as { single: 'LATEST' }
  })

  const chartTimeFreq = useDefaultStore((s) => {
    const chart = s.defaultCharts?.[id]
    if (chart && 'frequency' in chart && chart.frequency) {
      return chart.frequency
    }
    return '1D'
  })
  const frequency = FREQUENCIES[chartTimeFreq] || FREQUENCIES['1h']

  return { chartSeries, highlighted, setHighlighted, chartTimings, frequency }
}

const isKind = (s?: ChartKind): s is ChartKind => {
  return CHART_KIND.includes(s as ChartKind)
}

const replaceFreq = (s: string, freq?: Frequency) =>
  s.replaceAll(/\[FREQ\]|1m|1h/g, freq?.endsWith('m') ? '1m' : '1h')

export const generateExpression = (s: NewChartSeries, freq?: Frequency) => {
  if (s.expression) {
    const templatedExp = s.expression?.replace('[QFN]', s.qualifiedName) // Includes [FREQ], [CCY]
    return replaceFreq(templatedExp, freq).replaceAll(/\[CCY\]/g, s.base)
  }
  return replaceFreq(s.qualifiedName, freq)
}

export const useNewMultiSeriesChart = <T extends NewChartSeries>({
  id,
  defaultSeries,
  useLiveData = false, // TODO switch to true when everything working on BE
  axisDefault,
}: {
  id: string
  defaultSeries: T[]
  useLiveData?: boolean
  axisDefault?: NewChartState['axis']
}) => {
  const dispatch = useLiveStore((s) => s.actions.dispatch)
  const { hasMinuteData } = usePermissions()
  const [highlighted, setHighlighted] = useState<number | undefined>()
  const chart = useChartStoreChart({ id, defaultSeries, axisDefault })

  const queries = useMemo(
    () =>
      chart?.series?.map((s) => {
        const editorState = EditorState.create({
          doc: s.expression
            ? generateExpression(s)
            : replaceFreq(s.qualifiedName, chart?.frequency),
          extensions: [BSLANG()],
        })

        const parser = new Parser(editorState, [])
        return {
          queryKey: [
            ...replaceFreq(s.qualifiedName, chart?.frequency).split('.'),
          ],
          fetcher: (signal: AbortSignal, from: number, until: number) =>
            getHistoricalSeries(
              {
                start: new Date(from),
                end: new Date(until),
                parse_tree: parseTreeToAst(parser.parseTree, {}) || {},
              },
              signal,
            ),
          successKey: replaceFreq(s.qualifiedName, chart?.frequency),
          refetchInterval: 1000,
          refetchOnWindowFocus: true,
        }
      }) || [],
    [chart?.series, chart?.frequency],
  )

  const { data, isLoading } = useTimeSeriesQuery<TimeSeriesData>({
    queries,
    timestamp: getChartTimeStampRange(
      chart?.timings,
      chart?.frequency.endsWith('m') ? '1m' : '1h',
    ),
    frequency: chart?.frequency.endsWith('m') ? '1m' : '1h',
  })

  const liveData = useLiveStore(
    (state) =>
      chart?.series?.map((s) => {
        return s.expression
          ? state.data[
              state.bslangMap[
                generateExpression(s).replaceAll(/1h|1m/g, 'tick')
              ]
            ]?.p
          : state.data[getLiveKey(s.qualifiedName, hasMinuteData)]?.p
      }),
    shallow,
  )
  const bslangMap = useLiveStore((s) => s.bslangMap)

  const dataWithLive = useMemo(
    () =>
      ('range' in chart?.timings &&
        chart?.timings?.range &&
        typeof chart?.timings?.range === 'object') ||
      !liveData
        ? data
        : data?.map((d, i) =>
            liveData[i]
              ? {
                  ...d,
                  dataPoints: [
                    ...d.dataPoints,
                    ...(liveData[i].pv &&
                    d.dataPoints?.length > 2 &&
                    differenceInSeconds(new Date(), liveData[i].t / 1000000) <
                      60
                      ? [
                          {
                            y: liveData[i].pv,
                            x:
                              Number(
                                d.dataPoints?.[d.dataPoints?.length - 1].x,
                              ) +
                              (Number(
                                d.dataPoints?.[d.dataPoints?.length - 1].x,
                              ) -
                                Number(
                                  d.dataPoints?.[d.dataPoints?.length - 2].x,
                                )),
                          },
                          {
                            y: liveData[i].v,
                            x: getLiveTimestamp(
                              FREQUENCIES[chart?.frequency],
                              chart?.timeZone as TimeZone,
                              d.dataPoints,
                              liveData[i],
                            ),
                            liveTime: liveData[i].t,
                            increase: liveData[i].pv
                              ? liveData[i].v - liveData[i].pv
                              : 0,
                            live: true,
                          },
                        ]
                      : [
                          {
                            y: liveData[i]?.v,
                            x: getLiveTimestamp(
                              FREQUENCIES[chart?.frequency],
                              chart?.timeZone as TimeZone,
                              d.dataPoints,
                              liveData[i],
                            ),
                            liveTime: liveData[i].t,
                            live: true,
                          },
                        ]),
                  ],
                }
              : d,
          ),
    [liveData, chart?.timeZone, data, chart?.frequency, chart?.timings],
  )

  const newData = useMemo(
    () =>
      dataWithLive?.reduce((acc: TimeSeriesData[], d) => {
        if (d) {
          acc.push({
            ...d,
            dataPoints: d.dataPoints?.map((dp) => {
              return {
                ...dp,
                y: dp.y,
                x: changeTimeZone(new Date(dp.x), chart?.timeZone),
              }
            }),
          })
        }
        return acc
      }, []) || [],
    [dataWithLive, chart?.timeZone],
  )

  const dataKinds = [...new Set(newData?.map((d) => d.kind).filter(isKind))]

  const axis = useMemo(() => {
    const newAxisFormat = { ...chart.axis }
    Object.keys(chart.axis).forEach((name) => {
      if (newAxisFormat[name].startsWith('c')) {
        newAxisFormat[name] = chart.axis[name]
      }
      if (!newData) {
        newAxisFormat[name] = chart.axis[name] || 'n2'
      }
      let max = 0
      let min = 0
      newData.forEach(({ dataPoints }) => {
        const dps = dataPoints?.map((d) => Number(d.y))
        max = Math.max(max, ...dps)
        min = Math.min(min, ...dps)
      })
      const getZeroLength = (x: number) => -Math.floor(Math.log10(x || 0.1) + 1)
      const local = getZeroLength(max - min) + 1 || 3

      newAxisFormat[name] =
        max - min > 2
          ? chart.axis[name]
          : (`${(chart.axis[name]?.[0] as string) || 'n'}${
              chart.axis[name][0] === 'p' ? Math.max(0, local - 1) : local
            }` as AxisFormat)
    })
    return newAxisFormat
  }, [newData, chart.axis])

  useEffect(() => {
    if (chart?.series && useLiveData) {
      dispatch({
        type: LiveActionTypes.SUBSCRIBE,
        data: {
          keys: chart?.series.reduce((acc: { qualified_name: string }[], k) => {
            if (!k.expression) {
              const liveKey = getLiveKey(k.qualifiedName, hasMinuteData)
              if (liveKey) {
                acc.push({
                  qualified_name: liveKey,
                })
              }
            }
            return acc
          }, []),
          bslang: chart?.series.reduce((acc: string[], k) => {
            if (k.expression) {
              const exp = generateExpression(k, chart?.frequency)
              acc.push(exp)
            }
            return acc
          }, []),
        },
      })
      return () => {
        const unsub = chart?.series?.reduce((acc: string[], k) => {
          const id = k.expression
            ? bslangMap[generateExpression(k).replaceAll(/1m|1h/g, 'tick')]
            : getLiveKey(k.qualifiedName, hasMinuteData)

          if (id) {
            acc.push(id)
          }
          return acc
        }, [])
        if (unsub?.filter(Boolean).length) {
          dispatch({
            type: LiveActionTypes.UN_SUBSCRIBE,
            data: {
              streams: unsub.filter(Boolean),
            },
          })
        }
      }
    }
  }, [dispatch, chart?.series, useLiveData])

  const recentDates = [
    ...(data?.map((d) =>
      changeTimeZone(
        new Date(d.dataPoints?.[d.dataPoints?.length - 1]?.x || 0),
        chart?.timeZone,
      ),
    ) || []),
    ...(liveData?.map((d) =>
      changeTimeZone(new Date(d?.t / 1000000 || 0), chart?.timeZone),
    ) || []),
  ].filter((dte) => !Number.isNaN(new Date(dte).getTime()))

  const error = dataKinds.length > 1
  return {
    ...chart,
    highlighted,
    setHighlighted,
    timeZoneData: newData,
    axis,
    latestDate: maxDate(recentDates),
    isLoading,
    error,
    chartKind: dataKinds[0],
  }
}

export default useMultiSeriesChart
