/* eslint-disable react-hooks/rules-of-hooks */
import { CatalogContext } from 'context/CatalogContext'
import { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { endOfQuarter, format } from 'date-fns'

import { LiveActionTypes } from 'stores/types'
import { useLiveStore } from 'stores'
import { Ticker } from 'types'

import { getOptionContract } from './utils'

export interface Price {
  price: number
  prevPrice?: number
  label: string
}
export type LivePrices = Record<string, Price>
export const tickers = [
  'BTC',
  'BTCFuture',
  'BTCOptionC',
  'BTCOptionP',
  'ETH',
  'ETHFuture',
  'ETHOptionC',
  'ETHOptionP',
]
type Vars = typeof tickers
export type LatestFutures = { [K in Vars[number]]: string }
const currencies = [Ticker.BTC, Ticker.ETH]

// TODO - Rewrite this logic completely taking multiple currencies and more live prices with a carosel
export const useLivePrices = (): LivePrices => {
  const currentOptions = useRef<
    Partial<Record<Ticker, { call: string; put: string }>>
  >({})
  const currentFutures = useRef<Partial<Record<Ticker, string>>>({})
  const catalog = useContext(CatalogContext)
  const dispatch = useLiveStore((s) => s.actions.dispatch)
  const spots = useLiveStore((s) =>
    currencies.map((t) => s.data[`deribit.spot.${t}USD.tick.index.px`]),
  )

  const liveOptions = useLiveStore((s) =>
    Object.entries(currentOptions.current).reduce(
      (
        acc: Record<
          string,
          {
            call: { t: number; v: number; pv?: number }
            put: { t: number; v: number; pv?: number }
          }
        >,
        e,
      ) => {
        acc[e[0]] = {
          call: s.data[`deribit.option.${e[1].call}.tick.px`]?.p,
          put: s.data[`deribit.option.${e[1].put}.tick.px`]?.p,
        }

        return acc
      },
      {},
    ),
  )

  const liveFutures = useLiveStore((s) =>
    Object.entries(currentFutures.current).reduce(
      (
        acc: Record<string, { t: number; v: number; pv?: number }>,
        [ticker, contract],
      ) => {
        acc[ticker] = s.data[`deribit.future.${contract}.tick.px`]?.p
        return acc
      },
      {},
    ),
  )

  useEffect(() => {
    currencies.forEach((c, i) => {
      const contracts = getOptionContract(
        catalog.state.activeOptions,
        c,
        spots[i]?.p?.v,
      )
      if (
        contracts.call &&
        !(currentOptions.current[c]?.call === contracts.call)
      ) {
        dispatch({
          type: LiveActionTypes.SUBSCRIBE,
          data: {
            keys: [
              { qualified_name: `deribit.option.${contracts.call}.tick.px` },
            ],
          },
        })
      }
      if (
        contracts.put &&
        !(currentOptions.current[c]?.put === contracts.put)
      ) {
        dispatch({
          type: LiveActionTypes.SUBSCRIBE,
          data: {
            keys: [
              { qualified_name: `deribit.option.${contracts.put}.tick.px` },
            ],
          },
        })
      }
      currentOptions.current = {
        ...currentOptions.current,
        [c]: { call: contracts.call, put: contracts.put },
      }
    })
  }, [currentOptions, spots, catalog.state.activeOptions, dispatch])

  useEffect(() => {
    const quarterDate = format(endOfQuarter(new Date()), 'dLLLyy').toUpperCase()
    currencies.forEach((c, i) => {
      const future = catalog.state.activeFutures.find(
        (f) =>
          f.baseAsset === c &&
          f.exchange === 'deribit' &&
          f.instrument.includes(quarterDate),
      )

      if (future && future?.instrument !== currentFutures.current[c]) {
        dispatch({
          type: LiveActionTypes.SUBSCRIBE,
          data: {
            keys: [
              {
                qualified_name: `deribit.future.${future?.instrument}.tick.px`,
              },
            ],
          },
        })
        currentFutures.current = {
          ...currentFutures.current,
          [c]: future?.instrument,
        }
      }
    })
  }, [catalog.state.activeFutures, dispatch])

  return {
    BTC: {
      price: spots?.[0]?.p?.v,
      prevPrice: spots?.[0]?.p?.pv,
      label: 'BTC',
    },
    BTCFuture: {
      price: liveFutures[Ticker.BTC]?.v,
      prevPrice: liveFutures[Ticker.BTC]?.pv,
      label: currentFutures.current.BTC || '',
    },
    BTCOptionC: {
      price: liveOptions[Ticker.BTC]?.call?.v,
      prevPrice: liveOptions[Ticker.BTC]?.call?.pv,
      label: currentOptions.current.BTC?.call || '',
    },
    BTCOptionP: {
      price: liveOptions[Ticker.BTC]?.put?.v,
      prevPrice: liveOptions[Ticker.BTC]?.put?.pv,
      label: currentOptions.current.BTC?.put || '',
    },
    ETH: {
      price: spots?.[1]?.p?.v,
      prevPrice: spots?.[1]?.p?.pv,
      label: 'ETH',
    },
    ETHFuture: {
      price: liveFutures[Ticker.ETH]?.v,
      prevPrice: liveFutures[Ticker.ETH]?.pv,
      label: currentFutures.current.ETH || '',
    },
    ETHOptionC: {
      price: liveOptions[Ticker.ETH]?.call?.v,
      prevPrice: liveOptions[Ticker.ETH]?.call?.pv,
      label: currentOptions.current.ETH?.call || '',
    },
    ETHOptionP: {
      price: liveOptions[Ticker.ETH]?.put?.v,
      prevPrice: liveOptions[Ticker.ETH]?.put?.pv,
      label: currentOptions.current.ETH?.put || '',
    },
  }
}
