import { useMemo } from 'react'
import { capitalize } from 'lodash'

import {
  Chart,
  ChartArea,
  ChartLegend,
  ChartSeries,
  ChartSeriesItem,
  ChartSeriesItemTooltip,
  ChartTooltip,
  ChartXAxis,
  ChartXAxisItem,
  ChartYAxis,
  ChartYAxisItem,
  LineStyle,
  TooltipPoint,
  ZoomStartEvent,
} from '@progress/kendo-react-charts'
import { useTheme } from 'styled-components'
import {
  AxisFormat,
  MultiModelParametersSeries,
  MultiTermStructure,
  Source,
  Ticker,
  YAxis,
} from 'types'

import { formatNumber } from '@telerik/kendo-intl'
import { DaysTenor, MAX_TERM_DAYS } from 'consts'
import 'hammerjs'
import { Model, SABRParamWithVol, SVIParamWithVol } from 'types/models'
import { dateFormatter } from 'utils/date-formatter'

import ChartLoader, { ChartNoSeriesData } from './ChartLoader'
import { customMarker } from './common/markers/customMarker'
import WaterMark from './Watermark'
import CurrencyLogo from './common/CurrencyLogo'
import { getCrossingVals } from './common/util'

const TooltipRenderer = ({
  point,
  labelFormat,
  seriesLabel,
  currency,
  date,
}: {
  point: TooltipPoint
  labelFormat: string
  seriesLabel?: string
  currency: Ticker
  date: string
}) => {
  const series: string = seriesLabel || point.series.name || ''
  const instrument: string = point.dataItem?.instrument
  const expiryDays: number = point.dataItem?.dateOffset
  const expiryToTenor =
    DaysTenor[instrument?.slice(0, -1)] || DaysTenor[expiryDays]
  return (
    <table>
      <tbody>
        {(instrument || expiryToTenor) && (
          <tr>
            <td>
              <CurrencyLogo currency={currency} />
              {expiryToTenor ||
                (instrument.includes('-') && instrument.endsWith('d') // HACK as dates end with d, BTC-1JUN22d
                  ? instrument.slice(0, -1)
                  : instrument)}
            </td>
          </tr>
        )}

        <tr>
          <td style={{ textTransform: 'capitalize' }}>
            {`${series}: ${formatNumber(point.value.y, labelFormat)} `}
          </td>
        </tr>
        <tr>
          <td>{date} (UTC)</td>
        </tr>
      </tbody>
    </table>
  )
}

type ValidTermType = MultiTermStructure | MultiModelParametersSeries

interface ITermStructureChartProps<T extends ValidTermType> {
  xAxisTitle?: string
  curveStyle?: LineStyle
  axis: Partial<Record<YAxis, AxisFormat>>
  dataMapping?: Record<
    string,
    | {
        markers: { dateOffset: number; val: number; instrument: string }[]
        seriesPoints: { dateOffset: number; val: number; instrument: string }[]
        type: string
        currency: Ticker
        timestamp: Date
        source: Source
        axis: {
          yAxis: YAxis
        }
        color: string
      }
    | {
        seriesPoints: { dateOffset: number; val: number }[]
        currency: Ticker
        source: Source
        timestamp: Date
        param: SVIParamWithVol | SABRParamWithVol
        model: Model
        axis: {
          yAxis: YAxis
        }
        color: string
      }
  >
  latestDate?: string
  highlighted?: number
}

const TermStructureChart = <T extends ValidTermType>(
  props: ITermStructureChartProps<T>,
) => {
  const {
    xAxisTitle,
    curveStyle = 'smooth',
    dataMapping,
    latestDate,
    axis,
    highlighted,
  } = props

  const theme = useTheme()
  const data = dataMapping

  const { maxDateOffset, minYVal } = useMemo(() => {
    if (!data) {
      return { maxDateOffset: MAX_TERM_DAYS, minYVal: 0 }
    }
    let max = 0
    let leftMin = Infinity
    let rightMin = Infinity
    Object.entries(data || {})?.forEach(([seriesLabel, seriesData]) => {
      max = Math.max(max, ...seriesData?.seriesPoints?.map((d) => d.dateOffset))
      if (seriesData.axis.yAxis === YAxis.RIGHT) {
        rightMin = Math.min(
          rightMin,
          ...seriesData?.seriesPoints?.map((d) => d.val),
        )
      } else {
        leftMin = Math.min(
          leftMin,
          ...seriesData?.seriesPoints?.map((d) => d.val),
        )
      }
    })
    if (leftMin < 10) {
      if (leftMin < 0) {
        leftMin *= 1.1
      } else {
        leftMin *= 0.9
      }
    } else {
      leftMin -= 10
    }

    if (rightMin < 10) {
      if (rightMin < 0) {
        rightMin *= 1.1
      } else {
        rightMin *= 0.9
      }
    } else {
      rightMin -= 10
    }
    return {
      maxDateOffset: Number.isNaN(max) ? MAX_TERM_DAYS : max,
      minYVal: {
        [YAxis.LEFT]: leftMin,
        [YAxis.RIGHT]: rightMin,
      },
    }
  }, [data])

  const handleZoomStart = (e: ZoomStartEvent) => {
    if (!e.nativeEvent.shiftKey) {
      e.preventDefault()
    }
  }
  return (
    <>
      {Object.keys(data || {}).length === 0 && latestDate === 'LATEST' && (
        <ChartLoader />
      )}
      {Object.keys(data || {}).length === 0 && latestDate !== 'LATEST' && (
        <ChartNoSeriesData />
      )}
      {Object.keys(data || {}).length > 0 && (
        <WaterMark>
          <Chart
            renderAs="svg"
            pannable
            zoomable={{ mousewheel: { rate: 0.1, lock: 'y' } }}
            onZoomStart={handleZoomStart}
            style={{ height: '100%', touchAction: 'auto' }}
            transitions={false}
          >
            <ChartArea opacity={0} />
            <ChartLegend visible={false} />
            <ChartTooltip />
            <>
              <ChartYAxis>
                {Object.entries(axis)
                  .filter(([_, f]) => f)
                  .map(([seriesLabel, format], i) => {
                    if (!format) return <></>
                    return (
                      <ChartYAxisItem
                        key={seriesLabel.toUpperCase()}
                        name={seriesLabel.toUpperCase()}
                        min={minYVal[seriesLabel.toUpperCase()]}
                        axisCrossingValue={-Infinity}
                        labels={{
                          format: axis[seriesLabel],
                          font: '0.7em "OpenSans"',
                        }}
                        crosshair={{
                          visible: true,
                        }}
                      />
                    )
                  })}
              </ChartYAxis>
              <ChartSeries>
                {Object.entries(data || {}).map(
                  ([seriesLabel, seriesData], indx) => {
                    let opacity = 1
                    if (typeof highlighted === 'number') {
                      if (highlighted !== indx) {
                        opacity = 0.3
                      }
                    }
                    return (
                      <ChartSeriesItem
                        color={seriesData?.color}
                        key={seriesLabel}
                        type="scatterLine"
                        style={curveStyle}
                        width={2}
                        yField="val"
                        yAxis={seriesData.axis.yAxis.toUpperCase()}
                        xAxis="dateOffset"
                        xField="dateOffset"
                        opacity={opacity}
                        data={seriesData?.seriesPoints}
                        markers={{
                          visual:
                            'markers' in seriesData &&
                            seriesData?.markers?.length > 0
                              ? (e) => customMarker(e, seriesData.markers)
                              : undefined,
                        }}
                      >
                        <ChartSeriesItemTooltip
                          render={({ point }) => (
                            <TooltipRenderer
                              point={point}
                              labelFormat={axis[seriesData.axis.yAxis] || 'n2'}
                              seriesLabel={`${capitalize(seriesData.source)} ${
                                'param' in seriesData
                                  ? `${seriesData.model} ${seriesData.param}`
                                  : seriesData.type
                              }`}
                              date={`${dateFormatter.format(
                                seriesData.timestamp,
                              )}`}
                              currency={seriesData.currency}
                            />
                          )}
                          color="white"
                          background={theme.palette.common.blue4}
                        />
                      </ChartSeriesItem>
                    )
                  },
                )}
              </ChartSeries>
              <ChartXAxis>
                <ChartXAxisItem
                  narrowRange
                  name="dateOffset"
                  axisCrossingValue={getCrossingVals(maxDateOffset + 50, axis)}
                  title={{ text: xAxisTitle }}
                  labels={{
                    visible: true,
                  }}
                  crosshair={{
                    visible: true,
                  }}
                />
              </ChartXAxis>
            </>
          </Chart>
        </WaterMark>
      )}
    </>
  )
}

export default TermStructureChart
