/* eslint-disable no-case-declarations */
/* eslint-disable no-param-reassign */
import { Auth, Hub } from 'aws-amplify'
import { v4 as uuidv4 } from 'uuid'
import { flatten } from 'lodash'
import { Source, Ticker } from 'types'
import { StateCreator } from 'zustand'

import { BSLANG } from '@blockscholes/ql'
import { EditorState } from '@codemirror/state'
import { parseTreeToAst } from 'pages/HistoricalAnalyzer/language'
import { Parser } from 'pages/HistoricalAnalyzer/parser'
import { QualifiedName } from 'types/catalog'
import { Monitors } from 'types/Monitors'
import {
  DataKey,
  LiveActionTypes,
  LiveDataDispatch,
  LiveDataKey,
  LiveDispatchBsLang,
  LiveState,
} from './types'

const getRPCMessage = (
  method: 'subscribe' | 'authenticate' | 'unsubscribe',
  data: any,
  id = uuidv4(),
) =>
  JSON.stringify({
    jsonrpc: '2.0',
    method,
    params: data,
    id,
  })

export const initialState: Omit<LiveState, 'actions'> = {
  subscribed: {},
  monitors: {},
  bslangMap: {},
  toSubscribe: flatten(
    [Source.DERIBIT].map((s) =>
      Object.values(Ticker).map((t) => ({
        qualified_name: `${s.toLowerCase()}.spot.${t}USD.tick.index.px`,
      })),
    ),
  ),

  unSubscribe: [],
  ready: false,
  authenticated: false,
  open: false,
  data: {},
}

interface BaseMessage {
  jsonrpc: '2.0'
  id?: string
}

interface MessageWithResult<T> extends BaseMessage {
  result: T
}
interface ErrorEvent extends BaseMessage {
  error: { message: string; code: number }
}

type AuthEvent = MessageWithResult<'ok'>
interface BaseResultItem {
  channel: string
  timestamp: number
  value: number
}
interface MonitorDataItem extends BaseResultItem {
  rt: number
  field: LiveDataKey
  contract: string
}

type MonitorData = MessageWithResult<MonitorDataItem[]>
type GeneralData = MessageWithResult<BaseResultItem[]>

type SubscriptionSuccess =
  | Record<string, { qualified_name: string; client_id?: string }>
  | Record<string, LiveDispatchBsLang>

type ValueData = MonitorData | GeneralData
type SubscriptionSuccessData = MessageWithResult<SubscriptionSuccess[]>
type SubscriptionDataNotification = {
  jsonrpc: '2.0'
  method: 'subscription'
  params: BaseResultItem[]
}

type WSResponse = AuthEvent | ErrorEvent | SubscriptionSuccessData
type WSNotification = SubscriptionDataNotification
type WebSocketData = WSResponse | WSNotification

type WSResponseDp = string | SubscriptionSuccess
type NotificationData = BaseResultItem | MonitorDataItem

const isValueData = (i: NotificationData): i is BaseResultItem => {
  return (
    typeof i === 'object' && 'value' in i && 'timestamp' in i && 'channel' in i
  )
}

const isMonitorData = (i: NotificationData): i is MonitorDataItem =>
  isValueData(i) &&
  'field' in i &&
  'contract' in i &&
  i.channel.includes('monitor')

const isAuthSuccess = (i: WebSocketData): i is AuthEvent => {
  return 'result' in i && i.result === 'ok' && i.id === 'authenticate'
}

const isSubscribedMessage = (i: WSResponseDp): i is SubscriptionSuccess => {
  return typeof i === 'object'
}
const isNotification = (i: WebSocketData): i is WSNotification =>
  'params' in i && i.method === 'subscription'

const getJwt = async () => {
  const currentSession = await Auth.currentSession()
  const accessToken = currentSession.getAccessToken()
  if (!accessToken) {
    return undefined
  }
  return accessToken.getJwtToken()
}

const isError = (i: WebSocketData): i is ErrorEvent =>
  'error' in i && 'message' in i.error

const sendAuth = (ws: WebSocket, set?: (p: Partial<LiveState>) => void) =>
  getJwt().then((jwt) => {
    console.log('Opening ws...')
    if (jwt) {
      ws.send(
        getRPCMessage(
          'authenticate',
          {
            authorization: `Bearer ${jwt}`,
          },
          'authenticate',
        ),
      )
      if (set) {
        set({ ready: true })
      }
    }
  })

const reducer = (
  state: LiveState,
  action: LiveDataDispatch,
  ws: WebSocket,
  get: () => LiveState,
) => {
  switch (action.type) {
    case LiveActionTypes.SET_READY:
      if (state.open && !state.authenticated) {
        void sendAuth(ws)
      }
      return { ready: true }
    case LiveActionTypes.SUBSCRIBE:
      const { data } = action
      if ('keys' in data && data.keys) {
        const newKeys = data.keys.reduce(
          (acc: { qualified_name: string }[], x) => {
            if (!state.subscribed[x.qualified_name]) {
              acc.push(x)
            } else {
              state.subscribed[x.qualified_name] +=
                get().subscribed[x.qualified_name]
            }
            return acc
          },
          [],
        )
        if (newKeys.length > 0) {
          if (state.authenticated) {
            ws.send(getRPCMessage('subscribe', newKeys))
          }
          const newToSubSet = new Set<{ qualified_name: string }>()
          newKeys.forEach((n) => newToSubSet.add(n))
          state.toSubscribe = [...get().toSubscribe, ...newToSubSet]
        }
      }
      if ('streams' in data) {
        const newKeys = data.streams.filter((x) =>
          x.monitor.id.endsWith(Monitors.MARKET_OPTIONS)
            ? !state.subscribed[`${x.monitor.id}-${x.monitor.id[0]}`]
            : !state.subscribed[x.monitor.id],
        )

        if (newKeys.length > 0 && state.authenticated) {
          ws.send(getRPCMessage('subscribe', newKeys))
        }
        if (newKeys.length > 0) {
          state.toSubscribe = [...get().toSubscribe, ...newKeys]
        }
      }
      if ('bslang' in data && data.bslang) {
        const messages: Record<string, any> = []
        for (let i = 0; i < data.bslang?.length; i++) {
          const exp = data.bslang[i].replaceAll(/1h|1m/g, 'tick')
          const editorState = EditorState.create({
            doc: exp.replaceAll(/1h|1m/g, 'tick'),
            extensions: [BSLANG()],
          })

          const parser = new Parser(editorState, [])
          if (!state.subscribed[exp]) {
            messages.push({
              bslang: parseTreeToAst(parser.parseTree, {}) || {},
              client_id: exp,
            })

            state.subscribed[exp] = 1
          } else {
            state.subscribed[exp] = ++state.subscribed[exp]
          }
          // Convert expression
          // add to query map so we can match id to it
        }
        if (messages?.length > 0) {
          if (get().authenticated) {
            ws.send(getRPCMessage('subscribe', messages))
          }
          const newToSubSet = new Set<Record<string, any>>()
          messages.forEach((n) => newToSubSet.add(n))
          state.toSubscribe = [...get().toSubscribe, ...newToSubSet]
        }
      }
      return state
    case LiveActionTypes.UN_SUBSCRIBE:
      const {
        data: { streams },
      } = action
      const unsubscribe: string[] = []
      for (let i = 0; i < streams.length; i++) {
        let id = streams[i]
        unsubscribe.push(id)
        if (!state.subscribed[id]) {
          // get bslang expression
          id =
            Object.keys(state.bslangMap).find(
              (key) => state.bslangMap[key] === id,
            ) || ''
        }
        if (state.subscribed[id]) {
          if (state.subscribed[id] === 1) {
            delete state.subscribed[id]
          } else {
            state.subscribed[id] -= state.subscribed[id]
          }
        }
      }
      if (unsubscribe.length) {
        ws.send(getRPCMessage('unsubscribe', unsubscribe))
      }
      return state
    case LiveActionTypes.SET_MONITOR_IDS:
      if (
        'monitor' in action.data &&
        action.data.monitor &&
        state.monitors[action.data.monitor]
      ) {
        const val =
          action.data.monitor === Monitors.MARKET_OPTIONS
            ? action.data.ids[0]
            : action.data.ids

        return {
          ...state,
          monitors: {
            ...state.monitors,
            [action.data.monitor]: val,
          },
        }
      }
      return state
    default:
      return state
  }
}
export const isLivePercentageVal = (f: LiveDataKey, dataKey?: string) => {
  if (!dataKey || dataKey?.includes('SABR') || dataKey?.includes('SVI')) {
    return !!['vv', 'rho', 'atm', 'skew', 'fly'].includes(f)
  }
  if (dataKey.endsWith('-C') || dataKey.endsWith('-P')) {
    return !!['iv'].includes(f)
  }
  return false
}

const getVal = (v: number, f: LiveDataKey, dataKey: string) => {
  if (/d\d+$/.test(f) || /dytd+$/.test(f) || isLivePercentageVal(f, dataKey)) {
    // delta changes e.g. d24
    return Number((+v * 100).toFixed(2))
  }
  return +v < 0.1 ? +v.toFixed(6) : +v.toFixed(3)
}
export const mutations: StateCreator<
  LiveState,
  [],
  [['zustand/immer', never]],
  LiveState
> = (set, get) => {
  let ws: WebSocket

  Hub.listen('auth', (data) => {
    switch (data.payload.event) {
      case 'signOut':
        if (ws) ws.close()
        set(initialState)
        return {
          actions: {
            dispatch: (args: LiveDataDispatch) =>
              set((state) => reducer(state, args, ws, get)),
          },
          ...initialState,
        }
      case 'signIn':
        if (
          get()?.open &&
          !get()?.authenticated &&
          ws?.readyState === WebSocket.OPEN
        ) {
          void sendAuth(ws, set)
        }
        break
      default:
        break
    }
  })
  const openHandler = () => {
    set({ open: true })
    if (get()?.ready) {
      void sendAuth(ws, set)
    }
  }

  const messageHandler = (e) => {
    const data: WebSocketData = JSON.parse(e.data)
    if (isError(data)) {
      console.error('[ws-error]:', data)
    } else if ('result' in data && data.result) {
      if (isAuthSuccess(data)) {
        set({ authenticated: true })
        ws.send(getRPCMessage('subscribe', [...get().toSubscribe]))
      }
      set((state) => {
        for (let i = 0; i < data.result.length; i++) {
          const dp = data.result[i]
          if (isSubscribedMessage(dp)) {
            const entries = Object.entries(dp)

            entries.forEach((n) => {
              // eslint-disable-next-line @typescript-eslint/no-unused-expressions
              const id: string = n[1]?.qualified_name || n[1]?.monitor?.id

              if (id === undefined) {
                if (n[1].bslang && n[1].client_id) {
                  state.bslangMap[n[1].client_id] = n[0]
                }
              } else {
                state.subscribed[id] = state.subscribed[id]
                  ? state.subscribed[id]
                  : 1

                state.toSubscribe = state.toSubscribe.filter(
                  (f) => f !== Object.entries(n)[0][1],
                )
              }
            })
          }
        }
        return state
      })
    } else if (isNotification(data)) {
      set((state) => {
        for (let i = 0; i < data.params.length; i++) {
          const dp = data.params[i]
          if (isValueData(dp)) {
            if (isMonitorData(dp)) {
              const monitor = `${dp.channel.split('.')[2]}.${
                dp.channel.split('.')[3]
              }`
              const { channel, timestamp, value, field, contract, rt } = dp
              const exchange = channel.split('.')[1] as Source
              const dataKey = `${exchange}.${contract}` as DataKey

              if (!state.data?.[dataKey]) {
                state.data[dataKey] = {
                  [field]: {
                    t: timestamp,
                    v: getVal(value, field, dataKey),
                    rt,
                  },
                }
              } else {
                if (
                  getVal(value, field, dataKey) ===
                    state.data?.[dataKey]?.[field]?.v ||
                  (state.data?.[dataKey]?.[field]?.t &&
                    timestamp - (state.data?.[dataKey]?.[field]?.t as number) <
                      250)
                ) {
                  continue
                }

                state.data[dataKey][field] = state.data[dataKey][field]
                  ? {
                      t: timestamp,
                      v: getVal(value, field, dataKey),
                      rt,
                      pv: state.data[dataKey][field]?.v,
                    }
                  : { t: timestamp, v: getVal(value, field, dataKey), rt }

                if (monitor === Monitors.MARKET_OPTIONS) {
                  if (!state.monitors[monitor]) {
                    state.monitors[monitor] = contract.split('-')[1]
                  }
                } else {
                  state.monitors[monitor] = state.monitors[monitor]
                    ? Array.from(
                        new Set(
                          state.monitors[monitor].concat(
                            contract.split('-')[1],
                          ),
                        ),
                      )
                    : [contract.split('-')[1]]
                }
              }
            } else {
              const { channel, timestamp, value } = dp
              const val = getVal(value, 'p', channel as QualifiedName)
              if (!state.data[channel]) {
                state.data[channel] = {
                  p: { t: timestamp, v: val },
                }
              } else if (
                state.data[channel].p.v !== val &&
                timestamp !== state.data[channel].t
              ) {
                state.data[channel].p = {
                  t: timestamp,
                  v: val,
                  pv: state.data[channel].p.v,
                }
              }
            }
          }
        }
        return state
      })
    }
  }
  const closeHandler = () => {
    console.log('Closed WS')
    set({ subscribed: {} })
    ws = new WebSocket(
      process.env.REACT_APP_ENV === 'staging'
        ? 'wss://staging-websocket-api.blockscholes.com/'
        : 'wss://prod-websocket-api.blockscholes.com/',
    )

    ws.addEventListener('open', openHandler)
    ws.addEventListener('message', messageHandler)
    ws.addEventListener('close', closeHandler)
  }
  closeHandler()

  return {
    actions: {
      dispatch: (args: LiveDataDispatch) =>
        set((state) => reducer(state, args, ws, get)),
    },
    ...initialState,
  }
}
