import {
  Chart,
  ChartNavigator,
  ChartNavigatorSelect,
  ChartNavigatorSeries,
  ChartNavigatorSeriesItem,
  DragEndEvent,
  SelectEndEvent,
  SeriesClickEvent,
  SeriesHoverEvent,
  StockChart,
  ZoomEndEvent,
  ZoomStartEvent,
} from '@progress/kendo-react-charts'
import React, { useCallback } from 'react'

import { isEqual } from 'date-fns'
import { useNewMultiSeriesChart } from 'hooks/useMultiSeriesChart'
import { useDefaultStore } from 'stores'
import styled from 'styled-components'
import {
  IChartFrequencyData,
  QuoteAssetPair,
  TimeSeriesData,
  YAxis,
} from 'types'

type LocalAxis = {
  timestamp: { min: string; max: string | undefined }
}
interface IChartDecider {
  handleZoomStart: (e: ZoomStartEvent) => void
  handleZoomEnd: (
    e:
      | ZoomEndEvent
      | DragEndEvent
      | { axisRanges: { timestamp: { max: Date; min: Date } } },
  ) => void
  children: React.ReactNode
  data: TimeSeriesData[]
  frequency: IChartFrequencyData
  localAxisState?: LocalAxis
  id?: string
  axis: ReturnType<typeof useNewMultiSeriesChart>['axis']
}

const StyledStockChart = styled(StockChart)`
  .k-navigator-hint {
    .k-tooltip {
      background-color: ${({ theme }) => theme.palette.background.toolbarGrey};
      padding: 0.5rem;
    }
  }
`

export const SChart = styled(Chart)`
  .k-chart-surface {
    touch-action: auto !important;
  }
`

const ChartDecider = ({
  handleZoomStart,
  handleZoomEnd,
  children,
  data,
  frequency,
  localAxisState,
  id,
  axis,
}: IChartDecider): JSX.Element => {
  const setSeries = useDefaultStore((s) => s.tooltip.setHoveredSeries)
  const setHoveredSeries = useCallback(
    (e: SeriesHoverEvent | SeriesClickEvent) => {
      const s = e.series as unknown as { axis: YAxis; name: string }
      const d = data.find((i) => i.key === s.name)
      const lp = d?.dataPoints[d?.dataPoints.length - 1]
      setSeries({
        clientX: (e.nativeEvent.clientX ||
          e.nativeEvent.event.clientX) as number,
        clientY: (e.nativeEvent.clientY ||
          e.nativeEvent.event.clientY) as number,
        category: e.category,
        value: e.value,
        series: s.name,
        chartId: id,
        format: axis[s.axis],
        quote: QuoteAssetPair.USD,
        isLivePoint: lp
          ? isEqual(e.category, new Date(lp?.x || '')) &&
            e.value === lp?.y &&
            'live' in lp
          : false,
      })
    },
    [data, id, setSeries, axis],
  )

  const step = frequency?.duration ? frequency.duration / 3600000 : 4032
  if (
    Math.ceil(data[0]?.dataPoints?.length / step) > 4395 &&
    frequency.unit !== 'minutes'
  ) {
    // just over 6 months of 1hr data
    return (
      <StyledStockChart
        pannable
        onZoomStart={handleZoomStart}
        onZoomEnd={handleZoomEnd}
        onDragEnd={handleZoomEnd}
        onSeriesHover={setHoveredSeries}
        onSelectEnd={(e: SelectEndEvent) =>
          handleZoomEnd({
            axisRanges: {
              timestamp: { min: e.from as Date, max: e.to as Date },
            },
          })
        }
        transitions={false}
      >
        {children}
        <ChartNavigator>
          <ChartNavigatorSelect
            from={
              localAxisState?.timestamp?.min
                ? new Date(localAxisState?.timestamp?.min)
                : new Date(
                    data[0]?.dataPoints?.[data[0].dataPoints.length - 2500]?.x,
                  )
            }
            to={
              localAxisState?.timestamp?.max
                ? new Date(localAxisState?.timestamp?.max)
                : undefined
            }
          />
          <ChartNavigatorSeries>
            {data.map((d) => (
              <ChartNavigatorSeriesItem
                key={d.key}
                data={d.dataPoints}
                type="line"
                field="y"
                categoryField="x"
              />
            ))}
          </ChartNavigatorSeries>
        </ChartNavigator>
      </StyledStockChart>
    )
  }
  return (
    <SChart
      onSeriesHover={setHoveredSeries}
      onSeriesClick={setHoveredSeries}
      renderAs="canvas"
      zoomable
      transitions={false}
      style={{ height: '95%', touchAction: 'auto' }}
      onZoomStart={handleZoomStart}
      // onZoomEnd={handleZoomEnd}
    >
      {children}
    </SChart>
  )
}

export default ChartDecider
