import { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Formik, Field, Form } from 'formik'
import { z } from 'zod'
import { toFormikValidationSchema } from 'zod-formik-adapter'
import { Input } from '@progress/kendo-react-inputs'
import { DropDownList } from '@progress/kendo-react-dropdowns'
import countryList from 'country-list'

import { Auth, Hub } from 'aws-amplify'
import ErrorMessage from 'components/atoms/Typography/ErrorMessage'
import Button from 'components/atoms/Button'

import styled from 'styled-components'

import { useQuery } from '@tanstack/react-query'
import { getOauthUrl } from 'services/queries'
import PasswordInput from './PasswordInput'

const countries = countryList.getNames()
const Schema = z
  .object({
    firstName: z
      .string({ required_error: 'First name is required' })
      .min(2, { message: 'First name must be 2 or more characters long' }),
    lastName: z
      .string({ required_error: 'Last name is required' })
      .min(2, { message: 'Last name must be 2 or more characters long' }),
    organisation: z.string().min(3).optional(),
    checked: z.boolean({
      required_error: 'Accepting our terms & conditions is required.',
    }),
    email: z.string().email(),
    confirmEmail: z.string().email(),
    password: z
      .string()
      .min(10)
      .regex(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])((?=.*\W))^[^ ]+$/, {
        message: `Passwords require numbers, an uppercase letter, a lowercase letter and a symbol.`,
      }),
    confirmPassword: z
      .string()
      .min(10)
      .regex(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])((?=.*\W)|(?=.*_))^[^ ]+$/, {
        message: `Passwords require numbers, an uppercase letter, a lowercase letter and a symbol.`,
      }),
    country: z.string(),
  })
  .refine((data) => data.password === data.confirmPassword, {
    message: "Passwords don't match",
    path: ['confirmPassword'], // path of error
  })
  .refine((data) => data.email === data.confirmEmail, {
    message: "Emails don't match",
    path: ['confirmEmail'], // path of error
  })
  .refine((data) => data.checked, {
    message: 'Please accept our terms of service.',
    path: ['checked'], // path of error
  })
  .refine((data) => countries.find((c) => c === data.country), {
    message: 'Please choose country from our provided list.',
    path: ['country'], // path of error
  })

const StyledForm = styled(Form)`
  padding-top: 1rem;
  input,
  .k-dropdownlist > .k-input-inner {
    color: white;
    background-color: inherit;
    padding: 0.4rem;
  }
  .k-picker-outline {
    color: inherit;
    border-color: inherit;
    padding: 0.4rem;
  }

  display: flex;
  flex-direction: column;
`

class BitBnsError extends Error {
  public static http_code: number
  public error: string

  constructor(public message: string) {
    super(message)
    this.error = message
  }
}

const RegisterForm = ({
  persistedEmail,
  name,
  oauth,
}: {
  persistedEmail?: string
  name?: string
  oauth?: string
}): JSX.Element => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const referral = searchParams.get('referral')
  const [ApiError, setApiError] = useState<string | null>(null)
  const [email, setEmail] = useState<string>()

  Hub.listen('auth', (e) => {
    if (e.payload.event === 'autoSignIn') {
      if (oauth) {
        setEmail(e.payload.data?.attributes?.email)
      }
    }
  })
  Hub.listen('auth', ({ payload: { event, data } }) => {
    if (event === 'signIn' || event === 'autoSignIn') {
      const { signInUserSession = {} } = data
      const { accessToken = {} } = signInUserSession
      const { jwtToken, payload = {} } = accessToken
      const { 'cognito:groups': roles, username, exp } = payload
      localStorage.setItem('accessToken', jwtToken)
      const payloadToSave = {
        Role: roles[0],
        Username: username,
        exp,
      }
      localStorage.setItem('payload', JSON.stringify(payloadToSave))
      navigate('/dashboard/futures')
    }
  })
  const {
    data: body,
    error,
    isFetching,
  } = useQuery({ ...getOauthUrl(oauth, email) })

  if (body?.url && window) {
    window.location.href = body.url
  }
  return (
    <Formik
      initialValues={{
        firstName: name?.split(' ')?.[0] || '',
        lastName: name?.split(' ')?.[1] || '',
        email: persistedEmail || '',
        confirmEmail: persistedEmail || '',
        organisation: undefined,
        password: '',
        confirmPassword: '',
        checked: false,
        country: 'Select Country',
      }}
      validationSchema={toFormikValidationSchema(Schema)}
      onSubmit={async (values) => {
        try {
          setApiError(null)
          await Auth.signUp({
            username: values.email,
            password: values.password,
            attributes: {
              email: values.email,
              name: `${values.firstName} ${values.lastName}`,
              given_name: values.firstName,
              family_name: values.lastName,
              'custom:custom:companyname': values.organisation,
              'custom:oauth_sign_up': oauth || undefined,
              address: JSON.stringify({ country: values.country }),
              'custom:referral': referral,
            },
            autoSignIn: {
              enabled: true,
            },
          })
          setTimeout(() => navigate('/dashboard/futures'), 500)
        } catch (e) {
          if (e instanceof Error) {
            setApiError(e.message)
          }
        }
      }}
    >
      {({
        isSubmitting,
        errors,
        touched,
        handleChange,
        handleBlur,
        values,
      }) => {
        return (
          <StyledForm>
            <Input
              id="email"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
              type="email"
              placeholder="Email"
              name="email"
              disabled={!!persistedEmail}
            />
            {errors.email && touched.email && values.email.length > 0 ? (
              <ErrorMessage>{errors.email}</ErrorMessage>
            ) : null}
            <Input
              id="confirmEmail"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.confirmEmail}
              type="email"
              placeholder="Confirm email"
              name="confirmEmail"
              disabled={!!persistedEmail}
            />
            {errors.confirmEmail &&
            touched.confirmEmail &&
            values.confirmEmail.length > 0 ? (
              <ErrorMessage>{errors.confirmEmail}</ErrorMessage>
            ) : null}
            <div style={{ display: 'flex' }}>
              <Input
                name="firstName"
                id="firstName"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.firstName}
                type="text"
                placeholder="First Name"
              />

              <Input
                name="lastName"
                id="lastName"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.lastName}
                type="text"
                placeholder="Last Name"
              />
            </div>
            {errors.firstName &&
            touched.firstName &&
            values.firstName.length > 0 ? (
              <ErrorMessage>{errors.firstName}</ErrorMessage>
            ) : null}
            {errors.lastName &&
            touched.lastName &&
            values.lastName.length > 0 ? (
              <ErrorMessage>{errors.lastName}</ErrorMessage>
            ) : null}
            <PasswordInput
              name="password"
              id="password"
              handleChange={handleChange}
              handleBlur={handleBlur}
              value={values.password}
              placeholder="Password"
              showError={
                !!errors.password &&
                values.password?.length > 0 &&
                !!touched.password
              }
              error={errors.password}
            />
            <PasswordInput
              name="confirmPassword"
              id="confirmPassword"
              handleChange={handleChange}
              handleBlur={handleBlur}
              value={values.confirmPassword}
              placeholder="Confirm your Password"
              showError={
                !!errors.confirmPassword &&
                values.confirmPassword?.length > 0 &&
                !!touched.confirmPassword
              }
              error={errors.confirmPassword}
            />

            <Input
              name="organisation"
              id="organisation"
              type="text"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.organisation}
              placeholder="Organisation (Optional)"
            />
            <DropDownList
              name="country"
              id="country"
              data={countries}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.country}
              fillMode="outline"
            />
            {errors.country && touched.country && (
              <ErrorMessage>{errors.country}</ErrorMessage>
            )}
            <label
              htmlFor="checked"
              style={{
                padding: '0.5rem 0',
                width: 'fit-content',
              }}
            >
              <Field type="checkbox" name="checked" id="checked" /> I agree to
              the{' '}
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.blockscholes.com/terms/terms-conditions"
              >
                terms of service
              </a>
            </label>
            {errors.checked && touched.checked && values.checked === false ? (
              <ErrorMessage>{errors.checked}</ErrorMessage>
            ) : null}
            {ApiError ? <ErrorMessage>{ApiError}</ErrorMessage> : null}
            {error instanceof Error && (
              <ErrorMessage>{error.message}</ErrorMessage>
            )}
            {error instanceof BitBnsError && (
              <ErrorMessage>{error.error}</ErrorMessage>
            )}
            <Button type="submit" variant="contained">
              {isSubmitting || isFetching ? 'Registering...' : 'Register'}
            </Button>
          </StyledForm>
        )
      }}
    </Formik>
  )
}

export default RegisterForm
