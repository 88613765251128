import {
  Chart,
  ChartArea,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartLegend,
  ChartSeries,
  ChartSeriesItem,
  ChartSeriesItemTooltip,
  ChartTooltip,
  ChartValueAxis,
  ChartValueAxisItem,
} from '@progress/kendo-react-charts'
import { useTheme } from 'styled-components'
import { IChartFrequencyData } from 'types'
import { AxisFormat, TimeSeriesData, TimeZone, YAxis } from 'types/charts'

import 'hammerjs'
import useChartActions from 'hooks/useChartActions'
import { ChartState } from 'stores/types'
import { ChartNoSeriesData } from './ChartLoader'
import WaterMark from './Watermark'
import TooltipRenderer from './ToolTip'
import { ConstructKey, getCrossingVals } from './common/util'

interface IMultiTimeSeriesBarChartProps {
  data?: TimeSeriesData[]
  frequency: IChartFrequencyData
  valueAxisCrosshairFormat?: string
  chartSeries: ChartState['series']
  timeZone?: TimeZone
  axis: Partial<Record<YAxis, AxisFormat>>
  hoveredIndex?: number
  chartTimings?: ChartState['timings']
  id?: string
}

// Integrate into TimeSeriesLineChart?
const MultiTimeSeriesBarChart: React.FC<IMultiTimeSeriesBarChartProps> = ({
  data = [],
  frequency,
  valueAxisCrosshairFormat = 'p2',
  chartSeries,
  timeZone,
  axis,
  hoveredIndex,
  chartTimings,
  id,
}) => {
  const colors = chartSeries?.map((c) => c.color) || []
  const { handleZoomStart, handleZoomEnd, localAxisState } = useChartActions({
    chartTimings,
    unit: frequency.unit,
  })
  const theme = useTheme()

  return (
    <>
      {Object.keys(data).length === 0 ? (
        <ChartNoSeriesData />
      ) : (
        <WaterMark>
          <Chart
            pannable
            renderAs="canvas"
            zoomable={{ mousewheel: { rate: 0.1 } }}
            onZoomStart={handleZoomStart}
            onZoomEnd={handleZoomEnd}
            onDragEnd={handleZoomEnd}
            transitions={false}
            style={{ height: '95%', touchAction: 'auto' }}
          >
            <ChartArea opacity={0} />
            <ChartLegend visible={false} />
            <ChartTooltip />
            <ChartValueAxis>
              {Object.entries(axis)
                .filter(([_, f]) => f)
                .map(([seriesLabel, f], i) => {
                  if (!f) return <div key={seriesLabel} />
                  return (
                    <ChartValueAxisItem
                      key={seriesLabel}
                      name={seriesLabel}
                      min={localAxisState?.[seriesLabel]?.min}
                      max={localAxisState?.[seriesLabel]?.max}
                      axisCrossingValue={-Infinity}
                      labels={{
                        format: f,
                        font: '0.7em "OpenSans"',
                      }}
                      crosshair={{
                        visible: true,
                      }}
                      narrowRange
                    />
                  )
                })}
            </ChartValueAxis>
            <ChartSeries>
              {data.map(({ key, dataPoints, field }, indx) => {
                let opacity = 1
                if (typeof hoveredIndex === 'number') {
                  if (hoveredIndex !== indx) {
                    opacity = 0.3
                  }
                }
                return (
                  <ChartSeriesItem
                    color={colors[indx]}
                    key={key}
                    type="column"
                    name={key}
                    axis={chartSeries?.[indx]?.axis?.yAxis || YAxis.LEFT}
                    width={2}
                    field="y"
                    categoryAxis="timestamp"
                    categoryField="x"
                    data={dataPoints}
                    markers={{ visible: false }}
                    opacity={opacity}
                  >
                    <ChartSeriesItemTooltip
                      render={({ point }) => (
                        <TooltipRenderer
                          point={point}
                          labelFormat={valueAxisCrosshairFormat}
                          field={field}
                          timeZone={timeZone}
                        />
                      )}
                      color="white"
                      background={theme.palette.common.blue4}
                    />
                  </ChartSeriesItem>
                )
              })}
            </ChartSeries>
            <ChartCategoryAxis>
              <ChartCategoryAxisItem
                name="timestamp"
                type="date"
                baseUnit={frequency.unit}
                baseUnitStep={frequency.step}
                maxDivisions={20}
                max={localAxisState?.timestamp?.max}
                min={localAxisState?.timestamp?.min}
                axisCrossingValue={getCrossingVals(Date.now(), axis)}
                labels={{
                  rotation: 'auto',
                  font: '0.7em "OpenSans"',
                  dateFormats: {
                    weeks: 'd MMM yy',
                    days: 'd MMM yy',
                    months: 'MMM yy',
                  },
                }}
                crosshair={{
                  visible: true,
                }}
              />
            </ChartCategoryAxis>
          </Chart>
        </WaterMark>
      )}
    </>
  )
}

export default MultiTimeSeriesBarChart
