import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import RegisterForm from 'components/register/Form'
import { H3 } from 'components/atoms/Typography/styles'
import IntegrationButtonRegister from 'components/register/IntegrationButton'
import Layout from 'components/register/Layout'
import { integrationMap } from 'consts'
import Button from 'components/atoms/Button'
import { useMediaQuery } from 'hooks/useMediaQuery'

const Register = () => {
  const { referral } = useParams()
  const [searchParams] = useSearchParams()
  const searchReferral = searchParams.get('referral')
  const navigate = useNavigate()
  const isMobile = useMediaQuery('(max-width: 768px)')

  const referee = integrationMap[referral || ''] // TODO move to API call when we refer more
  return (
    <Layout
      LHSContent={
        <>
          {referee?.logo ? (
            <>
              <h3>X</h3>
              <img
                src={referee?.logo}
                alt={`${referee?.name || 'external'} logo`}
                style={{ width: '80%', maxHeight: '300px', zIndex: 10 }}
              />
            </>
          ) : undefined}
          {referee?.pricing && (
            <p style={{ textAlign: 'center' }}>
              Our subscription is{' '}
              <b style={{ color: 'white' }}>{referee?.pricing}</b>
              <br />
              You can cancel at anytime.
            </p>
          )}
          {(referral || searchReferral) && (
            <p style={{ textAlign: 'center', color: 'white' }}>
              Referral Code: <b>{referral || searchReferral}</b>
            </p>
          )}
        </>
      }
      isMobile={isMobile}
    >
      <>
        <H3 style={{ textAlign: 'center' }}>Register</H3>
        {referee ? (
          <IntegrationButtonRegister integration={referee} />
        ) : (
          <RegisterForm />
        )}
        <p
          style={{
            padding: '0.5rem 0',
            margin: 0,
            width: 'fit-content',
            alignSelf: 'center',
          }}
        >
          Already created an account?
        </p>
        <Button
          variant="contained"
          size="sm"
          style={{ margin: 'auto' }}
          onClick={() => navigate('/')}
        >
          Login
        </Button>
      </>
    </Layout>
  )
}

export default Register
