import * as S from '@radix-ui/react-separator'
import './styles.css'
import { useTheme } from 'styled-components'

const VerticalDivider = ({ colour }: { colour: `blue${number}` }) => {
  const theme = useTheme()
  return (
    <S.Root
      className="SeparatorRoot"
      decorative
      orientation="horizontal"
      style={{ backgroundColor: theme.palette.common[colour] }}
    />
  )
}

export default VerticalDivider
