import styled from 'styled-components'
import * as Dialog from '@radix-ui/react-dialog'
import { CrossCircledIcon } from '@radix-ui/react-icons'

interface ModalProps {
  open: boolean
  description?: string
  onClose: () => void
  children: JSX.Element | JSX.Element[]
  square?: boolean
  isOnlyModal?: boolean
  isMobile?: boolean
}
const DialogOverlay = styled(Dialog.Overlay)`
  @keyframes overlayShow {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  background-color: rgba(44, 58, 92, 0.8);
  position: fixed;
  inset: 0;
  animation: overlayShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
`
const DialogContent = styled(Dialog.Content)<{
  square?: boolean
  isMobile?: boolean
}>`
  @keyframes contentShow {
    from {
      opacity: 0;
      transform: translate(-50%, -48%) scale(0.96);
    }
    to {
      opacity: 1;
      transform: translate(-50%, -50%) scale(1);
    }
  }

  background-color: ${({ theme }) => theme.palette.common.blue2};
  border-radius: 6px;
  box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px,
    hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: ${({ isMobile }) => (isMobile ? 'fit-content' : '100%')};
  width: ${({ square }) => (square ? '60vw' : '90vw')};
  max-height: 90vh;
  max-width: ${({ square }) => (square ? '1600px' : '2000px')};
  padding: 25px 32px;
  animation: contentShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
`

const Close = styled.button`
  position: absolute;
  right: 8px;
  top: 8px;
  border-radius: 100%;

  &:hover {
    background-color: ${({ theme }) => theme.palette.common.blue5};
    color: ${({ theme }) => theme.palette.common.blue12};
  }
`
const Modal = ({
  open,
  onClose,
  children,
  description,
  square,
  isOnlyModal,
  isMobile,
}: ModalProps) => {
  if (isOnlyModal && !open) return <></>
  if (!open) return <>{children}</>
  return (
    <Dialog.Root open={open}>
      <Dialog.Trigger />
      <Dialog.Portal>
        <DialogOverlay />
        <DialogContent
          square={square}
          isMobile={isMobile}
          onEscapeKeyDown={onClose}
        >
          <Dialog.Close asChild>
            <Close type="button" onClick={onClose}>
              <CrossCircledIcon height={24} width={24} />
            </Close>
          </Dialog.Close>
          {children}
        </DialogContent>
      </Dialog.Portal>
    </Dialog.Root>
  )
}

export default Modal
