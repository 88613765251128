import { CHART_COLORS } from 'components/charts/common/consts'
import { useMemo } from 'react'
import { CatalogItem } from 'services'
import { NewChartSeries } from 'stores/types'
import { YAxis } from 'types'
import AssetClass from 'types/assetClass'

const validItem = (i?: CatalogItem): i is CatalogItem => {
  return !!i?.instrument
}

const getExpiry = (asset: AssetClass, d: CatalogItem) => {
  if (asset === AssetClass.FUTURE) {
    return d.expiry
  }
  if (asset === AssetClass.OPTION) {
    return d.expiry
  }
  return undefined
}

const useDefaultSeries = ({
  catalogLoading,
  items,
  assetClass,
  path,
  overRides,
}: {
  catalogLoading: boolean
  items: (CatalogItem | undefined)[]
  assetClass: AssetClass
  path: string | string[]
  overRides?: (undefined | Partial<NewChartSeries>)[]
}) => {
  const defaults = items.filter(validItem)
  return useMemo<NewChartSeries[]>(
    () =>
      catalogLoading
        ? []
        : defaults.map((d, indx) => ({
            qualifiedName: `${d?.exchange}.${assetClass.toLowerCase()}.${
              d.instrument
            }.[FREQ].${typeof path === 'string' ? path : path[indx]}`,
            listed: true,
            quote: d.quoteAsset,
            base: d.baseAsset,
            axis: {
              yAxis: YAxis.LEFT,
            },
            color: CHART_COLORS[indx],
            expiry: getExpiry(assetClass, d),
            ...(overRides && overRides?.[indx] ? overRides[indx] : []),
          })),
    [catalogLoading, defaults, overRides, assetClass, path],
  )
}

export default useDefaultSeries
