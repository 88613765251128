import React from 'react'
import { Source, Ticker } from 'types'
import AssetClass from 'types/assetClass'

import useMultiSeriesChart from 'hooks/useMultiSeriesChart'

import { SeriesPills } from '../common/series/Selector'
import MultiLineSeriesWrapper from '../common/series/MultiSeriesWrapper'

const FutureImpliedYield: React.FC = () => {
  const title = 'Spot Yields'
  const { chartSeries, highlighted, setHighlighted } = useMultiSeriesChart(
    title,
    [
      {
        assetClass: AssetClass.FUTURE,
        middle: {
          currency: Ticker.ETH,
          tenor: '30d',
          type: 'annual',
        },
        suffix: 'pct',
        source: Source.DERIBIT,
      },
      {
        assetClass: AssetClass.FUTURE,
        middle: {
          currency: Ticker.BTC,
          tenor: '30d',
          type: 'annual',
        },
        suffix: 'pct',
        source: Source.DERIBIT,
      },
    ],
  )

  return (
    <MultiLineSeriesWrapper
      chartSeries={chartSeries}
      title={title}
      id={title}
      axisLabel="Yields"
      axis={{
        LEFT: 'p2',
      }}
      assetType={AssetClass.FUTURE}
      useTypeToggle
      useTenors
      suffix="pct"
      defaultMiddle={{
        type: ['annual', 'basis'],
      }}
      seriesPills={
        <SeriesPills
          chartSeries={chartSeries}
          id={title}
          setHighlighted={setHighlighted}
        />
      }
      highlightedIndx={highlighted}
    />
  )
}

export default FutureImpliedYield
