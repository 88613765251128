const FlexContainer = ({
  children,
  justifyContent = 'flex-end',
  alignItems = 'center',
  width,
  height,
  direction,
}: {
  children: JSX.Element | JSX.Element[]
  justifyContent?: 'flex-end' | 'flex-start' | 'center' | 'space-between'
  alignItems?: 'flex-end' | 'flex-start' | 'center'
  width?: string
  height?: string
  direction?: 'column'
}) => (
  <div
    style={{
      display: 'flex',
      alignItems,
      justifyContent,
      flexWrap: 'wrap',
      gap: '16px',
      width,
      height,
      flexDirection: direction || 'row',
    }}
  >
    {children}
  </div>
)

export default FlexContainer
