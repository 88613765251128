/* eslint-disable no-nested-ternary */
import {
  CheckIcon,
  Cross2Icon,
  InfoCircledIcon,
  PlusCircledIcon,
} from '@radix-ui/react-icons'
import * as Popover from '@radix-ui/react-popover'
import * as ScrollArea from '@radix-ui/react-scroll-area'
import * as Select from '@radix-ui/react-select'
import * as Seperator from '@radix-ui/react-separator'
import { CatalogContext } from 'context/CatalogContext'
import { format, isValid, parse, sub } from 'date-fns'
import { capitalize } from 'lodash'
import { useContext, useEffect, useState } from 'react'
import styled, { useTheme } from 'styled-components'
import {
  AllKeys,
  AssetClassSource,
  AxisFormat,
  ConstantTenor,
  ConstantTenorByDays,
  Instrument,
  OPTION_TENORS,
  QuoteAsset,
  Source,
  Ticker,
  YAxis,
} from 'types'

import ControlledSelect, {
  SelectItem,
  SelectItemIndicator,
  SelectLabel,
} from 'components/atoms/Select/Index'
import { Form, Formik } from 'formik'
import { CatalogItem, dataService2 } from 'services'
import AssetClass from 'types/assetClass'

import {
  Model,
  SABRParam,
  SABRParamWithVol,
  SVIParam,
  SVIParamWithVol,
} from 'types/models'

import Switch from 'components/atoms/Switch'

import ToggleGroup, { ToggleGroupItem } from 'components/atoms/ToggleGroup'
import ToolTip from 'components/atoms/tooltip'
import { H6 } from 'components/atoms/Typography/styles'
import ColorPickerForCharts, {
  paletteSettings,
} from 'components/common/ColorPickerForCharts'

import { useMutation } from '@tanstack/react-query'
import FlexContainer from 'components/atoms/Flex'
import { NOW_UTC } from 'consts'
import { Catalog } from 'hooks/useCatalog'
import { useChartStore } from 'stores'
import { NewChartState } from 'stores/types'
import { daysToTenor } from 'utils/charts'
import { CHART_COLORS } from '../consts'
import DateLatestToggle from '../DateLatestToggle'
import { ConstructKey } from '../util'
import { underlyingOrIsoToReadableFormat } from 'utils/date-formatter'
import { useMediaQuery } from 'hooks/useMediaQuery'
import { Mode } from '@lezer/common/dist/tree'

const PopoverContent = styled(Popover.Content)`
  border-radius: 4px;
  padding: 20px;
  max-width: 285px;
  width: fit-content;
  background-color: ${({ theme }) => theme.palette.common.blue4};
  box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px,
    hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
  animation-duration: 400ms;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  will-change: transform, opacity;

  fieldset {
    display: flex;
    flex-direction: column;
    align-items: left;
  }

  .Label {
    font-size: 14px;
    text-transform: capitalize;
    color: ${({ theme }) => theme.palette.common.blue12};
    padding-right: 1rem;
  }
`
const AddButton = styled.button<{ isMobile?: boolean }>`
  font-family: inherit;
  border-radius: 4px;
  padding: ${({ isMobile }) => (isMobile ? '0.4rem' : '0.75rem')};
  margin: 0.5rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme, isMobile }) =>
    isMobile ? theme.palette.common.blue2 : theme.palette.common.blue12};
  background-color: ${({ theme, isMobile }) =>
    isMobile ? theme.palette.common.blue10 : theme.palette.common.blue4};
  box-shadow: 0 2px 10px ${({ theme }) => theme.palette.common.blue2};

  &:hover {
    background-color: ${({ theme }) => theme.palette.common.blue5};
  }

  &:disabled {
    background-color: ${({ theme }) => theme.palette.common.blue3};
    color: ${({ theme }) => theme.palette.common.blue10};
  }

  svg {
    margin-left: 0.5rem;
  }
`
const Submit = styled(AddButton)`
  background-color: ${({ theme }) => theme.palette.common.blue10};

  &:hover {
    background-color: ${({ theme }) => theme.palette.common.blue9};
  }
`
const PopoverClose = styled(Popover.Close)`
  font-family: inherit;
  border-radius: 100%;
  height: 25px;
  width: 25px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.palette.common.blue11};
  position: absolute;
  top: 5px;
  right: 5px;

  &:hover {
    background-color: ${({ theme }) => theme.palette.common.blue5};
    cursor: pointer;
  }
`

const Row = styled.div`
  display: flex;
  padding: 0.3rem 0;
  justify-content: space-between;
  flex-wrap: wrap;

  .padding {
    padding-right: 2rem;
  }
`

const ScrollAreaRoot = styled(ScrollArea.Root)<{ height?: false | string }>`
  max-width: 225px;
  width: fit-content;
  height: ${({ height }) => height || '200px'};
  // height: fit-content;
  border-radius: 4px;
  overflow: hidden;
  --scrollbar-size: 10px;
`
const ScrollAreaViewport = styled(ScrollArea.Viewport)`
  width: 100%;
  height: 100%;
  border-radius: inherit;
  padding: 0 10px;

  * > .hover:hover {
    transform: scale(1.03);
  }
`

type FormVals = {
  currency: Ticker
  source: Source
  tenor: ConstantTenorByDays
  assetClass: AssetClass.FUTURE | AssetClass.OPTION | AssetClass.INDICES
  suffix?: string
  isTenor: boolean
  model: Model
  series?: string
  expiry: string | CatalogItem | undefined
  type?: string
  param: SVIParam | SVIParamWithVol | SABRParam | SABRParamWithVol
  timestamp: Date | 'LATEST'
  color: string
  axis: YAxis
}

const PARAMS = {
  [Model.SABR]: {
    WITH_VOL: Object.entries(SABRParamWithVol).map(([key, val]) => ({
      name: key,
      value: val,
    })),
    PARAMS: Object.entries(SABRParam).map(([key, val]) => ({
      name: key,
      value: val,
    })),
  },
  [Model.SVI]: {
    WITH_VOL: Object.entries(SVIParamWithVol).map(([key, val]) => ({
      name: key,
      value: val,
    })),
    PARAMS: Object.entries(SVIParam).map(([key, val]) => ({
      name: key,
      value: val,
    })),
  },
}

const SeriesDropDown = ({
  assetType,
  listedExpiry,
  useTypeToggle,
  currencies = [Ticker.BTC, Ticker.ETH],
  sources,
  updateSeries,
  defaultMiddle,
  suffix,
  series,
  useModelParam,
  useLatestDatePicker,
  color,
  addVol = true,
  closeModal,
  useTenors,
  tenors,
  expiries,
  useFetchListed = false, // Expiries for smile/params have to be fetched at run time not through catalog
  availableTenors,
}: ISeriesDropDown & { closeModal: () => void }) => {
  const theme = useTheme()
  const PARAMS_TO_USE = addVol ? 'WITH_VOL' : 'PARAMS'
  const catalog = useContext(CatalogContext)
  const mutation = useMutation(
    ({
      source,
      baseAsset,
      model,
    }: {
      source: Source
      baseAsset: Ticker
      model: Model
    }) => {
      let s = source.toLowerCase()
      if (source === Source.BLOCKSCHOLES) {
        s = 'v2composite'
      } else if (source === Source.V2LYRA) {
        s = 'v2lyra'
      }
      return dataService2.getActiveListedExpiry({
        timings: {
          from: sub(NOW_UTC(), { hours: 6 }),
          until: NOW_UTC(),
        },
        key: `${s}.option.${baseAsset}.${model}.listed.1h${
          defaultMiddle?.type
            ? (`.${defaultMiddle.type[0]}.` as `${string}.`)
            : '.'
        }${(typeof suffix === 'string' ? suffix : suffix?.[0]) as 'smile'}`,
      })
    },
    {
      onSuccess: (data) => Object.keys(data),
    },
  )

  const assetSources = sources || AssetClassSource[assetType]

  const getListOfActiveExpires = ({ baseAsset, source }) =>
    [
      ...(assetType.toLowerCase() === 'future'
        ? catalog.state.activeFutures
        : catalog.state.activeOptions),
    ]
      .filter(
        (item) =>
          item.baseAsset === baseAsset &&
          item.exchange === source.toLowerCase(),
      )
      .map((e) => e.instrument)

  const defaultModel = Model.SVI

  const initialValues: FormVals = {
    currency: currencies[0],
    source: assetSources[0],
    tenor: tenors?.[0] || OPTION_TENORS[assetSources[0]][0],
    assetClass: assetType,
    suffix: suffix
      ? typeof suffix === 'string'
        ? suffix
        : suffix[0]
      : undefined,
    isTenor: !!useTenors,
    model: defaultModel,
    series: series?.[0],
    expiry:
      expiries && expiries?.length > 0
        ? expiries[0]
        : {
            ticker: currencies[0],
            source: assetSources[0],
          }[0]?.instrument,
    ...defaultMiddle,
    type:
      defaultMiddle && 'type' in defaultMiddle && defaultMiddle.type
        ? defaultMiddle?.type?.[0]
        : undefined,
    param: PARAMS[defaultModel][PARAMS_TO_USE][0].value,
    timestamp: 'LATEST',
    color:
      'value' in color ? color.value : paletteSettings.palette[color.index],
    axis: YAxis.LEFT,
  }
  useEffect(() => {
    if (useFetchListed) {
      mutation.mutate({
        baseAsset: initialValues.currency,
        model: initialValues.model,
        source: initialValues.source,
      }) // On first loaad get expires for option data
    }
  }, [useFetchListed])

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values) => {
        let middle
        if (values.isTenor) {
          middle = {
            currency: values.currency,
            tenor: values.tenor,
            type: values?.type,
          }
        }
        if (!values.isTenor && values.expiry) {
          middle = {
            listed: values.expiry,
            type: values?.type,
          }
        }
        if (values.assetClass === AssetClass.FUTURE) {
          middle = {
            ...middle,
            currency: values.currency,
            type: values.type,
            source: values.source,
          }
        }
        if (
          values.assetClass === AssetClass.OPTION &&
          values.suffix !== 'vol' && // VIX
          values.type !== 'index' &&
          values.suffix !== 'oi' &&
          values.type !== 'volume' &&
          values.suffix !== 'sum'
        ) {
          middle = {
            currency: values.currency,
            tenor: values.tenor,
            model: values.model,
            type: values?.type,
          }
        }
        if (series && series?.length > 0) {
          middle = { ...middle, series: values.series }
        }
        if (useModelParam) {
          middle = { ...middle, param: values.param }
        }

        if (useLatestDatePicker || values.timestamp === 'LATEST') {
          middle = { ...middle, timestamp: values.timestamp }
        }

        updateSeries({
          source: values.source,
          assetClass: values.assetClass,
          suffix: values.suffix as string,
          middle: middle as ConstructKey['middle'],
          color: values.color,
          axis: {
            yAxis: values.axis,
          },
        })
        closeModal()
      }}
    >
      {({ setFieldValue, values }) => {
        const listedExpiries = useFetchListed
          ? Object.keys(mutation.data || {})
          : getListOfActiveExpires({
              baseAsset: values.currency,
              source: values.source,
            })

        if (
          !values.expiry &&
          listedExpiry?.enabled &&
          listedExpiries?.length > 0
        ) {
          setFieldValue('expiry', listedExpiries[0])
        }
        return (
          <Form
            style={{
              display: 'grid',
              gap: '10px',
              gridTemplateColumns: 'repeat(2, 1fr)',
            }}
          >
            <div
            // style={{
            //   display: 'flex',
            //   flexDirection: 'column',
            //   justifySelf: 'flex-start',
            // }}
            >
              <div className="Label">Currency</div>
              <ControlledSelect
                colorShade="blue6"
                value={values.currency}
                onValueChange={(v) => {
                  if (useFetchListed) {
                    mutation.mutate({
                      baseAsset: v,
                      model: values.model,
                      source: values.source,
                    })
                  }
                  setFieldValue('currency', v)
                  if (listedExpiry?.enabled) {
                    const newExpiry = getListOfActiveExpires({
                      baseAsset: v,
                      source: values.source,
                    })[0]
                    setFieldValue('expiry', newExpiry)
                  }
                }}
              >
                {currencies.map((c) => (
                  <SelectItem value={c} key={c}>
                    <SelectItemIndicator>
                      <CheckIcon />
                    </SelectItemIndicator>
                    <Select.ItemText>{c}</Select.ItemText>
                  </SelectItem>
                ))}
              </ControlledSelect>
            </div>
            <div
              className="padding"
              // style={{
              //   display: 'flex',
              //   flexDirection: 'column',
              //   justifySelf: 'flex-start',
              // }}
            >
              <div className="Label">Source</div>
              {assetSources.length > 1 &&
              assetType === AssetClass.OPTION &&
              values.suffix !== 'realized' ? (
                <ControlledSelect
                  colorShade="blue6"
                  value={values.source}
                  valueDisplay={
                    values.source.split('-').length > 1
                      ? `${capitalize(
                          values.source.split('-')[0],
                        )} (${capitalize(values.source.split('-')[1])})`
                      : capitalize(values.source)
                  }
                  onValueChange={(v) => {
                    if (useFetchListed) {
                      mutation.mutate({
                        baseAsset: values.currency,
                        model: values.model,
                        source: v,
                      })
                    }
                    setFieldValue('source', v)
                    if (listedExpiry?.enabled) {
                      setFieldValue(
                        'expiry',
                        getListOfActiveExpires({
                          baseAsset: values.currency,
                          source: v,
                        })[0],
                      )
                    }
                  }}
                >
                  {assetSources.map((s) => (
                    <SelectItem value={s} key={s}>
                      <SelectItemIndicator>
                        <CheckIcon />
                      </SelectItemIndicator>
                      <Select.ItemText>
                        {s.split('-').length > 1
                          ? `${capitalize(s.split('-')[0])} (${capitalize(
                              s.split('-')[1],
                            )})`
                          : capitalize(s)}
                      </Select.ItemText>
                    </SelectItem>
                  ))}
                </ControlledSelect>
              ) : (
                <p
                  style={{
                    margin: '0.5rem 0 ',
                    color: theme.palette.common.blue12,
                  }}
                >
                  {values.source}
                </p>
              )}
            </div>
            {suffix && typeof suffix !== 'string' && (
              <fieldset>
                <div className="Label">Series</div>
                <ControlledSelect
                  colorShade="blue6"
                  value={values.suffix}
                  valueDisplay={values.suffix?.toUpperCase()}
                  alignSelf="flex-start"
                  onValueChange={(v) => {
                    if (v === 'realized') {
                      // Hack
                      setFieldValue('model', undefined)
                      setFieldValue('tenor', '')
                      setFieldValue('source', 'composite')
                    } else if (values.model === undefined) {
                      setFieldValue('model', Model.SVI)
                      setFieldValue('tenor', initialValues.tenor)
                      setFieldValue('source', assetSources[0])
                    }
                    setFieldValue('suffix', v)
                  }}
                >
                  {suffix
                    .sort((a, b) => {
                      const isADelta = a.includes('delta')
                      const isBDelta = b.includes('delta')

                      if (isADelta && !isBDelta) {
                        return 1 // Move `a` to the bottom if it's a delta and `b` is not
                      } else if (!isADelta && isBDelta) {
                        return -1 // Move `b` to the bottom if it's a delta and `a` is not
                      } else {
                        return 0 // Leave other elements in their relative order
                      }
                    })
                    .map((s) => {
                      return (
                        <SelectItem value={s} key={s}>
                          <SelectItemIndicator>
                            <CheckIcon />
                          </SelectItemIndicator>
                          <Select.ItemText>{s}</Select.ItemText>
                        </SelectItem>
                      )
                    })}
                </ControlledSelect>
              </fieldset>
            )}
            {assetType === AssetClass.OPTION &&
              values.suffix !== 'vol' && // VIX
              values.suffix !== 'oi' &&
              values.type !== 'index' &&
              values.type !== 'volume' &&
              values.suffix !== 'sum' &&
              values.suffix !== 'realized' && (
                <fieldset>
                  <label className="Label">Model:</label>
                  <ControlledSelect
                    colorShade="blue6"
                    value={values.model}
                    alignSelf="flex-start"
                    valueDisplay={values.model?.toUpperCase()}
                    onValueChange={(v: Model) => {
                      if (useFetchListed) {
                        mutation.mutate({
                          baseAsset: values.currency,
                          model: v,
                          source: values.source,
                        })
                      }
                      setFieldValue('model', v)
                      if (
                        values.source === Source.BLOCKSCHOLES &&
                        v === Model.SABR
                      ) {
                        setFieldValue('source', sources?.[0] || Source.DERIBIT)
                      }
                      setFieldValue('param', PARAMS[v][PARAMS_TO_USE][0].value)
                    }}
                  >
                    {[Model.SVI, Model.SABR].map((s) => (
                      <SelectItem value={s} key={s}>
                        <SelectItemIndicator>
                          <CheckIcon />
                        </SelectItemIndicator>
                        <Select.ItemText>{s.toUpperCase()}</Select.ItemText>
                      </SelectItem>
                    ))}
                  </ControlledSelect>
                </fieldset>
              )}
            {useTypeToggle && values.type && defaultMiddle?.type[1] && (
              <Row>
                <div className="Label">Type:</div>
                <Switch
                  labelOne={defaultMiddle.type[0]}
                  labelTwo={defaultMiddle.type[1]}
                  onChange={(v) =>
                    setFieldValue(
                      'type',
                      v ? defaultMiddle.type[1] : defaultMiddle?.type[0],
                    )
                  }
                />
              </Row>
            )}

            {series && series.length > 0 && (
              <fieldset>
                <div className="Label">Delta</div>
                <ControlledSelect
                  colorShade="blue6"
                  alignSelf="flex-start"
                  value={values.series}
                  onValueChange={(v) => setFieldValue('series', v)}
                >
                  {series.map((s) => {
                    return (
                      <SelectItem value={s} key={s}>
                        <SelectItemIndicator>
                          <CheckIcon />
                        </SelectItemIndicator>
                        <Select.ItemText>{s}</Select.ItemText>
                      </SelectItem>
                    )
                  })}
                </ControlledSelect>
              </fieldset>
            )}
            {useLatestDatePicker && (
              <div
                className="Picker"
                style={{ flexDirection: 'column', justifySelf: 'flex-start' }}
              >
                <span className="Label">Date:</span>
                <DateLatestToggle
                  setDate={(v: Date | 'LATEST') => {
                    if (v) return setFieldValue('timestamp', v)
                  }}
                  date={values.timestamp}
                />
              </div>
            )}
            <>
              {useModelParam && (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifySelf: 'flex-start',
                  }}
                >
                  <div className="Label">Param</div>
                  <ControlledSelect
                    colorShade="blue6"
                    value={values.param}
                    onValueChange={(v) => {
                      if (v) return setFieldValue('param', v)
                    }}
                  >
                    {PARAMS[values.model][PARAMS_TO_USE].map((s) => {
                      return (
                        <SelectItem value={s.value} key={s.value}>
                          <SelectItemIndicator>
                            <CheckIcon />
                          </SelectItemIndicator>
                          <Select.ItemText>{s.value}</Select.ItemText>
                        </SelectItem>
                      )
                    })}
                  </ControlledSelect>
                </div>
              )}
              {(values.isTenor || listedExpiry?.enabled) &&
                values.suffix !== 'realized' && (
                  <>
                    <div
                      className="padding"
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifySelf: 'flex-start',
                        gridColumn: 'span 2',
                      }}
                    >
                      <div className="Label">
                        {values.isTenor && 'Tenor'}
                        {values.isTenor && listedExpiry?.enabled && '/'}
                        {listedExpiry?.enabled && 'Expiry'}
                      </div>
                      <ControlledSelect
                        colorShade="blue6"
                        value={values.tenor}
                        alignSelf="flex-start"
                        valueDisplay={
                          daysToTenor(values.tenor as `${number}d`) ||
                          values.tenor?.replace('SYN.', '')
                        }
                        onValueChange={(v) => setFieldValue('tenor', v)}
                      >
                        <FlexContainer height="170px">
                          {values.isTenor ? (
                            <>
                              <Select.Group>
                                <SelectLabel>
                                  Tenors{' '}
                                  {availableTenors &&
                                    availableTenors.length > 0 && (
                                      <ToolTip
                                        content={
                                          <span>
                                            Sign up and gain immediate access to
                                            all tenors
                                          </span>
                                        }
                                      >
                                        <InfoCircledIcon />
                                      </ToolTip>
                                    )}
                                </SelectLabel>

                                {(tenors || OPTION_TENORS[values.source]).map(
                                  (s) => (
                                    <SelectItem
                                      value={s}
                                      key={s}
                                      disabled={
                                        availableTenors &&
                                        availableTenors.length > 0
                                          ? !availableTenors.includes(s)
                                          : false
                                      }
                                    >
                                      <SelectItemIndicator>
                                        <CheckIcon />
                                      </SelectItemIndicator>
                                      <Select.ItemText>
                                        {daysToTenor(s as `${number}d`)}
                                      </Select.ItemText>
                                    </SelectItem>
                                  ),
                                )}
                              </Select.Group>
                              {listedExpiry?.enabled && (
                                <Seperator.Root
                                  decorative
                                  orientation="vertical"
                                  style={{
                                    height: '170px',
                                    width: '1px',
                                    backgroundColor: theme.palette.common.blue9,
                                  }}
                                />
                              )}
                            </>
                          ) : (
                            <></>
                          )}
                          {listedExpiry?.enabled ? (
                            useFetchListed && mutation.isLoading ? (
                              <p>Loading...</p>
                            ) : listedExpiries.length > 0 ? (
                              <ScrollAreaRoot>
                                <ScrollAreaViewport>
                                  <Select.Group>
                                    <SelectLabel>Listed Contracts</SelectLabel>
                                    {listedExpiries
                                      .sort(
                                        (a, b) =>
                                          new Date(a).getTime() -
                                          new Date(b).getTime(),
                                      )
                                      .map((s) => {
                                        return (
                                          <SelectItem
                                            className="hover"
                                            value={s}
                                            key={s}
                                          >
                                            <Select.ItemText>
                                              {underlyingOrIsoToReadableFormat(
                                                s,
                                                false,
                                              )}
                                            </Select.ItemText>
                                          </SelectItem>
                                        )
                                      })}
                                  </Select.Group>
                                </ScrollAreaViewport>
                                <ScrollArea.Scrollbar
                                  className="ScrollAreaScrollbar"
                                  orientation="vertical"
                                  style={{
                                    width: '10px',
                                    background: theme.palette.common.blue8,
                                    display: 'flex',
                                  }}
                                >
                                  <ScrollArea.Thumb
                                    style={{
                                      background: theme.palette.common.blue11,
                                      flex: 1,
                                      borderRadius: '10px',
                                      position: 'relative',
                                    }}
                                  />
                                </ScrollArea.Scrollbar>
                              </ScrollAreaRoot>
                            ) : (
                              <></>
                            )
                          ) : (
                            <></>
                          )}
                        </FlexContainer>
                      </ControlledSelect>
                    </div>
                  </>
                )}
            </>
            <div
              style={{
                gridColumn: 'span 2',
                display: 'grid',
                gap: '10px',
                gridTemplateColumns: 'repeat(2, 1fr)',
              }}
            >
              <div className="padding">
                <label className="Label" htmlFor="color-picker">
                  Colour
                </label>
                <ColorPickerForCharts
                  id="color-picker"
                  defaultValue={values.color}
                  func={(c) => setFieldValue('color', c)}
                />
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifySelf: 'flex-start',
                }}
              >
                <label className="Label" htmlFor="color-picker">
                  Axis
                </label>
                <ToggleGroup
                  value={values.axis}
                  onValueChange={(v) => setFieldValue('axis', v)}
                >
                  <ToggleGroupItem
                    width={50}
                    key={YAxis.LEFT}
                    value={YAxis.LEFT}
                  >
                    Left
                  </ToggleGroupItem>
                  <ToggleGroupItem
                    width={50}
                    key={YAxis.RIGHT}
                    value={YAxis.RIGHT}
                    disabled={'index' in color && color.index === 0}
                  >
                    Right
                  </ToggleGroupItem>
                </ToggleGroup>
              </div>
            </div>
            <Submit
              type="submit"
              style={{ minWidth: 'fill-available', gridColumn: 'span 2' }}
            >
              Add
            </Submit>
          </Form>
        )
      }}
    </Formik>
  )
}
interface ISeriesDropDown {
  assetType: AssetClass.FUTURE | AssetClass.OPTION | AssetClass.INDICES
  listedExpiry?: {
    enabled: boolean
  }
  useTypeToggle?: boolean
  currencies?: Ticker[]
  sources?: Source[]
  suffix?: string | string[]
  defaultMiddle?: Omit<Partial<ConstructKey['middle']>, 'type'> & {
    type: [string, string] | [string]
  }
  series?: string[]
  updateSeries: (s: ConstructKey) => void
  useModelParam?: boolean
  useLatestDatePicker?: boolean
  addVol?: boolean
  color:
    | {
        index: number
      }
    | { value: string }
  useTenors?: boolean
  tenors?: ConstantTenor[]
  expiries?: string[]
  useFetchListed?: boolean
  availableTenors?: ConstantTenor[]
}

interface ISeriesDropDownWrapper extends Omit<ISeriesDropDown, 'color.index'> {
  children: JSX.Element | JSX.Element[]
  isOpen: boolean
  setIsOpen: (b: boolean) => void
}

export const AddOrEditSeries = ({
  assetType,
  listedExpiry,
  useTypeToggle,
  currencies = [Ticker.BTC, Ticker.ETH],
  sources,
  updateSeries,
  defaultMiddle,
  suffix,
  series,
  useModelParam,
  useLatestDatePicker,
  addVol = true,
  children,
  isOpen,
  color,
  setIsOpen,
  useTenors,
  tenors,
  expiries,
  useFetchListed,
  availableTenors,
}: ISeriesDropDownWrapper): JSX.Element => {
  return (
    <Popover.Root open={isOpen} onOpenChange={(e) => setIsOpen(e)}>
      <Popover.Trigger asChild>{children}</Popover.Trigger>
      <Popover.Portal>
        <PopoverContent sideOffset={3} sticky="always">
          <SeriesDropDown
            series={series}
            assetType={assetType}
            listedExpiry={listedExpiry}
            useTypeToggle={useTypeToggle}
            currencies={currencies}
            sources={sources}
            updateSeries={updateSeries}
            defaultMiddle={defaultMiddle}
            suffix={suffix}
            useModelParam={useModelParam}
            useLatestDatePicker={useLatestDatePicker}
            color={color}
            addVol={addVol}
            closeModal={() => setIsOpen(false)}
            useTenors={useTenors}
            tenors={tenors}
            expiries={expiries}
            useFetchListed={useFetchListed}
            availableTenors={availableTenors}
          />
          <PopoverClose className="PopoverClose" aria-label="Close">
            <Cross2Icon />
          </PopoverClose>
          <Popover.Arrow className="PopoverArrow" />
        </PopoverContent>
      </Popover.Portal>
    </Popover.Root>
  )
}

const AddSeries = (props: ISeriesDropDown) => {
  const { color } = props
  const [isOpen, setIsOpen] = useState(false)
  const isMobile = useMediaQuery('(max-width: 768px)')

  return (
    <AddOrEditSeries
      {...props}
      color={color}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
    >
      {'index' in color && color.index >= 8 ? (
        <ToolTip
          content={
            <div>
              A maximum of 8 series is allowed per graph, please remove 1 to
              continue.
            </div>
          }
        >
          <AddButton
            type="button"
            aria-label="Add series"
            disabled={'index' in color && color.index >= 8}
            onClick={() => setIsOpen(true)}
          >
            Maximum added
          </AddButton>
        </ToolTip>
      ) : (
        <AddButton
          type="button"
          aria-label="Add series"
          isMobile={isMobile}
          disabled={'index' in color && color.index >= 8}
          onClick={() => setIsOpen(true)}
        >
          {isMobile ? '' : 'Add Series'}
          <PlusCircledIcon
            style={{
              width: isMobile ? '16px' : '20px',
              height: isMobile ? '16px' : '20px',
              marginLeft: isMobile ? 0 : undefined,
            }}
          />
        </AddButton>
      )}
    </AddOrEditSeries>
  )
}

export const EditSeries = ({
  isOpen,
  color,
  axis = YAxis.LEFT,
  setColor,
  setAxis,
  setIsOpen,
  children,
  seriesLength,
  chartAxis,
}: {
  isOpen: boolean
  color: string
  axis?: YAxis
  setIsOpen: (b: boolean) => void
  children: JSX.Element | JSX.Element[]
  setColor: (c: string) => void
  setAxis: (a: YAxis) => void
  seriesLength: number
  chartAxis?: Partial<Record<YAxis, AxisFormat>>
}) => {
  const disableAxisChange =
    chartAxis &&
    Object.keys(chartAxis).length > 1 &&
    chartAxis[YAxis.RIGHT] &&
    chartAxis[YAxis.LEFT] &&
    chartAxis[YAxis.RIGHT]?.slice(0, 1) !== chartAxis[YAxis.LEFT]?.slice(0, 1)

  return (
    <Popover.Root open={isOpen} onOpenChange={(e) => setIsOpen(e)}>
      <Popover.Trigger asChild>
        <div>{children}</div>
      </Popover.Trigger>
      <Popover.Portal>
        <PopoverContent>
          <H6>Edit</H6>
          <Row>
            <div>
              <label className="Label" htmlFor="color-picker">
                Colour:
              </label>
              <ColorPickerForCharts
                id="color-picker"
                defaultValue={color}
                func={(c) => setColor(c)}
              />
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifySelf: 'flex-start',
              }}
            >
              <label className="Label" htmlFor="color-picker">
                Axis:
                <ToolTip
                  content={
                    <div>
                      {disableAxisChange
                        ? 'Cannot mix series formats'
                        : 'Change the axis the series appears on'}
                    </div>
                  }
                >
                  <InfoCircledIcon style={{ paddingLeft: '2px' }} />
                </ToolTip>
              </label>

              <ToggleGroup value={axis} onValueChange={setAxis}>
                <ToggleGroupItem
                  width={50}
                  key={YAxis.LEFT}
                  value={YAxis.LEFT}
                  disabled={disableAxisChange}
                >
                  Left
                </ToggleGroupItem>
                <ToggleGroupItem
                  width={50}
                  key={YAxis.RIGHT}
                  value={YAxis.RIGHT}
                  disabled={seriesLength <= 1 || disableAxisChange}
                >
                  Right
                </ToggleGroupItem>
              </ToggleGroup>
            </div>
          </Row>
          <PopoverClose className="PopoverClose" aria-label="Close">
            <Cross2Icon />
          </PopoverClose>
          <Popover.Arrow className="PopoverArrow" />
        </PopoverContent>
      </Popover.Portal>
    </Popover.Root>
  )
}
export default AddSeries
// TODO REMOVE ABOVE ONCE USING NEW LOGIC

const Item = styled.div<{ w?: string }>`
  width: ${({ w }) => w || '100px'};
  display: flex;
  flex-direction: column;
  padding-bottom: 0.75rem;

  .padding {
    padding-right: 2rem;
  }

  .grid-span-2-col {
    grid-column: span 2;
  }
`

const AddSeriesDropDown = ({
  initial,
  addSeries,
  id,
  submitText = 'Add',
  sources,
  seriesIndex,
  getAvailableSeries,
  currencies,
}: {
  initial: Exclude<NewChartState['add'], undefined>
  addSeries: (e: SeriesInfo, i: number) => void
  id: string
  sources: Source[]
  submitText?: string
  seriesIndex: number
  getAvailableSeries?: (
    s: Source,
    ccy: Ticker | `${Ticker}/${QuoteAsset}`,
  ) => [string[], 'instrument' | 'expiry' | undefined]
  currencies: Ticker[] | `${Ticker}/${QuoteAsset}`[]
}) => {
  const updateChart = useChartStore((s) => s.updateTimeSeriesChart)

  const addSeriesState: Exclude<NewChartState['add'], undefined> =
    useChartStore((s) => ({ ...s.defaultCharts[id]?.add, ...initial })) ||
    initial

  const theme = useTheme()

  const updateAddSeries = (
    key: AllKeys<NewChartState['add']>,
    val: Exclude<NewChartState['add'], undefined>[keyof Exclude<
      NewChartState['add'],
      undefined
    >],
  ) => {
    let updated = { ...addSeriesState, [key]: val }
    if (key === 'source') {
      updated.ccy = currencies[0] as `${Ticker}/${QuoteAsset}`
    }

    if (getAvailableSeries && (key === 'ccy' || key === 'source')) {
      const availSeries =
        getAvailableSeries && getAvailableSeries(updated.source, updated.ccy)
      if (availSeries[1]) {
        updated = {
          ...updated,
          [availSeries[1]]: getAvailableSeries(
            updated.source,
            updated.ccy,
          )[0][0] as Instrument,
        }
      }
    }

    return updateChart(id, { add: updated })
  }
  const onSubmit = (e) => {
    return addSeries(e, seriesIndex)
  }
  return (
    <Formik onSubmit={onSubmit} initialValues={addSeriesState}>
      {({ values, setFieldValue }) => {
        const availSeries =
          getAvailableSeries && getAvailableSeries(values.source, values.ccy)

        const updateLocalAndGlobal = (
          field: AllKeys<NewChartState['add']>,
          val: Exclude<NewChartState['add'], undefined>[keyof Exclude<
            NewChartState['add'],
            undefined
          >],
        ) => {
          setFieldValue(field, val)
          updateAddSeries(field, val)
          if (field === 'source' && currencies[0] !== addSeriesState.ccy) {
            setFieldValue('ccy', currencies[0])
          }
          if (getAvailableSeries && val && availSeries?.[1]) {
            if (field === 'ccy') {
              setFieldValue(
                availSeries[1],
                getAvailableSeries(values.source, val as Ticker)[0][0],
              )
            } else if (field === 'source') {
              setFieldValue(
                availSeries[1],
                getAvailableSeries(val as Source, values.ccy)[0][0],
              )
            }
          }
        }

        return (
          <Form style={{ display: 'flex', flexDirection: 'column' }}>
            <div className="padding" style={{ display: 'flex' }}>
              <Item className="padding">
                <div className="Label">Source</div>
                {sources.length > 1 ? (
                  <ControlledSelect
                    colorShade="blue6"
                    value={values.source}
                    valueDisplay={
                      values?.source?.split('-')?.length > 1
                        ? `${capitalize(
                            values?.source?.split('-')[0],
                          )} (${capitalize(values?.source?.split('-')[1])})`
                        : capitalize(values.source)
                    }
                    onValueChange={(v: Source) => {
                      updateLocalAndGlobal('source', v)
                    }}
                    alignSelf="flex-start"
                  >
                    {sources.map((s) => (
                      <SelectItem value={s} key={s}>
                        <SelectItemIndicator>
                          <CheckIcon />
                        </SelectItemIndicator>
                        <Select.SelectItemText>
                          {s.split('-').length > 1
                            ? `${capitalize(s.split('-')[0])} (${capitalize(
                                s.split('-')[1],
                              )})`
                            : capitalize(s)}
                        </Select.SelectItemText>
                      </SelectItem>
                    ))}
                  </ControlledSelect>
                ) : (
                  <p
                    style={{
                      margin: '0.5rem 0 ',
                      color: theme.palette.common.blue12,
                    }}
                  >
                    {values.source}
                  </p>
                )}
              </Item>
              <Item className="padding">
                <>
                  <div className="Label">
                    {currencies && currencies?.[0]?.includes('/')
                      ? 'Pair'
                      : 'Currency'}
                  </div>
                  {currencies.length > 1 ? (
                    <ControlledSelect
                      colorShade="blue6"
                      value={values.ccy}
                      onValueChange={(v) => updateLocalAndGlobal('ccy', v)}
                      alignSelf="flex-start"
                    >
                      {currencies.map((c) => (
                        <SelectItem value={c} key={c}>
                          <SelectItemIndicator>
                            <CheckIcon />
                          </SelectItemIndicator>
                          <Select.SelectItemText>{c}</Select.SelectItemText>
                        </SelectItem>
                      ))}
                    </ControlledSelect>
                  ) : (
                    <p
                      style={{
                        margin: '0.5rem 0 ',
                        color: theme.palette.common.blue12,
                      }}
                    >
                      {values.ccy}
                    </p>
                  )}
                </>
              </Item>
            </div>
            <div className="padding">
              {availSeries &&
                availSeries[0]?.length > 0 &&
                availSeries[1] !== undefined && (
                  <Item className="padding" w="100%">
                    <>
                      <div className="Label">{availSeries[1]}</div>
                      {availSeries[0].length > 1 && availSeries[1] ? (
                        <ControlledSelect
                          colorShade="blue6"
                          value={values[availSeries[1]]}
                          valueDisplay={
                            isValid(new Date(values[availSeries[1]]))
                              ? format(
                                  new Date(values[availSeries[1]]),
                                  'dd LLL yy',
                                ).toUpperCase()
                              : values[availSeries[1]]
                          }
                          onValueChange={(v) => {
                            if (!v || !availSeries[1]) return
                            updateLocalAndGlobal(availSeries[1], v)
                          }}
                          alignSelf="flex-start"
                        >
                          <ScrollAreaRoot
                            className="ScrollAreaRoot"
                            height={
                              availSeries[0].length < 6 &&
                              `${availSeries[0].length * 25}px`
                            }
                          >
                            <ScrollAreaViewport>
                              {availSeries[0]
                                .sort((a, b) =>
                                  isValid(new Date(a))
                                    ? new Date(a).getTime() -
                                      new Date(b).getTime()
                                    : 0,
                                )
                                .map((c) => (
                                  <SelectItem value={c} key={c}>
                                    <SelectItemIndicator>
                                      <CheckIcon />
                                    </SelectItemIndicator>
                                    <Select.SelectItemText>
                                      {isValid(new Date(c))
                                        ? format(
                                            new Date(c),
                                            'dd LLL yy',
                                          ).toUpperCase()
                                        : c}
                                    </Select.SelectItemText>
                                  </SelectItem>
                                ))}
                            </ScrollAreaViewport>
                            <ScrollArea.Scrollbar
                              className="ScrollAreaScrollbar"
                              orientation="vertical"
                              style={{
                                width: '10px',
                                background: theme.palette.common.blue8,
                                display: 'flex',
                              }}
                            >
                              <ScrollArea.Thumb
                                style={{
                                  background: theme.palette.common.blue11,
                                  flex: 1,
                                  borderRadius: '10px',
                                  position: 'relative',
                                }}
                              />
                            </ScrollArea.Scrollbar>
                            <ScrollArea.Corner className="ScrollAreaCorner" />
                          </ScrollAreaRoot>
                        </ControlledSelect>
                      ) : (
                        <p
                          style={{
                            margin: '0.5rem 0 ',
                            color: theme.palette.common.blue12,
                          }}
                        >
                          {isValid(new Date(values[availSeries[1]]))
                            ? format(
                                new Date(values[availSeries[1]]),
                                'dd LLL yy',
                              ).toUpperCase()
                            : values[availSeries[1]]}
                        </p>
                      )}
                    </>
                  </Item>
                )}
            </div>
            <div className="padding" style={{ display: 'flex' }}>
              <Item className="padding">
                <label className="Label" htmlFor="color-picker">
                  Colour:
                </label>
                <ColorPickerForCharts
                  id="color-picker"
                  defaultValue={values.colour}
                  func={(c) => updateLocalAndGlobal('colour', c)}
                />
              </Item>
              <Item className="padding">
                <div className="Label">Axis</div>
                <ToggleGroup
                  value={values.yAxis}
                  onValueChange={(v) => updateLocalAndGlobal('yAxis', v)}
                >
                  <ToggleGroupItem
                    width={45}
                    key={YAxis.LEFT}
                    value={YAxis.LEFT}
                  >
                    Left
                  </ToggleGroupItem>
                  <ToggleGroupItem
                    width={45}
                    key={YAxis.RIGHT}
                    value={YAxis.RIGHT}
                  >
                    Right
                  </ToggleGroupItem>
                </ToggleGroup>
              </Item>
            </div>
            <Submit type="submit">{submitText}</Submit>
          </Form>
        )
      }}
    </Formik>
  )
}

export interface SeriesInfo {
  ccy: Ticker | `${Ticker}/${QuoteAsset}`
  source: Source
  colour: string
  yAxis: YAxis
}

export const AddOrEditSeriesNew = ({
  trigger,
  id,
  assetClass,
  sources,
  initial,
  addSeries,
  submitText,
  seriesIndex,
  getAvailableSeries,
  currencies,
}: {
  trigger: JSX.Element
  id: string
  assetClass: AssetClass
  sources?: Source[]
  initial: Exclude<NewChartState['add'], undefined>
  submitText?: string
  addSeries: (s: SeriesInfo, i: number) => void
  seriesIndex: number
  getAvailableSeries?: (
    s: Source,
    ccy: Ticker | `${Ticker}/${QuoteAsset}`,
  ) => [string[], 'instrument' | 'expiry' | undefined]
  currencies: Ticker[] | `${Ticker}/${QuoteAsset}`[]
}) => {
  let sourceList = sources
  if (!sourceList) {
    sourceList = AssetClassSource[assetClass]
  }

  return (
    <Popover.Root>
      <Popover.Trigger asChild>{trigger}</Popover.Trigger>
      <Popover.Portal>
        <PopoverContent sideOffset={3} sticky="always">
          <AddSeriesDropDown
            initial={initial}
            addSeries={addSeries}
            id={id}
            seriesIndex={seriesIndex}
            sources={sourceList}
            getAvailableSeries={getAvailableSeries}
            submitText={submitText}
            currencies={currencies}
          />
          <PopoverClose className="PopoverClose" aria-label="Close">
            <Cross2Icon />
          </PopoverClose>
          <Popover.Arrow className="PopoverArrow" />
        </PopoverContent>
      </Popover.Portal>
    </Popover.Root>
  )
}

export const AddSeriesNew = ({
  id,
  catalog,
  assetClass,
  sources,
  addSeries,
  submitText,
  useInstrument,
  useExpiries,
}: {
  id: string
  catalog?: Catalog
  assetClass: AssetClass.PERP | AssetClass.FUTURE | AssetClass.OPTION
  sources?: Source[]
  addSeries: (s: NewChartState['add']) => void
  submitText?: string
  useInstrument?: boolean
  useExpiries?: boolean
}) => {
  const isMobile = useMediaQuery('(max-width: 768px)')

  let sourceList = sources
  if (!sourceList) {
    sourceList = AssetClassSource[assetClass]
  }
  const addSeriesState = useChartStore((s) => s.defaultCharts[id]?.add)
  const seriesLength = useChartStore(
    (s) => s.defaultCharts[id]?.series?.length || 0,
  )
  const source = addSeriesState?.source || sourceList[0]

  const currencies = useInstrument
    ? (Object.keys(Ticker) as Ticker[])
    : (catalog?.[source]?.pairs as `${Ticker}/${QuoteAsset}`[])

  const ccy = addSeriesState?.ccy || currencies?.[0]
  const getAvailableSeries = (
    s: Source,
    c: Ticker | `${Ticker}/${QuoteAsset}`,
  ): [string[], 'expiry' | 'instrument' | undefined] => {
    if (useInstrument) {
      return [
        catalog?.[s]?.[assetClass]?.[c.split('/')[0]]?.instrument || [],
        'instrument',
      ]
    }
    if (useExpiries) {
      const expiries = catalog?.[s]?.[assetClass]?.[
        c.split('/')[0]
      ]?.active.reduce((acc: string[], i: CatalogItem) => {
        if (i.quoteAsset === c.split('/')[1]) {
          acc.push(i.expiry)
        }
        return acc
      }, [])
      return [expiries || [], 'expiry']
    }
    return [[], undefined]
  }

  const series = getAvailableSeries(source, ccy)

  const initial = {
    ccy,
    source,
    colour: CHART_COLORS[seriesLength + 1],
    yAxis: addSeriesState?.yAxis || YAxis.LEFT,
  }
  if (series[1]) {
    initial[series[1]] =
      addSeriesState?.[series[1]] || (series[0][0] as Instrument)
  }

  if (!catalog || !sourceList?.length) return <></>

  const addBasic = () => addSeries(initial)

  return (
    <AddOrEditSeriesNew
      addSeries={addBasic}
      seriesIndex={seriesLength}
      id={id}
      assetClass={assetClass}
      submitText={submitText}
      initial={initial}
      sources={sources}
      currencies={currencies || []}
      getAvailableSeries={getAvailableSeries}
      trigger={
        seriesLength >= 8 ? (
          <ToolTip
            content={<div>A maximum of 8 series is allowed per graph.</div>}
          >
            <AddButton
              type="button"
              aria-label="Add series"
              disabled={seriesLength >= 8}
              onClick={() => {}}
            >
              Maximum added
            </AddButton>
          </ToolTip>
        ) : (
          <AddButton
            type="button"
            isMobile={isMobile}
            aria-label="Add series"
            disabled={seriesLength >= 8}
          >
            {isMobile ? '' : 'Add Series'}
            <PlusCircledIcon
              style={{
                width: isMobile ? '12px' : '20px',
                height: isMobile ? '12px' : '20px',
                marginLeft: isMobile ? 0 : undefined,
              }}
            />
          </AddButton>
        )
      }
    />
  )
}
