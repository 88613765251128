import { formatNumber } from '@telerik/kendo-intl'
import { format } from 'date-fns'
import { capitalize } from 'lodash'
import { splitCurrency, TenorOrExpiryKey } from 'services'
import { Ticker, TimeZone } from 'types'
import { reverseSABRMap, reverseSVIMap } from 'types/models'
import { daysToTenor } from 'utils/charts'
import CurrencyLogo from './common/CurrencyLogo'
import { deconstructKey } from './common/util'

const TooltipRenderer = ({ point, labelFormat, field, timeZone }) => {
  const dk = deconstructKey(point.series.name)
  if (!dk) return <></>
  const currency = (() => {
    if (dk && 'listed' in dk.middle) {
      return splitCurrency(dk.middle.listed as TenorOrExpiryKey)[0]
    }
    if (dk && 'currency' in dk.middle) {
      return dk?.middle.currency
    }
    if (dk && 'perp' in dk.middle) {
      return splitCurrency(dk.middle.perp as TenorOrExpiryKey)[0]
    }
  })()
  if (!currency) throw new Error('No Currency found for tooltip')
  let label = ''
  if ('tenor' in dk.middle && dk.middle.tenor) {
    label = label.concat(
      `${daysToTenor(dk.middle.tenor) || dk.middle.tenor.replace('d', '')}`,
    )
  } else if ('listed' in dk.middle) {
    label = label.concat(
      `${splitCurrency(dk.middle.listed as TenorOrExpiryKey)[1]}`,
    )
    if (dk.middle.listed.split('-').length > 2) {
      //option contract
      label = label.concat(
        ` ${dk.middle.listed.split('-')[2]} ${dk.middle.listed.split('-')[3]}`,
      )
    }
  }
  if ('type' in dk.middle && dk.middle.type) {
    label = label.concat(` ${capitalize(dk.middle.type)}`)
  }
  if ('model' in dk.middle && dk.middle.model) {
    label = label.concat(` ${dk.middle.model}`)
    if ('param' in dk.middle && dk.middle.param) {
      label = label.concat(` ${dk.middle.param}`)
    }
  }
  if ('series' in dk.middle && dk.middle.series) {
    label = label.concat(` ${capitalize(dk.middle.series)}`)
  }
  if (field) {
    label = label.concat(
      ` (${
        (reverseSVIMap[field] ||
          reverseSABRMap[field] ||
          capitalize(field)) as string
      })`,
    )
  }

  return (
    <table>
      <tbody>
        <tr>
          <td>
            <CurrencyLogo currency={currency as Ticker} />
            {dk?.source}
          </td>
          <td>
            {format(new Date(point.category), "d LLL yy' 'HH:mm:ss")} (
            {
              (timeZone === TimeZone.UTC
                ? TimeZone.UTC
                : Intl.DateTimeFormat().resolvedOptions().timeZone
              ).split('/')[1]
            }
            )
          </td>
        </tr>
        <tr>
          <td>{label}</td>
          <td>{formatNumber(point.value, labelFormat)}</td>
        </tr>
      </tbody>
    </table>
  )
}
export default TooltipRenderer
