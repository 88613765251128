import { BSLANG } from '@blockscholes/ql'
import { EditorState } from '@codemirror/state'
import { Parser } from 'pages/HistoricalAnalyzer/parser'
import { HistoricalAnalyzerContext } from 'context/HistoricalAnalyzerContext'
import Query from 'pages/HistoricalAnalyzer/query'
import { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { labelForQueryNum } from 'pages/HistoricalAnalyzer/HistoricalAnalyzer'
import { Axis } from 'pages/HistoricalAnalyzer/types'
import { DateRange, Frequency } from 'types'

const getAxisToAddTo = (
  axis: Axis,
  format: string | undefined,
): 'left' | 'right' => {
  // handle no values first
  if (!format || !axis.left.format || !axis.right.format) {
    return 'left'
  }
  // Check left first
  if (axis.left.format[0] === format[0]) {
    return 'left'
  }
  // If doesn't equal left go right
  if (axis.right.format[0] === format[0] || axis.right.format === undefined) {
    return 'right'
  }
  return 'left'
}

const getTenorOrExpiry = (cellQuery: string) => {
  const expiry =
    cellQuery.split('.')[3] === '1h'
      ? cellQuery.split('.')[2]
      : cellQuery.split('.')[3]

  return expiry.includes('-') ? expiry.split('-')[1] : expiry
}
const useImportToAnalyzer = (args?: {
  frequency?: Frequency
  timestampRange?: DateRange
  format?: string
}): ((queries: string[], title?: string) => void) => {
  const navigate = useNavigate()

  const haContext = useContext(HistoricalAnalyzerContext)
  const { createQuery, state, setChartFrequency, setTimestampRange } = haContext
  const axisToAddTo = getAxisToAddTo(state.chartAxis, args?.format)

  const addQueriesToAnalyzer = (queries: string[], title?: string) => {
    if (state.queryCount <= 0) {
      if (args?.frequency)
        setChartFrequency(args.frequency === '1m' ? '1h' : args.frequency)
      if (args?.timestampRange) {
        setTimestampRange({
          from: args.timestampRange.from,
          until: args.timestampRange.until,
        })
      }
    }
    queries.forEach((cellQuery: string, i) => {
      const editorState = EditorState.create({
        doc: cellQuery,
        extensions: [BSLANG()],
      })
      const parser = new Parser(editorState, state.sources)
      const tenorOrExpiry = getTenorOrExpiry(cellQuery)
      const ticker = cellQuery.split('.')[2].includes('-')
        ? cellQuery.split('.')[2].split('-')[0]
        : cellQuery.split('.')[2]
      const query = new Query({
        expression: cellQuery,
        label: labelForQueryNum(state.queryCount + i),
        tree: parser.parseTree,
        name: title ? `[${ticker}] ${title} (${tenorOrExpiry})` : '',
        axis: axisToAddTo,
      })

      createQuery(query, parser)
    })
    navigate('/historical-analyzer')
  }
  return addQueriesToAnalyzer
}

export default useImportToAnalyzer
