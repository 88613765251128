import { H6 } from 'components/atoms/Typography/styles'
import React, { useLayoutEffect } from 'react'
import styled, { useTheme } from 'styled-components'
import { useDefaultStore } from 'stores'
import {
  ChartBottomToolbar,
  ChartTopToolbar,
} from 'components/charts/common/toolbar'
import { AxisFormat, Chart, TimeZone, YAxis } from 'types'
import AssetClass from 'types/assetClass'
import useImportToAnalyzer from 'hooks/useImportToAnalyzer'
import { ConstructKey } from 'components/charts/common/util'
import { FutureTermStructureSeries, ListedSeries } from 'stores/types'
import LastUpdated from 'components/charts/common/series/LastUpdated'

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
`

const CenteredContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
`

interface IChartWrapperProps {
  title: string
  chart: React.ReactNode
  seriesPills?: React.ReactNode
  infoButton?: React.ReactNode
  type?: Chart
  disableMinutes?: boolean
  legend?: React.ReactNode
  topBarContent?: JSX.Element
  assetClass?: AssetClass
  keys?: { key: string; field: string }[]
  addSeries?: JSX.Element
  id: string
  timeZone?: TimeZone
  updatedStamp?: Date
  chartSeries?:
    | (Omit<ConstructKey, 'frequency'> | ListedSeries)[]
    | FutureTermStructureSeries[]
  axis: Partial<Record<YAxis, AxisFormat>>
}

const ChartWrapper: React.FC<IChartWrapperProps> = ({
  title,
  chart,
  infoButton,
  type,
  seriesPills,
  disableMinutes,
  legend,
  topBarContent,
  assetClass,
  keys,
  addSeries,
  id,
  timeZone,
  updatedStamp,
  chartSeries,
  axis,
}) => {
  const setUpChart = useDefaultStore((state) => state.setInitialChart)
  const updateChart = useDefaultStore((state) => state.updateTimeSeriesChart)
  const idKey = id || title
  const storedChartTimings = useDefaultStore((state) => {
    const c = state.defaultCharts?.[idKey]
    if (c && 'timings' in c && 'range' in c.timings) {
      return c.timings
    }
  })
  const storedChartFreq = useDefaultStore((state) => {
    const c = state.defaultCharts[idKey]
    if (c && c.type === Chart.TERM_STRUCTURE) {
      return '1h'
    }
    if (c && (c.type === Chart.TIMESERIES || c.type === Chart.TIMESERIES_BAR)) {
      return c.frequency
    }
    if (c && c.type === Chart.SMILE) {
      return '1h'
    }
    return c?.frequency || '1h'
  })

  const updateTimeRange = (range) => updateChart(idKey, 'timings', { range })
  const updateFrequency = (f) => updateChart(idKey, 'frequency', f)
  const toggleTimeZone = () =>
    updateChart(
      idKey,
      'timeZone',
      timeZone === TimeZone.UTC ? TimeZone.LOCAL : TimeZone.UTC,
    )

  useLayoutEffect(() => {
    if (type === Chart.HEATMAP) {
      setUpChart({ key: idKey, type, assetClass })
    }
    if (type === Chart.TERM_STRUCTURE && chartSeries) {
      setUpChart({ key: idKey, type, assetClass, series: chartSeries })
    } else {
      const state = {
        key: idKey,
        type: type || Chart.TIMESERIES,
        assetClass,
        series: chartSeries,
        axis,
      }

      setUpChart({ ...state })
    }
  }, [])

  const importToAnalyzer = useImportToAnalyzer({
    frequency: storedChartFreq,
  })
  const importSeriesToHA = () => {
    if (keys) {
      importToAnalyzer(
        keys.map(
          ({ key, field }) =>
            `${key.replace('.1m.', '.1h.')}${
              field
                ? `.${field
                    .toLowerCase()
                    .replace('svi_', '')
                    .replace('sabr_', '')
                    .replace('alpha', 'a')
                    .replace('future', 'px')}`
                : ''
            }`, // No minute data for now
        ),
        title,
      )
    }
  }
  const theme = useTheme()

  return (
    <FlexColumn>
      <ChartTopToolbar
        id={id}
        title={title}
        infoButton={infoButton}
        disableMinutes={disableMinutes}
        frequency={storedChartFreq}
        updateFrequency={updateFrequency}
        type={type}
        topBarContent={topBarContent}
        importSeriesToHA={importSeriesToHA}
        addSeries={addSeries}
      />
      {seriesPills}
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'flex-end',
          fontSize: '0.75rem',
          padding: '0.3rem 0 0',
          margin: 0,
        }}
      >
        {!type?.startsWith('TIMESERIES') &&
          updatedStamp &&
          !Number.isNaN(updatedStamp.getTime()) &&
          timeZone && (
            <>
              <p style={{ margin: 0, fontSize: '0.75rem' }}>Last Updated:</p>{' '}
              <LastUpdated
                timeZone={timeZone}
                updatedStamp={updatedStamp}
                shouldFormat
              />
            </>
          )}
      </div>
      <CenteredContent>{chart}</CenteredContent>
      {(type === Chart.TIMESERIES || type === Chart.TIMESERIES_BAR) && (
        <ChartBottomToolbar
          updatedStamp={updatedStamp}
          frequency={storedChartFreq}
          timings={storedChartTimings}
          updateTimeRange={updateTimeRange}
          timeZone={timeZone}
          toggleTimeZone={toggleTimeZone}
          shouldFormat
        />
      )}
      {legend}
    </FlexColumn>
  )
}

export default ChartWrapper
