import React from 'react'
import { createRoot } from 'react-dom/client'
import { init } from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import App from './App'

if (process.env.REACT_APP_ENV !== 'staging') {
  init({
    dsn: 'https://8afba496cc604a2284e34909d1097ff3@o4504482921185280.ingest.sentry.io/4504482923020288',
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
  })
}
const root = createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
)
