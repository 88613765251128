import {
  DragEndEvent,
  DragStartEvent,
  ZoomEndEvent,
  ZoomStartEvent,
} from '@progress/kendo-react-charts'

import { differenceInHours, differenceInMinutes } from 'date-fns'
import { useEffect, useState } from 'react'
import { ChartState } from 'stores/types'
import { IChartFrequencyData, YAxis } from 'types'
import { useMediaQuery } from './useMediaQuery'

export type LocalAxis = {
  timestamp: { min: string; max: string | undefined }
  [YAxis.LEFT]: { min: number; max: number }
  [YAxis.RIGHT]: { min: number; max: number }
}

const useChartActions = ({
  chartTimings,
  unit,
}: {
  chartTimings?: ChartState['timings']
  unit: IChartFrequencyData['unit']
}) => {
  const [localAxisState, setLocalAxisState] = useState<LocalAxis | undefined>()
  const isMobile = useMediaQuery('(max-width: 768px)')

  useEffect(() => {
    setLocalAxisState(undefined)
  }, [chartTimings, setLocalAxisState])

  const handleZoomStart = (e: ZoomStartEvent | DragStartEvent) => {
    if (!e.nativeEvent.shiftKey) {
      e.preventDefault()
    }
  }

  const handleZoomEnd = (
    e:
      | ZoomEndEvent
      | DragEndEvent
      | { axisRanges: { timestamp: { max: Date; min: Date } } },
  ) => {
    if (isMobile) {
      return
    }
    if (
      e instanceof DragEndEvent &&
      e.nativeEvent.event.shiftKey &&
      !isMobile
    ) {
      // leave shift key events to ZoomEndEvents
      return
    }
    const axisRanges = {
      ...localAxisState,
      ...e.axisRanges,
    } as unknown as LocalAxis

    if (
      e instanceof DragEndEvent &&
      axisRanges.timestamp.max === localAxisState?.timestamp.max &&
      axisRanges.timestamp.min === localAxisState?.timestamp.min &&
      !isMobile
    ) {
      return
    }

    const diff = unit === 'minutes' ? 150 : 48
    const diffFn = unit === 'minutes' ? differenceInMinutes : differenceInHours
    if (
      diffFn(new Date(), new Date(axisRanges.timestamp?.max as string)) < diff
    ) {
      axisRanges.timestamp.max = undefined
    }
    setLocalAxisState(axisRanges)
  }

  return {
    handleZoomStart,
    handleZoomEnd,
    localAxisState,
    setLocalAxisState,
  }
}

export default useChartActions
