import React, { InputHTMLAttributes } from 'react'
import { Label, Indicator, Input } from './styles'

export interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string
  disabled?: boolean
  className?: string
}
const Checkbox: React.FC<CheckboxProps> = ({
  label,
  disabled,
  className,
  ...rest
}) => (
  <Label disabled={disabled} hasLabel={!!label} className={className}>
    <Input {...rest} type="checkbox" disabled={disabled} />
    <Indicator />
    {label}
  </Label>
)

export default Checkbox
