import { SMILE_CATEGORIES } from 'consts'

export enum Timeseries {
  PX = 'px',
  ANNUAL_PCT = 'annual.pct',
  BASIS_PCT = 'basis.pct',
  OI = 'oi',
  VOL_RATIO = 'vol.ratio',
  VOL_SUM = 'volume.sum',
  SKEW = 'skew',
  PARAMS = 'params',
  SMILE = 'smile',
  BUTTERFLY = 'butterfly',
  VIX = 'index.vol',
  FUNDING = 'funding.rate',
}
type DataObj = { key: string; resKey: string }
export type TimeSeriesDataValue =
  | string
  | { keyByTenor: boolean; dataPoints: DataObj[] }
type TimeSeriesData = Record<string, TimeSeriesDataValue>
export const timeSeriesDataKey: TimeSeriesData = {
  'annual.pct': 'pct',
  'basis.pct': 'pct',
  px: 'px',
  oi: 'oi',
  'vol.ratio': 'ratio',
  'volume.sum': 'sum',
  'index.vol': 'vol',
  'funding.rate': 'rate',
  smile: {
    keyByTenor: true,
    dataPoints: [...SMILE_CATEGORIES, 'atm'].map((i) => ({
      key: i,
      resKey: i,
    })),
  },
}
