/* eslint-disable no-return-assign */
/* eslint-disable no-param-reassign */
import { OptionPricerSlice } from 'stores/types'
import { immer } from 'zustand/middleware/immer'
import { v4 as uuidv4 } from 'uuid'
import { OptionType, Ticker } from 'types'
import { Model } from 'types/models'
import { AnalyseScenario, EditableOptionPriceQuery } from 'types/queries'
import { StateCreator } from 'zustand'
import { openDB } from 'idb'
import { NOW_UTC } from 'consts'

const defaultOption = () => ({
  inEdit: true,
  model: Model.SVI,
  ccy: Ticker.BTC,
  quantity: 1,
  type: OptionType.CALL,
  pricingTimestamp: 'LATEST' as const,
  id: uuidv4(),
})

// Initialize or open the IndexedDB database
const initDB = async () => {
  return await openDB('OptionsDB', 1, {
    upgrade(db) {
      if (!db.objectStoreNames.contains('options')) {
        db.createObjectStore('options', { keyPath: 'id', autoIncrement: true })
      }
    },
  })
}

// Function to fetch all options from IndexedDB
const fetchAllOptionsFromDB = async () => {
  const db = await initDB()
  const allOptions = await db.getAll('options')
  return allOptions
}

// Function to update the IndexedDB with the new options
const updateOptionInDB = async (query: EditableOptionPriceQuery, i: number) => {
  const db = await initDB()
  await db.put('options', { ...query, id: i })
}
const deleteOptionFromDB = async (i: number) => {
  const db = await initDB()
  await db.delete('options', i)
}
const createOptionPricer: StateCreator<
  OptionPricerSlice,
  [],
  [['zustand/immer', never]],
  OptionPricerSlice
> = immer((set) => ({
  options: {
    analysis: {
      fetched: false,
      ladder: AnalyseScenario.SPOT,
      currency: null,
    },
    queries: [],
    setAnalysisLadder: (scenarioAnalysisLadder) =>
      set((state) => {
        state.options.analysis.ladder = scenarioAnalysisLadder
        state.options.analysis.fetched = false
      }),
    updateOption: (query, i) =>
      set((state) => {
        state.options.queries[i] = query
        // Persist to IndexedDB
        updateOptionInDB(query, i).catch(console.error)
      }),
    addOption: (q?: EditableOptionPriceQuery) =>
      set((state) => {
        state.options.queries.push(q ?? defaultOption())
      }),
    deleteOption: (i) =>
      set((state) => {
        state.options.queries.splice(i, 1)
        deleteOptionFromDB(i).catch(console.error)
      }),
    setAnalysisFetched: () =>
      set((s) => {
        s.options.analysis.fetched = true
      }),
    setAnalysisCurrency: (t: Ticker) =>
      set((s) => {
        s.options.analysis.currency = t
        s.options.analysis.fetched = false
      }),
    setDefaults: (queries) =>
      set((state) => {
        state.options.queries = queries
      }),
    initializeOptions: async () => {
      const storedOptions: EditableOptionPriceQuery[] =
        await fetchAllOptionsFromDB()

      // Get active options and capture indices and data of stale options
      const active: EditableOptionPriceQuery[] = []
      const staleIndicesAndOptions: [number[], EditableOptionPriceQuery[]] = [
        [],
        [],
      ]

      storedOptions.forEach((option, index) => {
        if (option.expiry && new Date(option.expiry) > NOW_UTC()) {
          active.push(option)
        } else {
          staleIndicesAndOptions[0].push(index)
          staleIndicesAndOptions[1].push(option)
        }
      })

      set((state) => {
        state.options.queries = active
      })

      // Delete stale options from IndexedDB using their indices
      for (const index of staleIndicesAndOptions[0]) {
        deleteOptionFromDB(index).catch(console.error)
      }

      return staleIndicesAndOptions[1] // Return tuple with indices and options
    },
  },
}))

export default createOptionPricer
