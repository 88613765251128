import logo from 'images/logo.svg'
import React, { ReactNode } from 'react'

const LoaderContainer: React.FC<{
  children: ReactNode
  height?: string
}> = ({ children, height }) => (
  <div
    style={{
      height: height || '200px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      margin: 'auto',
    }}
  >
    {children}
  </div>
)
const ChartLoader: React.FC = () => (
  <LoaderContainer height="400px">
    <div className="spinner">
      <img src={logo} alt="loader" />
    </div>
  </LoaderContainer>
)

export const ChartNoSeriesData = ({
  text = 'No series data available',
}: {
  text?: string
}) => (
  <LoaderContainer>
    <p>{text}</p>
  </LoaderContainer>
)
export default ChartLoader
