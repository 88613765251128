/* eslint-disable @typescript-eslint/no-shadow */
import {
  API_ROOT_URL,
  MAX_TERM_DAYS,
  NOW_UTC,
  START_OF_BLOCKSCHOLES_TIME,
} from 'consts'
import { sub } from 'date-fns'
import { QueryRequest, TreeNode } from 'pages/HistoricalAnalyzer/types'
import { Series, Source, Ticker } from 'types'
import AssetClass from 'types/assetClass'
import { Instrument, QuoteAsset } from 'types/catalog'
import {
  ConstantTenorByDays,
  DateRange,
  Frequency,
  HistoricImpliedVolParamsWithDate,
  HistoricImpliedVolSeries,
  MultiHistoricalAnalyzerQueryResponse,
  MultiTermStructure,
  MultiTimeSeriesData,
  TimeSeriesData,
} from 'types/charts'
import { Model, SABRParam, SVIParam } from 'types/models'
import { convertDateToUTCOrLocal } from 'utils/date-formatter'
import {
  ModelParametersDatetimeRangeParams,
  MultiSeriesResult,
  smileItemRes,
} from './common'
import { SETTLEMENT_OVERWRITE } from './dataService'
import { fetchWithAuth } from './fetch'
import { getHistoricalModelParams } from './historicImpliedVolatility'

const SeriesEndpointMap: Record<Series, string> = {
  [Series.CONSTANT_MATURITIES]: 'getConstantMaturityFutures',
  [Series.LISTED_EXPIRIES]: 'getListedTermstructure',
}

type HistoricalAnalyzerVectorResponse = {
  type: 'vector'
  timestamps: Array<number>
  values: Array<number>
  message?: string
}

type HistoricalAnalyzerScalarResponse = {
  type: 'num'
  timestamps: Array<number>
  values: number
  message?: string
}

type HistoricalAnalyzerRegressionResponse = {
  type: 'regression'
  timestamps: Array<number>
  values: [
    Array<number>,
    Array<number>,
    Array<number>,
    number,
    number,
    number,
    number,
    number,
  ]
  message?: string
}

type HistoricalAnalyzerCdfResponse = {
  type: 'cdf'
  timestamps: Array<number>
  values: [Array<number>, Array<number>, Array<number>]
  message?: string
}

type HistoricalAnalyzerResponse =
  | HistoricalAnalyzerVectorResponse
  | HistoricalAnalyzerScalarResponse
  | HistoricalAnalyzerRegressionResponse
  | HistoricalAnalyzerCdfResponse

function isHaVectorResponse(
  resp: HistoricalAnalyzerResponse,
): resp is HistoricalAnalyzerVectorResponse {
  return resp.type === 'vector'
}

function isHaScalarResponse(
  resp: HistoricalAnalyzerResponse,
): resp is HistoricalAnalyzerScalarResponse {
  return resp.type === 'num'
}

function isHaRegressionResponse(
  resp: HistoricalAnalyzerResponse,
): resp is HistoricalAnalyzerRegressionResponse {
  return resp.type === 'regression'
}

function isHaCdfResponse(
  resp: HistoricalAnalyzerResponse,
): resp is HistoricalAnalyzerCdfResponse {
  return resp.type === 'cdf'
}

type AmountResponse = {
  data: {
    count: number
    items: Array<{ timestamp: string; sum: string; amt: string }> // TODO: remove amt once backfilled correctly
  }
}

type ConstantMaturityFuturesResponse = {
  data: {
    items: Array<{
      qualified_name: string
      future: number
      yield: number
      expiry: number
      timestamp: number
    }>
    error?: string
  }
  error: {
    code: number
    message: string
  }
}

interface VolatilityATMResponse {
  data: {
    qualifiedName: string
    count: number
    lastModified: number | null
    items: Array<smileItemRes>
  }
}

export type CatalogItem = {
  active?: boolean | 'active' | 'expired'
  availableSince: string
  baseAsset: Ticker
  expiry: string
  instrument: Instrument
  listing: string
  quoteAsset: QuoteAsset
  exchange: string
  assetType?: 'option' | 'future'
  type?: string
  strike?: number
  settlementAsset: QuoteAsset
}

export interface StaticCatalogItem extends CatalogItem {
  lastLeafs?: TreeNode[]
  staticCal?: boolean
}
const haEndpoint: string = `${API_ROOT_URL}bsLangEvaluator` || ''

export const dataService = {
  getCatalogData: async (
    asset: 'option' | 'future',
    active = true,
  ): Promise<CatalogItem[]> => {
    type CatalogResponse = {
      data: {
        count: number
        items: Array<{
          active: Array<boolean>
          availableSince: Array<string>
          baseAsset: Array<Ticker>
          expiry: Array<string>
          index: Array<string>
          instrument: Array<Instrument>
          listing: Array<string>
          quoteAsset: Array<QuoteAsset>
          type?: Array<string>
          strike?: Array<number>
          settlementAsset?: Array<QuoteAsset>
        }>
      }
      error?: string
    }
    async function performRequest(
      currency: string,
      assetType: 'option' | 'future',
      exchange: string,
    ) {
      const URL = `${API_ROOT_URL}catalog/getData?exchange=${exchange}&currency=${currency}&assetType=${assetType}&active=${active.toString()}${
        active === false
          ? `&startTime=${
              sub(new Date(), {
                months: 1,
              }).getTime() * 1000000
            }&endTime=${new Date().getTime() * 1000000}`
          : ``
      }`
      const { data } = await fetchWithAuth<CatalogResponse>({
        url: URL,
        options: {
          mode: 'cors',
        },
      })

      const item = data.items[0]
      const catalogItems: Array<CatalogItem> = item.instrument?.reduce(
        (acc: CatalogItem[], instrument, i) => {
          if (new Date(item.expiry[i]) < new Date('01/01/2020')) return acc
          acc.push({
            active:
              new Date(item.expiry[i]) > new Date() ? 'active' : 'expired',
            availableSince: item.availableSince?.[i],
            baseAsset: item.baseAsset[i],
            expiry: item.expiry[i],
            instrument,
            listing: item.listing?.[i],
            quoteAsset: item.quoteAsset?.[i],
            type: item.type?.[i],
            assetType,
            exchange,
            strike: item.strike?.[i],
            settlementAsset:
              item.settlementAsset?.[i] ||
              SETTLEMENT_OVERWRITE[`${exchange}-${item.instrument[i]}`],
          })
          return acc
        },
        [],
      )

      return catalogItems || []
    }

    const results = await Promise.all([
      ...['BTC', 'ETH'].map((i) => performRequest(i, asset, 'deribit')),
      ...['BTC', 'ETH'].map((i) => performRequest(i, asset, 'bybit')),
    ])

    return results.flatMap((i) => i)
  },
  getExpiredCatalogOptionData: async (): Promise<CatalogItem[]> => {
    type CatalogResponse = {
      data: {
        count: number
        items: Array<{
          active: Array<boolean>
          availableSince: Array<string>
          baseAsset: Array<Ticker>
          expiry: Array<string>
          index: Array<string>
          instrument: Array<Instrument>
          listing: Array<string>
          quoteAsset: Array<QuoteAsset>
          settlementAsset?: Array<QuoteAsset>
          type?: Array<string>
        }>
      }
      error?: string
    }
    async function performRequest(currency: string, exchange: string) {
      const URL = `${API_ROOT_URL}catalog/getData?exchange=${exchange}&currency=${currency}&assetType=option&active=false`
      const { data } = await fetchWithAuth<CatalogResponse>({
        url: URL,
        options: { mode: 'cors' },
      })

      const item = data.items[0]
      const catalogItems: Array<CatalogItem> = item.active?.reduce(
        (acc: CatalogItem[], active, i) => {
          if (new Date(item.expiry[i]) < new Date('01/01/2020')) return acc
          acc.push({
            active:
              new Date(item.expiry[i]) > new Date() ? 'active' : 'expired',
            availableSince: item.availableSince?.[i],
            baseAsset: item.baseAsset[i],
            expiry: item.expiry[i],
            instrument: item.instrument[i],
            listing: item.listing?.[i],
            quoteAsset: item.quoteAsset?.[i],
            settlementAsset:
              item.settlementAsset?.[i] ||
              SETTLEMENT_OVERWRITE[`${exchange}-${item.instrument[i]}`],
            type: item.type?.[i],
            assetType: 'option',
            exchange,
          })
          return acc
        },
        [],
      )

      return catalogItems || []
    }

    const results = await Promise.all([
      ...['BTC', 'ETH'].map((i) => performRequest(i, 'deribit')),
      ...['BTC', 'ETH'].map((i) => performRequest(i, 'bybit')),
    ])

    return results.reduce((acc: CatalogItem[], i) => {
      i.map((x) => acc.push(x as unknown as CatalogItem))
      return acc
    }, [])
  },
  getHistoricalSeries: async (
    queries: QueryRequest[],
    timestampRange: DateRange,
  ): Promise<MultiHistoricalAnalyzerQueryResponse> => {
    const url = haEndpoint
    async function performFetch(query: QueryRequest) {
      try {
        const data = await fetchWithAuth<HistoricalAnalyzerResponse>({
          url,
          options: {
            method: 'POST',
            body: JSON.stringify(query.ast),
            mode: 'cors',
            headers: {
              'Content-Type': 'application/json',
            },
          },
        })

        if (isHaRegressionResponse(data)) {
          return {
            kind: 'regression',
            data: {
              scatter: data.values[0].map((xVal, i) => ({
                x: xVal,
                y: data.values[1][i],
              })),
              line: data.values[0].map((xVal, i) => ({
                x: xVal,
                y: data.values[2][i],
              })),
              alpha: data.values[3],
              beta: data.values[4],
              rSquared: data.values[5],
            },
          }
        }
        if (isHaCdfResponse(data)) {
          return {
            kind: 'cdf',
            data: data.values[0].map((xVal, i) => ({
              x: xVal,
              y: data.values[1][i],
            })),
            percentiles: [
              data.values[2][4],
              data.values[2][24],
              data.values[2][49],
              data.values[2][74],
              data.values[2][94],
            ],
          }
        }
        if (isHaScalarResponse(data)) {
          if (data.timestamps.length) {
            return {
              kind: 'timeseries',
              data: data.timestamps.map((timestamp) => ({
                x: convertDateToUTCOrLocal(timestamp / 1000000),
                y: data.values,
              })),
            }
          }
          return {
            kind: 'timeseries',
            data: [
              { x: timestampRange.from, y: data.values },
              { x: timestampRange.until, y: data.values },
            ],
          }
        }
        if (isHaVectorResponse(data)) {
          if (!data.timestamps.length) {
            return { error: 'timeseries not found' }
          }
          return {
            kind: 'timeseries',
            data: (data.timestamps || []).map((timestamp, i) => ({
              x: convertDateToUTCOrLocal(timestamp / 1000000),
              y: data.values[i],
            })),
          }
        }
        return { data: [] }
      } catch (error) {
        return { error }
      }
    }

    const results = await Promise.all(queries.map(performFetch))
    return queries.reduce(
      (acc, curr, i) => {
        const output = results[i]
        if (output.error) {
          acc.errors.set(curr.query, output.error)
        } else {
          acc.successes.set(curr.query, output)
        }
        return acc
      },
      { successes: new Map(), errors: new Map() },
    )
  },
  getTermStructure: async (
    exchange: Source,
    currency: Ticker,
    dates: Array<number | 'LATEST'>,
    series: Series,
    assetType = 'future',
  ): Promise<MultiTermStructure> => {
    type FetchTermStructureResponse = {
      timestamp: string
      data: Array<{
        instrument: string
        yield: number
        price: number
        dateOffset: number
      }>
    }
    async function performFetch(date: number | 'LATEST', seriesParam: Series) {
      const endpoint = SeriesEndpointMap[seriesParam]
      let URL = `${API_ROOT_URL}timeseries/${endpoint}?exchange=${exchange.toLowerCase()}&currency=${currency}&assetType=${assetType}&frequency=1h`
      if (date !== 'LATEST') {
        URL += `&timestamp=${date * 1000000}`
      }
      let response: ConstantMaturityFuturesResponse | undefined
      try {
        const data = await fetchWithAuth<ConstantMaturityFuturesResponse>({
          url: URL,
        })
        response = data
      } catch (err) {
        console.error('error getting term structure with params', {
          date,
          seriesParam,
          exchange,
          currency,
          assetType,
        })
      }
      if (!response || response.error) {
        return undefined
      }

      const {
        data: { items },
      } = response

      // clean up validation of responses
      const validItems = items.filter((item) => Object.keys(item).length)

      const instruments = validItems.map((item, i) => {
        return {
          instrument: item.qualified_name.split('.')[3],
          yield: item.yield,
          price: item.future,
          dateOffset: item.expiry * MAX_TERM_DAYS,
        }
      })
      if (!instruments.length) {
        return undefined
      }
      instruments.sort((a, b) => a.dateOffset - b.dateOffset)

      const returnTimestamp = new Date(
        validItems[0]?.timestamp / 1000000,
      ).toISOString()

      return {
        timestamp: returnTimestamp,
        data: instruments,
      }
    }
    const results = await Promise.all(
      dates.map((date) => performFetch(date, series)),
    )
    const filteredResults = results.filter(
      (result): result is FetchTermStructureResponse => !!result,
    )
    return filteredResults.reduce((acc, curr) => {
      return {
        ...acc,
        [curr.timestamp]: curr.data,
      }
    }, {})
  },
  getVolume: async (
    source: Source,
    assetClass: AssetClass,
    ticker: Ticker,
    instruments: Array<string>,
  ): Promise<MultiTimeSeriesData> => {
    const exchangeParam = source.toLowerCase()
    const assetClassParam = assetClass.toLowerCase()

    async function performFetch(instrument: string) {
      const url = `${API_ROOT_URL}timeseries/getData?key=${exchangeParam}.${assetClassParam}.${ticker}-${instrument}.1h.volume.sum`
      const { data } = await fetchWithAuth<AmountResponse>({
        url,
        customError: (status) => `getVolume returned ${status}`,
      })

      return data.items.map((item) => ({
        y: item.amt ? item.amt : item.sum,
        x: convertDateToUTCOrLocal(+item.timestamp / 1000000),
      }))
    }

    const results = await Promise.all(instruments.map(performFetch))
    return instruments.map((instrument, i) => {
      return {
        key: instrument,
        dataPoints: results[i],
      }
    }, {})
  },
  getOptionsATMVol: async (
    key: string,
    startTime: string,
    endTime: string,
    tenor: string,
  ): Promise<TimeSeriesData> => {
    let URL = `${API_ROOT_URL}timeseries/getHistoricTimeseriesData?key=${key}`
    URL += `&startTime=${startTime}&endTime=${endTime}`
    const { data: output } = await fetchWithAuth<VolatilityATMResponse>({
      url: URL,
      customError: (status) => `getOptionsATMVol returned ${status}`,
      options: { mode: 'cors' },
    })

    if (!output) {
      return {
        key: tenor,
        dataPoints: [],
      }
    }

    const points = output.items[0]?.timestamp.map((timestamp, i) => {
      return {
        x: convertDateToUTCOrLocal(timestamp / 1000000),
        y: output.items[0].atm[i],
      }
    })

    return {
      key: tenor,
      dataPoints: points,
    }
  },
  getHistoricalModelParams: async (
    source: Source,
    currency: Ticker,
    model: Model,
    tenors: Array<ConstantTenorByDays>,
    param: SVIParam | SABRParam,
    frequency: Frequency,
  ): Promise<MultiSeriesResult<TimeSeriesData>> => {
    const queries: Array<ModelParametersDatetimeRangeParams> = tenors.map(
      (tenor) => {
        return {
          model,
          exchange: source.toLowerCase(),
          currency,
          startTime: (
            START_OF_BLOCKSCHOLES_TIME.getTime() * 1000000
          ).toString(),
          endTime: (NOW_UTC().getTime() * 1000000).toString(),
          tenor,
          frequency,
        }
      },
    )
    const results = await Promise.all(
      queries.map(getHistoricalModelParams),
    ).then((res) => {
      const successes = res.filter(
        (res): res is HistoricImpliedVolSeries => !(res instanceof Error),
      )
      const queryErrors = res.filter(
        (res): res is Error => res instanceof Error,
      )
      if (queryErrors.length) {
        return { error: queryErrors }
      }
      const series = successes.reduce((acc, cur) => {
        return {
          ...acc,
          [cur.key]: cur.dataPoints,
        }
      }, {} as Record<string, Array<HistoricImpliedVolParamsWithDate>>)

      return series
    })
    const activeSeries = tenors.map((tenor) => {
      const dataPoints = results[tenor]?.map((value) => ({
        x: convertDateToUTCOrLocal(value.timestamp),
        y: value[param],
      }))
      return {
        key: tenor,
        dataPoints,
      }
    })

    return {
      successes: activeSeries,
    }
  },
}
