import {
  ChartArea,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartLegend,
  ChartSeries,
  ChartSeriesItem,
  ChartTooltip,
  ChartValueAxis,
  ChartValueAxisItem,
  ValueAxisLabels,
} from '@progress/kendo-react-charts'
import { useMemo } from 'react'
import {
  AxisFormat,
  IChartFrequencyData,
  TimeSeriesData,
  TimeZone,
  YAxis,
} from 'types'

import { Path } from '@progress/kendo-drawing'
import { add } from 'date-fns'
import useChartActions from 'hooks/useChartActions'
import { ChartState } from 'stores/types'
import { changeTimeZone } from 'utils/date-formatter'

import 'hammerjs'
import ChartDecider from './ChartDecider'
import { ChartNoSeriesData } from './ChartLoader'
import WaterMark from './Watermark'

import liveMarker from './common/markers/live'
import { getCrossingVals } from './common/util'

interface IMultiTimeSeriesLineChartProps {
  data?: TimeSeriesData[] | undefined
  frequency: IChartFrequencyData
  valueAxisLabels?: ValueAxisLabels
  valueAxisTitle: string
  valueAxisCrosshairFormat?: string
  seriesLabelFormatter?: (label: string) => string
  isDelta?: boolean
  timeSeriesKeys?: string[]
  chartSeries: ChartState['series']
  timeZone: TimeZone
  axis: Partial<Record<YAxis, AxisFormat>>
  hoveredIndex?: number
  chartTimings?: ChartState['timings']
  id?: string
}

const MultiTimeSeriesLineChart: React.FC<IMultiTimeSeriesLineChartProps> = ({
  data = [],
  frequency,
  valueAxisLabels,
  valueAxisCrosshairFormat = 'p2',
  seriesLabelFormatter,
  isDelta = false,
  chartSeries,
  timeZone,
  axis,
  hoveredIndex,
  chartTimings,
  id,
}) => {
  const colors = chartSeries?.map((s) => s.color) || []
  const { handleZoomStart, handleZoomEnd, localAxisState } = useChartActions({
    chartTimings,
    unit: frequency.unit,
  })

  const newDates = useMemo(
    () =>
      data.reduce((acc: TimeSeriesData[], d) => {
        acc.push({
          ...d,
          dataPoints: d.dataPoints?.map((dp) => {
            return {
              ...dp,
              y: dp.y,
              x: changeTimeZone(new Date(dp.x), timeZone),
            }
          }),
        })
        return acc
      }, []),
    [data, timeZone],
  )

  let format = valueAxisLabels?.format || valueAxisCrosshairFormat || 'n2'
  format = useMemo(() => {
    if (!newDates) {
      return format
    }
    let max = 0
    let min = 0
    newDates.forEach(({ dataPoints }) => {
      const dps = dataPoints?.map((d) => Number(d.y)) || []
      max = Math.max(max, ...dps)
      min = Math.min(max, ...dps)
    })
    const getZeroLength = (x) => -Math.floor(Math.log10(x) + 1)
    return max - min > 2
      ? format
      : `${format[0] || 'n2'}${getZeroLength(max - min) + 1 || 3}`
  }, [newDates, format])

  return (
    <>
      {newDates.length === 0 ? (
        <ChartNoSeriesData />
      ) : (
        <>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              alignItems: 'end',
              position: 'relative',
              top: isDelta ? '40px' : '40px',
              zIndex: 1,
              height: 0,
            }}
          >
            {isDelta && <p style={{ margin: 0, opacity: '75%' }}>Δ = delta</p>}
          </div>
          <WaterMark>
            <ChartDecider
              handleZoomStart={handleZoomStart}
              handleZoomEnd={handleZoomEnd}
              data={newDates}
              axis={axis}
              frequency={frequency}
              localAxisState={localAxisState}
              id={id}
            >
              <ChartArea opacity={0} />
              <ChartLegend visible={false} />
              <ChartTooltip />
              <ChartValueAxis>
                {Object.entries(axis)
                  .filter(([_, f]) => f)
                  .map(([seriesLabel, f], i) => {
                    if (!f) return <div key={seriesLabel} />
                    return (
                      <ChartValueAxisItem
                        key={seriesLabel}
                        name={seriesLabel}
                        min={localAxisState?.[seriesLabel]?.min}
                        max={localAxisState?.[seriesLabel]?.max}
                        axisCrossingValue={-Infinity}
                        labels={{
                          format: f,
                          font: '0.65rem "OpenSans"',
                        }}
                        crosshair={{
                          visible: true,
                        }}
                        narrowRange
                      />
                    )
                  })}
              </ChartValueAxis>
              <ChartSeries>
                {newDates.map(({ key, dataPoints, field }, indx) => {
                  let opacity = 1
                  if (typeof hoveredIndex === 'number') {
                    if (hoveredIndex !== indx) {
                      opacity = 0.3
                    }
                  }
                  const showHoveredMarker =
                    typeof hoveredIndex === 'number'
                      ? hoveredIndex === indx
                      : true
                  const lp = dataPoints?.[dataPoints?.length - 1]
                  return (
                    <ChartSeriesItem
                      color={colors[indx]}
                      key={`${key}${field || ''}`}
                      type="line"
                      opacity={opacity}
                      style="normal"
                      missingValues="gap"
                      name={
                        seriesLabelFormatter ? seriesLabelFormatter(key) : key
                      }
                      axis={chartSeries?.[indx]?.axis?.yAxis || YAxis.LEFT}
                      width={
                        frequency?.duration && frequency?.duration <= 3700000
                          ? 0.8
                          : 1
                      }
                      field="y"
                      categoryAxis="timestamp"
                      categoryField="x"
                      data={dataPoints}
                      markers={{
                        visual:
                          showHoveredMarker && lp && 'live' in lp && lp?.live
                            ? (e) => liveMarker(e, lp)
                            : () => new Path(),
                      }}
                      tooltip={{ visible: false }}
                    />
                  )
                })}
              </ChartSeries>
              <ChartCategoryAxis>
                <ChartCategoryAxisItem
                  type="date"
                  name="timestamp"
                  baseUnit={frequency.unit}
                  baseUnitStep={frequency.step}
                  maxDivisions={15}
                  axisCrossingValue={getCrossingVals(
                    changeTimeZone(new Date(Date.now()), timeZone).getTime(),
                    axis,
                  )}
                  max={
                    typeof chartTimings?.range !== 'object' &&
                    (localAxisState?.timestamp?.max ||
                      add(changeTimeZone(new Date(Date.now()), timeZone), {
                        minutes: frequency.unit === 'minutes' ? 60 : 0,
                        hours: frequency.unit === 'hours' ? 7 : 0,
                        days: frequency.unit === 'days' ? 2 : 0,
                        weeks: frequency.unit === 'weeks' ? 2 : 0,
                        months: frequency.unit === 'months' ? 1 : 0,
                      }))
                  }
                  min={localAxisState?.timestamp?.min}
                  labels={{
                    rotation: 'auto',
                    font: '0.7rem "OpenSans"',
                    dateFormats: {
                      weeks: 'd MMM yy',
                      days: 'd MMM yy',
                      months: 'MMM yy',
                    },
                  }}
                  crosshair={{
                    visible: true,
                  }}
                />
              </ChartCategoryAxis>
            </ChartDecider>
          </WaterMark>
        </>
      )}
    </>
  )
}

export default MultiTimeSeriesLineChart
