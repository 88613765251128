import { TreeNode } from 'pages/HistoricalAnalyzer/types'
import DeribitStaticCatalog from 'pages/HistoricalAnalyzer/deribit-catalog.json'
// import BybitStaticCatalog from 'pages/HistoricalAnalyzer/bybit-catalog.json'
import CompositeStaticCatalog from 'pages/HistoricalAnalyzer/composite-catalog.json'
import { CatalogItem, StaticCatalogItem } from './lambdaDataService'

const generateChild: (c: TreeNode[]) => TreeNode = (children: TreeNode[]) => ({
  label: '1h',
  children: [...children],
})

const genericFutureChild: TreeNode = generateChild([
  { label: 'px', children: [] },
  {
    label: 'curve',
    children: [{ label: 'future', children: [] }],
  },
  // { label: 'index', children: [{ label: 'px', children: [] }] },
  { label: 'oi', children: [] },
  { label: 'volume', children: [{ label: 'sum', children: [] }] },
])

const genericOptionChild: TreeNode = generateChild([
  // { label: 'px', children: [] },
  // { label: 'mid', children: [{ label: 'px', children: [] }] },
  // { label: 'index', children: [{ label: 'px', children: [] }] },
  { label: 'oi', children: [] },
])

const addChild = ({
  assetType,
  type,
  lastLeafs,
  staticCal,
}: StaticCatalogItem): TreeNode[] => {
  if (staticCal) return []
  if (lastLeafs) return lastLeafs

  if (assetType === 'option') {
    return [genericOptionChild]
  }
  return [genericFutureChild]
}

export const constructCatalog = (results: CatalogItem[]): TreeNode[] => {
  const keys = [
    'exchange',
    'assetType',
    'baseAsset',
    'active',
    'type',
    'instrument',
  ]

  const result = [
    ...results,
    ...DeribitStaticCatalog,
    // ...BybitStaticCatalog,
    ...CompositeStaticCatalog,
  ].reduce(
    (acc, obj) => {
      keys.reduce((q: { temp: TreeNode[] }, key) => {
        let label: string = obj[key]
        if (!obj[key]) return q
        if (obj[key] === 'C') label = 'call'
        if (obj[key] === 'P') label = 'put'

        if (!q[label]) {
          q.temp.push({
            label,
            children: (q[label] = {
              temp:
                key === 'instrument' ? addChild(obj as StaticCatalogItem) : [],
            }).temp,
            ...(key === 'instrument' && obj),
          })
        }
        return q[label]
      }, acc)
      return acc
    },
    { temp: [] },
  ).temp

  return result
}
