/* eslint-disable react/no-children-prop */
import { useContext, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { CrossCircledIcon } from '@radix-ui/react-icons'
import ChartLoader from 'components/charts/ChartLoader'
import './index.scss'
import {
  Grid,
  GridCellProps,
  GridColumn as Column,
  GridPageChangeEvent,
} from '@progress/kendo-react-grid'
import Button from 'components/atoms/Button'
import { GridLayout, GridLayoutItem } from '@progress/kendo-react-layout'
import styled, { useTheme } from 'styled-components'
import { useDebounce } from 'hooks/useDebounce'

import { CatalogContext } from '../../../context/CatalogContext'
import { HistoricalAnalyzerContext } from '../../../context/HistoricalAnalyzerContext'

type TreeNode = {
  label: string
  children?: TreeNode[]
}

const OverflowGrid = styled(Grid)`
  .k-grid-content {
    overflow: hidden;

    &:hover {
      overflow: auto;
    }

    .k-grid-layout {
    }
  }
`
const TreeLeaf = ({
  children,
  level,
  handleSelection,
  searchTerms,
}: {
  level: number
  children?: TreeNode[]
  searchTerms: string[]
  handleSelection: (id: string, indx: number) => void
}) => {
  const selected = searchTerms[level - 1]
  const hasChildren = children && children.length > 0
  const [skip, setSkip] = useState<number>(0)
  const [searchTerm, setSearchTerm] = useState<string>()

  const pageChange = (event: GridPageChangeEvent) => {
    setSkip(event.page.skip)
  }

  const handleClick = (id: string) => {
    handleSelection(id, level)
  }
  const changeHandler = (event) => {
    setSearchTerm(event.target.value)
  }
  const debouncedChangeHandler = useDebounce({ fn: changeHandler, time: 300 })
  const theme = useTheme()
  return (
    <>
      <GridLayoutItem key={level} className="grid-item" col={level}>
        <div className="search-box">
          <input
            type="text"
            name="search"
            placeholder="Search"
            onChange={debouncedChangeHandler}
          />
        </div>
        <OverflowGrid
          style={{
            backgroundColor: theme.palette.common.blue1,
            height: '500px',
            padding: 0,
            border: 0,
            overflow: 'auto',
            textTransform: 'capitalize',
          }}
          rowHeight={20}
          data={
            !searchTerm
              ? children?.slice(skip, skip + 20)
              : children
                  ?.filter((i) => i.label.toLowerCase().includes(searchTerm))
                  .slice(skip, skip + 20)
          }
          pageSize={20}
          total={children?.length}
          skip={skip}
          scrollable="virtual"
          onPageChange={pageChange}
          dataItemKey="id"
        >
          <Column
            field="id"
            title={undefined}
            headerCell={() => <div />}
            cell={(cellProps: GridCellProps) => (
              <td style={{ padding: 0, height: 'inherit' }}>
                <div
                  key={cellProps.dataItem.label}
                  className={`item-name ${
                    cellProps.dataItem.label === selected ? 'selected' : ''
                  }`}
                >
                  <Button
                    variant="inline"
                    fullWidth
                    style={{ padding: '0.25rem' }}
                    className="list-item"
                    onClick={() => handleClick(cellProps.dataItem.label)}
                  >
                    <span>
                      {cellProps.dataItem?.label
                        ?.replace(/-P$/, '')
                        ?.replace(/-C$/, '')
                        ?.replace(/px$/, 'price')
                        ?.replace(/oi$/, 'open interest')
                        ?.replace(/vol$/, 'Volume')}
                    </span>
                  </Button>
                </div>
              </td>
            )}
          />
        </OverflowGrid>
      </GridLayoutItem>
      {hasChildren &&
        children?.map(
          (i) =>
            i.label === selected &&
            i?.children &&
            i?.children?.length > 0 && (
              <TreeLeaf
                key={`${i.label}${level}`}
                level={level + 1}
                children={i.children}
                handleSelection={handleSelection}
                searchTerms={searchTerms}
              />
            ),
        )}
    </>
  )
}

const DataSearchModal = ({
  onSelect,
}: {
  onSelect: (s: string) => void
}): React.ReactElement => {
  const context = useContext(HistoricalAnalyzerContext)
  const [searchParams, setSearchParams] = useSearchParams()
  const searchQuery = searchParams.get('search')
  const searchTerms = searchQuery?.split('.') || []

  const liveCatalog = useContext(CatalogContext)

  const handleSelection = (id: string, index: number) => {
    const prevSearch = searchParams.get('search')?.split('.') || []

    if (prevSearch?.length < index) {
      setSearchParams({
        searchOpen: searchParams.get('searchOpen') || 'false',
        search: prevSearch.length > 0 ? `${prevSearch.join('.')}.${id}` : '',
      })
    } else {
      const newSearch = [...prevSearch.slice(0, index - 1), id]
      setSearchParams({
        searchOpen: searchParams.get('searchOpen') || 'false',
        search: newSearch ? `${newSearch.join('.')}` : '',
      })
    }
  }

  useEffect(() => {
    if (!searchQuery) {
      setSearchParams({
        searchOpen: searchParams.get('searchOpen') || 'false',
        search: 'deribit.future.BTC',
      })
    }
  }, [])

  useEffect(() => {
    document.body.style.overflow = 'hidden'
    return () => {
      document.body.style.overflow = 'unset'
    }
  }, [])

  const close = () => {
    setSearchParams({
      searchOpen: 'false',
      search: searchParams.get('search') || '',
    })
  }

  const stripUneccessaryInfo = (): string => {
    // strip out additional decorators
    if (
      searchTerms[1] === 'future' &&
      (searchTerms[3] === 'active' || searchTerms[3] === 'expired')
    ) {
      const [exchange, assetType, currency, active, ...rest] = searchTerms
      return [exchange, assetType, ...rest].join('.')
    }

    // Swapped in static catalog, need to swap back for API call
    if (
      searchTerms[1] === 'future' &&
      (searchTerms[3] === 'basis' || searchTerms[3] === 'annual')
    ) {
      const [exchange, assetType, currency, type, tenor, frequency, ...rest] =
        searchTerms
      return [
        exchange,
        assetType,
        currency,
        tenor,
        frequency,
        type,
        ...rest,
      ].join('.')
    }
    // strip out additional decorators
    if (
      searchTerms[1] === 'option' &&
      (searchTerms[3] === 'active' || searchTerms[3] === 'expired')
    ) {
      const [exchange, assetType, currency, active, type, ...rest] = searchTerms
      return [exchange, assetType, ...rest].join('.')
    }
    // Remove BSIV as just helps readability
    if (searchTerms[1] === 'option' && searchTerms[3] === 'BSIV') {
      const [exchange, assetType, currency, bsiv, ...rest] = searchTerms
      return [exchange, assetType, currency, ...rest].join('.')
    }
    // swap constant maturity for curve
    if (searchTerms[1] === 'future' && searchTerms[3] === 'constant maturity') {
      const [exchange, assetType, currency, cm, tenor, freq, ...rest] =
        searchTerms
      return [
        exchange,
        assetType,
        currency,
        tenor,
        freq,
        'curve',
        ...rest,
      ].join('.')
    }
    return searchTerms.join('.')
  }
  const updateChart = () => {
    onSelect(stripUneccessaryInfo())
    setSearchParams({
      searchOpen: 'false',
      search: searchParams.get('search') || '',
    })
  }
  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      close()
    }
  }

  return (
    <>
      <div className="data-search-main">
        <div className="modal-header">
          <div className="title">Data Search</div>
          <Button
            variant="inline"
            type="button"
            style={{ display: 'flex', alignItems: 'center' }}
            className="close-btn k-cursor-pointer"
            onKeyDown={handleKeyPress}
            onClick={close}
          >
            <CrossCircledIcon height={22} width={22} />
          </Button>
        </div>

        <div className="divider" />

        <div className="content">
          <GridLayout
            className="grid-layout"
            gap={{
              cols: 0,
            }}
          >
            {!liveCatalog.state.tree ? (
              <ChartLoader />
            ) : (
              <TreeLeaf
                key={`top-${1}`}
                children={liveCatalog.state.tree}
                level={1}
                handleSelection={handleSelection}
                searchTerms={searchTerms}
              />
            )}
          </GridLayout>
        </div>
        <div className="divider" />
        <div className="modal-footer">
          <Button
            disabled={
              // TODO SORT OUT THIS MESS
              searchTerms[searchTerms.length - 1] !== 'px' &&
              searchTerms[searchTerms.length - 1] !== 'oi' &&
              searchTerms[searchTerms.length - 1] !== 'sum' &&
              searchTerms[searchTerms.length - 1] !== 'index' &&
              searchTerms[searchTerms.length - 1] !== 'rate' &&
              searchTerms[searchTerms.length - 1] !== 'atm' &&
              searchTerms[searchTerms.length - 1] !== 'ratio' &&
              searchTerms[searchTerms.length - 1] !== 'pct' &&
              searchTerms[searchTerms.length - 1] !== 'a' &&
              searchTerms[searchTerms.length - 1] !== 'b' &&
              searchTerms[searchTerms.length - 1] !== 'rho' &&
              searchTerms[searchTerms.length - 1] !== 'm' &&
              searchTerms[searchTerms.length - 1] !== 'sigma' &&
              searchTerms[searchTerms.length - 1] !== 'volvol' &&
              searchTerms[searchTerms.length - 1] !== 'future' &&
              searchTerms[searchTerms.length - 2] !== 'index' &&
              searchTerms[searchTerms.length - 1] !== 'vol' &&
              !searchTerms[searchTerms.length - 1].includes('delta') &&
              !searchTerms[searchTerms.length - 1].endsWith('money') &&
              !searchTerms[searchTerms.length - 1].includes(
                searchTerms[3]?.toLowerCase(),
              )
            }
            onClick={updateChart}
          >
            Add
          </Button>
        </div>
      </div>
    </>
  )
}

export default DataSearchModal
