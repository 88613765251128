import AssetClass from './assetClass'
import { Chart } from './charts'
import { CardLayout } from './grid'

export enum DefaultCharts {
  HEATMAP_OPTION_IMPLIED_VOL = 'heatmap-option-implied-vol',
  HEATMAP_FUTURE = 'heatmap-future',
  OPTION_SKEW = 'option-skew',
  OPTION_ATM = 'option-atm-vol',
  OPTION_RATIO = 'option-ratio',
  OPTION_SMILE = 'option-smile',
  PERP_FUNDING_RATE = 'perpetual-funding-rate',
}
export enum DefaultTables {
  OPTION_PRICER = 'option-pricer',
}
interface BaseDashboardChart {
  id: string
  layout: CardLayout
  defaultId?: DefaultCharts | DefaultTables
  tenors?: string[]
}

interface BaseDashboardTable {
  id: string
  layout: CardLayout
  type: 'table'
  defaultId?: 'option-pricer'
}
interface DashboardTimeSeriesChart extends BaseDashboardChart {
  type: Chart.TIMESERIES | Chart.TIMESERIES_AREA | Chart.TIMESERIES_BAR
}
interface DashboardHeatmapChart extends BaseDashboardChart {
  type: Chart.HEATMAP
  assetClass: AssetClass
}
interface DashboardSmileChart extends BaseDashboardChart {
  type: Chart.SMILE
  assetClass: AssetClass.OPTION
  useLatestDatePicker: boolean
}
export type DashboardChart =
  | DashboardTimeSeriesChart
  | DashboardHeatmapChart
  | DashboardSmileChart

export interface Dashboard {
  id: string
  items: (DashboardChart | BaseDashboardTable)[]
}
