import { ReactNode, Attributes } from 'react'
import styled from 'styled-components'

export interface PageProps extends Attributes {
  children?: ReactNode
  mt?: number
  padding?: string
}

const Page = styled.div<PageProps>`
  border-radius: 10px;
  padding: ${({ padding }) => padding ?? '25px'};
  margin: 0 0 1rem 0;
  margin-top: ${({ mt }) => (mt ? `${mt}rem` : 0)};
  border: 1px solid #4b5056;
  border-radius: 4px;
  background: rgba(44, 48, 92, 0.22);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  // backdrop-filter: blur(1px);
  border: 1px solid rgba(26, 0, 30, 0.14);
`

export default Page
