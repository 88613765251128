import * as React from 'react'
import { Dropdown } from 'components/atoms'
import { Ticker } from 'types'
import { classNames } from '@progress/kendo-react-common'
import { useMemo } from 'react'

export interface ChangeTickerProps {
  selected?: Ticker
  handleSelection: (ticker: Ticker) => void
  className?: string
  labelClassName?: string
  supportedTickers?: Array<Ticker>
}

const DEFAULT_OPTIONS = Object.values(Ticker)

export const ChangeTicker = ({
  selected,
  handleSelection,
  className,
  labelClassName,
  supportedTickers,
}: ChangeTickerProps): React.ReactElement => {
  const options = useMemo(() => {
    const items = supportedTickers?.length ? supportedTickers : DEFAULT_OPTIONS
    return items.map((item) => ({
      name: item,
      value: item,
    }))
  }, [supportedTickers])
  return (
    <Dropdown
      showLabel
      current={options.find(({ value }) => value === selected)}
      options={options}
      onChange={handleSelection}
      className={className}
      labelClassName={classNames('k-mr-2', labelClassName)}
      label="Ticker"
    />
  )
}
